import * as React from "react";
import { FastField } from "formik";
import { UI } from "../../../../../../../core/ui/index";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import cloneDeep from "lodash/cloneDeep";
import { Switch } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { RestaurantUtils } from "@lib/common";
import { SelectAdv } from "@lib/components";
import { Howl } from "howler";
import { config } from "../../../../../../../config";
import { Input } from "@lib/components";

interface Props { }
type FormValues = T.Schema.Restaurant.RestaurantSettingsNotificationsAudio;

@inject("store") @observer
export class SettingsFormNotificationsAudio extends MobxComponent<Props, {}> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.initialValues = cloneDeep(r.settings.notifications.audio) || RestaurantUtils.settings.defaultAudioNotifications();
	}

	play = (sound: string) => {
		if (!sound) return;
		const s = new Howl({
			src: [`${config.urls.cloudfront}/assets/audio/${sound.split(".")[0] + ".mp3"}`],
		});
		s.play();
		console.log("PLAY");
	}

	render() {
		return (
			<div>

				<RestaurantForm<FormValues>
					submit={async (r, v) => {
						r.settings.notifications.audio = v;
						const update = { $set: { "settings.notifications.audio": v } };
						return { r, update };
					}}
					initialValues={this.initialValues}
					onError={() => UI.notification.error("An error occurred")}
					onSuccessMessage="Settings Updated"
				>
					{({ form, error }) => {
						const { isSubmitting, setFieldValue } = form;
						return (
							<div className="p-4">

								<FastField
									name="sound"
									render={({ field }: any) => (
										<FormGroup
											title="Sound"
											help={<span>Choose which sound to play for notifications. <span className="underline cursor" onClick={() => this.play(field.value)}>Test sound</span></span>}>
											<SelectAdv
												type="single"
												options={[
													{ value: "", label: "None" },
													{ value: "job-done.ogg", label: "Default" },
													{ value: "filling-your-inbox.ogg", label: "Filling Your Inbox" },
													{ value: "hand-bell.ogg", label: "Hand Bell" },
													{ value: "tiny-bell.ogg", label: "Tiny Bell" },
													{ value: "plucky.ogg", label: "Plucky" },
													{ value: "oringz-w429.ogg", label: "O-Ringz" },
												]}
												value={field.value}
												onChange={(option: string) => setFieldValue("sound", option)}
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="repeat_count"
									render={({ field }: any) => (
										<FormGroup
											title="Repeat Count"
											help="The number of times the sound should be played">
											<Input type="number" min="1" step="1" {...field} />
										</FormGroup>
									)}
								/>

								<FastField
									name="stop_on_click"
									render={({ field }: any) => (
										<FormGroup
											title="Stop On Click"
											help="Stop playing the audio notification as soon as the screen is clicked or tapped. You can set the repeat count to a high number to continuously play the sound until there is a screen interaction">
											<Switch
												id="audio-stop-on-click"
												checked={field.value}
												onChange={(e) => setFieldValue("stop_on_click", e.target.checked)}
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="play_on.order_new"
									render={({ field }: any) => (
										<FormGroup
											title="Play On - New Order"
											help="Enable or disable the audio notification for new orders">
											<Switch
												id="audio-play_on.order_new"
												checked={field.value}
												onChange={(e) => setFieldValue("play_on.order_new", e.target.checked)}
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="play_on.booking_new"
									render={({ field }: any) => (
										<FormGroup
											title="Play On - New Booking"
											help="Enable or disable the audio notification for new bookings">
											<Switch
												id="audio-play_on.booking_new"
												checked={field.value}
												onChange={(e) => setFieldValue("play_on.booking_new", e.target.checked)}
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="play_on.customer_age_verification"
									render={({ field }: any) => (
										<FormGroup
											title="Play On - Customer Age Verification Request"
											help="Enable or disable the audio notification for customer age verification requests">
											<Switch
												id="audio-play_on.customer_age_verification"
												checked={field.value}
												onChange={(e) => setFieldValue("play_on.customer_age_verification", e.target.checked)}
											/>
										</FormGroup>
									)}
								/>

								{error && <FormGroup error={error} />}

								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>

							</div>
						);
					}}
				</RestaurantForm>

			</div>
		);

	}

}
