import * as React from "react";
import posed from "react-pose";
import { styled, css } from "@lib/components";
import { CS } from "@lib/components";
import { Box } from "@lib/components";


const WrapperPose = posed.div({

})
const Wrapper = styled(WrapperPose)`
 width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: ${(props) => props.bg_color};
  ::before{
	background-image: url(${(props) => props.bg_img});
	opacity: 0.4;
	width: 100%;
	height: 100%;
	position: absolute;
	content: "";
	background-size: cover;
	};
`;


const MainPose = posed.div({
	visible: {
		opacity: 1,
		translateY: 0,
	},
	hidden: {
		opacity: 0,
		translateY: 14,

	},
});

const Main = styled(MainPose)`
  ${CS.flex.center};
  padding: 20px;
  min-height: 90vh;
`;
const BoxWrapperPose = posed.div({
})

const BoxWrapper = styled(BoxWrapperPose)`
  background-color: ${(props) => props.boxStyle === 'expanded' ? "white" : ""};
  padding: ${(props) => props.boxStyle === 'expanded' ? "75px 63px" : ""};
  z-index: 9;
`;

const Content = styled("div")`
  width: 360px;
  margin: 0 auto;
  z-index: 90;

`;

const Logo = styled("div")`
  text-align: center;
  margin-bottom: 30px;
  img {
    max-height: 100px;
  }
  z-index: 90;
`;

const ContentBox = styled(Box)`
 border-radius: ${(props) => props.boxStyle === 'expanded' ? "0px" : "3px"};
  border: ${(props) => props.boxStyle === 'expanded' ? "0px" : ""};
  box-shadow: ${(props) => props.boxStyle === 'expanded' ? "0px" : "0 4px 36px rgba(0, 0, 0, 0.2)"};
  .box-loader {
    border-radius: ${(props) => props.boxStyle === 'expanded' ? "0px" : "3px"};
  }
  z-index: 90;
`;

export const AuthLayoutComponents = {
	Main, Content, Logo, ContentBox, Wrapper, BoxWrapper
};
