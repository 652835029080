import React from 'react';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Field, FastField, FieldProps, FastFieldProps } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, SelectAdv, Input } from '@lib/components';
import { PaymentBaseFields } from './base';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { config } from '../../../../../../../config';

interface Props {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentVivaWallet;

const validationSchema = Yup.object().shape({
  env: Yup.string().required(),
  merchant_id: Yup.string().required(),
  api_key: Yup.string().required(),
  oauth_client_id: Yup.string().required(),
  oauth_client_secret: Yup.string().required(),
  is_isv_partner: Yup.boolean(),
  isv_amount: Yup.number().min(0),
});

const WebhookHelp = () => {
  return (
    <>
      <div>
        Please copy this URL and paste it on Viva Wallet Portal - API Access {'>'} Webhooks section when configuring
        your Viva Wallet Webhook URL.
      </div>
      <div>Make sure to configure the Webhooks for following events:</div>
      <ul>
        <li>Transaction Payment Created</li>
        <li>Transaction Failed</li>
        <li>Transaction Reversal Created</li>
      </ul>
    </>
  );
};

@inject('store')
@observer
export class SettingsFormPaymentsVivaWallet extends MobxComponent<Props, {}> {
  initialValues: FormValues;
  webhookUrl: string;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const restaurant = this.injected.store.restaurant!;
    this.webhookUrl = `${config.urls.api2}/v1/viva-wallet/webhook/${restaurant._id}`;
    this.initialValues = restaurant.settings.payments.viva_wallet || {
      enabled: false,
      label: 'Viva Wallet',
      services: [],
      env: 'production',
      merchant_id: '',
      api_key: '',
      oauth_client_id: '',
      oauth_client_secret: '',
      is_isv_partner: false,
      isv_amount: 0,
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.viva_wallet = values;
          const update = {
            $set: {
              'settings.payments.viva_wallet': r.settings.payments.viva_wallet,
            },
          };

          return { r, update };
        }}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, getFieldError }) => {
          const { isSubmitting, setFieldValue, errors } = form;
          return (
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: FastFieldProps<boolean>) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using Viva Wallet payment method.">
                    <Switch
                      id="viva-wallet-enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="env"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="Environment"
                    help="Use Production environment to accept real payments. Sandbox environment is used to test your integration."
                    error={getFieldError(form, 'env')}
                  >
                    <SelectAdv
                      type="single"
                      value={field.value}
                      onChange={(env: string) => setFieldValue('env', env)}
                      options={[
                        { label: 'Production', value: 'production' },
                        { label: 'Sandbox', value: 'sandbox' },
                      ]}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="merchant_id"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="Merchant ID"
                    help="Viva Wallet Merchant ID. You can obtain this from Viva Wallet admin dashboard 'Settings > API Access' section."
                    error={getFieldError(form, 'merchant_id')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('merchant_id', e.target.value)
                      }
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="api_key"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="API Key"
                    help="Viva Wallet API Key. You can obtain this from Viva Wallet admin dashboard 'Settings > API Access' section."
                    error={getFieldError(form, 'api_key')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('api_key', e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="is_isv_partner"
                render={({ field }: FastFieldProps<boolean>) => (
                  <FormGroup
                    optional
                    title="ISV Partner"
                    help="Is part of Independent Software Vendor (ISV) Partner Program?"
                  >
                    <Switch
                      id="viva-wallet-isv-partner-switch"
                      checked={field.value}
                      onChange={e => {
                        const checked = e.target.checked;
                        setFieldValue('is_isv_partner', e.target.checked);
                        if (!checked) {
                          setFieldValue('isv_amount', 0);
                        }
                      }}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="isv_amount"
                render={({ field }: FastFieldProps<number>) => (
                  <FormGroup optional title="ISV Amount" help="The amount which will be paid out to the ISV partner.">
                    <Input {...field} type="number" value={field.value || ''} step="0.1" />
                  </FormGroup>
                )}
              />

              <Field
                name="oauth_client_id"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="OAuth 2.0 Client ID"
                    help="Viva Wallet OAuth 2.0 credentials, use correct value for your current environment."
                    error={getFieldError(form, 'oauth_client_id')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('oauth_client_id', e.target.value)
                      }
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="oauth_client_secret"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="OAuth 2.0 Client Secret"
                    help="Viva Wallet OAuth 2.0 credentials, use correct value for your current environment."
                    error={getFieldError(form, 'oauth_client_secret')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('oauth_client_secret', e.target.value)
                      }
                    />
                  </FormGroup>
                )}
              />

              <FormGroup title="Webhook URI" help={<WebhookHelp />}>
                <Input id="webhook-url-input" type="text" value={this.webhookUrl} readOnly={true} />

                <Button
                  type="button"
                  color="primary"
                  size="xs"
                  className="m-t-2"
                  onClick={() => {
                    const input = document.querySelector('#webhook-url-input');
                    if (input) {
                      // @ts-ignore
                      input.select();
                      document.execCommand('copy');
                    }
                  }}
                >
                  Copy URL
                </Button>
              </FormGroup>

              <PaymentBaseFields defaultLabel="Viva Wallet" setFieldValue={setFieldValue} />

              {errors && Object.keys(errors).length > 0 && (
                <FormGroup no_border error="Validation failed. Please check Viva Wallet configurations again." />
              )}

              <Button full color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
