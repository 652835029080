import * as React from "react";
import { Button } from "@lib/components";

interface Props extends T.Lib.Form.Button.ButtonProps {}

export const MenuListButton = (props: Props) => (
	<div className="list-item">
		<Button full={true} color="transparent-with-border" {...props}>
			{props.children}
		</Button>
	</div>
);
