import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { ColorPicker } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";

interface Props { }
interface State { }
type FormValues = T.Schema.Website.WebsiteSchema["colors"];

@inject("store") @observer
export class WebsiteFormColors extends MobxComponent<Props, State> {
	initialValues: FormValues;
	constructor(props: Props) {
		super(props);
		const w = this.injected.store.website!;
		this.state = {};
		this.initialValues = w.colors;
	}
	render() {
		const col_props = {
			className: "col",
			small_title: true,
			no_border: true,
		};
		return (
			<WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.colors = values;
					const update = { $set: { "colors": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FormGroup contentClassName="grid-2 sm sm-gap">

								<FastField
									name="primary"
									render={({ field }: any) => (
										<FormGroup title="Primary" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("primary", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="primary_text"
									render={({ field }: any) => (
										<FormGroup title="Primary Text" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("primary_text", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="background"
									render={({ field }: any) => (
										<FormGroup title="Background" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("background", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="text"
									render={({ field }: any) => (
										<FormGroup title="Text" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("text", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="box"
									render={({ field }: any) => (
										<FormGroup title="Box & Popup Background" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("box", color)} />
										</FormGroup>
									)}
								/>

								<FastField
									name="box_text"
									render={({ field }: any) => (
										<FormGroup title="Box & Popup Text" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("box_text", color)} />
										</FormGroup>
									)}
								/>

							</FormGroup>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</WebsiteForm>
		);
	}
}
