import React from 'react';
import styled from 'styled-components';
import { CommonText } from '@lib/components';

type PropsType = {
  totalItems: number | undefined;
  totalPausedItems: number | undefined;
};

const ItemCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const addZero = (num: number | undefined) => {
  if (num && num < 10) {
    return `0${num.toString()}`
  }
  if (num && num >= 10) {
    return `${num.toString()}`
  }
  return '0';
};

export const PausedItemsCountTag = (props: PropsType) => {
  const { totalItems = 0, totalPausedItems = 0 } = props;
  return (
    <ItemCountWrapper>
      <CommonText
        size="small"
        weight={700}
        color="#BC362F"
        style={{
          marginRight: '12px',
        }}
      >
        {addZero(totalPausedItems)}/{addZero(totalItems)}
      </CommonText>
      <CommonText
        size="small"
        weight={700}
        color="#BC362F"
        style={{
          marginRight: '24px',
        }}
      >
        Items Paused
      </CommonText>
    </ItemCountWrapper>
  );
};
