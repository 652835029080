import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import moment from "moment";
import { Box, BoxHeading, BoxSection, Button, FormGroup, Input, RotateLoader } from "@lib/components";
import { MobxComponent } from "../../../../../../mobx/components";
import { UI } from "../../../../../../core/ui";
import { config } from "../../../../../../config";
import { FaPlug, FaSync } from "react-icons/fa";

interface Props {
	restaurant: T.Schema.Restaurant.RestaurantSchema | null;
	oauthApp: T.Schema.Restaurant.Integrations.OAuthApp;
}

interface State {
	refreshingToken: boolean;
}

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    margin-right: 5px;
  }
`

@inject("store") @observer
export class OAuthAppTokenManager extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			refreshingToken: false,
		};
	}

	refreshCurrentAccessToken = async (e: any) => {
		this.setState({ refreshingToken: true })
		const refreshToken = this.props.oauthApp.tokens!.refresh_token;
		const response = await this.injected.store.api.request({
			path: '/oauth/token/refresh',
			data: {
				tokenEndpoint: this.props.oauthApp.token_url,
				clientID: this.props.oauthApp.client_id,
				clientSecret: this.props.oauthApp.client_secret,
				refreshToken: refreshToken,
				restaurantID: this.props.restaurant!._id,
				baseAppID: this.props.oauthApp!.base_app_id,
			}
		});

		if (response.outcome === 1) {
			UI.notification.error(response.message);
		} else {
			UI.notification.success(response.message);
		}

		await this.injected.store.service.restaurant.get();

		this.setState({ refreshingToken: false })
	}

	render() {
		const accessToken = this.props.oauthApp.tokens!.access_token;
		const refreshToken = this.props.oauthApp.tokens!.refresh_token;
		const accessTokenExpiresAt = this.props.oauthApp.tokens!.expires_at || 0;
		let accessTokenHelp = '', accessTokenError = '';
		if (accessTokenExpiresAt > 0) {
			if (moment.unix(accessTokenExpiresAt).isSameOrBefore(moment())) {
				accessTokenError = 'Access token expired.'
			} else {
				accessTokenHelp = `Access token will be expired ${moment.unix(accessTokenExpiresAt).fromNow()}`;
			}
		}

		return (
			<Box style={{ marginBottom: '2rem' }}>
				<BoxHeading>Token Manager</BoxHeading>
				<BoxSection>
					{accessToken && (
						<FormGroup
							title="Access Token"
							help={accessTokenHelp}
							error={accessTokenError}
							no_border={true}>
							<Input type="text" value={accessToken} readOnly={true} />
						</FormGroup>
					)}

					{refreshToken && (
						<FormGroup
							title="Refresh Token"
							help="This token will be used to get new access token if the current access token is expired. You may click the 'Refresh The Token' button to manually refresh the current acess token."
							no_border={true}>
							<Input type="text" value={refreshToken} readOnly={true} />
						</FormGroup>
					)}

					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Button
							size="sm"
							full={false}
							color="primary"
							type="button"
							style={{ marginRight: '1rem' }}
							onClick={() => window.location.href = `${config.urls.api}/oauth/redirect?restaurantID=${this.props.restaurant!._id}&baseAppID=${this.props.oauthApp.base_app_id}&origin=${window.location.origin}`}>
							<ButtonText>
								<FaPlug /> <span>Connect</span>
							</ButtonText>
						</Button>

						{refreshToken && (
							<Button
								size="sm"
								full={false}
								color="primary"
								type="button"
								onClick={this.refreshCurrentAccessToken}
								disabled={this.state.refreshingToken}>
								{this.state.refreshingToken && <RotateLoader size={2} color="white" />}
								{!this.state.refreshingToken && (<ButtonText><FaSync /> <span>Refresh The Token</span></ButtonText>)}
							</Button>
						)}
					</div>
				</BoxSection>
			</Box>
		);
	}
}
