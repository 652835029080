import React from 'react';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Field, FastField, FieldProps, FastFieldProps } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, SelectAdv, Input } from '@lib/components';
import { PaymentBaseFields } from './base';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentCabbagePay;

const validationSchema = Yup.object().shape({
  env: Yup.string().required(),
  client_id: Yup.string().required(),
  client_secret: Yup.string().required(),
  connected_account: Yup.string().required(),
});

@inject('store')
@observer
export class SettingsFormPaymentsCabbagePay extends MobxComponent<Props, {}> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.cabbagepay || {
      enabled: false,
      label: 'CabbagePay',
      services: [],
      env: 'production',
      client_id: '',
      client_secret: '',
      connected_account: '',
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.cabbagepay = values;
          const update = {
            $set: {
              'settings.payments.cabbagepay': r.settings.payments.cabbagepay,
            },
          };

          return { r, update };
        }}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, getFieldError }) => {
          const { isSubmitting, setFieldValue, errors } = form;
          return (
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: FastFieldProps<boolean>) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using CabbagePay payment method.">
                    <Switch
                      id="cabbagepay-enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="env"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="CabbagePay Environment"
                    help="Use Production environment to accept real payments. Sandbox environment is used to test your integration."
                    error={getFieldError(form, 'env')}
                  >
                    <SelectAdv
                      type="single"
                      value={field.value}
                      onChange={(env: string) => setFieldValue('env', env)}
                      options={[
                        { label: 'CabbagePay Production', value: 'production' },
                        { label: 'CabbagePay Sandbox', value: 'sandbox' },
                      ]}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="client_id"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="CabbagePay Client ID"
                    small_title
                    no_border
                    error={getFieldError(form, 'client_id')}
                  >
                    <Input
                      type="text"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('client_id', e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="client_secret"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="CabbagePay Secret Key"
                    small_title
                    no_border
                    error={getFieldError(form, 'client_secret')}
                  >
                    <Input
                      type="text"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('client_secret', e.target.value)
                      }
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="connected_account"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="CabbagePay Connected Account"
                    small_title
                    no_border
                    error={getFieldError(form, 'connected_account')}
                  >
                    <Input
                      type="text"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('connected_account', e.target.value)
                      }
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="CabbagePay" setFieldValue={setFieldValue} />

              {errors && Object.keys(errors).length > 0 && (
                <FormGroup no_border error="Validation failed. Please check CabbagePay configurations again." />
              )}

              <Button full color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
