import * as React from 'react';
import { FastField, Field } from 'formik';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { FormGroup, LinkTag, SelectAdv, styled } from '@lib/components';
import { Button } from '@lib/components';
import { RotateLoader } from '@lib/components';
import { Input } from '@lib/components';
import { Switch } from '@lib/components';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { config } from '../../../../../../../config';
import Lalamove from '@lib/lalamove';
import { Market } from '@lib/lalamove/types/lib';

interface Props {
  close?: () => void;
}
interface State {
  market: Market | null;
}

type FormValues =
  T.Schema.Restaurant.RestaurantSchema['settings']['services']['delivery']['providers'];

const ErrorBox = styled.div`
  color: red;
  padding: 4px 0;
`;

@inject('store')
@observer
export class SettingsFormIntegrationLalamove extends MobxComponent<
  Props,
  State
> {
  initialValues: FormValues;
  webhookUrl: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      market: null,
    };
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.services.delivery.providers;
    this.webhookUrl = `${config.urls.api2}/v1/lalamove/webhook`;
  }

  validateRequiredField(value: string) {
    let error;
    if (_isEmpty(value)) {
      error = 'This field value cannot be empty.';
    }
    return error;
  }

  getError(errors: any, key: string): string {
    if (errors && errors.lalamove) {
      const message = _get(errors.lalamove, key, '');
      return _isEmpty(message) ? '' : message;
    }
    return '';
  }

  render() {
    let currentMarket =
      this.initialValues?.lalamove?.market || this.state.market;
    let services = currentMarket
      ? Lalamove.serviceTypesByMarket(currentMarket)
      : [];

    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.services.delivery.providers = values;
          const update = {
            $set: {
              'settings.services.delivery.providers':
                r.settings.services.delivery.providers,
            },
          };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onSuccess={() => {
          if (this.props.close) {
            this.props.close();
          }
        }}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue, errors } = form;
          return (
            <>
              <FastField
                name="lalamove.enabled"
                render={({ field }: any) => (
                  <FormGroup
                    title="Enabled"
                    help="Enabling this will automatically trigger the Lalamove delivery process for new orders."
                  >
                    <Switch
                      id="lalamove-enabled-switch"
                      checked={field.value || false}
                      onChange={e =>
                        setFieldValue('lalamove.enabled', e.target.checked)
                      }
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="lalamove.api_key"
                validate={this.validateRequiredField}
                render={({ field }: any) => (
                  <FormGroup
                    title="Lalamove API Key"
                    help="Your Lalamove API key. This key is issued by Lalamove after you registered as a partner."
                  >
                    <Input type="text" {...field} value={field.value || ''} />
                    {this.getError(errors, 'api_key') && (
                      <ErrorBox>{this.getError(errors, 'api_key')}</ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <FastField
                name="lalamove.api_secret"
                validate={this.validateRequiredField}
                render={({ field }: any) => (
                  <FormGroup
                    title="Lalamove API Secret"
                    help="Your Lalamove API secret. This key is issued by Lalamove after you registered as a partner."
                  >
                    <Input type="text" {...field} value={field.value || ''} />
                    {this.getError(errors, 'api_secret') && (
                      <ErrorBox>{this.getError(errors, 'api_secret')}</ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <FastField
                name="lalamove.market"
                validate={this.validateRequiredField}
                render={({ field }: any) => (
                  <FormGroup
                    title="Region"
                    help={
                      <span>
                        Select the market for your integration. Please refer to
                        this{' '}
                        <LinkTag
                          href="https://developers.lalamove.com/#available-markets"
                          target="_blank"
                        >
                          link
                        </LinkTag>{' '}
                        for more information.
                      </span>
                    }
                  >
                    <SelectAdv
                      type="single"
                      id="lalamove_market"
                      options={Lalamove.MARKETS}
                      value={field.value || ''}
                      onChange={(option: string) => {
                        this.setState({ market: option as Market });
                        setFieldValue('lalamove.market', option);
                        setFieldValue('lalamove.service_types', []);
                      }}
                    />
                    {this.getError(errors, 'market') && (
                      <ErrorBox>{this.getError(errors, 'market')}</ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <Field
                name="lalamove.service_types"
                validate={this.validateRequiredField}
                render={({ field }: any) => (
                  <FormGroup
                    title="Service Types"
                    help="Select service types that are applicable to your business."
                  >
                    <SelectAdv
                      type="multi"
                      id="lalamove_service_types"
                      value={field.value || []}
                      options={services}
                      onChange={(selectedValues: string[]) => {
                        setFieldValue('lalamove.service_types', selectedValues);
                      }}
                    />
                    {this.getError(errors, 'service_types') && (
                      <ErrorBox>
                        {this.getError(errors, 'service_types')}
                      </ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <FormGroup
                title="Webhook URI"
                help="Please copy this URL and paste it on Lalamove Partner Portal - Webhooks section when configuring your Lalamove Webhook URL."
              >
                <Input
                  id="webhook-url-input"
                  type="text"
                  value={this.webhookUrl}
                  readOnly={true}
                />

                <Button
                  type="button"
                  color="primary"
                  size="xs"
                  className="m-t-2"
                  onClick={() => {
                    const input = document.querySelector('#webhook-url-input');
                    if (input) {
                      // @ts-ignore
                      input.select();
                      document.execCommand('copy');
                    }
                  }}
                >
                  Copy URL
                </Button>
              </FormGroup>

              <FastField
                name="lalamove.pickup_phone_number"
                validate={this.validateRequiredField}
                render={({ field }: any) => (
                  <FormGroup
                    title="Pickup Phone Number"
                    help="Your store phone number. Lalamove uses this phone number when creating new delivery. This phone number will be used by driver to reach your store."
                  >
                    <Input type="tel" {...field} value={field.value || ''} />
                    {this.getError(errors, 'pickup_phone_number') && (
                      <ErrorBox>
                        {this.getError(errors, 'pickup_phone_number')}
                      </ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <FastField
                name="lalamove.test_mode"
                render={({ field }: any) => (
                  <FormGroup
                    title="Test mode"
                    help="Enable this to use Lalamove in Test Mode. You should use this setting when you want to test the Lalamove delivery before switching to production."
                  >
                    <Switch
                      id="lalamove_test_mode-switch"
                      checked={field.value || false}
                      onChange={e =>
                        setFieldValue('lalamove.test_mode', e.target.checked)
                      }
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="lalamove.enable_pod"
                render={({ field }: any) => (
                  <FormGroup
                    title="Enable Proof Of Delivery (POD)"
                    help="Proof Of Delivery (POD) is a request that you can put when you placed the order.
										If you submit an order requesting POD, the driver will be notified to get signature(s)
										from the recipient(s) (or their delegates) as a proof of a success delivery."
                  >
                    <Switch
                      id="lalamove-enabled-pod-switch"
                      checked={field.value || false}
                      onChange={e =>
                        setFieldValue('lalamove.enable_pod', e.target.checked)
                      }
                    />
                  </FormGroup>
                )}
              />

              {/* <FastField
								name="lalamove.allow_priority_fee"
								render={({ field }: any) => (
									<FormGroup
										title="Allow Priority Fee"
										help="Allow adding Priority fees (commonly known as tips) which are
										often used by customers to encourage drivers to accept an order. "
									>
										<Switch
											id="lalamove-allow-priority-fee"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"lalamove.allow_priority_fee",
													e.target.checked
												)
											}
										/>
									</FormGroup>
								)}
							/> */}

              {error && <FormGroup error={error} />}

              <Button
                full={true}
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </>
          );
        }}
      </RestaurantForm>
    );
  }
}
