import store from "store";

export const ExpiryCache = {
	set(key: string, val: T.ObjectAny, expiry: number) {
		store.set(`expiry-cache:${key}`, JSON.stringify({
			...val,
			expiry: (Date.now() + expiry),
		}));
	},
	get(key: string) {
		const val = store.get(`expiry-cache:${key}`);
		return val ? JSON.parse(val) : null;
	},
	clearExpired() {
		store.each((value, key) => {
			if (key.indexOf("expiry-cache") !== -1) {
				value = JSON.parse(value);
				if (Date.now() > value.expiry) {
					store.remove(key);
				}
			}
		});
	},
};
