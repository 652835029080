import shortid from 'shortid';

export const pizzariaMenu = [
    {
      "_id": "qCXSSHgPN",
      "name": "Pizzaria Menu",
      "display_name": "",
      "description": "",
      "conditions": {
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "9I-pk8AwB",
          "menu_id": "qCXSSHgPN",
          "name": "STUZZICHINI E ANTIPASTI",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "fSvFrjQL7",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "FOCACCIA",
              "display_name": "",
              "description": "herbs and salt or garlic ",
              "subtitle": "",
              "price": 16,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "bee9ffab-bcdf-43f5-885b-851d28390003",
                "name": "aea5282a_1421_4bbc_92f7_5bcf0355a511.JPG",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "EHKXpL7PpY",
                "7NS_A8Zm_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HS36rzYu8",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "FOCACCIA GARLIC, HERBS AND CHEESE",
              "display_name": "",
              "description": "Garlic and mozzarella cheese focaccia",
              "subtitle": "",
              "price": 19,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "7444cfda-a135-4d4c-85ac-8ccf3998bb0c",
                "name": "094abec2_36bc_46d2_b23a_dbefb8476493__1_.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "7NS_A8Zm_",
                "XnL17jx9-"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "VnuRLajYf",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "FOCACCIA COSACCA ",
              "display_name": "",
              "description": "Focaccia with a touch of tomato and parmesan cheese",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "1f723e8f-8996-4178-9ee4-122175aa9f6b",
                "name": "tempImagemXRLZf.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "XnL17jx9-"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EhokMJMbj",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "ARANCINI ALLA NORMA ",
              "display_name": "",
              "description": "Rice balls with Napoli sauce, filled with salted ricotta, eggplants and mozzarella cheese",
              "subtitle": "",
              "price": 19,
              "tags": [
                "5uTgiye4-",
                "617DnJ14jM"
              ],
              "image": {
                "_id": "b7de3252-e146-407d-abe5-63f9ebc8cfc2",
                "name": "IMG_5632.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "PtTh0gbsN",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "POLPETTE DI CARNE",
              "display_name": "",
              "description": "Traditional beef meat balls served with rich Napoli sauce,  basil and parmesan cheese",
              "subtitle": "",
              "price": 20,
              "tags": [
                "2GOlnYmtC",
                "5uTgiye4-"
              ],
              "image": {
                "_id": "629adea4-c2bb-406b-9da3-39218b6e2387",
                "name": "IMG_5639.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY",
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "jKKwQY-Or",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "CALAMARI FRITTI  ",
              "display_name": "",
              "description": "Deep fried calamari with  aioli",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": {
                "_id": "31233665-b205-4d94-8caa-81a679e3a5fd",
                "name": "IMG_2433.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "McBn8r-8y",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "PANE E OLIVE",
              "display_name": "",
              "description": "Warm mixed olives served with home made bread",
              "subtitle": "",
              "price": 12,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "4fdda8cf-fed1-4712-a203-c40ca89ccb65",
                "name": "tempImageTK5Wdb.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY",
                "7NS_A8Zm_",
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "tOuKu9JC8",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "PROSCIUTTO E BUFALA",
              "display_name": "",
              "description": "Prosciutto di Parma and Buffalo mozzarella served with home made bread",
              "subtitle": "",
              "price": 22,
              "tags": [
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "2e2c1473-bfd4-4c08-b6ae-fb61b1a3c616",
                "name": "tempImageUsFYKJ.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "t-2kiSsT0",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "ARANCINI DELL'ESTATE ",
              "display_name": "",
              "description": "Rice balls with zucchini sauce, filled with zucchini, mint, Provolone Valpadana DOP cheese, served with parmesan sauce  ",
              "subtitle": "",
              "price": 19,
              "tags": [],
              "image": {
                "_id": "c7e5758c-fbbf-42ff-8c68-13cb40914b24",
                "name": "tempImageKdiY8N.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "zaGoN9Alm",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "CARPACCIO DI PESCE SPADA ",
              "display_name": "",
              "description": "Cured Swardfish carpaccio in orange osmosi, served with bisque mayo, parsley microsponge and caperberrie",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": {
                "_id": "7afbb7d9-fd9e-48d2-a77f-0f944d4f1d9c",
                "name": "tempImageQjRC7F.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_rlZBk-JN",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "POLENTA FRITTA ",
              "display_name": "",
              "description": "Fried polenta on peas pureè, served with parmesan sauce, chutney tomatoes and Maldon salt",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "46b3e154-0c8e-4660-8615-57c9c6f29d24",
                "name": "tempImageSO0CuV.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_CkQ__ba7",
              "menu_id": "qCXSSHgPN",
              "category_id": "9I-pk8AwB",
              "name": "BURRATA E MELANZANE ",
              "display_name": "",
              "description": "Burrata 125gr served with smoked eggplant pureè, eggplant chips, basil oil reduction and tomato powder",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "bc9d8c16-22a6-40cb-a5ab-e2ab40ffb050",
                "name": "tempImageadhvEP.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "3VGotV15y",
          "menu_id": "qCXSSHgPN",
          "name": "PASTE",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "JhoQxzoGc",
              "menu_id": "qCXSSHgPN",
              "category_id": "3VGotV15y",
              "name": "LASAGNA CLASSICA",
              "display_name": "",
              "description": "Home made lasagna with béchamel and beef bolognese sauce",
              "subtitle": "",
              "price": 30,
              "tags": [
                "5uTgiye4-"
              ],
              "image": {
                "_id": "9828929e-6713-4341-8b18-49ae9852e15d",
                "name": "Il-Caminetto-Uber-2.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "9juCuMeY2",
              "menu_id": "qCXSSHgPN",
              "category_id": "3VGotV15y",
              "name": "GNOCCHI BOLOGNESE",
              "display_name": "",
              "description": "Home made potato gnocchi served with beef ragu' sauce",
              "subtitle": "",
              "price": 30,
              "tags": [
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "4da193ba-752e-4437-a14e-f49abd6e0621",
                "name": "tempImagetQDZgJ.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY",
                "-2Jz5p3NV"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "qVL3-EmjO",
              "menu_id": "qCXSSHgPN",
              "category_id": "3VGotV15y",
              "name": "BOTTONI ALL'ITALIANA ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 34,
              "tags": [],
              "image": {
                "_id": "bed9653a-6af5-45d5-801b-85daccfdad16",
                "name": "tempImagea57ca3.heic",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "FEmbWMaf6",
              "menu_id": "qCXSSHgPN",
              "category_id": "3VGotV15y",
              "name": "CARBONARA DI MARE",
              "display_name": "",
              "description": "Linguine served with bisque, clams, mixed fish, prawns, carbonara mix (egg yolks and parmesan) and parsley",
              "subtitle": "",
              "price": 39,
              "tags": [],
              "image": {
                "_id": "ce7c42d7-a311-4e59-a349-0ba5f8577ea8",
                "name": "tempImage0Wv0P7.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "-2Jz5p3NV"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "82k2ZACxB",
              "menu_id": "qCXSSHgPN",
              "category_id": "3VGotV15y",
              "name": "RISOTTO ALLE BARBABIETOLE ",
              "display_name": "",
              "description": "Beetroot risotto topped with asparagus pureè, goat's curd cheese, diced beetroot and sorrel",
              "subtitle": "",
              "price": 31,
              "tags": [],
              "image": {
                "_id": "d24d1771-3de4-400a-b55f-4172008f72f4",
                "name": "tempImageqiZI0V.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY",
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SRwK5dBlI",
              "menu_id": "qCXSSHgPN",
              "category_id": "3VGotV15y",
              "name": "TAGLIATELLE CREMOSE AI 4 COLORI ",
              "display_name": "",
              "description": "Home made green and white tagliatelle, served with smoked eggplant pureè, parmesan sauce, roasted cherry tomatoes sauce, house made semi dried cherry tomatoes, topped with basil breadcrumbs",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "76bdc3e1-a373-46c7-b96c-123da652a8a6",
                "name": "tempImageJQ21vQ.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "IM6k0LJY8",
              "menu_id": "qCXSSHgPN",
              "category_id": "3VGotV15y",
              "name": "STROZZAPRETI ALLA  TRAPANESE ",
              "display_name": "",
              "description": "Home made strozzapreti pasta served with crispy pancetta, pesto trapanese (almond, basil, tomatoes and garlic) topped with basil breadcrumbs",
              "subtitle": "",
              "price": 32,
              "tags": [],
              "image": {
                "_id": "b73f8d47-6c71-4fc0-be64-c4518f3f3c37",
                "name": "tempImageTSjdle.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY",
                "-2Jz5p3NV"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "Gyhzfo2Dt",
          "menu_id": "qCXSSHgPN",
          "name": "PIZZE ",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "34MjAGr3G",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "MARGHERITA",
              "display_name": "",
              "description": "Pomodoro, mozzarella and basilico",
              "subtitle": "",
              "price": 24,
              "tags": [
                "2GOlnYmtC",
                "617DnJ14jM",
                "5uTgiye4-"
              ],
              "image": {
                "_id": "d37b8b68-d229-4989-ae96-faf82d572761",
                "name": "Margherita.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "SjDxdrZs_",
                "dWz1UbiSi",
                "XnL17jx9-"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "eG9i9xKwn",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "PROVOLA E PEPE ",
              "display_name": "",
              "description": "Pomodoro, smoked provola, parmesan cheese, basil and black cracked pepper",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": {
                "_id": "2a9d7e2b-7e0f-48ca-8b79-a2c2eed88f24",
                "name": "tempImage7GzLWF.heic",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "XnL17jx9-",
                "dWz1UbiSi",
                "SjDxdrZs_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "A0AgQD0kh",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "CAPRICCIOSA",
              "display_name": "",
              "description": "Pomodoro, mozzarella, ham,fresh mushroom, artichokes and olives",
              "subtitle": "",
              "price": 28,
              "tags": [
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "6f6147ee-8ac1-41a9-9f33-f53627b6bc03",
                "name": "tempImageqzVvZ5.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "SjDxdrZs_",
                "dWz1UbiSi",
                "XnL17jx9-"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "-F6qPwgVR",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "HAWAIIAN",
              "display_name": "",
              "description": "Pomodoro, mozzarella, ham and pineapple",
              "subtitle": "",
              "price": 27,
              "tags": [
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "56195453-77d9-4a2a-8c19-2e84fff105b2",
                "name": "Hawaiian.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "SjDxdrZs_",
                "dWz1UbiSi",
                "XnL17jx9-"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "-l7th8HI6",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "CALABRESE ",
              "display_name": "",
              "description": "Pomodoro, mozzarella, parmesan cheese, mild salami, chilli flakes, olives and basi",
              "subtitle": "",
              "price": 28,
              "tags": [],
              "image": {
                "_id": "0a6bd4a6-190e-4438-8ee4-d75455137593",
                "name": "tempImageBUv3ju.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "XnL17jx9-",
                "dWz1UbiSi",
                "SjDxdrZs_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "1A7Yha_4l",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "PARMA ",
              "display_name": "",
              "description": "Mozzarella,  Prosciutto di Parma, shaved parmesan cheese and rocket ",
              "subtitle": "",
              "price": 30,
              "tags": [
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "b20ede2c-d5d1-4316-95a1-3e8b2c351f6c",
                "name": "tempImagefH3PjZ.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "SjDxdrZs_",
                "j8rPYDNjk"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "tXKUwIjOC",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "SALSICCIA E FRIARIELLI ",
              "display_name": "",
              "description": "Friarielli sauce base, smoked provola, chilli, sausages and chilli hair",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "c8c37fb1-3a71-4e7e-ab71-bb06b2e58f96",
                "name": "tempImageHcwnip.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "XnL17jx9-",
                "SjDxdrZs_",
                "dWz1UbiSi"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dEQ_qdOhX",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "UMBRA ",
              "display_name": "",
              "description": "Truffle paste base, smoked provola, mozzarella, mushrooms, shaved parmesan cheese and truffle oil",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "35e2f64e-0e29-4823-aa41-e17691be259c",
                "name": "tempImageXT2ZoG.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "dWz1UbiSi",
                "XnL17jx9-",
                "SjDxdrZs_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "U3B3_CXxD",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "SICILIANA ",
              "display_name": "",
              "description": "Fresh spinach, mozzarella, roasted potatoes, caramelized onion and crispy pancetta",
              "subtitle": "",
              "price": 28,
              "tags": [],
              "image": {
                "_id": "aac1bf6d-4ede-4086-b6b1-2fe62add32a0",
                "name": "tempImageoaWfKk.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "SjDxdrZs_",
                "dWz1UbiSi",
                "XnL17jx9-"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "iazhdqAqZ",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "VEGETARIANA ",
              "display_name": "",
              "description": "Fresh spinach, mozzarella, cherry tomatoes, mushrooms, onion and roasted potatoes",
              "subtitle": "",
              "price": 28,
              "tags": [],
              "image": {
                "_id": "98c9b44f-e2c8-4116-af2a-d01fb79c0e0a",
                "name": "tempImage8IrZVJ.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "dWz1UbiSi",
                "XnL17jx9-",
                "SjDxdrZs_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "wt2HACatX",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "LIGURE ",
              "display_name": "",
              "description": "Mozzarella, prawns, zucchini, cherry tomatoes, zucchini chips and fresh chilli ",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "c6dd6d4f-6098-4455-ab15-d50f7b8c2763",
                "name": "tempImagejyHaJk.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Gbtc6CPLd",
              "menu_id": "qCXSSHgPN",
              "category_id": "Gyhzfo2Dt",
              "name": "SALSICCIA E PATATA ",
              "display_name": "",
              "description": "Mozzarella, sausage, potatoes ",
              "subtitle": "",
              "price": 29,
              "tags": [
                "2GOlnYmtC",
                "5uTgiye4-"
              ],
              "image": {
                "_id": "0b0fc545-bf75-4fed-8f9f-469c9f924a0f",
                "name": "Patate_e_salsiccia.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "SjDxdrZs_",
                "T-bP3zHad",
                "XnL17jx9-"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "N0Y3QVX5S",
          "menu_id": "qCXSSHgPN",
          "name": "MAIN",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "bK6YRleT3",
              "menu_id": "qCXSSHgPN",
              "category_id": "N0Y3QVX5S",
              "name": "CALAMARI FRITTI MAIN",
              "display_name": "",
              "description": "Deep fried calamari with roquette and aioli",
              "subtitle": "",
              "price": 39,
              "tags": [],
              "image": {
                "_id": "eb19aa13-db3a-416c-8ed9-bf0f64a1deb6",
                "name": "IMG_2433.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_yAmZCT8F",
              "menu_id": "qCXSSHgPN",
              "category_id": "N0Y3QVX5S",
              "name": "PARMIGIANA DI MELANZANE ",
              "display_name": "",
              "description": "Layers of fried eggplants with rich Napoli sauce,  basil and parmesan cheese ",
              "subtitle": "",
              "price": 25,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "6f404389-d55f-4464-a30d-1cd440246483",
                "name": "IMG_5652.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY",
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2LgpWauo7",
              "menu_id": "qCXSSHgPN",
              "category_id": "N0Y3QVX5S",
              "name": "BARRAMUNDI GRIGLIATO ",
              "display_name": "",
              "description": "Grilled Barramundi fillet served with chutney tomatoes, roasted corn and zucchini Scapece",
              "subtitle": "",
              "price": 42,
              "tags": [],
              "image": {
                "_id": "638fffbe-3223-4323-a950-ab29aa4d6658",
                "name": "tempImagewUJUt6.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2b1EA1RVg",
              "menu_id": "qCXSSHgPN",
              "category_id": "N0Y3QVX5S",
              "name": "FILETTO DI MANZO ",
              "display_name": "",
              "description": "250gr Grass fed Eye fillet served with rosti potato, peppercorn sauce and jus reduction",
              "subtitle": "",
              "price": 56,
              "tags": [],
              "image": {
                "_id": "2bcb118b-ccc1-4510-9548-375a8dfbe04d",
                "name": "tempImageRFZBZR.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "hr989H8CT",
                "1HHmRDHHl"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "X-DJ-YwCr",
          "menu_id": "qCXSSHgPN",
          "name": "CONTORNI E INSALATE",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "5GlUSsJOy",
              "menu_id": "qCXSSHgPN",
              "category_id": "X-DJ-YwCr",
              "name": "PATATE FRITTE",
              "display_name": "",
              "description": "Deep fried potatoes",
              "subtitle": "",
              "price": 13,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-"
              ],
              "image": {
                "_id": "6b2a63da-a81d-4210-8a8e-fdfdbf23c3a0",
                "name": "french-fries.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "kdWR3s5xS",
              "menu_id": "qCXSSHgPN",
              "category_id": "X-DJ-YwCr",
              "name": "INSALATA MISTA ",
              "display_name": "",
              "description": "Mixed leaves salad, shaved parmesan and balsamic dressing",
              "subtitle": "",
              "price": 15,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "ecf606e5-f94b-40de-8e39-d4ec9b58c13c",
                "name": "radiccio-salad.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY",
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "k-D0pjiGR",
              "menu_id": "qCXSSHgPN",
              "category_id": "X-DJ-YwCr",
              "name": "INSALATA CAPRESE",
              "display_name": "",
              "description": "Mixed cherry tomatoes with Buffalo mozzarella and basil ",
              "subtitle": "",
              "price": 20,
              "tags": [
                "617DnJ14jM",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "2376634d-ac83-47b2-9373-2d10afa0aaa7",
                "name": "IMG_5704.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "hr989H8CT"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "inu2u1GOv",
              "menu_id": "qCXSSHgPN",
              "category_id": "X-DJ-YwCr",
              "name": "BROCCOLINI ",
              "display_name": "",
              "description": "Charred broccolini with garlic and chilli ",
              "subtitle": "",
              "price": 13,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "40054463-be1e-454e-a021-e99c481bcae6",
                "name": "IMG_5671.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "hr989H8CT",
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "C2qI-O5-i",
              "menu_id": "qCXSSHgPN",
              "category_id": "X-DJ-YwCr",
              "name": "SPINACI LESSI ",
              "display_name": "",
              "description": "Steamed spinach served with lemon wedges",
              "subtitle": "",
              "price": 13,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": {
                "_id": "6357c0dd-ce1e-4fb0-804a-7e45c72cd772",
                "name": "IMG_5662.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "hr989H8CT",
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "xsyUA5h0t",
          "menu_id": "qCXSSHgPN",
          "name": "DESSERT",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "fx7-XIfS0",
              "menu_id": "qCXSSHgPN",
              "category_id": "xsyUA5h0t",
              "name": "TIRAMISU'",
              "display_name": "",
              "description": "Home-made traditional Italian tiramisu' with coffee, savoiardi biscuits and mascarpone cream",
              "subtitle": "",
              "price": 15.99,
              "tags": [],
              "image": {
                "_id": "372e0653-c0ec-4d79-b0d0-2aa7ffa41593",
                "name": "Tiramisu.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "kqjdygyhv",
              "menu_id": "qCXSSHgPN",
              "category_id": "xsyUA5h0t",
              "name": "VEGAN TIRAMISU ",
              "display_name": "",
              "description": "Vegan biscuits, coffee and vegan cream",
              "subtitle": "",
              "price": 15,
              "tags": [
                "5uTgiye4-"
              ],
              "image": {
                "_id": "55d1e592-f899-4309-92d6-58804d49048a",
                "name": "tempImagebojmgs.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "bbRRiWIuI",
              "menu_id": "qCXSSHgPN",
              "category_id": "xsyUA5h0t",
              "name": "CANNOLO SICILIANO ",
              "display_name": "",
              "description": "Traditional sicilian ricotta, chocolate and pistacchio cannolo ",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "d2bb0fb7-d573-45b2-89e1-3bb63d917ae7",
                "name": "IMG_5676.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "xQK3u8UO0",
              "menu_id": "qCXSSHgPN",
              "category_id": "xsyUA5h0t",
              "name": "PIZZA FORESTA NERA",
              "display_name": "",
              "description": "Small pizza topped with Nutella, forest coulis, shredded coconut and icing sugar",
              "subtitle": "",
              "price": 16,
              "tags": [
                "617DnJ14jM",
                "2GOlnYmtC",
                "5uTgiye4-"
              ],
              "image": {
                "_id": "563beb94-b980-464c-9e4f-62a37a8d1d2a",
                "name": "nutella.jpg",
                "modifiers": "-/crop/454x334/55,45/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "hpL2X9HJm",
          "menu_id": "qCXSSHgPN",
          "name": "DRINK LIST",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "PR_wOwOSe",
              "menu_id": "qCXSSHgPN",
              "category_id": "hpL2X9HJm",
              "name": "SOFT DRINKS",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "892bfa12-1672-4a31-be56-1e410f1e8888",
                "name": "IMG_4221.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "j8rPYDNjk"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YF7PMKi1C",
              "menu_id": "qCXSSHgPN",
              "category_id": "hpL2X9HJm",
              "name": "BEERS",
              "display_name": "Liquor licence N 32253162",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "31b77d1b-e5a5-4114-b73c-8cb7e858a63a",
                "name": "IMG_4227.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "341psodBF"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "wFZuJJc8i",
              "menu_id": "qCXSSHgPN",
              "category_id": "hpL2X9HJm",
              "name": "SPARKLING WATER 750ML",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "9c5b9284-439c-4fce-a832-13b764059407",
                "name": "water.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rZ4FLMSem",
              "menu_id": "qCXSSHgPN",
              "category_id": "hpL2X9HJm",
              "name": "RED WINES ",
              "display_name": "Liquor licence N 32253162",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "8dfc6cc4-0154-432e-8a22-e1bdadb90261",
                "name": "IMG_4222.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "HOINPEVL3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SUtUHH0uU",
              "menu_id": "qCXSSHgPN",
              "category_id": "hpL2X9HJm",
              "name": "WHITE WINES ",
              "display_name": "Liquor licence N 32253162",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "68c36c63-e86b-475e-a524-91f19ec75923",
                "name": "tempImagerhTpyW.heic",
                "modifiers": "-/crop/1170x1456/0,491/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "vJnwhVtPv"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "9Mf1CymKs",
          "menu_id": "qCXSSHgPN",
          "name": "KIDS OPTIONS",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "P1-BxJHdG",
              "menu_id": "qCXSSHgPN",
              "category_id": "9Mf1CymKs",
              "name": "KIDS PENNE ",
              "display_name": "",
              "description": "Penne with a choice of bolognese sauce or napoli sauce",
              "subtitle": "",
              "price": 18,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "0XO5TQWzD",
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "p4fYnwjCS",
              "menu_id": "qCXSSHgPN",
              "category_id": "9Mf1CymKs",
              "name": "KIDS GNOCCHI DI PATATE ",
              "display_name": "",
              "description": "Home made gnocchi served with a choice of bolognese sauce or napoli sauce",
              "subtitle": "",
              "price": 18,
              "tags": [
                "617DnJ14jM",
                "5uTgiye4-",
                "2GOlnYmtC"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "0XO5TQWzD",
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "gBgnMm40d",
              "menu_id": "qCXSSHgPN",
              "category_id": "9Mf1CymKs",
              "name": "KIDS LASAGNA ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 18,
              "tags": [
                "5uTgiye4-"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "jZ5DEPU4P",
              "menu_id": "qCXSSHgPN",
              "category_id": "9Mf1CymKs",
              "name": "KIDS MARGHERITA ",
              "display_name": "",
              "description": "Tomato and mozzarella cheese",
              "subtitle": "",
              "price": 18,
              "tags": [
                "2GOlnYmtC",
                "617DnJ14jM",
                "5uTgiye4-"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "T-bP3zHad",
                "SjDxdrZs_",
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Q5iBfHjjP",
              "menu_id": "qCXSSHgPN",
              "category_id": "9Mf1CymKs",
              "name": "KIDS CAPRICCIOSA ",
              "display_name": "",
              "description": "Pomodoro, mozzarella, ham, mushroom and olives",
              "subtitle": "",
              "price": 18,
              "tags": [
                "2GOlnYmtC",
                "617DnJ14jM",
                "5uTgiye4-"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "SjDxdrZs_",
                "T-bP3zHad",
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "0wKNt0LO4",
              "menu_id": "qCXSSHgPN",
              "category_id": "9Mf1CymKs",
              "name": "KIDS HAWAIIAN ",
              "display_name": "",
              "description": "Tomato, mozzarella cheese, ham and pinapple ",
              "subtitle": "",
              "price": 18,
              "tags": [
                "2GOlnYmtC",
                "617DnJ14jM",
                "5uTgiye4-"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "SjDxdrZs_",
                "T-bP3zHad",
                "EHKXpL7PpY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        }
      ]
    }
  ]

export const pizzariaOptionSet = [
    {
      "_id": "T-bP3zHad",
      "name": "Gluten Free +5",
      "display_name": "Gluten Free",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "KjWv83Q1Xz",
          "name": "Gluten Free",
          "price": "5",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "7NS_A8Zm_",
      "name": "FOCACCIA OPTIONS ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "WR3LSII1w",
          "name": "Garlic ",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "QThiKn6IJK",
          "name": "herb and salt ",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "XTqvGIMrP",
          "name": "Extra Cheese",
          "price": "3",
          "quantity": 0
        },
        {
          "_id": "TbUzM2y7S",
          "name": "Bruschetta topping ",
          "price": "4",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "EHKXpL7PpY",
      "name": "VEGAN OPTION ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "hzNp6q_y8",
          "name": "Vegan",
          "price": "",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "-2Jz5p3NV",
      "name": " GF PASTA CHOICE ",
      "display_name": "GF Pasta Choice ",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "tLrZGfesPF",
          "name": "PENNE GF",
          "price": "3",
          "quantity": 0
        },
        {
          "_id": "LsZ2b8giu",
          "name": "GNOCCHI GF",
          "price": "3",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "WJpki0r1V",
      "name": "Pasta Choice",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "ZqMHI4Te6",
          "name": "Homemade Gnocchi",
          "price": "2",
          "quantity": 0
        },
        {
          "_id": "oMyTRWqQWc",
          "name": "Penne",
          "price": "",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "SjDxdrZs_",
      "name": "Extra Toppings",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "29e7w6Ouu",
          "name": "Prawns",
          "price": "5",
          "quantity": 0
        },
        {
          "_id": "kDMEoN85j0",
          "name": "Buffalo Cheese  ",
          "price": "6",
          "quantity": 0
        },
        {
          "_id": "DuUz4P87l",
          "name": "Salame",
          "price": "4",
          "quantity": 0
        },
        {
          "_id": "14Zw5SWR3",
          "name": "Prosciutto",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "BwiEP7VxX",
          "name": "Ham",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "mmH4OdZZn",
          "name": "'Ndujia",
          "price": "3",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "X2RLbEOjP",
          "name": "extra cheese( type your choice)",
          "price": "3",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "L6h82m74w",
          "name": "Pancetta",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "N8Xh5h_nJ",
          "name": "olives",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "vmMVWl2Ph",
          "name": "extra veggies( type your choice)",
          "price": "3",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "bsqlHsDiJ",
          "name": "rocket ",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "4C70UHgiC",
          "name": "mushrooms",
          "price": "3",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "uiiEYX7U-",
          "name": "pineapple",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Vc1u88nZU",
          "name": "anchovies",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Lhi-0Tdz2",
          "name": "onion",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "5P5z-65FH",
          "name": "Extra Basil ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "yxWxkDfSX",
          "name": "Extra Napoli sauce ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "0XO5TQWzD",
      "name": "Bolognese or Napoli",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "zWORDvmUT",
          "name": "Bolognese sauce",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "4TmJGQ5fUQ",
          "name": "Napoli sauce",
          "price": "",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "nqsw6QdZ8",
      "name": "Steak side options",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2eZcjrj7m",
          "name": "Salads",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "iH4Iy20L2",
          "name": "Chips",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "1HHmRDHHl",
      "name": "Steak cooking option",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "FCsOMOYG6",
          "name": "Rare",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Srrg0a5o9",
          "name": "Medium Rare",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "6r83bWfdG",
          "name": "Medium",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "uu0aYsdrL",
          "name": "Medium Well Done",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "YsjgQAdhL",
          "name": "Well Done",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "j8rPYDNjk",
      "name": "SOFT DRINKS",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "JYfF3BxX5",
          "name": "Chinotto",
          "price": "5",
          "quantity": 0,
          "prices": {},
          "status": null
        },
        {
          "_id": "AVnM9hry5",
          "name": "Aranciata rossa(blood or.)",
          "price": "5",
          "quantity": 0,
          "prices": {},
          "status": null
        },
        {
          "_id": "NuEwblTMs",
          "name": "Lemonade (can)",
          "price": "5",
          "quantity": 0,
          "prices": {},
          "status": null
        },
        {
          "_id": "y-v8IS7-0",
          "name": "Coca Cola (can)",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "9yhrDchnj",
          "name": "Coca Cola 0 (no sugar can)",
          "price": "5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "341psodBF",
      "name": "BEERS",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "niZcP2eDo",
          "name": "Peroni ",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "SLuEiKeub",
          "name": "Antoniana(crispy lager)",
          "price": "8",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "256u5Dw3o",
          "name": "Menabrea",
          "price": "8",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "n1yWpP8j7",
          "name": "Cascade light",
          "price": "8",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "HOINPEVL3",
      "name": "RED WINES",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "VjAkfnJBZ",
          "name": "Shiraz ( SA )",
          "price": "42",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1p5wlv2U4",
          "name": "Pinot Noir ( Mornington Peninsula) ",
          "price": "42",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "OXV8wqqHp",
          "name": "Chianti (IT)",
          "price": "44",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "vJnwhVtPv",
      "name": "WHITE WINES",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "Mzmn4oG-A",
          "name": "Sauv Blanc (NZ)",
          "price": "42",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "NtnaznAbU",
          "name": "Chardonnay  (Yarra Valley)",
          "price": "42",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "F0amjhRb6",
          "name": "Riesling (SA)",
          "price": "42",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "9Dja3UJTH",
          "name": "Prosecco (IT)",
          "price": "42",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "eQLbKHvtg",
      "name": "Mother's Day MENU $60PP",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "U05bFzPmb",
          "name": "MENU QUANTITY  ",
          "price": "60",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "SIQYqOouA",
      "name": "Dessert GF",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "QaqnM-rq-",
          "name": "GF ",
          "price": "2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "dWz1UbiSi",
      "name": "VEGAN EXTRA",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "TdRxBqcZY",
          "name": "VEGAN HAM ",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "d1XHbPfzN",
          "name": "VEGAN SALAMI ",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "OZ7wavpN5",
          "name": "EXTRA VEGAN CHEESE ",
          "price": "3",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "hr989H8CT",
      "name": "GLUTEN FREE ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "vdksnyLlX",
          "name": "GLUTEN FREE",
          "price": "0",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "XnL17jx9-",
      "name": "PIZZA VEGAN OPTION + $1 ",
      "display_name": "PIZZA VEGAN OPTION ",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "xUBUM8pCP",
          "name": "PIZZA VEGAN OPTION ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        }
      ],
      "using_points": false
    }
  ];


function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createPizzariaMenu = () => {
    return {
        pizzariaMenu:updateIds(pizzariaMenu), pizzariaOptionSet:updateIds(pizzariaOptionSet)
    }
}

