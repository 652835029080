import React from 'react';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Field, FastField, FieldProps, FastFieldProps } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, SelectAdv, Input } from '@lib/components';
import { PaymentBaseFields } from './base';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentFiserv;

const validationSchema = Yup.object().shape({
  env: Yup.string().required(),
  api_key: Yup.string().required(),
  api_secret: Yup.string().required(),
  store_name: Yup.string().required(),
  connect_url: Yup.string().required(),
  connect_secret: Yup.string().required(),
});

@inject('store')
@observer
export class SettingsFormPaymentsFiserv extends MobxComponent<Props, {}> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.fiserv || {
      enabled: false,
      label: 'Fiserv',
      services: [],
      env: 'production',
      api_key: '',
      api_secret: '',
      store_name: '',
      connect_url: '',
      connect_secret: '',
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.fiserv = values;
          const update = {
            $set: {
              'settings.payments.fiserv': r.settings.payments.fiserv,
            },
          };

          return { r, update };
        }}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, getFieldError }) => {
          const { isSubmitting, setFieldValue, errors } = form;
          return (
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: FastFieldProps<boolean>) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using Fiserv payment method.">
                    <Switch
                      id="fiserv-enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="env"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="Fiserv Environment"
                    help="Use Production environment to accept real payments. Sandbox environment is used to test your integration."
                    error={getFieldError(form, 'env')}
                  >
                    <SelectAdv
                      type="single"
                      value={field.value}
                      onChange={(env: string) => setFieldValue('env', env)}
                      options={[
                        { label: 'Fiserv Production', value: 'production' },
                        { label: 'Fiserv Sandbox', value: 'sandbox' },
                      ]}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="api_key"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="API Key"
                    help="Fiserv-provided API key, use correct value for your current environment."
                    error={getFieldError(form, 'api_key')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('api_key', e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="api_secret"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="API Secret"
                    help="Fiserv-provided API Secret, use correct value for your current environment."
                    error={getFieldError(form, 'api_secret')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('api_secret', e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="store_name"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="Store Name"
                    help="Fiserv-provided store name, this value is usually in form of a number such as 3600000762."
                    error={getFieldError(form, 'store_name')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('store_name', e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="connect_url"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="Connect Payment Page URL"
                    help="The connect URL used for processing the payment in the Fiserv hosted payment page."
                    error={getFieldError(form, 'connect_url')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('connect_url', e.target.value)
                      }
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="connect_secret"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="Connect Shared Secret"
                    help="The connect secret used for processing the payment in the Fiserv hosted payment page."
                    error={getFieldError(form, 'connect_secret')}
                  >
                    <Input
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('connect_secret', e.target.value)
                      }
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="Fiserv" setFieldValue={setFieldValue} />

              {errors && Object.keys(errors).length > 0 && (
                <FormGroup no_border error="Validation failed. Please check Fiserv configurations again." />
              )}

              <Button full color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
