import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { FaArrowsAlt } from 'react-icons/fa'
import { Checkbox } from '@lib/components'
import { AlignItems, StyledItem } from './component'


interface ItemProps {
	text: string
	index: number
	activeCol: boolean;
	updateActiveCol: (colName: string) => void;
}


const Item: React.FC<ItemProps> = ({ text, index, activeCol, updateActiveCol }) => {
	return (
		<Draggable draggableId={text} index={index}>
			{provided => (
				<StyledItem
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<AlignItems>
						<Checkbox
							key={text}
							id={"text"}
							checked={activeCol}
							label={text}
							onChange={() => {
								updateActiveCol(text.split(".").pop()!);
							}}
						/>
						<div className="cursor-move p-lr-2 p-tb-1">
							<FaArrowsAlt />
						</div>
					</AlignItems>
				</StyledItem>
			)}
		</Draggable>
	)
}

export default Item
