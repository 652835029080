import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { FormUpload } from "@lib/components";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";

interface Props { }
interface State { }
type FormValues = T.Schema.Website.WebsiteSchema["favicon"];

@inject("store") @observer
export class WebsiteFormFavicon extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const w = this.injected.store.website!;
		this.initialValues = w.favicon;
	}

	render() {
		return (
			<WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.favicon = values;
					const update = {
						$set: { "favicon": values },
					};
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FastField
								name="image"
								render={({ field }: any) => (
									<FormGroup help="A square icon that is typically used as an app icon in various contexts across various devices. If your image is not square, it will be cropped from the center. Recommend at least 250x250 pixels. File name can't contain special charaters">
										<FormUpload
											maxSize={400}
											imagesOnly={true}
											inputAcceptTypes="image/png"
											value={[field.value]}
											onRemove={() => setFieldValue("image", null)}
											onChange={(files) => setFieldValue("image", files[0])} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</WebsiteForm>
		);
	}

}
