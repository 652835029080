import * as React from "react";
import { logger } from "@lib/common";
import { UI } from "../../../../../core/ui";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { Untrusive } from "@lib/common";
import { ItemListContent, ItemListItem, ItemListWrapper } from "@lib/components";
import { Tag } from "@lib/components";
import { Button } from "@lib/components";
import { ChargebeeSubscriptionPlans, CWSubscriptionPlans } from "@lib/common";
import styled from "styled-components";

interface Props extends WithTranslation { }
interface State { }

const DetailTag = (props: { text: string; tag: string }) => (
	<div className="flex-line centered flex-no-wrap p-tb-1">
		<p className="">{props.text}</p>
		<Tag className="m-l-2">{props.tag}</Tag>
	</div>
);

const ActiveWrapper = styled.div`
display: flex;
gap: 8px;
align-items: center;`

const ActivateButton = styled.button`
display: flex;
width: 81px;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: var(--status-completed, #009100);
border: 1px solid #009100;
color: var(--neutral-white, #FEFEFE);
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
`
const DeactivateButton = styled(ActivateButton)`
background: var(--status-cancelled, #BC362F);
border: 1px solid #BC362F;
`

const DisabledButton = styled(ActivateButton)`
background: #ADADAD;
border: 1px solid #ADADAD;
`
function ChargebeeDetails({ t, r, reseller_id }: { t: any, r: T.API.DashboardRestaurantsResponseItem, reseller_id: string }) {

	const cb = r.billing.cb;
	if (!cb) {
		return null;
	}

	let plan;
	if (["cloudwaitress", "cloudwaitress-test"].indexOf(reseller_id) !== -1) {
		plan = plan || CWSubscriptionPlans.v4[cb.plan];
		plan = plan || CWSubscriptionPlans.v3[cb.plan];
		plan = plan || CWSubscriptionPlans.v2[cb.plan];
	}
	else {
		const plans = ChargebeeSubscriptionPlans[reseller_id];
		if (plans) {
			plan = plans.find((p) => p.id === cb.plan);
		}
	}

	return (
		<>
			<DetailTag
				text="Total orders"
				tag={t("number", { value: r.counts.services.total })}
			/>

			{(!plan || plan.included_orders === 0) && (
				<>
					<DetailTag
						text="Orders this cycle"
						tag={t("number", { value: cb.outstanding })}
					/>
					<DetailTag
						text="Next billing"
						tag={t("dateFromTimestamp", { value: cb.next_billing_at })}
					/>
				</>
			)}

			{(plan && plan.included_orders !== 0) && (
				<>
					<DetailTag
						text="Orders left"
						tag={t("number", { value: plan.included_orders - cb.outstanding })}
					/>
					<DetailTag
						text="Free orders reset"
						tag={t("dateFromTimestamp", { value: cb.next_billing_at })}
					/>
				</>
			)}
		</>
	);

}

@inject("store") @observer
class DashboardRestaurantsListClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	view = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		const { restrictions } = store;

		if (!restrictions._.restaurantView) {
			return;
		}

		const rr = restrictions.restaurant;
		const r = store.restaurants.items[index];
		let path = `/restaurant/${r._id}`;

		if (rr.dashboard) {
			path = `/restaurant/${r._id}`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_board) {
			path = `/restaurant/${r._id}/orders`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_list) {
			path = `/restaurant/${r._id}/orders/list`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_bump) {
			path = `/restaurant/${r._id}/orders/bump`;
		}
		else if (rr.bookings) {
			path = `/restaurant/${r._id}/bookings`;
		}
		else if (rr.customers) {
			path = `/restaurant/${r._id}/customers`;
		}
		else if (rr.menus) {
			path = `/restaurant/${r._id}/menus`;
		}
		else if (restrictions._.restaurantSettingsEnabled) {
			path = `/restaurant/${r._id}/settings`;
		}

		store.router.push(path);
	}

	delete = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		try {

			const p = prompt("Are you sure you want to delete this restaurant. Once deleted, none of it's data can be recovered. Enter 'delete' into the field below to proceed with delation");

			if (p === null) { return; }

			if (p.toLowerCase() !== "delete") {
				UI.notification.error("Enter 'delete' to delete the restaurant");
				return;
			}

			Untrusive.start();

			const r = store.restaurants.items[index];
			const res = await this.injected.store.api.restaurant_delete({ _id: r._id });

			Untrusive.stop();

			if (res.outcome) {
				UI.notification.error(res.message);
				return;
			}

			const restaurants = [...store.restaurants.items];
			restaurants.splice(index, 1);

			this.setState({ delete_modal_active: null });
			store.updateRestaurants({ items: restaurants });

			UI.notification.success("Restaurant deleted");

		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, try again or contact us");
			Untrusive.stop();
		}
	}
  enableOrganization = async(index: number | null, flag: boolean = false, enableFlag: boolean = false ) => {
    if(index === null) return;
    const { store } = this.injected;
    let confirmText = `Do you want to publish this restaurant ?`;
    const r = store.restaurants.items[index];

    if(flag) {
      if(( r.enable_uber_organization && r.enable_uber_organization !== undefined  && r.enable_uber_organization !== true ) &&  (!store.reseller?.billing_info || !r.settings.billing_info || !store.organisation?.billing_info)) {
        UI.notification.warning("Restaurant/Admin/Reseller don't have billing info yet");
        return;
      }
    } else {
      if(!r.settings.billing_info) {
        UI.notification.warning("Restaurant don't have billing info yet");
        return;
      } else confirmText = `You are going to unpublish this restaurant. Do you want to proceed?`;
    }
    const c = confirm(confirmText)

    if(c) {
      Untrusive.start();

      const res = await this.injected.store.api.restaurant_update({
        _id: r._id,
        update: {$set: {enable_uber_organization: flag}}
      });

      Untrusive.stop();

      if (res.outcome) {
        UI.notification.error(res.message);
        return;
      }

      const restaurants = [...store.restaurants.items];
      restaurants[index].enable_uber_organization = flag;

      store.updateRestaurants({ items: restaurants });
      if(flag) UI.notification.success(`Restaurant has been published`);
      else UI.notification.error(`Restaurant has been unpublished`)
    }
  }


	render() {
		const reseller = this.injected.store.reseller!;
		const { store, t } = this.injected;
		const { restrictions } = store;
		const canViewRestaurant = restrictions._.restaurantView;
		return (
			<ItemListWrapper>
				<ItemListContent>
					{store.restaurants.items.map((r, k) => {
						const cb = r.billing.cb;
						const store_url = r.domain ? `https://${r.domain}` : `https://${r.subdomain}.${reseller.store_host}`;
						return (
							<ItemListItem
								key={k}
								id={`restaurant-list-item-${k}`}
								style={{ cursor: canViewRestaurant ? "cursor" : "inherit" }}
								onClick={() => this.view(k)}
								>
								<div className="p-lr-6 p-t-5 p-b-4 flex-grow">

									<div className="flex-l-r-center">
										<h4 className="big m-r-4">{r.name}</h4>
										{/*{!!cb && (*/}
                    {/*  <ActiveWrapper>*/}

                    {/*    {reseller?.billing_info && r.settings && r.settings?.billing_info && r?.enable_uber_organization  ?*/}
                    {/*      <DeactivateButton onClick={(e) => {*/}
                    {/*        e.preventDefault()*/}
                    {/*        this.enableOrganization(k)*/}
                    {/*      }}>Unpublish</DeactivateButton>*/}
                    {/*      :  r.settings && r.settings?.billing_info && !r.settings.billing_info ? <DisabledButton onClick={(e) => {*/}
                    {/*        e.preventDefault()*/}
                    {/*        this.enableOrganization(k, false, true)*/}
                    {/*      }}>*/}
                    {/*        Disabled*/}
                    {/*      </DisabledButton>:<ActivateButton onClick={(e) => {*/}
                    {/*        e.preventDefault()*/}
                    {/*        this.enableOrganization(k, true)*/}
                    {/*      }}>Publish </ActivateButton>}*/}
                    {/*  </ActiveWrapper>*/}
										{/*)}*/}
									</div>

									{!!cb && (
										<div className="flex-line centered flex-wrap child-mr-20 m-t-2">
											<ChargebeeDetails
												t={t}
												r={r}
												reseller_id={reseller._id}
											/>
										</div>
									)}

									<div className="flex-line centered child-mr-10 m-t-2">

										{canViewRestaurant && (
											<Button
												id={`restaurant-list-item-view-store-${k}`}
												size="xxs"
												color="primary-inverse"
												className="p-lr-2"
												disableHoverShadow={true}
												onClick={() => this.view(k)}>
												Manage
											</Button>
										)}

										<Button
											id={`restaurant-list-item-view-store-${k}`}
											size="xxs"
											color="primary-inverse"
											className="p-lr-2"
											disableHoverShadow={true}
											onClick={(e) => {
												e.stopPropagation();
												window.open(store_url);
											}}>
											View Store
										</Button>

										{(reseller.chargebee && restrictions.restaurant.billing) && (
											<Button
												id={`restaurant-list-item-billing-${k}`}
												size="xxs"
												color="primary-inverse"
												className="p-lr-2"
												disableHoverShadow={true}
												onClick={(e) => {
													e.stopPropagation();
													store.billing.initChargebeeSession(r._id);
												}}>
												Billing
											</Button>
										)}

										{(!reseller.admin.disable_delete && restrictions.restaurant.delete) && (
											<Button
												size="xxs"
												color="primary-inverse"
												className="p-lr-2"
												disableHoverShadow={true}
												onClick={(e) => {
													e.stopPropagation();
													this.delete(k);
												}}>
												Delete
											</Button>
										)}

									</div>

								</div>
							</ItemListItem>
						);
					})}
				</ItemListContent>
			</ItemListWrapper>
		);
	}

}

export const DashboardRestaurantsList = withTranslation()(DashboardRestaurantsListClass);
