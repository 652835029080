import shortid from 'shortid';

export const pizza_menu = [
    {
      "_id": "leKRQiU6xx",
      "name": "Pizza Menu",
      "display_name": "Pizza Menu",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": false,
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "_23cpNbrq",
          "menu_id": "leKRQiU6xx",
          "name": "MEAL DEALS",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "combo",
              "_id": "5a6vQXLjw",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Meal Deal #1",
              "display_name": "",
              "description": "Does not include any modifications made to products. Not in conjunction with any other offer",
              "subtitle": "Any 2 Pizzas from",
              "price": 42,
              "tags": [],
              "image": {
                "_id": "0ded9570-4cb7-4af5-acf9-291574bee181",
                "name": "meal_deal_1.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [
                {
                  "_id": "_2Uk7UXV9",
                  "name": "Pizza 1",
                  "dishes": [
                    "FCMGxzw-oR",
                    "awMFQzW0rl",
                    "4goeOjNYuD",
                    "QcN-Deu1Sw",
                    "ddy3QmA-xT",
                    "-x8qPH7Xh2",
                    "5g5ZWvUY5R",
                    "9GshSHBAd3",
                    "N01C88fOyK",
                    "imj8Entzfl",
                    "wuEJ55WK5G",
                    "6TVLuHctTv",
                    "VSBLyhT2O7",
                    "5_i8PGWWFt",
                    "E0gEWH-XFW",
                    "jzfq9wZKJot",
                    "GeVbQ4tTUaT",
                    "ejyfpAZug",
                    "7PIa6kNwa",
                    "2YsSbHnwD",
                    "KoDyjkeel"
                  ],
                  "selected": null,
                  "lpo": 22
                },
                {
                  "_id": "mQCA9LGfp",
                  "name": "Pizza 2",
                  "dishes": [
                    "FCMGxzw-oR",
                    "awMFQzW0rl",
                    "4goeOjNYuD",
                    "QcN-Deu1Sw",
                    "ddy3QmA-xT",
                    "-x8qPH7Xh2",
                    "5g5ZWvUY5R",
                    "9GshSHBAd3",
                    "N01C88fOyK",
                    "imj8Entzfl",
                    "wuEJ55WK5G",
                    "6TVLuHctTv",
                    "VSBLyhT2O7",
                    "5_i8PGWWFt",
                    "E0gEWH-XFW",
                    "jzfq9wZKJot",
                    "GeVbQ4tTUaT",
                    "ejyfpAZug",
                    "7PIa6kNwa",
                    "2YsSbHnwD",
                    "KoDyjkeel"
                  ],
                  "selected": null,
                  "lpo": 22
                }
              ],
              "price_type": "difference",
              "taxes": [],
              "option_set_blacklist": []
            },
            {
              "type": "combo",
              "_id": "tbWhGM1tO",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Meal Deal #2",
              "display_name": "",
              "description": "Does not include any modifications made to products. Not in conjunction with any other offer",
              "subtitle": "Pizza & Pasta from",
              "price": 39,
              "tags": [],
              "image": {
                "_id": "bc92dcbd-9d13-44d6-bcfe-8e6740bced59",
                "name": "meal_deal_2.jpg",
                "modifiers": "-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [
                {
                  "_id": "-_gaEXe2y",
                  "name": "Pizza ",
                  "dishes": [
                    "FCMGxzw-oR",
                    "awMFQzW0rl",
                    "4goeOjNYuD",
                    "QcN-Deu1Sw",
                    "ddy3QmA-xT",
                    "-x8qPH7Xh2",
                    "5g5ZWvUY5R",
                    "9GshSHBAd3",
                    "N01C88fOyK",
                    "imj8Entzfl",
                    "wuEJ55WK5G",
                    "6TVLuHctTv",
                    "VSBLyhT2O7",
                    "5_i8PGWWFt",
                    "E0gEWH-XFW",
                    "jzfq9wZKJot",
                    "GeVbQ4tTUaT",
                    "ejyfpAZug",
                    "7PIa6kNwa",
                    "2YsSbHnwD",
                    "KoDyjkeel"
                  ],
                  "selected": null,
                  "lpo": 22
                },
                {
                  "_id": "jogU4mJmu",
                  "name": "Pasta ",
                  "dishes": [
                    "eylxewdGdQ",
                    "-AZK0k6XHX",
                    "uzVQz9v-kz",
                    "GyRL76jhd0",
                    "IhmAHwfmUK",
                    "JaViGB9iW_",
                    "Ryq4iW8mPI",
                    "P-NyZ5Fd5t"
                  ],
                  "selected": null,
                  "lpo": 20
                }
              ],
              "price_type": "difference",
              "taxes": [],
              "option_set_blacklist": []
            },
            {
              "type": "standard",
              "_id": "FCMGxzw-oR",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Margherita",
              "display_name": "",
              "description": "Tomato, Mozzarella, Fior di Latte, Basil",
              "subtitle": "",
              "price": 22,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "ace345b8-5443-499a-8cd2-dfd2c7269abe",
                "name": "1.jpg",
                "modifiers": "-/crop/893x822/7,277/-/preview/-/enhance/60/-/sharp/18/"
              },
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "1aAfEpVpkg",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "xjXpIGT2b",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "VokHehtYkE",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "awMFQzW0rl",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Buffalo Soldier",
              "display_name": "",
              "description": "Tomato, Buffalo Mozzarella, Basil",
              "subtitle": "",
              "price": 24,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "163338a9-d30a-4c1f-a989-86d734dffae2",
                "name": "15.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "6d6FUYrlL",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "Y1VdjJac1",
                  "name": "Buffalo Mozzarella",
                  "active": true
                },
                {
                  "_id": "igZ3njUVQ",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "4goeOjNYuD",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Capricciosa",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior di Latte, Mushroom, Double Smoked Ham, Artichoke, Olives",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": {
                "_id": "959298dc-c8de-4540-ad97-63819c192c83",
                "name": "16.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "T0W2uGPQRY",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "9ncktKA_g",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "S7C4zFV3NS",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "NSZ88oqXH",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "U8zY7b2Gj",
                  "name": "Artichokes",
                  "active": true
                },
                {
                  "_id": "kPu2JJhSlf",
                  "name": "Olives",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "QcN-Deu1Sw",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Tony Anchovy",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior di Latte, Anchovies, Oregano",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "07f8be0d-9d6e-4e15-bf6d-1facde447e63",
                "name": "17.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "fuh8FfXCFu",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "bUB9xFE8l",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "w1mp18VWA0",
                  "name": "Anchovies",
                  "active": true
                },
                {
                  "_id": "KR3E4_OA3",
                  "name": "Oregano",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "ddy3QmA-xT",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Prosciutto",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior di Latte, Prosciutto di Parma, Parmigiano Reggiano, Rocket",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": {
                "_id": "732ff421-ae82-4dcd-a20b-110cf85fd5d1",
                "name": "18.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "UHByzMXRSa",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "d0NiXIL9H",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "6j9g5lxQhk",
                  "name": "Rocket",
                  "active": true
                },
                {
                  "_id": "yR39y7zflR",
                  "name": "Prosciutto",
                  "active": true
                },
                {
                  "_id": "hlfZ9cz98f",
                  "name": "Parmesan Shaved",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "-x8qPH7Xh2",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Piero's Prawns",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Prawns, Chilli, Garlic, Parsley",
              "subtitle": "",
              "price": 24,
              "tags": [
                "NbYqxiNzj"
              ],
              "image": {
                "_id": "b4d70a9b-9396-451c-960b-63b808110801",
                "name": "19.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "rcRjZmja1H",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "YwD0pc4HO",
                  "name": "Mozzarella ",
                  "active": true
                },
                {
                  "_id": "4LOoCoEUuY",
                  "name": "Prawns",
                  "active": true
                },
                {
                  "_id": "Q2-_nKI0j",
                  "name": "Garlic",
                  "active": true
                },
                {
                  "_id": "xm0ZO2Sxy",
                  "name": "Chilli",
                  "active": true
                },
                {
                  "_id": "3CFt0EoPCp",
                  "name": "Parsley",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "5g5ZWvUY5R",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Pineapple Express",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Double Smoked Ham, Pineapple",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "3LNuWpHi8j",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "um89uUq79",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "EVT4QFDVQ",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "7OGaWsAw-",
                  "name": "Pineapple",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "9GshSHBAd3",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Don Supreme",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Double Smoked Ham, Mushrooms, Roasted Capsicum, Cabanossi, Pineapple, Olives",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "Rh_JMgFeOv",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "05OEMZN6_",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "gbg11TtN1G",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "e9IhrFtnIn",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "UmGsGcjqBf",
                  "name": "Capsicum",
                  "active": true
                },
                {
                  "_id": "sSAJl0rPO",
                  "name": "Olives",
                  "active": true
                },
                {
                  "_id": "isfQFdfJId",
                  "name": "Pineapple",
                  "active": true
                },
                {
                  "_id": "jCwVp1DpNR",
                  "name": "Cabanossi",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "N01C88fOyK",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Donny Diavola",
              "display_name": "",
              "description": "Hot Salami, Tomato, Mozzarella Fior Di Latte, Smoked Provolone, Roasted Capsicum, Chilli",
              "subtitle": "",
              "price": 23,
              "tags": [
                "NbYqxiNzj"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "8DsArnZ87",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "InmszTAeS",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "Eiu5Mtw6zy",
                  "name": "Hot Salami",
                  "active": true
                },
                {
                  "_id": "yKosFlH1q_",
                  "name": "Capsicum",
                  "active": true
                },
                {
                  "_id": "fA3ptlhQsL",
                  "name": "Chilli",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "imj8Entzfl",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Chicken Special",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Baby Spinach, Mushroom, Roasted Chicken, Onion",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "DGH-vK77jY",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "gOiIPs4da",
                  "name": "Mozzarella ",
                  "active": true
                },
                {
                  "_id": "t7ZT-RTY1h",
                  "name": "Baby Spinach",
                  "active": true
                },
                {
                  "_id": "H9E9PnaRh",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "TLK-8mVqO",
                  "name": "Chicken",
                  "active": true
                },
                {
                  "_id": "tbrd8lJsIZ",
                  "name": "Onion",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "wuEJ55WK5G",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Seafood",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Fresh Mix Seafood, Garlic, Parsley",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "kOpUD15aJd",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "aSIiRmpf_",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "QPeg3aje7",
                  "name": "Mix Seafood",
                  "active": true
                },
                {
                  "_id": "IbBdRheiu",
                  "name": "Garlic ",
                  "active": true
                },
                {
                  "_id": "ncQ7kXvk1",
                  "name": "Parsley",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "6TVLuHctTv",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Vegetarian",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Eggplant, Zucchini, Artichoke, Roasted Capsicum, Salted Ricotta",
              "subtitle": "",
              "price": 23,
              "tags": [
                "X91suDUzR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "d_qh_5MZLf",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "p5S8-BpVC",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "_88HYAKAn",
                  "name": "Eggplant",
                  "active": true
                },
                {
                  "_id": "sjIJFebhZ2",
                  "name": "Capsicum",
                  "active": true
                },
                {
                  "_id": "VSn7W7RzTZ",
                  "name": "Artichokes ",
                  "active": true
                },
                {
                  "_id": "DEQHeUa68",
                  "name": "Zucchini",
                  "active": true
                },
                {
                  "_id": "PdTQVxascf",
                  "name": "Salted Ricotta",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "VSBLyhT2O7",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Mel & Zane",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Ricotta, Parmigiano Reggiano, Eggplant, Basil",
              "subtitle": "",
              "price": 23,
              "tags": [
                "X91suDUzR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "uN4M2MrqsC",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "OiwMhRXQM",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "ddrVKfpsZ",
                  "name": "Eggplant",
                  "active": true
                },
                {
                  "_id": "Me-tfxyUKe",
                  "name": "Parmesan",
                  "active": true
                },
                {
                  "_id": "ouYWe4XZXY",
                  "name": "Fresh Ricotta",
                  "active": true
                },
                {
                  "_id": "nJ5jXaOAw",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "5_i8PGWWFt",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Meat Lovers",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Double Smoked Ham, Pepperoni, Cabanossi, Norm's Streaky Bacon",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "x4pP7OOKLf",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "3jmIs0sGb",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "EDcGmaxBk3",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "KNfa1Dyyfr",
                  "name": "Pepperoni",
                  "active": true
                },
                {
                  "_id": "-GjlDISPT",
                  "name": "Cabanossi",
                  "active": true
                },
                {
                  "_id": "CZwGKZ_pF5",
                  "name": "Bacon",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "E0gEWH-XFW",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Gourmet Meat Lovers",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Homemade Sausage, Norm's Streaky Bacon, Salami, Roasted Chicken",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "Hg425PQN6",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "EwjRO01kC",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "jFxDljn6kx",
                  "name": "Mild Salami",
                  "active": true
                },
                {
                  "_id": "1vyUy1XwXq",
                  "name": "Italian Sausage",
                  "active": true
                },
                {
                  "_id": "jDL3osUfNb",
                  "name": "Chicken",
                  "active": true
                },
                {
                  "_id": "eWLGGVdbu",
                  "name": "Bacon",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "jzfq9wZKJot",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Palm Springs",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Fried Eggplant, Hot Nduja, Basil",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "SsSXHpSMt",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "tTfoka0rk",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "8drA9Qp6ex",
                  "name": "Eggplant",
                  "active": true
                },
                {
                  "_id": "Hgm7eXZpOy",
                  "name": "Nduja",
                  "active": true
                },
                {
                  "_id": "A-6fKShw-",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "GeVbQ4tTUaT",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Siciliana ",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Mushroom, Salami, Buffalo Mozzarella, Basil",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "7J5l5YBeFY",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "Z5y7kWhgx",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "3P9n2Uu9Ou",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "i3tWJSI9oV",
                  "name": "Mild Salami",
                  "active": true
                },
                {
                  "_id": "0FP7pjHIy",
                  "name": "Buffalo Mozzarella",
                  "active": true
                },
                {
                  "_id": "kIiaTAGgHK",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "ejyfpAZug",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Potato & Sausage ",
              "display_name": "",
              "description": "Potato, Mozzarella Fior Di Latte, House Made Sausage, Rosemary",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "bFotiTFdw",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "xTP6N5VQd",
                  "name": "Potato",
                  "active": true
                },
                {
                  "_id": "y6BSU41eK6",
                  "name": "Italian Sausage",
                  "active": true
                },
                {
                  "_id": "YKSh1OeEnH",
                  "name": "Rosemary",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "7PIa6kNwa",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Blue Potato",
              "display_name": "",
              "description": "Mozzarella Fior Di Latte Potato, Gorgonzola Cheese",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "NwPY90fGZ",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "IjlbU9cbdv",
                  "name": "Potato",
                  "active": true
                },
                {
                  "_id": "oHfruH4JRx",
                  "name": "Gorgonzola ",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "2YsSbHnwD",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Quattro Formaggi ",
              "display_name": "",
              "description": "Mozzarella Fior Di Latte, Gorgonzola, Parmigiano Reggiano, Smoked Provolone",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "Nyhof9Kat",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "tvm3CeO0Uh",
                  "name": "Gorgonzola ",
                  "active": true
                },
                {
                  "_id": "5LMEUlsBHj",
                  "name": "Parmesan",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "KoDyjkeel",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Trufflemen ",
              "display_name": "",
              "description": "Mozzarella Fior Di Latte, Mushroom, Truffle, Prosciutto, Parmigiano Reggiano",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "fxvSbWg6b",
                  "name": "Mozzarella ",
                  "active": true
                },
                {
                  "_id": "OuVP3EHra",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "eIYAqL6L6",
                  "name": "Prosciutto",
                  "active": true
                },
                {
                  "_id": "WAGORqvPMi",
                  "name": "Parmesan Shaved",
                  "active": true
                },
                {
                  "_id": "sKTyAP7wxg",
                  "name": "Truffle Oil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "P-NyZ5Fd5t",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Spaghetti Pomodoro",
              "display_name": "",
              "description": "Traditional italian napoli sauce, parmigiano reggiano",
              "subtitle": "",
              "price": 20,
              "tags": [
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "uE7ogG3Q-",
                  "name": "Parmesan",
                  "active": true
                }
              ],
              "option_sets": [
                "lbkkabwq8",
                "HXNK9t9qp"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "YRzoi6UU2"
            },
            {
              "type": "standard",
              "_id": "Ryq4iW8mPI",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Penne Boscaiola",
              "display_name": "",
              "description": "Norm's streaky bacon, porcini mushroom, cream",
              "subtitle": "",
              "price": 22,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "3hQX1M1fS",
                  "name": "Mushroom",
                  "active": true
                },
                {
                  "_id": "fkiVJD3dc",
                  "name": "Bacon",
                  "active": true
                },
                {
                  "_id": "b1Xaqg6lt",
                  "name": "Parmesan",
                  "active": true
                }
              ],
              "option_sets": [
                "HXNK9t9qp",
                "g3zx8doUw"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "YRzoi6UU2"
            },
            {
              "type": "standard",
              "_id": "JaViGB9iW_",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Spaghetti Bolognese",
              "display_name": "",
              "description": "Slow cooked pork and veal ragu, parmigiano reggiano",
              "subtitle": "",
              "price": 22,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "GcxdiHQqf",
                  "name": "Parmesan",
                  "active": true
                }
              ],
              "option_sets": [
                "lbkkabwq8",
                "HXNK9t9qp"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "IhmAHwfmUK",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Fettuccine Salsiccia",
              "display_name": "",
              "description": "Homemade italian sausage, mushroom, pink sauce, parmigiano reggiano",
              "subtitle": "",
              "price": 23,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "nZ5_kvGGd",
                  "name": "Mushroom",
                  "active": true
                },
                {
                  "_id": "ubLCJNJ5Q",
                  "name": "Italian Sausage",
                  "active": true
                },
                {
                  "_id": "-7QiMI3Gx",
                  "name": "Parmesan",
                  "active": true
                }
              ],
              "option_sets": [
                "qKSpm885e",
                "HXNK9t9qp"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "GyRL76jhd0",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Penne Pesto",
              "display_name": "",
              "description": "Basil, parmigiano reggiano, pine nuts",
              "subtitle": "",
              "price": 21,
              "tags": [
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "HXNK9t9qp",
                "g3zx8doUw"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "uzVQz9v-kz",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Spaghetti Gamberi",
              "display_name": "",
              "description": "King prawns, napoli sauce, garlic, fresh chilli",
              "subtitle": "",
              "price": 24,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [
                {
                  "_id": "K54zIg-VS",
                  "name": "Garlic",
                  "active": true
                },
                {
                  "_id": "c9n0dCjp7",
                  "name": "Chilli",
                  "active": true
                },
                {
                  "_id": "1V41ZcC7n",
                  "name": "Parsley",
                  "active": true
                }
              ],
              "option_sets": [
                "HXNK9t9qp",
                "lbkkabwq8"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "-AZK0k6XHX",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Gnocchi Gorgonzola",
              "display_name": "",
              "description": "Homemade potato gnocchi, creamy gorgonzola sauce, parmigiano reggiano",
              "subtitle": "",
              "price": 23,
              "tags": [
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tgHlLCpVB",
                "HXNK9t9qp"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "eylxewdGdQ",
              "menu_id": "leKRQiU6xx",
              "category_id": "_23cpNbrq",
              "name": "Lasagna",
              "display_name": "",
              "description": "Lasagna, bolognese ragu, bechamel",
              "subtitle": "",
              "price": 22,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "D5P1eAgOd"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "cjtNYS2le",
          "menu_id": "leKRQiU6xx",
          "name": "SPECIAL OF THE DAY",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "wzLCWNPpN",
              "menu_id": "leKRQiU6xx",
              "category_id": "cjtNYS2le",
              "name": "Stuffed Olives (6 pcs)",
              "display_name": "",
              "description": "Crumbed olives stuffed with mozzarella cheese, spicy aioli",
              "subtitle": "",
              "price": 12,
              "tags": [],
              "image": {
                "_id": "c0cc5a40-8b96-42a8-a415-475cce0f7046",
                "name": "stuffed_olives.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "B5ByVyBf4",
              "menu_id": "leKRQiU6xx",
              "category_id": "cjtNYS2le",
              "name": "Crispy Mozzarella Bites 6PCS",
              "display_name": "",
              "description": "Crispy mozzarella bites served with truffle mayo",
              "subtitle": "",
              "price": 11,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "CwHiOqZXU",
              "menu_id": "leKRQiU6xx",
              "category_id": "cjtNYS2le",
              "name": "Popcorn chicken ",
              "display_name": "",
              "description": "served with spicy aioli",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "232aba87-4270-4bb2-9f88-3f30148ecaf9",
                "name": "popcorn_chicken.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "5OkH_JTue",
              "menu_id": "leKRQiU6xx",
              "category_id": "cjtNYS2le",
              "name": "Risotto alla milanese",
              "display_name": "",
              "description": "Risotto with saffron and slow-cooked ossobuco",
              "subtitle": "",
              "price": 32,
              "tags": [],
              "image": {
                "_id": "3628ec5b-42fb-4745-b632-c0aa6c972155",
                "name": "IMG_20241020_WA0001.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Clj7Z2LIT",
              "menu_id": "leKRQiU6xx",
              "category_id": "cjtNYS2le",
              "name": "Squid ink spaghetti",
              "display_name": "",
              "description": "Squid ink spaghetti with prawns, calamari, fresh cherry tomatoes, garlic, chilli, and parsley.",
              "subtitle": "",
              "price": 32,
              "tags": [],
              "image": {
                "_id": "54ae941e-82e0-4162-8261-ffd2e935f1a9",
                "name": "squid_spaghetti.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "KgPqM9JsE",
              "menu_id": "leKRQiU6xx",
              "category_id": "cjtNYS2le",
              "name": "Pizza Special",
              "display_name": "",
              "description": "White base with mozzarella, spinach, zucchini flowers, cherry tomatoes, fresh ricotta, and parmesan.",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "95236075-c5e5-4727-a844-8d2ed34f962c",
                "name": "Background_Eraser.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "R60yBZ7W9",
              "menu_id": "leKRQiU6xx",
              "category_id": "cjtNYS2le",
              "name": "Cannolo",
              "display_name": "Sicilian cannolo",
              "description": "Cannolo filled with sweet ricotta cheese and chocolate",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "61936c5e-6621-4808-9a3b-1c1c1e60299b",
                "name": "cannolo_chocolate.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": "CANNOLO"
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "4DIGlBr2x",
          "menu_id": "leKRQiU6xx",
          "name": "STARTERS",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "ZyruEVK3e",
              "menu_id": "leKRQiU6xx",
              "category_id": "4DIGlBr2x",
              "name": "Arancini Truffle & Mushrooms (3 PCS)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 13,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "e64dcf7c-6eba-4b43-96bf-9245e20ef603",
                "name": "arancini_truffle.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": "ARANCINI TRUFFLE"
            },
            {
              "type": "standard",
              "_id": "AONh_8DK1",
              "menu_id": "leKRQiU6xx",
              "category_id": "4DIGlBr2x",
              "name": "Zucchini flower (3Pcs)",
              "display_name": "",
              "description": "Zucchini flowers (3 pcs) with fresh ricotta, pecorino & parmesan",
              "subtitle": "",
              "price": 21,
              "tags": [],
              "image": {
                "_id": "fddc3beb-9634-48b9-b12f-47d22c3b5f73",
                "name": "zucchini.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ZfSYdKsLZ",
              "menu_id": "leKRQiU6xx",
              "category_id": "4DIGlBr2x",
              "name": "Croquettes",
              "display_name": "",
              "description": "Potato and mozzarella crumbed with home made panko",
              "subtitle": "",
              "price": 16,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "bbabdc13-98c7-47a5-a5ee-0f2c82040906",
                "name": "CROQUETTES_3.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "l8Iv7ZENV",
              "menu_id": "leKRQiU6xx",
              "category_id": "4DIGlBr2x",
              "name": "Polenta Chips",
              "display_name": "",
              "description": "Polenta chips served with gorgonzola sauce",
              "subtitle": "",
              "price": 14,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "b6c0b668-8347-4068-9dfc-c352f158d655",
                "name": "POLENTA_CHIPS_2.jpg",
                "modifiers": "-/crop/1309x1653/93,0/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EDS72lxmA",
              "menu_id": "leKRQiU6xx",
              "category_id": "4DIGlBr2x",
              "name": "Calamari",
              "display_name": "",
              "description": "Fried hawkesbury squid lightly dusted served with Aioli sauce",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": {
                "_id": "ed52a668-53f6-4bfb-ad88-3f34d96d2cc9",
                "name": "CALAMARI_FRITTI.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E-nok4c3R",
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UWEGcvFPE",
              "menu_id": "leKRQiU6xx",
              "category_id": "4DIGlBr2x",
              "name": "Meatballs",
              "display_name": "",
              "description": "Traditional homemade pork and veal meatballs",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "850c7290-d7d4-47d0-a563-967cc67209b1",
                "name": "MEATBALLS_4.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "FcGY-mKO6",
              "menu_id": "leKRQiU6xx",
              "category_id": "4DIGlBr2x",
              "name": "Garlic Prawns (6 PCS)",
              "display_name": "",
              "description": "Garlic prawns cooked in napolitana sauce",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": {
                "_id": "9052d834-7335-4a32-a5f8-4adf25e133cb",
                "name": "GARLIC_PRAWNS_3.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Dun8bOQRR",
              "menu_id": "leKRQiU6xx",
              "category_id": "4DIGlBr2x",
              "name": "Caprese",
              "display_name": "",
              "description": "Fresh buffalo mozzarella, fresh tomato, basil, balsamic reduction, extra virgin olive oil",
              "subtitle": "",
              "price": 19,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "e865ad97-86cd-481a-a36d-89003528cdf3",
                "name": "CAPRESE_SALAD_2.jpg",
                "modifiers": "-/preview/-/enhance/83/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "p0OTS012k",
          "menu_id": "leKRQiU6xx",
          "name": "CRUSTS & BREAD",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "mWV-ZTAQ3",
              "menu_id": "leKRQiU6xx",
              "category_id": "p0OTS012k",
              "name": "Garlic Crust",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 14,
              "tags": [
                "DjrhROwYl",
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "b8c200c3-13b0-41f9-bf41-7fc156929251",
                "name": "220206_DONNY_S_PIZZA_Abbotsford_Shoot_1_0048_JPEG___Full_size__highest_quality.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "l-UftJj0_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "5NG55PhtN",
              "menu_id": "leKRQiU6xx",
              "category_id": "p0OTS012k",
              "name": "Garlic & Cheese Crust",
              "display_name": "",
              "description": "Garlic, salt, parsley, extra virgin olive oil, mozzarella fior di latte",
              "subtitle": "",
              "price": 17,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "c2e0d4f5-7f1e-4bcb-9351-11a226872bff",
                "name": "GARLIC___CHEESE_2.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "l-UftJj0_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "ycFSav2Ym",
              "menu_id": "leKRQiU6xx",
              "category_id": "p0OTS012k",
              "name": "Bruschetta Crust",
              "display_name": "",
              "description": "Garlic, fresh tomatoes, basil, extra virgin olive oil",
              "subtitle": "",
              "price": 19,
              "tags": [
                "DjrhROwYl",
                "X91suDUzR"
              ],
              "image": {
                "_id": "babee662-bca7-4930-94fc-ea59aa0d47ce",
                "name": "10.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "l-UftJj0_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "vRz6wpWdH",
              "menu_id": "leKRQiU6xx",
              "category_id": "p0OTS012k",
              "name": "Bruschetta with Burrata Crust",
              "display_name": "",
              "description": "Garlic, fresh tomatoes, basil, extra virgin olive oil, burrata",
              "subtitle": "",
              "price": 27,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "c52c5cce-19e0-492a-a8f3-6bda3bd1562b",
                "name": "11.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "l-UftJj0_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "",
              "print_name": "bruschetta + burrata"
            },
            {
              "type": "standard",
              "_id": "bOnodvZfE",
              "menu_id": "leKRQiU6xx",
              "category_id": "p0OTS012k",
              "name": "Garlic Bread",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "f8b49889-2610-4584-be96-57ff77972e27",
                "name": "GARLIC_BREAD.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "bQdSseQXE",
              "menu_id": "leKRQiU6xx",
              "category_id": "p0OTS012k",
              "name": "Homemade Bread Loaf",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "cfef3b5d-f271-40a8-93fb-1bd9951f30a6",
                "name": "BRED_LOAF.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "832QVMcAq",
          "menu_id": "leKRQiU6xx",
          "name": "TRADITIONAL PIZZA",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "vYGfl9Epi",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Margherita",
              "display_name": "",
              "description": "Tomato, Mozzarella, Fior di Latte, Basil",
              "subtitle": "",
              "price": 22,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "3475b29a-9fe4-4f47-aa99-1d8edc6c49e5",
                "name": "MARGHERITA.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "1aAfEpVpkg",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "xjXpIGT2b",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "VokHehtYkE",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "TcLu0IMiy",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Buffalo Soldier",
              "display_name": "",
              "description": "Tomato, Buffalo Mozzarella, Basil",
              "subtitle": "",
              "price": 26,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "897a0f7a-8553-4b8f-a6f5-ad12706177fd",
                "name": "BUFFALO_SOLDIER_3.jpg",
                "modifiers": "-/preview/-/enhance/100/"
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "w4HDzTymb",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "mLD3p7nDk",
                  "name": "Buffalo Mozzarella",
                  "active": true
                },
                {
                  "_id": "cYSGPC_fy",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "QP05kYTkt",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Capricciosa",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior di Latte, Mushroom, Double Smoked Ham, Artichoke, Olives",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": {
                "_id": "3b81cab5-1977-45d1-ab52-ff0fdba0f5cc",
                "name": "CAPRICCIOSA_3.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "T0W2uGPQRY",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "9ncktKA_g",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "S7C4zFV3NS",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "NSZ88oqXH",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "U8zY7b2Gj",
                  "name": "Artichokes",
                  "active": true
                },
                {
                  "_id": "kPu2JJhSlf",
                  "name": "Olives",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "i-NquXISv",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Tony Anchovy",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior di Latte, Anchovies, Oregano",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": {
                "_id": "82b15ecc-73d3-45c7-adca-6332df3821e2",
                "name": "TONY_ANCHOVY_3.jpg",
                "modifiers": "-/preview/-/enhance/41/"
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "fuh8FfXCFu",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "bUB9xFE8l",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "w1mp18VWA0",
                  "name": "Anchovies",
                  "active": true
                },
                {
                  "_id": "KR3E4_OA3",
                  "name": "Oregano",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "zE1zAyLIU",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Prosciutto",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior di Latte, Prosciutto di Parma, Parmigiano Reggiano, Rocket",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": {
                "_id": "dbbc2052-eb7a-407b-a637-99f9fa0167d6",
                "name": "PROSCIUTTO.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "UHByzMXRSa",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "d0NiXIL9H",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "6j9g5lxQhk",
                  "name": "Rocket",
                  "active": true
                },
                {
                  "_id": "yR39y7zflR",
                  "name": "Prosciutto",
                  "active": true
                },
                {
                  "_id": "hlfZ9cz98f",
                  "name": "Parmesan Shaved",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "qStRWjLsT",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Piero's Prawns",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Prawns, Chilli, Garlic, Parsley",
              "subtitle": "",
              "price": 27,
              "tags": [
                "NbYqxiNzj"
              ],
              "image": {
                "_id": "ee2b4f03-af53-4c8b-bbe3-fe954a005f5c",
                "name": "PIERO_S_PRAWNS_2.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "rcRjZmja1H",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "YwD0pc4HO",
                  "name": "Mozzarella ",
                  "active": true
                },
                {
                  "_id": "4LOoCoEUuY",
                  "name": "Prawns",
                  "active": true
                },
                {
                  "_id": "Q2-_nKI0j",
                  "name": "Garlic",
                  "active": true
                },
                {
                  "_id": "xm0ZO2Sxy",
                  "name": "Chilli",
                  "active": true
                },
                {
                  "_id": "3CFt0EoPCp",
                  "name": "Parsley",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "yBSPn8l0w",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Pineapple Express",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Double Smoked Ham, Pineapple",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": {
                "_id": "2b469d6e-1564-4e5d-81ed-d0af10fef296",
                "name": "PINEAPPLE_EXPRESS_2.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "3LNuWpHi8j",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "um89uUq79",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "EVT4QFDVQ",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "7OGaWsAw-",
                  "name": "Pineapple",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "OI1xI6zw_",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Don Supreme",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Double Smoked Ham, Mushrooms, Roasted Capsicum, Cabanossi, Pineapple, Olives",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": {
                "_id": "0839bd05-55fd-4d08-99f1-b8c38f906477",
                "name": "SUPREME_2.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "Rh_JMgFeOv",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "05OEMZN6_",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "gbg11TtN1G",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "e9IhrFtnIn",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "UmGsGcjqBf",
                  "name": "Capsicum",
                  "active": true
                },
                {
                  "_id": "sSAJl0rPO",
                  "name": "Olives",
                  "active": true
                },
                {
                  "_id": "isfQFdfJId",
                  "name": "Pineapple",
                  "active": true
                },
                {
                  "_id": "jCwVp1DpNR",
                  "name": "Cabanossi",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "EPzuKaI5S",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Donny Diavola",
              "display_name": "",
              "description": "Hot Salami, Tomato, Mozzarella Fior Di Latte, Smoked Provolone, Roasted Capsicum, Chilli",
              "subtitle": "",
              "price": 24,
              "tags": [
                "NbYqxiNzj"
              ],
              "image": {
                "_id": "90e73a13-4c26-42af-8891-fa7bd97ea98f",
                "name": "DIAVOLA_3.jpg",
                "modifiers": "-/preview/-/enhance/80/"
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "8DsArnZ87",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "InmszTAeS",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "Eiu5Mtw6zy",
                  "name": "Hot Salami",
                  "active": true
                },
                {
                  "_id": "yKosFlH1q_",
                  "name": "Capsicum",
                  "active": true
                },
                {
                  "_id": "fA3ptlhQsL",
                  "name": "Chilli",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "z-cwXpx9C",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Chicken Special",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Baby Spinach, Mushroom, Roasted Chicken, Onion",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "image": {
                "_id": "e1112094-89fd-43cb-9bb0-1b8bdb41dc43",
                "name": "CHICKEN_SPECIAL_3.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "DGH-vK77jY",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "gOiIPs4da",
                  "name": "Mozzarella ",
                  "active": true
                },
                {
                  "_id": "t7ZT-RTY1h",
                  "name": "Baby Spinach",
                  "active": true
                },
                {
                  "_id": "H9E9PnaRh",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "TLK-8mVqO",
                  "name": "Chicken",
                  "active": true
                },
                {
                  "_id": "tbrd8lJsIZ",
                  "name": "Onion",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "DdH5kQaYz",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Seafood",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Fresh Mix Seafood, Garlic, Parsley",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "image": {
                "_id": "1e47f203-1683-4ae7-9827-71d1d44a75a3",
                "name": "SEAFOOD_2.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "kOpUD15aJd",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "aSIiRmpf_",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "QPeg3aje7",
                  "name": "Mix Seafood",
                  "active": true
                },
                {
                  "_id": "IbBdRheiu",
                  "name": "Garlic ",
                  "active": true
                },
                {
                  "_id": "ncQ7kXvk1",
                  "name": "Parsley",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "kEEn2A1z4",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Vegetarian",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Eggplant, Zucchini, Artichoke, Roasted Capsicum, Salted Ricotta",
              "subtitle": "",
              "price": 25,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "fd7e22a4-82a1-4881-bc55-31cea353ecc3",
                "name": "VEGETARIAN_3.jpg",
                "modifiers": "-/preview/-/enhance/100/"
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "d_qh_5MZLf",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "p5S8-BpVC",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "_88HYAKAn",
                  "name": "Eggplant",
                  "active": true
                },
                {
                  "_id": "sjIJFebhZ2",
                  "name": "Capsicum",
                  "active": true
                },
                {
                  "_id": "VSn7W7RzTZ",
                  "name": "Artichokes ",
                  "active": true
                },
                {
                  "_id": "DEQHeUa68",
                  "name": "Zucchini",
                  "active": true
                },
                {
                  "_id": "PdTQVxascf",
                  "name": "Salted Ricotta",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "BOs8Yzd6T",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Mel & Zane",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Ricotta, Parmigiano Reggiano, Eggplant, Basil",
              "subtitle": "",
              "price": 24,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "e8dc18b5-eedd-4ab4-9ace-fd296646a0d0",
                "name": "MEL_ZANE_3.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "uN4M2MrqsC",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "OiwMhRXQM",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "ddrVKfpsZ",
                  "name": "Eggplant",
                  "active": true
                },
                {
                  "_id": "Me-tfxyUKe",
                  "name": "Parmesan",
                  "active": true
                },
                {
                  "_id": "ouYWe4XZXY",
                  "name": "Fresh Ricotta",
                  "active": true
                },
                {
                  "_id": "nJ5jXaOAw",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "5NN5Ph5tM",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Meat Lovers",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Double Smoked Ham, Pepperoni, Cabanossi, Norm's Streaky Bacon",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": {
                "_id": "eea276a8-d91a-4e8d-9211-7360548fffdd",
                "name": "MEATLOVER_3.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "x4pP7OOKLf",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "3jmIs0sGb",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "EDcGmaxBk3",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "KNfa1Dyyfr",
                  "name": "Pepperoni",
                  "active": true
                },
                {
                  "_id": "-GjlDISPT",
                  "name": "Cabanossi",
                  "active": true
                },
                {
                  "_id": "CZwGKZ_pF5",
                  "name": "Bacon",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "Ip51qTmoR",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Gourmet Meat Lovers",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Homemade Sausage, Norm's Streaky Bacon, Salami, Roasted Chicken",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "image": {
                "_id": "4db244ea-b54e-4e3c-aa83-001ec46bbf26",
                "name": "GOURMET_MEATLOVER_2.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "Hg425PQN6",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "EwjRO01kC",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "jFxDljn6kx",
                  "name": "Mild Salami",
                  "active": true
                },
                {
                  "_id": "1vyUy1XwXq",
                  "name": "Italian Sausage",
                  "active": true
                },
                {
                  "_id": "jDL3osUfNb",
                  "name": "Chicken",
                  "active": true
                },
                {
                  "_id": "eWLGGVdbu",
                  "name": "Bacon",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "LYorB62RZ",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Palm Springs",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Fried Eggplant, Hot Nduja, Basil",
              "subtitle": "",
              "price": 24,
              "tags": [
                "NbYqxiNzj"
              ],
              "image": {
                "_id": "41d3efc7-fda0-4444-a15d-b44738fb74a7",
                "name": "PALM_SPRINGS.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "lZ8UnagR2",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "tTfoka0rk",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "8drA9Qp6ex",
                  "name": "Eggplant",
                  "active": true
                },
                {
                  "_id": "Hgm7eXZpOy",
                  "name": "Nduja",
                  "active": true
                },
                {
                  "_id": "A-6fKShw-",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "VM9pCYb1D",
              "menu_id": "leKRQiU6xx",
              "category_id": "832QVMcAq",
              "name": "Siciliana ",
              "display_name": "",
              "description": "Tomato, Mozzarella Fior Di Latte, Mushroom, Salami, Buffalo Mozzarella, Basil",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": {
                "_id": "43c86561-1dfc-433e-bb15-5fe9f254ab30",
                "name": "SICILIANA.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "7J5l5YBeFY",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "Z5y7kWhgx",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "3P9n2Uu9Ou",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "i3tWJSI9oV",
                  "name": "Mild Salami",
                  "active": true
                },
                {
                  "_id": "0FP7pjHIy",
                  "name": "Buffalo Mozzarella",
                  "active": true
                },
                {
                  "_id": "kIiaTAGgHK",
                  "name": "Basil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "Wo0ZPLxxO",
          "menu_id": "leKRQiU6xx",
          "name": "PASTA",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "6OoYS7v7h",
              "menu_id": "leKRQiU6xx",
              "category_id": "Wo0ZPLxxO",
              "name": "Spaghetti Pomodoro",
              "display_name": "",
              "description": "Traditional italian napoli sauce, parmigiano reggiano",
              "subtitle": "",
              "price": 21,
              "tags": [
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "a4a80c5c-71a8-4371-a48f-3fb8ea7e321e",
                "name": "_65A0370.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "21qY3gEAA",
                  "name": "Napoli Sauce",
                  "active": true
                },
                {
                  "_id": "o5YN4tuPZ",
                  "name": "Parmigiano Reggiano",
                  "active": true
                }
              ],
              "option_sets": [
                "lbkkabwq8",
                "HXNK9t9qp"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "YRzoi6UU2"
            },
            {
              "type": "standard",
              "_id": "tjSLcosuy",
              "menu_id": "leKRQiU6xx",
              "category_id": "Wo0ZPLxxO",
              "name": "Penne Boscaiola",
              "display_name": "",
              "description": "Norm's streaky bacon, porcini mushroom, cream",
              "subtitle": "",
              "price": 24,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "2f6ee3b9-2c41-43d7-b2b5-d59434956cca",
                "name": "_65A0269.jpg",
                "modifiers": "-/crop/869x637/83,42/-/preview/"
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "OvqlNRNLi",
                  "name": "Mushroom",
                  "active": true
                },
                {
                  "_id": "VSWPhsm9t",
                  "name": "Bacon",
                  "active": true
                },
                {
                  "_id": "4RZP-JXHu",
                  "name": "Parmigiano reggiano",
                  "active": true
                }
              ],
              "option_sets": [
                "HXNK9t9qp",
                "g3zx8doUw"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "YRzoi6UU2"
            },
            {
              "type": "standard",
              "_id": "jjMvc0QLQ",
              "menu_id": "leKRQiU6xx",
              "category_id": "Wo0ZPLxxO",
              "name": "Spaghetti Bolognese",
              "display_name": "",
              "description": "Slow cooked pork and veal ragu, parmigiano reggiano",
              "subtitle": "",
              "price": 24,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "84b92462-4331-40cb-a178-fcfc29a7c206",
                "name": "_65A0337.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "X4P5AaiBV",
                  "name": "Parmiggiano Reggiano",
                  "active": true
                }
              ],
              "option_sets": [
                "lbkkabwq8",
                "HXNK9t9qp"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "svwxQdoj5",
              "menu_id": "leKRQiU6xx",
              "category_id": "Wo0ZPLxxO",
              "name": "Fettuccine Salsiccia",
              "display_name": "",
              "description": "Homemade italian sausage, mushroom, pink sauce, parmigiano reggiano",
              "subtitle": "",
              "price": 25,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "96230997-9a4a-433c-889a-19a57cf0cba3",
                "name": "_65A0348.jpg",
                "modifiers": "-/crop/908x642/64,39/-/preview/"
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "sm_x5E5Em",
                  "name": "Mushroom",
                  "active": true
                },
                {
                  "_id": "kz3KhQ3-8",
                  "name": "Italian Sausage",
                  "active": true
                },
                {
                  "_id": "iJjxs4lm8",
                  "name": "Parmigiano Reggiano",
                  "active": true
                }
              ],
              "option_sets": [
                "qKSpm885e",
                "HXNK9t9qp"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "h6oDF6GLc",
              "menu_id": "leKRQiU6xx",
              "category_id": "Wo0ZPLxxO",
              "name": "Penne Pesto",
              "display_name": "",
              "description": "Basil, parmigiano reggiano, pine nuts",
              "subtitle": "",
              "price": 24,
              "tags": [
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "1f7ca652-8e37-4158-86f0-8fb5e8ad00dd",
                "name": "_65A0395.jpg",
                "modifiers": "-/crop/1080x1192/0,225/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "HXNK9t9qp",
                "g3zx8doUw"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "yzxjX668h",
              "menu_id": "leKRQiU6xx",
              "category_id": "Wo0ZPLxxO",
              "name": "Spaghetti Gamberi",
              "display_name": "",
              "description": "King prawns, napoli sauce, garlic, fresh chilli",
              "subtitle": "",
              "price": 27,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "b7784499-595c-4674-949d-c63499f812c3",
                "name": "_65A0301.jpg",
                "modifiers": "-/crop/932x645/46,50/-/preview/"
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "HID95MO2f",
                  "name": "Garlic",
                  "active": true
                },
                {
                  "_id": "UaCVdvR6P",
                  "name": "Chilli",
                  "active": true
                },
                {
                  "_id": "jkwCxMEQU",
                  "name": "Parsley",
                  "active": true
                }
              ],
              "option_sets": [
                "HXNK9t9qp",
                "lbkkabwq8"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "6un9RCfEX",
              "menu_id": "leKRQiU6xx",
              "category_id": "Wo0ZPLxxO",
              "name": "Gnocchi Gorgonzola",
              "display_name": "",
              "description": "Homemade potato gnocchi, creamy gorgonzola sauce, parmigiano reggiano",
              "subtitle": "",
              "price": 25,
              "tags": [
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "fcac110c-e7f7-49b6-a07b-0018fc164309",
                "name": "_65A0354.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tgHlLCpVB",
                "HXNK9t9qp"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "BDFt2sSRn",
              "menu_id": "leKRQiU6xx",
              "category_id": "Wo0ZPLxxO",
              "name": "Lasagna",
              "display_name": "",
              "description": "Lasagna, bolognese ragu, bechamel",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": {
                "_id": "4367e603-fd85-46a4-896a-3b0ecdf23b51",
                "name": "_65A0239.jpg",
                "modifiers": "-/crop/847x575/107,88/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "D5P1eAgOd"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "-PN8BxK7y",
          "menu_id": "leKRQiU6xx",
          "name": "WHITE BASE PIZZA",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "AnuLasUv3",
              "menu_id": "leKRQiU6xx",
              "category_id": "-PN8BxK7y",
              "name": "Potato & Sausage",
              "display_name": "",
              "description": "Potato, Mozzarella Fior Di Latte, House Made Sausage, Rosemary",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": {
                "_id": "a51bb6cc-80bc-4d75-8b53-c14909a7a025",
                "name": "POT_S.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "bFotiTFdw",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "xTP6N5VQd",
                  "name": "Potato",
                  "active": true
                },
                {
                  "_id": "y6BSU41eK6",
                  "name": "Italian Sausage",
                  "active": true
                },
                {
                  "_id": "YKSh1OeEnH",
                  "name": "Rosemary",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "JMkGICFMT",
              "menu_id": "leKRQiU6xx",
              "category_id": "-PN8BxK7y",
              "name": "Blue Potato",
              "display_name": "",
              "description": "Mozzarella Fior Di Latte Potato, Gorgonzola Cheese",
              "subtitle": "",
              "price": 25,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "ada3cdcb-8ed7-419b-9fd2-b8e51722c519",
                "name": "BLUE.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "NwPY90fGZ",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "IjlbU9cbdv",
                  "name": "Potato",
                  "active": true
                },
                {
                  "_id": "oHfruH4JRx",
                  "name": "Gorgonzola ",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "YkF1ZpOwe",
              "menu_id": "leKRQiU6xx",
              "category_id": "-PN8BxK7y",
              "name": "Trufflemen",
              "display_name": "",
              "description": "Mozzarella Fior Di Latte, Mushroom, Truffle, Prosciutto, Parmigiano Reggiano",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "image": {
                "_id": "765e3367-6473-4d5e-87d6-a29f952f4f86",
                "name": "TRUFFLEMEN_3.jpg",
                "modifiers": "-/preview/-/enhance/100/"
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "fxvSbWg6b",
                  "name": "Mozzarella ",
                  "active": true
                },
                {
                  "_id": "OuVP3EHra",
                  "name": "Mushrooms",
                  "active": true
                },
                {
                  "_id": "GqFNajSkI",
                  "name": "Prosciutto",
                  "active": true
                },
                {
                  "_id": "WAGORqvPMi",
                  "name": "Parmesan Shaved",
                  "active": true
                },
                {
                  "_id": "sKTyAP7wxg",
                  "name": "Truffle Oil",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            },
            {
              "type": "standard",
              "_id": "qq7jn-viD",
              "menu_id": "leKRQiU6xx",
              "category_id": "-PN8BxK7y",
              "name": "Quattro Formaggi",
              "display_name": "",
              "description": "Mozzarella Fior Di Latte, Gorgonzola, Parmigiano Reggiano, Smoked Provolone",
              "subtitle": "",
              "price": 24,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "03f76d4e-4890-4cef-bc24-8c060bcb92e0",
                "name": "4FORMAGGI3.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "Nyhof9Kat",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "tvm3CeO0Uh",
                  "name": "Gorgonzola ",
                  "active": true
                },
                {
                  "_id": "5LMEUlsBHj",
                  "name": "Parmesan",
                  "active": true
                }
              ],
              "option_sets": [
                "BOnFxXZLZ",
                "NSDczrBqo"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": ""
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "0IqTyOzg6",
          "menu_id": "leKRQiU6xx",
          "name": "MAINS",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "AvXapiCpJ",
              "menu_id": "leKRQiU6xx",
              "category_id": "0IqTyOzg6",
              "name": "Chicken Schnitzel",
              "display_name": "",
              "description": "Free range crumbed chicken breast",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "image": {
                "_id": "982f3ca8-7eda-4aef-b2a1-974074e944e4",
                "name": "_65A0403.jpg",
                "modifiers": "-/crop/1063x1442/15,95/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "YDJhELm3d",
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2kVjaIwy5",
              "menu_id": "leKRQiU6xx",
              "category_id": "0IqTyOzg6",
              "name": "Chicken Parmigiana",
              "display_name": "",
              "description": "Free range chicken schnitzel, tomato, basil, buffalo mozzarella",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "a9eb1434-62e2-4eac-925c-ea99b8ad5a8a",
                "name": "_65A0434.jpg",
                "modifiers": "-/crop/1033x1298/5,145/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "YDJhELm3d",
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Egxk7f0Su",
              "menu_id": "leKRQiU6xx",
              "category_id": "0IqTyOzg6",
              "name": "Salmon",
              "display_name": "",
              "description": "Pan fried skin on salmon fillet, sea salt flakes",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "a867a45e-4173-4287-baf7-42f48212f9f8",
                "name": "_65A0427.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "YDJhELm3d",
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "t7JNVO1sq",
          "menu_id": "leKRQiU6xx",
          "name": "SIDES",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "5ToYTUD7W",
              "menu_id": "leKRQiU6xx",
              "category_id": "t7JNVO1sq",
              "name": "Garden Salad",
              "display_name": "",
              "description": "Mixed leaf, tomato, cucumber, onion, extra virgin olive oil, balsamic vinegar",
              "subtitle": "",
              "price": 12,
              "tags": [
                "DjrhROwYl",
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "d20e4acd-bc99-4ad6-a117-a968e5f5c8f2",
                "name": "_65A0130.jpg",
                "modifiers": "-/crop/951x613/43,78/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Cdz6E2fpO",
              "menu_id": "leKRQiU6xx",
              "category_id": "t7JNVO1sq",
              "name": "Rocket & Parmesan Salad",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 14,
              "tags": [
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "21dab3bc-97c0-4bfa-ad62-48067fb0d907",
                "name": "_65A0153.jpg",
                "modifiers": "-/crop/801x522/166,102/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "uruzNg3sQ",
              "menu_id": "leKRQiU6xx",
              "category_id": "t7JNVO1sq",
              "name": "Rosemary Salted Chips",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 11,
              "tags": [
                "DjrhROwYl",
                "X91suDUzR"
              ],
              "image": {
                "_id": "ca69b704-2220-4314-935e-92445697f495",
                "name": "_65A0375.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "q4akJaxGl",
                  "name": "Rosemary Salt",
                  "active": true
                }
              ],
              "option_sets": [
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "fLhzZKzVF",
              "menu_id": "leKRQiU6xx",
              "category_id": "t7JNVO1sq",
              "name": "Roasted Vegetables",
              "display_name": "",
              "description": "Seasonal roasted vegetables",
              "subtitle": "",
              "price": 15,
              "tags": [
                "DjrhROwYl",
                "X91suDUzR",
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "82e170dc-1cdf-4303-a80e-1460dc2ffd28",
                "name": "_65A0382.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "LhRpM4u7c",
          "menu_id": "leKRQiU6xx",
          "name": "KIDS MENU",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "cOWTtz2DH",
              "menu_id": "leKRQiU6xx",
              "category_id": "LhRpM4u7c",
              "name": "Kids Margherita Pizza",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 14,
              "tags": [
                "X91suDUzR"
              ],
              "image": {
                "_id": "b6373a1e-6012-4efe-9d2d-ec829c963eea",
                "name": "_65A0863.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "xF0OQXjFW",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "JeKcl33wZ",
                  "name": "Mozzarella",
                  "active": true
                }
              ],
              "option_sets": [
                "g09shYvis"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "-UfdQrIVt",
              "menu_id": "leKRQiU6xx",
              "category_id": "LhRpM4u7c",
              "name": "Kids Ham & Pineapple Pizza",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "ffb23ca9-c030-4818-bedf-2222fc95e0a9",
                "name": "_65A0859.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "i4JwzDvvM",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "xS2Eq6LdG",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "geD3useDo",
                  "name": "Ham",
                  "active": true
                },
                {
                  "_id": "TAJxotvp3",
                  "name": "Pineapple",
                  "active": true
                }
              ],
              "option_sets": [
                "g09shYvis"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "l8CY-HaIx",
              "menu_id": "leKRQiU6xx",
              "category_id": "LhRpM4u7c",
              "name": "Kids Pepperoni Pizza",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "8b6fbfd8-3480-4198-bcc7-abd2cc51f645",
                "name": "_65A0836.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [
                {
                  "_id": "2mIu5Uogt",
                  "name": "Tomato",
                  "active": true
                },
                {
                  "_id": "LZPukQFCh",
                  "name": "Mozzarella",
                  "active": true
                },
                {
                  "_id": "FSFfw_PKv",
                  "name": "Pepperoni",
                  "active": true
                }
              ],
              "option_sets": [
                "g09shYvis"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2E9o3T5R4",
              "menu_id": "leKRQiU6xx",
              "category_id": "LhRpM4u7c",
              "name": "Kids Penne Pomodoro Pasta",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "679251be-40a7-450d-9ca1-9cd7357e7496",
                "name": "_65A0445.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "gh-TzPQ8I",
              "menu_id": "leKRQiU6xx",
              "category_id": "LhRpM4u7c",
              "name": "Kids Chicken Schnitzel & Chips",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "253629f5-4405-4a73-975b-d36257b8b86a",
                "name": "_65A0415.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "ZszTs4P6N",
          "menu_id": "leKRQiU6xx",
          "name": "DESSERTS",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3bjZ_7JM_",
              "menu_id": "leKRQiU6xx",
              "category_id": "ZszTs4P6N",
              "name": "Tiramisu",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 12,
              "tags": [],
              "image": {
                "_id": "332b8c89-6c9e-43c5-8333-de502b825bb4",
                "name": "_65A0466.jpg",
                "modifiers": "-/crop/685x501/186,739/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_uotLGXg2",
              "menu_id": "leKRQiU6xx",
              "category_id": "ZszTs4P6N",
              "name": "Panna Cotta",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "6f612c69-f67f-4cb5-a64e-6512eb41cb15",
                "name": "_65A0478.jpg",
                "modifiers": "-/crop/893x634/102,696/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RBIzoHyqM",
              "menu_id": "leKRQiU6xx",
              "category_id": "ZszTs4P6N",
              "name": "Sticky Date Pudding with Caramel Sauce",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 11,
              "tags": [],
              "image": {
                "_id": "86af178c-2465-48e6-b2ee-fcc92dd848ee",
                "name": "STICKY_DATE_PUDDING_3.jpg",
                "modifiers": "-/preview/-/enhance/100/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "x-qfVEB1O"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2_hWZM_vK",
              "menu_id": "leKRQiU6xx",
              "category_id": "ZszTs4P6N",
              "name": "Nutella Pizza",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [
                "8-8BXDj1-"
              ],
              "image": {
                "_id": "20f0eb12-77a4-4a22-a384-dc4a1f0172dd",
                "name": "_65A0757.jpg",
                "modifiers": "-/crop/864x946/0,169/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tvwZah91f",
                "l-UftJj0_"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "WUwKPGSDV",
          "menu_id": "leKRQiU6xx",
          "name": "BEVERAGES",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "RKcC2_jAi",
              "menu_id": "leKRQiU6xx",
              "category_id": "WUwKPGSDV",
              "name": "Soft Drinks",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "oKq3c0T2e",
                "ZL4ZLyl8pg"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "oKq3c0T2e"
            },
            {
              "type": "standard",
              "_id": "jvYRKc-6j",
              "menu_id": "leKRQiU6xx",
              "category_id": "WUwKPGSDV",
              "name": "San Pellegrino - Chinotto",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "X-y5OukCt",
              "menu_id": "leKRQiU6xx",
              "category_id": "WUwKPGSDV",
              "name": "San Pellegrino - Aranciata Rossa",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "8odAcYG2T",
          "menu_id": "leKRQiU6xx",
          "name": "WATER",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "DLxdkyq0h",
              "menu_id": "leKRQiU6xx",
              "category_id": "8odAcYG2T",
              "name": "San Pellegrino Sparkling Water",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tHwenw9AW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "tHwenw9AW"
            },
            {
              "type": "standard",
              "_id": "GcJOU3uxd",
              "menu_id": "leKRQiU6xx",
              "category_id": "8odAcYG2T",
              "name": "Still water PANNA 1 Lt",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "m85eOsmpD",
          "menu_id": "leKRQiU6xx",
          "name": "GARNISH",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "z3ubw7pc2",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "DRIED CHILLI",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 1,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "My8V7U24u",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "CHILLI OIL",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 1,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "hY2bZ0LZ8",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "PARMESAN CHEESE",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 2.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "eDsgFkKkn",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "OREGANO",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 1,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "jTu3EnnV8",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "GARLIC AIOLI",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "iyV32XBFf",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "TRUFFLE MAYO",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "FmgZzLH2K",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "KETCHUP",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "5DTgkj9AV",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "BBQ SAUCE",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "VWo7HymEu",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "WHITE TRUFFLE OIL",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "s7_TKHXsE",
              "menu_id": "leKRQiU6xx",
              "category_id": "m85eOsmpD",
              "name": "BALSAMIC GLAZE",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "83308JZNI",
          "menu_id": "leKRQiU6xx",
          "name": "SPECIALS",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "l6xjwM_YF",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Prosciutto & Burrata",
              "display_name": "",
              "description": "24 months aged Prosciutto di Parma with fresh Burrata, served with homemade rosemary focaccia.",
              "subtitle": "",
              "price": 23,
              "tags": [],
              "image": {
                "_id": "3c3a2bed-5c0c-4b86-9449-80657f2040c5",
                "name": "BackgroundEraser_20240202_164807822.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Pr3yHYG9R",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Spaghetti Meatballs",
              "display_name": "",
              "description": "Spaghetti with homemade meatballs, napoletana sauce & parmesan",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "15a57ebf-2e1c-402b-b978-18b0ccb4f2ae",
                "name": "BackgroundEraser_20240628_164250553.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "tTQIYrYbO",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Marinated Italian Olives",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [
                "DjrhROwYl",
                "X91suDUzR"
              ],
              "image": {
                "_id": "5da5fce4-4563-4f58-bbbe-2be86d76ee4a",
                "name": "MARINATED_OLIVES.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "sV2yDhjz1",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Spicy crispy chicken wings",
              "display_name": "",
              "description": "Spicy crispy chicken wings served with smokey chiplote mayo",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "42587a7e-1ee2-4f09-a00b-1ec34a8d86d9",
                "name": "BackgroundEraser_20230929_155311108.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "xIWjAao_S",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Spicy chicken drummetts (3Pcs)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 11,
              "tags": [],
              "image": {
                "_id": "6a177d1d-d6ee-44b6-a041-49f9671150f4",
                "name": "20230113_171526.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "WZ0oHUosA",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Vegetarian lasagna",
              "display_name": "",
              "description": "oven-baked layered pasta, bechamel, mushrooms, leeks, mozzarella and parmiggiano reggiano",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "EHHCi74RL",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special",
              "display_name": "",
              "description": "Red base with mozzarella, mushrooms, pancetta, truffle, smoked mozzarella, and rosemary",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "NxLaCi7Ww",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Spaghetti Vongole",
              "display_name": "",
              "description": "Spaghetti with fresh vongole, cherry tomato, parsley",
              "subtitle": "",
              "price": 32,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "zngLrL9F9",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Rigatoni beef ragu",
              "display_name": "",
              "description": "Rigatoni with slow cooked beef ragu, pecorino romano and cracked pepper",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "108fc138-baab-4087-a501-05047eb4dd62",
                "name": "BackgroundEraser_20230416_172427360.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "pTOhdMX1a",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Fusilli Truffle",
              "display_name": "",
              "description": "Fusilli with creamy mushroom sauce and white truffle oil.",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YeTUucAKm",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Rigatoni, Slow Cook Pork Neck Ragu, Pecorino",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "92f63bff-872a-46f4-bf4a-5dd8ec91426f",
                "name": "rigatoni_pork.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "0VLE3qGzl",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Spaghetti Crab",
              "display_name": "",
              "description": "With blue swimmer crab, cherry tomato chilli and lemon zest",
              "subtitle": "",
              "price": 32,
              "tags": [],
              "image": {
                "_id": "60768aba-d641-440d-9e8c-4db3ad9aabb6",
                "name": "spagh_crab.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "4jSBjxmd_",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special folded ",
              "display_name": "",
              "description": "Folded pizza filled with fresh ricotta, mild salami, black pepper, topped with tomato, mozzarella and basil",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "2a70fc8e-011a-4678-bb0e-1a7e8d738d4c",
                "name": "BackgroundEraser_20230303_155208226.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YKb6oxF8f",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special FRIARIELLI",
              "display_name": "",
              "description": "White base with mozzarella, friarielli, italian sausage, garlic & fresh chilli",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "2a48feb7-ffab-4245-a9ea-6edb001626fe",
                "name": "BackgroundEraser_20230604_164152826.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_iVUY9EUh",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Gnocchi beetroot & pinenuts",
              "display_name": "",
              "description": "Homemade potato gnocchi with creamy beetroot sauce, pecorino romano & toasted pinenuts",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "40f8e0ab-1f51-446f-8c3e-4644189ff553",
                "name": "20221028_163921.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "hf0hm29Ca",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Arancini Bolognese (3 PCS) ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 13,
              "tags": [],
              "image": {
                "_id": "850ebf75-b448-4db2-b250-9259f42a6b7b",
                "name": "arancini_bolo.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "wOB7foD2J"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "9NJWXMzuW",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Fettuccine slow cooked beef",
              "display_name": "",
              "description": "Egg fettuccine, slow cooked beef ragout, parmigiano reggiano and chilli",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "20e47c0b-2a9e-4b57-8bc9-477bf25bf759",
                "name": "BackgroundEraser_20230929_155440882.png",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "uCSZ6qxyk",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Gnocchi Lamb",
              "display_name": "",
              "description": "Homemade potato gnocchi with slow-cooked lamb ragu and parmigiano reggiano",
              "subtitle": "",
              "price": 31,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "51kOWDtRJ",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Spaghetti spicy nduja and fresh stracciatella",
              "display_name": "",
              "description": "Spaghetti with spicy nduja, fresh stracciatella cheese and basil",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "BSCh0QOEO"
              ]
            },
            {
              "type": "standard",
              "_id": "zZcRRN2je",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Spaghetti Burrata",
              "display_name": "",
              "description": "Spaghetti with confit fresh tomato, kalamata olives, burrata cheese and basil",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "1b15d276-8619-4337-b27c-cff552af10a2",
                "name": "BackgroundEraser_20231201_165743610.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "u1kOQlZ1u",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Bruschetta Nduja & fresh burrata (3PCS)",
              "display_name": "",
              "description": "Bruschetta with spicy nduja, fresh burrata & basil",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Bhj6ZM5ki",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special Beetroot Base",
              "display_name": "",
              "description": "Beetroot base w/ mozzarella, blue cheese, rocket and pine nuts",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": {
                "_id": "3f4cc028-6279-4596-9281-3fe0c67cd4d0",
                "name": "pizza_beet.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "fmpy6tEpS",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special anchovy breadcrumb",
              "display_name": "",
              "description": "White base with mozzarella, cherry tomato, anchovies, fresh buffalo mozzarella and spicy breadcrumb",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "06e37a28-72db-41d3-8718-76b9c84cc8dd",
                "name": "20221013_165140.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": "Pizza Special"
            },
            {
              "type": "standard",
              "_id": "lu7Yy9tWB",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special PUMPKIN PANCETTA",
              "display_name": "",
              "description": "White base with mozzarella and pumpkin sauce, mushrooms, pancetta, Parmesan reduction, and Rosemary.",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RLhfQHpMP",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special ZUCCHINI NDUJA",
              "display_name": "",
              "description": "White base with mozzarella, hot salami, fried zucchini, gorgonzola, nduja sauce and parmesan",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "vSQqIpFBi",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special PESTO",
              "display_name": "",
              "description": "White base with mozzarella, basil pesto, fried eggplant, Italian sausage and fresh chilli ",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rth5vFONS",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special sundried tom",
              "display_name": "",
              "description": "White base with mozzarella, red onions, sundried tomatoes, olives, chilli, fresh buffalo mozzarella and basil",
              "subtitle": "",
              "price": 28,
              "tags": [],
              "image": {
                "_id": "a6b8bcf1-4b26-4166-92e8-8d25f32f9b38",
                "name": "BackgroundEraser_20230331_162209305.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ze8ZtMA58",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special PORK BELLY",
              "display_name": "",
              "description": "White base with radicchio, roasted porkbelly, balsamic reduction and rosemary",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "s8sby_9ul",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special PUMPKIN",
              "display_name": "",
              "description": "White base with pumpkin, flat pancetta, shaved parmesan & rosemary",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "e46531ce-02d7-45fc-80ad-09e4274dc6f7",
                "name": "BackgroundEraser_20230628_165639572.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EAN3PMweX",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special leeks",
              "display_name": "",
              "description": "Tomato, mozzarella, hot salami, leeks, fresh ricotta & cracked black pepper",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Si0UDlVbp",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special PRAWNS LEMON ZEST",
              "display_name": "",
              "description": "White base with mozzarella, cherry tomato, zucchini, prawns, chilli and fresh lemon zest",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "68c4013d-3b57-41d3-9cdc-03a813a1a62e",
                "name": "BackgroundEraser_20231116_155036013.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "1-tz6plRx",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special 3C",
              "display_name": "",
              "description": "Tomato, mozzarella, roasted chicken, capsicum and chilli",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "image": {
                "_id": "d7eea106-a5ce-4910-94f9-c3c19b841d1e",
                "name": "BackgroundEraser_20230324_161619427.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "adu8JbUGy",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special beetroot prosciutto",
              "display_name": "",
              "description": "Beetroot base with mozzarella, rocket, prosciutto and fresh buffalo mozzarella",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "b4846be9-028f-46ca-9d95-bcf268c33364",
                "name": "BackgroundEraser_20230624_170022499.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "NDB9vfhQL",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special potato pancetta",
              "display_name": "",
              "description": "White base with mozzarella, potato, smoked pancetta and cracked pepper",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "image": {
                "_id": "86ac871f-7cc5-4504-8bcb-0158a50f244f",
                "name": "20221124_170119.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "csrYQzpa5",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special hot salami eggplant",
              "display_name": "",
              "description": "White base with mozzarella, eggplant, hot salami, fresh buffalo mozzarella & basil",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": {
                "_id": "165640bd-00af-4955-8793-64e4ba13c417",
                "name": "BackgroundEraser_20230520_170546730.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "mMAOfDGVU",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special SOUR CREAM",
              "display_name": "",
              "description": "White base with mozzarella, potato, bacon,  red onions, sour cream and black cracked pepper",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_Oz_K-7d_",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special broccolini",
              "display_name": "",
              "description": "White base with mozzarella, broccolini, anchovies, olives, crispy breadcrumb and fresh chilli",
              "subtitle": "",
              "price": 28,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "nGiSEXRh-",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special CABBAGE",
              "display_name": "",
              "description": "White base with mozzarella, spinach, capsicum, zucchini, oregano, fresh ricotta and red cabbage puree ",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "y2_AznDeW",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special JALAPENO",
              "display_name": "",
              "description": "Tomato, mozzarella, hot salami, onions & fresh Jalapeno",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": {
                "_id": "4b3fd395-34fa-4f2c-a97e-61363a963e74",
                "name": "BackgroundEraser_20230429_161113327.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "yjpHj3tJ5",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special NDUJA",
              "display_name": "",
              "description": "Tomato, mozzarella, hot salami, nduja, onions and olives",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": {
                "_id": "a2e34626-c99e-43b5-80af-a0ceafd54fac",
                "name": "20221026_181607.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "KfyHvW-5h",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special MUSH BLUE SAUSA",
              "display_name": "",
              "description": "Red base with tomato, mozzarella, mushrooms, italian sausage and blue cheese",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rruWyZ9uz",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special ITALIAN SAUSAGE",
              "display_name": "",
              "description": "red base with mozzarella, capsicum, onions, Italian sausage, fresh chilli",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ut9rAz30E",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special ZUCCHINI FLOWER",
              "display_name": "",
              "description": "White base with mozzarella, zucchini flowers, spicy nduja and fresh ricotta",
              "subtitle": "",
              "price": 28,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ISj3iQnHX",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special radicchio balsamic glaze",
              "display_name": "",
              "description": "White base with mozzarella, radicchio, blue cheese, pancetta & balsamic glaze",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "J4R1pOE6w",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special fried zucchini",
              "display_name": "",
              "description": "White base with mozzarella, ham, fried zucchini, and smoked mozzarella",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "62JaRZiHH",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special ZUCCHINI PUREE",
              "display_name": "",
              "description": "White base with mozzarella, ham, cherry tomatoes, roasted zucchini puree, buffalo mozzarella, chilli and basil",
              "subtitle": "",
              "price": 29,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Tq7xRLHBC",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special feta",
              "display_name": "",
              "description": "White base with mozzarella, salted spinach, cherry tomatoes, onion, Italian sausage, Feta cheese, and chilli",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "faZjDjh1y",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special pesto and roasted chicken",
              "display_name": "",
              "description": "White base with mozzarella, basil pesto, chicken, cherry tomato, pinenuts & chilli",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "8435d576-13c7-4641-ae13-287d692d458b",
                "name": "IMG_20241031_WA0004.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "fi3FUseCc",
              "menu_id": "leKRQiU6xx",
              "category_id": "83308JZNI",
              "name": "Pizza Special  rocket pesto",
              "display_name": "",
              "description": "white base with mozzarella, rocket pesto, anchovy, cherry tomatoes, fresh buffalo mozzarella and chilli",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "85a610b8-b064-4c52-834f-f1896160929d",
                "name": "20221110_165804.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": true,
            "services": [],
            "hours": []
          }
        }
      ],
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    }
  ]

export const pizza_option_sets = [
    {
      "_id": "l-UftJj0_",
      "name": "GF Base +5.5",
      "display_name": "Make Gluten Free",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "7INOZdMeGP",
          "name": "Gluten Free Base (10 inch)",
          "price": "5.5",
          "quantity": 0,
          "prices": {},
          "status": "not-available"
        }
      ]
    },
    {
      "_id": "NSDczrBqo",
      "name": "GF Base +6.5 ",
      "display_name": "Make Gluten Free",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "oZ3LmH6yk5",
          "name": "Gluten Free Base (12 inch)",
          "price": "6.5",
          "quantity": 0,
          "prices": {},
          "status": null
        }
      ]
    },
    {
      "_id": "h7Nj1PhPu",
      "name": "GF Base +5  - Nutella Pizza",
      "display_name": "Make Gluten Free",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "fMrY9v01m5",
          "name": "Gluten Free Base (12 inch)",
          "price": "5",
          "quantity": 0,
          "prices": {},
          "status": "not-available"
        }
      ]
    },
    {
      "_id": "BOnFxXZLZ",
      "name": "Pizza Extras",
      "display_name": "Extras",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "PGUk5v_Lu",
          "name": "Mozzarella",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "dDLlXDoVTw",
          "name": "Tomato",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "HgnC_IXnY",
          "name": "Buffalo Mozzarella",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "wl6PydJwwF",
          "name": "Parmesan",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "g-CwtPvEP8",
          "name": "Fresh Ricotta",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "yJOlka1Alk",
          "name": "Salted Ricotta",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "FjMe4Rcp1x",
          "name": "Mushrooms",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "SM3F6wdjg0",
          "name": "Artichokes",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "IfdOnAhfw",
          "name": "Olives",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Qq70fEWkFe",
          "name": "Capsicum",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "rRQjy-9ax4",
          "name": "Eggplant",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "MJa__hw9-L",
          "name": "Zucchini",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "dFxgiwjiGY",
          "name": "Potato",
          "price": "2.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "z7EOFqyIbT",
          "name": "Rocket",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Othtx5jfF",
          "name": "Baby Spinach",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "cwCAMBswN0",
          "name": "Pineapple",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Wp86Y6kpHU",
          "name": "Onion",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Uo6_h06LKw",
          "name": "Ham",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "M9fd9f7Pj",
          "name": "Mild Salami",
          "price": "2.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "DB4wfO2VNs",
          "name": "Hot Salami",
          "price": "2.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "In3kT40rjs",
          "name": "Proscuitto ",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "xkKDLZ8y8h",
          "name": "Pepperoni",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "9moj5Z0o47",
          "name": "Cabanossi",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "-jn3Cexv-",
          "name": "Chicken",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "JJeMrNNT-",
          "name": "Italian Sausage",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "6jNKO8Kr-e",
          "name": "Bacon",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "89bRDMBP5u",
          "name": "Nduja",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "F-dLpVndId",
          "name": "Prawns ",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "bcfYxjiROb",
          "name": "Anchovies ",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "GOG7ewl-K",
          "name": "Mixed Seafood",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "8b5uV2JnZ",
          "name": "Truffle Oil",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "obwTvQrhEK",
          "name": "BBQ Sauce",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "cMy32-UROB",
          "name": "Garlic ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "D0LlEcKpHl",
          "name": "Basil ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "lygGTTSdU",
          "name": "Parsley ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "2wM2xirHjX",
          "name": "Chilli",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "imhgP4Hi4L",
          "name": "Rosemary ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "UIzph2vbKu",
          "name": "Oregano",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "gG9VpZi5Uw",
          "name": "Gorgonzola",
          "price": "2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "lbkkabwq8",
      "name": "Choice of Pasta (Spag)",
      "display_name": "Choice of Pasta",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "Sj4a79yox",
          "name": "Gluten Free Penne",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Za4LnVMKu",
          "name": "Penne",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "3t-sdSrIii",
          "name": "Fettucine ",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "KPzW21IL7",
          "name": "Homemade Gnocchi",
          "price": "3",
          "quantity": 0,
          "prices": {},
          "status": null
        }
      ]
    },
    {
      "_id": "g3zx8doUw",
      "name": "Choice of Pasta (Penne)",
      "display_name": "Choice of Pasta",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1Oad13wLRG",
          "name": "Gluten Free Penne",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "0PDfhAHoPF",
          "name": "Spaghetti ",
          "price": "1",
          "quantity": 0,
          "prices": {},
          "status": null
        },
        {
          "_id": "yomhvxvO55",
          "name": "Fettucine ",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "OIJhmk7KV7",
          "name": "Homemade Gnocchi",
          "price": "3",
          "quantity": 0,
          "prices": {},
          "status": null
        }
      ]
    },
    {
      "_id": "qKSpm885e",
      "name": "Choice of Pasta (Fettucine) ",
      "display_name": "Choice of Pasta",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "TE5vIaqND",
          "name": "Gluten Free Penne",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "aA1oQ_ZlgZ",
          "name": "Penne",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "JqPbJ2Um4N",
          "name": "Spaghetti ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "EQi_-RCsiN",
          "name": "Homemade Gnocchi",
          "price": "3",
          "quantity": 0,
          "prices": {},
          "status": null
        }
      ]
    },
    {
      "_id": "tgHlLCpVB",
      "name": "Choice of Pasta (Gnocchi)",
      "display_name": "Choice of Pasta",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "R5VEBwUHh",
          "name": "Gluten Free Penne",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "MWlVKpzDvn",
          "name": "Penne",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "0ASOwTMQ4R",
          "name": "Spaghetti ",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "mR-NFDLlD2",
          "name": "Fettucine ",
          "price": "2",
          "quantity": 0,
          "prices": {},
          "status": null
        }
      ]
    },
    {
      "_id": "HXNK9t9qp",
      "name": "Pasta Add-On",
      "display_name": "Add-On",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "PCiEHBkxQ",
          "name": "Side Of Chilli",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "MHN-FQeJcD",
          "name": "Extra Parmesan",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Wcv6mPEyFM",
          "name": "Side Of Parmesan ",
          "price": "2.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "D1Ph_BpRP",
          "name": "Pink Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "D5P1eAgOd",
      "name": "Lasagna Add-On",
      "display_name": "Add-On",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "FXcp-Waq7m",
          "name": "Side Of Chilli",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "iZ63pslUdi",
          "name": "Extra Parmesan",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "WUp7zuzOyX",
          "name": "Side Of Parmesan ",
          "price": "2.5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "YDJhELm3d",
      "name": "Sides (Main)",
      "display_name": "Choose Side",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "PfNVyuXzC",
          "name": "Garden Salad",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "aXrdb0Zm7",
          "name": "Rosemary Salted Chips",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "wOB7foD2J",
      "name": "Add Ketchup +2 / Aioli Sauce",
      "display_name": "Add Sauce",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "lMTxlWZ5kz",
          "name": "Ketchup",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Mzp-3K0kp",
          "name": "Aioli Sauce ",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "FLFiqOBKF",
          "name": "Truffle Mayo",
          "price": "3",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "0Xj-MTK2D",
          "name": "BBQ Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "E-nok4c3R",
      "name": "Add Ketchup +2",
      "display_name": "Add Sauce",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "YXzDgT3K2",
          "name": "Ketchup",
          "price": "1",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "tvwZah91f",
      "name": "Add Banana +1 / Strawberry +2",
      "display_name": "Add-On",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "YiM--EJ3j",
          "name": "Banana",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "WrDeWNS_pC",
          "name": "Strawberry",
          "price": "3",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "tHwenw9AW",
      "name": "SP Sparkling",
      "display_name": "Size",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "Ur08k7JW5M",
          "name": "1 Litre",
          "price": "9",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "oKq3c0T2e",
      "name": "Soft Drinks",
      "display_name": "Size",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "zvhTVStoj",
          "name": "375ml Can",
          "price": "4.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "DSsRAt1w4o",
          "name": "1.25Lts Bottle",
          "price": "7",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "ZL4ZLyl8pg",
      "name": "Soft Drinks Option",
      "display_name": "Soft Drink Option",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "lLBhxpvxD",
          "name": "Coke",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "2XitHseGt6",
          "name": "Coke Zero",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "WQBg3ItGP",
          "name": "Sprite",
          "price": "",
          "quantity": 0,
          "prices": {},
          "status": "not-available"
        },
        {
          "_id": "ZPJg4LfpPw",
          "name": "Solo",
          "price": "",
          "quantity": 0,
          "prices": {},
          "status": "not-available"
        }
      ]
    },
    {
      "_id": "g09shYvis",
      "name": "Kid Pizza Extra",
      "display_name": "Extra",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "sHM2MUzOq",
          "name": "Mushroom",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "LByG_KSev",
          "name": "Olives",
          "price": "1",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "x-qfVEB1O",
      "name": "Add Icecream",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "7unQxDYEM",
          "name": "Vanilla Icecream",
          "price": "5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "KtHji-brM",
      "name": "WATER",
      "display_name": "",
      "show_in_menu": true,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": []
    }
  ];


function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createPizzaMenu = () => {
    return {
        pizza_menu:updateIds(pizza_menu), pizza_option_sets:updateIds(pizza_option_sets)
    }
}

