import shortid from 'shortid';

export const vietnameseMenu = [
    {
      "_id": "jt70FRFXK",
      "name": "Vietnamese Menu",
      "display_name": "",
      "description": "",
      "conditions": {
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false,
        "hide_unavailable_menu": true
      },
      "categories": [
        {
          "_id": "EC_-br3lA",
          "menu_id": "jt70FRFXK",
          "name": "BANH MI",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "vwADn8xbO",
              "menu_id": "jt70FRFXK",
              "category_id": "EC_-br3lA",
              "name": "Crispy Pork Belly (Banh Mi)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 15,
              "tags": [],
              "image": {
                "_id": "3bd34dd4-433a-4de3-a07a-8a1d31de6771",
                "name": "Pork_belly_Banh_mi.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "px69ZEbZG"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "jU6VoyhRK",
              "menu_id": "jt70FRFXK",
              "category_id": "EC_-br3lA",
              "name": "BBQ Pork (Banh Mi)",
              "display_name": "",
              "description": "A Vietnamese Baguette (Roll) with Mayonnaise. Pate. Pickled, Carrots. Cucumber. Onion. Spring Onion.",
              "subtitle": "",
              "price": 13,
              "tags": [],
              "image": {
                "_id": "13d94857-1fcc-415b-883f-6835700a5c4f",
                "name": "BBQ_pork_Banh_mi.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "ECR2cmqse",
                "px69ZEbZG",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Cx-Nj4DdK",
              "menu_id": "jt70FRFXK",
              "category_id": "EC_-br3lA",
              "name": "BBQ Chicken (Banh Mi)",
              "display_name": "",
              "description": "A Vietnamese Baguette (Roll) with Mayonnaise. Pate. Pickled, Carrots. Cucumber. Onion. Spring Onion.",
              "subtitle": "",
              "price": 13,
              "tags": [],
              "image": {
                "_id": "ecb3ee5c-9297-4bad-bdfa-6e86b8753086",
                "name": "Chicken_Banh_mi.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "ECR2cmqse",
                "px69ZEbZG",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "n5pnZTAj6",
              "menu_id": "jt70FRFXK",
              "category_id": "EC_-br3lA",
              "name": "Lemongrass Beef (Banh Mi)",
              "display_name": "",
              "description": "A Vietnamese Baguette (Roll) with Mayonnaise. Pate. Pickled, Carrots. Cucumber. Onion. Spring Onion.",
              "subtitle": "",
              "price": 13,
              "tags": [],
              "image": {
                "_id": "9ff15a00-bf67-497a-942b-6cebb55837c2",
                "name": "Lemongrass_beef_Banh_mi.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "ECR2cmqse",
                "px69ZEbZG",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "8iEk-4MBj",
              "menu_id": "jt70FRFXK",
              "category_id": "EC_-br3lA",
              "name": "Marinated Tofu (Banh Mi)",
              "display_name": "",
              "description": "A Vietnamese Baguette (Roll) with Mayonnaise. Pate. Pickled, Carrots. Cucumber. Onion. Spring Onion.",
              "subtitle": "",
              "price": 13,
              "tags": [],
              "image": {
                "_id": "82fe5e79-0c79-48f5-a28f-ad655a390c3b",
                "name": "Tofu_Banh_mi.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "ECR2cmqse",
                "px69ZEbZG",
                "Dhk2sP4_n",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305,
          "description": "(Vietnamese Baguette)"
        },
        {
          "_id": "-ZjEkiBkt",
          "menu_id": "jt70FRFXK",
          "name": "SALAD BOWLS (GF)",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "CcVNkXqgl",
              "menu_id": "jt70FRFXK",
              "category_id": "-ZjEkiBkt",
              "name": "Mekong - Crispy Pork Belly (Salad Bowl)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "80c182a5-58a5-4470-ac0d-9e3aa59b01e8",
                "name": "Pork_belly_salad_bowl.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "-eoveEYx0",
                "px69ZEbZG"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": "Pork Belly Salad Bowl"
            },
            {
              "type": "standard",
              "_id": "a9xCgBZKt",
              "menu_id": "jt70FRFXK",
              "category_id": "-ZjEkiBkt",
              "name": "Saigon - BBQ Pork (Salad Bowl)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "2cdac31e-fb80-4864-ab0a-296dec621460",
                "name": "Bbq_pork_salad_bowl.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "ECR2cmqse",
                "px69ZEbZG",
                "roglgrDHW",
                "-eoveEYx0"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SiNMtnow9",
              "menu_id": "jt70FRFXK",
              "category_id": "-ZjEkiBkt",
              "name": "Hoi An - Lemongrass Beef (Salad Bowl)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "1f010669-add6-4583-85ec-aa729ef66eff",
                "name": "Lemongrass_beef_salad_bowl.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "px69ZEbZG",
                "roglgrDHW",
                "-eoveEYx0"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "1zHIuU3TL",
              "menu_id": "jt70FRFXK",
              "category_id": "-ZjEkiBkt",
              "name": "Dalat - Marinated Tofu (Salad Bowl)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "ca72a608-ea06-4950-a33f-f9c5b0016cc3",
                "name": "Tofu_salad_bowl.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "px69ZEbZG",
                "roglgrDHW",
                "-eoveEYx0"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "QT4ezSibQ",
              "menu_id": "jt70FRFXK",
              "category_id": "-ZjEkiBkt",
              "name": "Pork Spring Rolls (Salad Bowl)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "297819dc-37fd-4479-803d-fbc2d5558db8",
                "name": "32236_724870_022320103212.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "px69ZEbZG",
                "roglgrDHW",
                "-eoveEYx0"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "S6oNfFD3y",
              "menu_id": "jt70FRFXK",
              "category_id": "-ZjEkiBkt",
              "name": "BBQ Chicken (Salad Bowl)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "5bee0401-ee0e-45dc-9817-6e25af1fca9c",
                "name": "Chicken_salad_bowl.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "px69ZEbZG",
                "roglgrDHW",
                "-eoveEYx0"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dspO-utD9",
              "menu_id": "jt70FRFXK",
              "category_id": "-ZjEkiBkt",
              "name": "Veg Spring Rolls (Salad Bowl)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "297819dc-37fd-4479-803d-fbc2d5558db8",
                "name": "32236_724870_022320103212.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "px69ZEbZG",
                "roglgrDHW",
                "-eoveEYx0"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "ru6HzfF7C",
          "menu_id": "jt70FRFXK",
          "name": "SOUP",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "CVLGa3awR",
              "menu_id": "jt70FRFXK",
              "category_id": "ru6HzfF7C",
              "name": "Pho",
              "display_name": "",
              "description": "Beef Stock Noodle Soup",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "8c91a934-7e5a-4d65-bc49-64481d48a556",
                "name": "32236_724877_030220121428.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "NnH4RJXJo",
                "ldReV09wS",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063307
        },
        {
          "_id": "Q6MqmSTUo",
          "menu_id": "jt70FRFXK",
          "name": "RICE PAPER ROLLS",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "l_PnLnuEA",
              "menu_id": "jt70FRFXK",
              "category_id": "Q6MqmSTUo",
              "name": "BBQ Pork (Rice Paper Roll)",
              "display_name": "",
              "description": "Including Rice Noodles. Carrots. Vietnamese Mint. Bean Sprouts. Lettuce. Cucumber and Hoisin Sauce.",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "7fee847e-5f5e-4db5-a561-589cfddecbbd",
                "name": "Rice_paper_Rolls.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "94Yn4-Rw9",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "IWZAJliu3",
              "menu_id": "jt70FRFXK",
              "category_id": "Q6MqmSTUo",
              "name": "BBQ Chicken (Rice Paper Roll)",
              "display_name": "",
              "description": "Including Rice Noodles. Carrots. Vietnamese Mint. Bean Sprouts. Lettuce. Cucumber and Hoisin Sauce.",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "6140431e-e57a-40d5-98ae-3a467836b182",
                "name": "Rice_paper_Rolls.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "94Yn4-Rw9",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "lUz_OlPUa",
              "menu_id": "jt70FRFXK",
              "category_id": "Q6MqmSTUo",
              "name": "Lemongrass Beef (Rice Paper Roll)",
              "display_name": "",
              "description": "Including Rice Noodles. Carrots. Vietnamese Mint. Bean Sprouts. Lettuce. Cucumber and Hoisin Sauce.",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "b91a1539-e46a-451f-9f8c-d50d2fa6c99a",
                "name": "Rice_paper_Rolls.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "94Yn4-Rw9",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "JaJPDCVOQ",
              "menu_id": "jt70FRFXK",
              "category_id": "Q6MqmSTUo",
              "name": "Marinated Tofu (Rice Paper Roll)",
              "display_name": "",
              "description": "Including Rice Noodles. Carrots. Vietnamese Mint. Bean Sprouts. Lettuce. Cucumber and Hoisin Sauce.",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "d5e8cab0-e812-4336-9973-e82f90fbc824",
                "name": "Rice_paper_Rolls.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "94Yn4-Rw9",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "XMs8JBFI0",
          "menu_id": "jt70FRFXK",
          "name": "SPRING ROLLS",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "zwh6IG90y",
              "menu_id": "jt70FRFXK",
              "category_id": "XMs8JBFI0",
              "name": "1 Serve (3pcs) (Spring Roll)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.5,
              "tags": [],
              "image": {
                "_id": "6f3397a6-677b-49c3-a4dd-91bce0db5cd2",
                "name": "Spring_Rolls.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "o1YsfHUwV",
                "-Xpy5nJZQ",
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3jKDsQr-z",
              "menu_id": "jt70FRFXK",
              "category_id": "XMs8JBFI0",
              "name": "1 Single (Spring Roll)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3.5,
              "tags": [],
              "image": {
                "_id": "4c811616-510f-4775-811b-c566f7c4628c",
                "name": "Spring_Rolls.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "roglgrDHW"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "-_Q_yOmUy",
              "menu_id": "jt70FRFXK",
              "category_id": "XMs8JBFI0",
              "name": "Pork Spring Roll",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3.5,
              "tags": [],
              "image": {
                "_id": "ba064af3-ede1-40b1-9c36-ca5148350380",
                "name": "Spring_Rolls.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "o1YsfHUwV",
                "roglgrDHW",
                "GET4qdaWt"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "GET4qdaWt"
            },
            {
              "type": "standard",
              "_id": "_i8lctt16",
              "menu_id": "jt70FRFXK",
              "category_id": "XMs8JBFI0",
              "name": "Veggie  Spring Roll",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3.5,
              "tags": [],
              "image": {
                "_id": "74a197b4-569e-4317-8e82-9f5e224a81d9",
                "name": "Spring_Rolls.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "o1YsfHUwV",
                "roglgrDHW",
                "GET4qdaWt"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "GET4qdaWt"
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "fwK8czhbk",
          "menu_id": "jt70FRFXK",
          "name": "DRINKS",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "gU-mHR7xd",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Mt Franklin Lightly Sparkling Water (330ml)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "33ad0991-f2c5-43ef-b63e-a0c0551cd898",
                "name": "WhatsApp_Image_2024_08_06_at_13_08_08.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Xp71jBegW",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Mt Franklin Still Water (600ml)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "359a2e00-c486-49dd-af4c-3009eff70463",
                "name": "WhatsApp_Image_2024_08_06_at_13_07_43.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "xKEcjPtMN",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "San Pellegrino Water (750ml)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.5,
              "tags": [],
              "image": {
                "_id": "6d46dd26-9d10-4158-9cf8-b017743d517e",
                "name": "750ml_san_Pellegrino.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "goPzxDDrf",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Fresh Chilled Coconut",
              "display_name": "",
              "description": "Opened and sealed.",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "1ee5f48f-5be4-45c1-8873-06ec4858eb80",
                "name": "Fresh_coconut.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "sjwy5mLor",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Tombucha ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "4Qc4LUa0X",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Fanta Orange",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "f70a2a37-7b87-4db3-b1ab-825b2e9a069f",
                "name": "WhatsApp_Image_2024_08_06_at_13_09_10.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "xsqjft5BV",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Sprite Lemonade",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "4ba01990-5ad6-47e6-b24c-fcaba7d0e729",
                "name": "WhatsApp_Image_2024_08_06_at_13_09_23.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ZRdbGGoai",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Coca Cola Classic",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "6f1fbcfd-1b05-455f-862d-cb5d426678b3",
                "name": "WhatsApp_Image_2024_08_06_at_13_10_19.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "kjS0eFMuq",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Coca Cola Zero Sugar",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "ab04963e-d68f-4f98-ad26-426795dfd624",
                "name": "WhatsApp_Image_2024_08_06_at_13_10_05.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "eZXl7-HuO",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Cascade Lemon, Lime and Bitters ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "47d87b72-43d5-4d25-a4fb-8fe68735e077",
                "name": "WhatsApp_Image_2024_08_06_at_13_08_57.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_snVG1nW4",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Cascade Ginger Beer ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "23d93ea8-1db9-4f76-a460-995b74927a4f",
                "name": "WhatsApp_Image_2024_08_06_at_13_08_27.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "riSGQMu9w",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Capi Sparkling Grapefruit (250ml)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "2baec349-80cd-48fa-8e59-dba9db8d068d",
                "name": "Capi_grapefruit.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "zXEnxVNUs",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Soft Drinks ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "cb085191-1329-45db-b350-8ece32e422d0",
                "name": "WhatsApp_Image_2024_08_06_at_13_09_47.jpeg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "YQ4rKh_S2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "yD59AmS1h",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Iced Coffee Black (250ml)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "0609ec26-9962-4b8a-b2de-800621fdb2b7",
                "name": "Vietnamese_iced_coffee__cafe_sua_da_.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "pgbPfKxr3",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Cafe Sua Da ",
              "display_name": "",
              "description": "Traditional Vietnamese Iced Coffee in a takeaway cup with lid.",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": {
                "_id": "4d9338e1-a137-4e93-8a77-75d654a4eed4",
                "name": "Cafe_sua_da_premix.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "PlqvzphIe",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Lift (330ml)",
              "display_name": "",
              "description": "",
              "subtitle": "Glass Bottle",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "NJLdBdkHE",
              "menu_id": "jt70FRFXK",
              "category_id": "fwK8czhbk",
              "name": "Fresh Coconut Water (250ml)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "j7yxcuKtx",
          "menu_id": "jt70FRFXK",
          "name": "FRESHLY SQUEEZED JUICE",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "4LU6vH0e7v",
              "menu_id": "jt70FRFXK",
              "category_id": "j7yxcuKtx",
              "name": "Cleanser",
              "display_name": "",
              "description": "Apple, carrot, celery, ginger, orange",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "4E0btARWxt",
              "menu_id": "jt70FRFXK",
              "category_id": "j7yxcuKtx",
              "name": "Detox",
              "display_name": "",
              "description": "Apple, carrot, beetroot, celery, ginger",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "nGhYoNUaod",
              "menu_id": "jt70FRFXK",
              "category_id": "j7yxcuKtx",
              "name": "Mr Green",
              "display_name": "",
              "description": "Apple, cucumber, celery, ginger",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "f3iJzyVYxQ",
              "menu_id": "jt70FRFXK",
              "category_id": "j7yxcuKtx",
              "name": "Vision",
              "display_name": "",
              "description": "Carrot, orange, ginger",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "-PtbF6_cD",
          "menu_id": "jt70FRFXK",
          "name": "MEKONG BUBBLE TEA",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "bNwXSZ8Vs",
              "menu_id": "jt70FRFXK",
              "category_id": "-PtbF6_cD",
              "name": "Black Tea (Bubble Tea)",
              "display_name": "",
              "description": "Choose from: Pearl - Chocolate - Matcha - Taro - Strawberry -Mango",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "KtqALumZJ",
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YgXZqAd7I",
              "menu_id": "jt70FRFXK",
              "category_id": "-PtbF6_cD",
              "name": "Green Tea (Bubble Tea)",
              "display_name": "",
              "description": "Choose from:  Lychee - Orange - Peach - Kamguat - Mango - Lemon, Passion & Peach - Kamguat, Peach & Orange - Lemon, Peach & Lychee",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "7YfG6CB4X",
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "combo",
              "_id": "mtcQhtOYJ",
              "menu_id": "jt70FRFXK",
              "category_id": "-PtbF6_cD",
              "name": "Bubble Tea",
              "display_name": "",
              "description": "Milk Tea: Pearl - Chocolate - Matcha - Taro / Green Tea: Lychee - Orange - Peach - Kamguat - Mango - Lemon, Passion & Peach - Kamguat, Peach & Orange - Lemon, Peach & Lychee",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [
                {
                  "_id": "lCNZHVIYo",
                  "name": "Choose Your Bubble Tea",
                  "dishes": [
                    "bNwXSZ8Vs",
                    "YgXZqAd7I"
                  ],
                  "selected": null,
                  "lpo": 8
                }
              ],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063308
        },
        {
          "_id": "BcidJm7-m",
          "menu_id": "jt70FRFXK",
          "name": "BUBBLE TEA | BLACK",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "NK9rs4Stwz",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Kumquat Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "ODL3fVHjd6",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Lychee Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "0tbPQtuuJC",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Orange Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "p4XyRHnAjv",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Peach Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "eW4n5l_tFa",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Strawberry Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "cS3AWrmqMn",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Lemon, Passion & Peach Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "diteg2EqLm",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Lemon, Peach & Lychee Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "OFZQBDIp8Q",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Pearl Milk Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "2ff5e003-68f0-4261-8d85-dc65f5cf42c2",
                "name": "Pearl_milk_tea.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "xwJYawCDm",
                "XlR5-ymlG"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "OWQpEVKpOW",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Kumquat, Peach & Orange Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "YBoybBnQ-W",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Taro Milk Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "xwJYawCDm",
                "XlR5-ymlG"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "jlEYVb0o_K",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Chocolate Milk Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "b2Gs-P15HF",
              "menu_id": "jt70FRFXK",
              "category_id": "BcidJm7-m",
              "name": "Matcha Milk Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063308
        },
        {
          "_id": "3CKgPh_xU",
          "menu_id": "jt70FRFXK",
          "name": "COFFEE & TEA",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "i5cLKZQnB",
              "menu_id": "jt70FRFXK",
              "category_id": "3CKgPh_xU",
              "name": "Vietnamese Drip Filter Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "mB1pClMHX",
              "menu_id": "jt70FRFXK",
              "category_id": "3CKgPh_xU",
              "name": "Hot Coffee & Condensed Milk",
              "display_name": "",
              "description": "",
              "subtitle": "(Cà Phê Sũa Nóng)",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "TDlO952T9",
              "menu_id": "jt70FRFXK",
              "category_id": "3CKgPh_xU",
              "name": "Iced Coffee with Condensed Milk",
              "display_name": "",
              "description": "",
              "subtitle": "(Cà Phê Sūa Đá)",
              "price": 6.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Yzg4kclSI",
              "menu_id": "jt70FRFXK",
              "category_id": "3CKgPh_xU",
              "name": "Iced Black Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "(Cà Phê Đá)",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "oZ_f4SgI-",
              "menu_id": "jt70FRFXK",
              "category_id": "3CKgPh_xU",
              "name": "Jasmine/ Green/ Lotus Tea (Pot)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "xSNtA0mL9",
                "8TWD7KkRy"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Nr3t7WCC5",
              "menu_id": "jt70FRFXK",
              "category_id": "3CKgPh_xU",
              "name": "Iced Latte 250ml Can",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "q0oT368w2",
          "menu_id": "jt70FRFXK",
          "name": "BEER",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "FuNiZZaWi",
              "menu_id": "jt70FRFXK",
              "category_id": "q0oT368w2",
              "name": "Corona Beer",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": {
                "_id": "d11b633b-5d77-4cf6-9670-330d495bb3b5",
                "name": "Corona.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "6UnjWUfG_",
              "menu_id": "jt70FRFXK",
              "category_id": "q0oT368w2",
              "name": "Tsingtao Beer",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": {
                "_id": "36970378-9972-4b3a-b349-be91e5e5c8a9",
                "name": "Tsingtao.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "nr1eSAErl",
              "menu_id": "jt70FRFXK",
              "category_id": "q0oT368w2",
              "name": "Asahi Beer",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": {
                "_id": "8abd55ac-c4b2-4114-9897-7eefedcd183d",
                "name": "Asahi.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "FgvZvpq-r",
              "menu_id": "jt70FRFXK",
              "category_id": "q0oT368w2",
              "name": "Tiger Beer",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": {
                "_id": "5bb36813-2e2d-4cce-8089-59da3c0f2112",
                "name": "Tiger.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "t6gD26Mt5",
          "menu_id": "jt70FRFXK",
          "name": "WINE",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "Totiw4lkq",
              "menu_id": "jt70FRFXK",
              "category_id": "t6gD26Mt5",
              "name": "Chardonnay ",
              "display_name": "",
              "description": "",
              "subtitle": "(Per Glass)",
              "price": 9,
              "tags": [],
              "image": {
                "_id": "defb895f-e398-4c86-98ad-4aabb338fe8d",
                "name": "Chardonnay.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "On7Z_qHl0",
              "menu_id": "jt70FRFXK",
              "category_id": "t6gD26Mt5",
              "name": "Sauvignon Blanc",
              "display_name": "",
              "description": "",
              "subtitle": "(Per Glass)",
              "price": 9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "4UvqL3Mpl",
              "menu_id": "jt70FRFXK",
              "category_id": "t6gD26Mt5",
              "name": "Pinot Grigio",
              "display_name": "",
              "description": "",
              "subtitle": "(Per Glass)",
              "price": 9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "z2CYgXxMh",
              "menu_id": "jt70FRFXK",
              "category_id": "t6gD26Mt5",
              "name": "Chardonnay 750ml Bottle",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 12.5,
              "tags": [],
              "image": {
                "_id": "92dbf51d-e1c4-4f1f-9707-fbff36a9c9a8",
                "name": "Chardonnay.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "AZK4YyswH",
              "menu_id": "jt70FRFXK",
              "category_id": "t6gD26Mt5",
              "name": "Shiraz Cabernet 185ml Bottle ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "NUWqYG53K",
          "menu_id": "jt70FRFXK",
          "name": "RED WINE",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "THehDv9sK",
              "menu_id": "jt70FRFXK",
              "category_id": "NUWqYG53K",
              "name": "Jacobs Creek Chardonnay",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "pxe-Y64JI",
              "menu_id": "jt70FRFXK",
              "category_id": "NUWqYG53K",
              "name": "Jacobs Shiraz Cabernet",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "zhkjkZQcd",
              "menu_id": "jt70FRFXK",
              "category_id": "NUWqYG53K",
              "name": "Banrock Cabernet Merlot",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "XUpE6UnzA",
              "menu_id": "jt70FRFXK",
              "category_id": "NUWqYG53K",
              "name": "Rawsons Retreat Cab Sauv",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "V6q6Fevls",
              "menu_id": "jt70FRFXK",
              "category_id": "NUWqYG53K",
              "name": "Yellow Tail Pinot Noir",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "w3A6qJeLk",
              "menu_id": "jt70FRFXK",
              "category_id": "NUWqYG53K",
              "name": "Penfolds Koonunga Hill",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "gPBKPM0g4",
          "menu_id": "jt70FRFXK",
          "name": "SIDES",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "o7UvB-AuY",
              "menu_id": "jt70FRFXK",
              "category_id": "gPBKPM0g4",
              "name": "Steam Rice (Small)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "NShvYBdKT",
              "menu_id": "jt70FRFXK",
              "category_id": "gPBKPM0g4",
              "name": "Steam Rice (Large)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "qga5V_ZFF",
              "menu_id": "jt70FRFXK",
              "category_id": "gPBKPM0g4",
              "name": "Steamed Mixed Vegetables",
              "display_name": "",
              "description": "With cabbage, broccoli and carrots.",
              "subtitle": "",
              "price": 12,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063307
        },
        {
          "_id": "-DEbN0ngg",
          "menu_id": "jt70FRFXK",
          "name": "MADAME YUZU PREMIUM ICE CREAM",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3FPQwv46h",
              "menu_id": "jt70FRFXK",
              "category_id": "-DEbN0ngg",
              "name": "Premium Japanese Matcha Green Tea",
              "display_name": "",
              "description": "Madame Yuzu Premium Ice Cream 125ml & 500ml",
              "subtitle": "",
              "price": 6,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "grCq4je6E"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "grCq4je6E"
            },
            {
              "type": "standard",
              "_id": "L52-VhUWo",
              "menu_id": "jt70FRFXK",
              "category_id": "-DEbN0ngg",
              "name": "1980 Strawberry Milk",
              "display_name": "",
              "description": "Madame Yuzu Premium Ice Cream 125ml & 500ml",
              "subtitle": "",
              "price": 6,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "grCq4je6E"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "grCq4je6E"
            },
            {
              "type": "standard",
              "_id": "sWx9A09HO",
              "menu_id": "jt70FRFXK",
              "category_id": "-DEbN0ngg",
              "name": "Japanese Miso Caramel & Peanut Brittle ",
              "display_name": "",
              "description": "Madame Yuzu Premium Ice Cream 125ml & 500ml",
              "subtitle": "",
              "price": 6,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "grCq4je6E"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "grCq4je6E"
            },
            {
              "type": "standard",
              "_id": "r5_ZD7LvU",
              "menu_id": "jt70FRFXK",
              "category_id": "-DEbN0ngg",
              "name": "Dark Roasted Black Sesame",
              "display_name": "",
              "description": "Madame Yuzu Premium Ice Cream 125ml & 500ml",
              "subtitle": "",
              "price": 6,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "grCq4je6E"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "grCq4je6E"
            },
            {
              "type": "standard",
              "_id": "K5B8IL4FX",
              "menu_id": "jt70FRFXK",
              "category_id": "-DEbN0ngg",
              "name": "Japanese Yuzu & Sweet ",
              "display_name": "",
              "description": "Madame Yuzu Premium Ice Cream 125ml & 500ml",
              "subtitle": "",
              "price": 6,
              "tags": [
                "3yF22s4hhR",
                "KiVpR3VHS",
                "L45skX8SG"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "grCq4je6E"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "grCq4je6E"
            },
            {
              "type": "standard",
              "_id": "T_1JZLyJP",
              "menu_id": "jt70FRFXK",
              "category_id": "-DEbN0ngg",
              "name": "Absolute Lychee",
              "display_name": "",
              "description": "Madame Yuzu Premium Ice Cream 125ml & 500ml",
              "subtitle": "",
              "price": 6,
              "tags": [
                "3yF22s4hhR",
                "KiVpR3VHS",
                "L45skX8SG"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "grCq4je6E"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "grCq4je6E"
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "description": "125ml & 500ml",
          "abacus_open_item": 4063305
        }
      ]
    },
    {
      "_id": "DX0wnXsvs",
      "name": "Dinner Menu",
      "display_name": "",
      "description": "",
      "conditions": {
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false,
        "hide_unavailable_menu": true
      },
      "categories": [
        {
          "_id": "eKCvEty_W",
          "menu_id": "DX0wnXsvs",
          "name": "STARTERS ",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "F7CLr0FzK",
              "menu_id": "DX0wnXsvs",
              "category_id": "eKCvEty_W",
              "name": "Dinner Menu Unavailable",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "tADF1aOYH",
              "menu_id": "DX0wnXsvs",
              "category_id": "eKCvEty_W",
              "name": "1. Rice Paper Roll",
              "display_name": "",
              "description": "Two delicate rolls with a choice of pork, beef, chicken or tofu combined with fresh vegetables, Vietnamese mints and hoisin sauce. ",
              "subtitle": "",
              "price": 12,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tb_WDPcwh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Q0N5-e5vT",
              "menu_id": "DX0wnXsvs",
              "category_id": "eKCvEty_W",
              "name": "2. Homemade Spring Roll (3pcs)",
              "display_name": "",
              "description": "Black mushroom, taro and glass noodles with dipping sauce. ",
              "subtitle": "",
              "price": 10,
              "tags": [
                "F4NGiaFA3"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "lzzjR16zO",
              "menu_id": "DX0wnXsvs",
              "category_id": "eKCvEty_W",
              "name": "3. Chicken Wings (5pcs)",
              "display_name": "",
              "description": "Crispy homemade marinated chicken wings served with chilli sauce. ",
              "subtitle": "",
              "price": 12,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "kbUxN5FiM",
              "menu_id": "DX0wnXsvs",
              "category_id": "eKCvEty_W",
              "name": "4. Soft Shell Crab",
              "display_name": "",
              "description": "Crispy soft shell crab served with special seafood sauce. ",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "Dc0GcXa3c",
              "menu_id": "DX0wnXsvs",
              "category_id": "eKCvEty_W",
              "name": "5. Pan-Fried Dumpling (4pcs)",
              "display_name": "",
              "description": "Pan-fried prawn and cabbage dumplings served with soy sauce. ",
              "subtitle": "",
              "price": 12,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "m4eiLfzhz"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "Zr82S7j0v",
              "menu_id": "DX0wnXsvs",
              "category_id": "eKCvEty_W",
              "name": "6. Chicken Salad",
              "display_name": "",
              "description": "Steamed chicken, pickle carrot, cabbage and Vietnamese herbs, topping with dried shallots, peanuts and sesame seed. ",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063307
        },
        {
          "_id": "xtgnkZnNN",
          "menu_id": "DX0wnXsvs",
          "name": "MAIN COURSE",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "aDT7LTLtK",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "7. Vietnamese Crispy Pancake",
              "display_name": "",
              "description": "Crispy flour pancake served with chicken or pork, bean sprouts, lettuce, Vietnamese mint and nuoc cham. ",
              "subtitle": "",
              "price": 22,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "955NKhC0E"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "X822sZO0z",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "8. Chicken Curry",
              "display_name": "",
              "description": "Chicken curry with sweet potato, carrot, lemongrass and coconut cream. ",
              "subtitle": "",
              "price": 19,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "2Xu0Eexwr",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "9. Grilled Pork Skewers ",
              "display_name": "",
              "description": "Vietnamese grilled pork skewers served with rice noodles, rice paper, fresh vegetables and nuoc cham. ",
              "subtitle": "",
              "price": 22,
              "tags": [
                "3yF22s4hhR",
                "F4NGiaFA3"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "kMzbKQx7h",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "10. Grilled Beef In Betel Leaves",
              "display_name": "",
              "description": "Grilled beef wrapped in betel leaves served with rice noodles, rice paper, fresh vegetables and nuoc cham. ",
              "subtitle": "",
              "price": 25,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "uBoSRqYgZ",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "11. Caramelised Pork",
              "display_name": "",
              "description": "Marinated pork, free range egg with coconut juice and special sauce paste. ",
              "subtitle": "",
              "price": 20,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "YqQHBp3lF",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "12. Crispy Chicken Rice",
              "display_name": "",
              "description": "Crispy chicken, steamed rice, fresh tomato and cucumber served with soy sauce. ",
              "subtitle": "",
              "price": 21,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "OGDRuTzdL",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "13. Pork Chop Rice",
              "display_name": "",
              "description": "Steamed rice, BBQ pork chop, sunny side up egg, fresh tomato and cucumber with dressing. ",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "qgJJBXgZ0",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "14. Pho",
              "display_name": "",
              "description": "Vietnamese beef noodle soup with bean sprouts, fresh chilli, lemon served with hoisin and chilli sauce. ",
              "subtitle": "",
              "price": 18,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "WaZKuE3uu"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "A71gw2EBp",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "15. Lemongrass Chicken",
              "display_name": "",
              "description": "Vietnamese lemongrass chilli chicken with fresh vegetables. ",
              "subtitle": "",
              "price": 21,
              "tags": [
                "3yF22s4hhR"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "ZnC5F3Rh5",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "16. Lemongrass Tofu",
              "display_name": "",
              "description": "Vietnamese lemongrass tofu chilli with fresh vegetables. ",
              "subtitle": "",
              "price": 22,
              "tags": [
                "3yF22s4hhR",
                "KiVpR3VHS"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "BOLtR-qpr",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "17. Salt and Pepper Squid",
              "display_name": "",
              "description": "Deep-fried salt and pepper squid, fresh vegetables served with homemade special seafood sauce. ",
              "subtitle": "",
              "price": 22,
              "tags": [
                "F4NGiaFA3"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "qbGh5ZaP_",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "18. Tamarind Prawn",
              "display_name": "",
              "description": "Pan-fried wild caught prawn served with lettuce leaves and Vietnamese mint. ",
              "subtitle": "",
              "price": 28,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "vYvWB37Kw",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "19. Stir-Fried or Steamed Mixed Vegetables",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 15,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "f3zduraMe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "lLysTSfDF",
              "menu_id": "DX0wnXsvs",
              "category_id": "xtgnkZnNN",
              "name": "*** Rice Bowls",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063307
        },
        {
          "_id": "4ipZOiVA4",
          "menu_id": "DX0wnXsvs",
          "name": "MEKONG BUBBLE TEA ",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "yNwUfIkw9L",
              "menu_id": "DX0wnXsvs",
              "category_id": "4ipZOiVA4",
              "name": "Milk Tea",
              "display_name": "",
              "description": "Choose from: Pearl - Chocolate - Matcha - Taro",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "KtqALumZJ",
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "khXenk_qHf",
              "menu_id": "DX0wnXsvs",
              "category_id": "4ipZOiVA4",
              "name": "Green Tea",
              "display_name": "",
              "description": "Choose from:  Lychee - Orange - Peach - Kamguat - Mango - Lemon, Passion & Peach - Kamguat, Peach & Orange - Lemon, Peach & Lychee",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "7YfG6CB4X",
                "XlR5-ymlG",
                "xwJYawCDm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "combo",
              "_id": "Z-W_Booi4",
              "menu_id": "DX0wnXsvs",
              "category_id": "4ipZOiVA4",
              "name": "Bubble Tea",
              "display_name": "",
              "description": "Milk Tea: Pearl - Chocolate - Matcha - Taro / Green Tea: Lychee - Orange - Peach - Kamguat - Mango - Lemon, Passion & Peach - Kamguat, Peach & Orange - Lemon, Peach & Lychee",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [
                {
                  "_id": "lpo76d3ym",
                  "name": "Choose Your Bubble Tea",
                  "dishes": [
                    "yNwUfIkw9L",
                    "khXenk_qHf"
                  ],
                  "selected": null,
                  "lpo": 8
                }
              ],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063308
        },
        {
          "_id": "Voc0gNSfl",
          "menu_id": "DX0wnXsvs",
          "name": "COLD BEVERAGE",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "6VDYfJk64",
              "menu_id": "DX0wnXsvs",
              "category_id": "Voc0gNSfl",
              "name": "Soft Drink",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "LAKK8cQ-V",
              "menu_id": "DX0wnXsvs",
              "category_id": "Voc0gNSfl",
              "name": "Still Water",
              "display_name": "",
              "description": "",
              "subtitle": "600ml",
              "price": 3.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "277GO7kC1",
              "menu_id": "DX0wnXsvs",
              "category_id": "Voc0gNSfl",
              "name": "San Pellegrino",
              "display_name": "",
              "description": "",
              "subtitle": "750ml",
              "price": 7.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "k1M12zntr",
          "menu_id": "DX0wnXsvs",
          "name": "COFFEE & TEA",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "Hnjfdl__3",
              "menu_id": "DX0wnXsvs",
              "category_id": "k1M12zntr",
              "name": "Drip Filter Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "WHC33aP_Y",
              "menu_id": "DX0wnXsvs",
              "category_id": "k1M12zntr",
              "name": "Iced Coffee with Condensed Milk",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "RuYIR9Oy3",
              "menu_id": "DX0wnXsvs",
              "category_id": "k1M12zntr",
              "name": "Tea (Pot)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "ji1c3FInJ",
                "8TWD7KkRy"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "JtYerLRgM",
          "menu_id": "DX0wnXsvs",
          "name": "KOMBUCHA",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "7TcdN9kfY",
              "menu_id": "DX0wnXsvs",
              "category_id": "JtYerLRgM",
              "name": "Kombucha Ginger + Tumeric",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "AqZGOCEES",
              "menu_id": "DX0wnXsvs",
              "category_id": "JtYerLRgM",
              "name": "Kombucha with Rum/Vodka/Gin",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "9bFQej01Q"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "7CVNm-rV1",
          "menu_id": "DX0wnXsvs",
          "name": "BEER",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "VNCfYSSw5",
              "menu_id": "DX0wnXsvs",
              "category_id": "7CVNm-rV1",
              "name": "Asahi",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "knlh0DMJ2",
              "menu_id": "DX0wnXsvs",
              "category_id": "7CVNm-rV1",
              "name": "Corona",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "wJiwydw-N",
              "menu_id": "DX0wnXsvs",
              "category_id": "7CVNm-rV1",
              "name": "Tiger",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "Y2N72DBni",
              "menu_id": "DX0wnXsvs",
              "category_id": "7CVNm-rV1",
              "name": "Tsingtao",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        },
        {
          "_id": "rOttfbTPk",
          "menu_id": "DX0wnXsvs",
          "name": "WINE",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "C3VsEkKbm",
              "menu_id": "DX0wnXsvs",
              "category_id": "rOttfbTPk",
              "name": "The House White",
              "display_name": "",
              "description": "",
              "subtitle": "Per glass",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            },
            {
              "type": "standard",
              "_id": "01IAxKXMe",
              "menu_id": "DX0wnXsvs",
              "category_id": "rOttfbTPk",
              "name": "The House Red",
              "display_name": "",
              "description": "",
              "subtitle": "Per glass",
              "price": 8,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "x1HO0IkuG"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 4063305
        }
      ],
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    }
  ]

export const vietnamese_option_sets = [
    {
      "_id": "Dhk2sP4_n",
      "name": "Tofu selections",
      "display_name": "Would you like pate?",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "M_fiUXJjd",
          "name": "No Pate",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "ZwyjBX38P",
          "name": "With Pate",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "-Xpy5nJZQ",
      "name": "Spring Roll (Pork or Veg)",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "nAxRRip9Z",
          "name": "Pork",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "BREpW1HKXq",
          "name": "Veggie",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "GET4qdaWt",
      "name": "Quantity: Spring Roll ",
      "display_name": "Quantity",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "Iy3D3ihjTt",
          "name": "1 Single",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "FzMxcpDqQ_",
          "name": "1 Serve (3 pcs)",
          "price": "5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "o1YsfHUwV",
      "name": "Spring Roll (Extra)",
      "display_name": "Add More",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "6fe6k5bBB",
          "name": "Extra Piece",
          "price": "2.5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "px69ZEbZG",
      "name": "Heat",
      "display_name": "Choose Heat",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "TdY0Pi3Hu",
          "name": "No Chilli",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "SlGXWeCXB",
          "name": "🌶️ Low",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "tAtHso4cX",
          "name": "🌶️🌶️ Medium",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "xo7vALMbSF",
          "name": "🌶️🌶️🌶️ Hot",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "NnH4RJXJo",
      "name": "Meat Option: Pho",
      "display_name": "Meat Option",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "4nEnvcoiL",
          "name": "Thinly Sliced Beef",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "RC1CC1WJCJ",
          "name": "BBQ Chicken",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "-eoveEYx0",
      "name": "Extra:  Salad Bowl",
      "display_name": "Extras",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "gyO8DE-IM",
          "name": "Pork Belly",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "S_dlTESPy",
          "name": "BBQ Pork",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "cc0qMdrd2d",
          "name": "BBQ Chicken",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "qGvGn88OTm",
          "name": "Lemongrass Beef",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "E-QHMMUrS_",
          "name": "Tofu",
          "price": "5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "ECR2cmqse",
      "name": "Extra Filling",
      "display_name": "Extras",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "_PDS4KkQV",
          "name": "Extra Filling",
          "price": "4",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "W3hNCzWBd",
      "name": "Extra Cups",
      "display_name": "Extras",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "l_tlHjWO7",
          "name": "Extra Cups",
          "price": "4",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "xSNtA0mL9",
      "name": "Choice of Tea",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "RqXViptCI",
          "name": "Jasmine Tea",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "A9Yz0inYjG",
          "name": "Green Tea",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "IPbKSeySl6",
          "name": "Lotus Tea",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "tPxU5VEKB",
          "name": "Billy Tea",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "YQ4rKh_S2",
      "name": "Drink Option: Softdrink",
      "display_name": "Drink Option",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "IA_fi9QI2",
          "name": "Fanta Orange",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "MWtORinm9N",
          "name": "Sprite Lemonade",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "eZ_ioKS_Y",
          "name": "Coca Cola Classic",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "Mvjpn_yEza",
          "name": "Coca Cola Zero Sugar",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "rY2tBLQIEI",
          "name": "Cascade Lemon Lime Bitters",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "nASd28yJC_",
          "name": "Cascade Ginger Beer",
          "price": "",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "tb_WDPcwh",
      "name": "Pork, Beef, Chicken or Tofu",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "hbE2kvhP2",
          "name": "Pork",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "iU6t4YZEYC",
          "name": "Beef",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "mYJHkswjz0",
          "name": "Chicken",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "zB15DrmhNT",
          "name": "Tofu",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "m4eiLfzhz",
      "name": "Prawn or Cabbage Dumplings",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "DGWKVFrQV",
          "name": "Prawn Dumplings",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Ww0_WnTIZf",
          "name": "Cabbage Dumplings",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "955NKhC0E",
      "name": "Chicken or Pork",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "FotflqSJ5",
          "name": "Chicken",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "VaIoAijNuu",
          "name": "Pork",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "WaZKuE3uu",
      "name": "Beef, Chicken, Tofu",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "w9gwMB9dW",
          "name": "Beef",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "4OVKhJsQqW",
          "name": "Chicken",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "B9Ym2D4-0",
          "name": "Tofu",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "f3zduraMe",
      "name": "Stir-Fried or Steamed",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "Mrd92kHW-",
          "name": "Stir-Fried",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "nkeNIIGlUy",
          "name": "Steamed",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "ji1c3FInJ",
      "name": "Jasmine/Green/Lotus",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "urm8E0dx2",
          "name": "Jasmine",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "XAF2tlBoil",
          "name": "Green",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "AYchITGGrq",
          "name": "Lotus",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "9bFQej01Q",
      "name": "Rum, Vodka, Gin",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "SoIDtFE32",
          "name": "Rum",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Qd-FJyGFdj",
          "name": "Vodka",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "aFti41k18O",
          "name": "Gin",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "8TWD7KkRy",
      "name": "Extra Cup: Tea",
      "display_name": "Add-On",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "uLu1U1QW7",
          "name": "Extra Cup",
          "price": "2.5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "7YfG6CB4X",
      "name": "Flavour : Green Tea (Bubble Tea)",
      "display_name": "Flavour",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "03bFY58y2z",
          "name": "Lychee",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "YKbo2SXPs",
          "name": "Orange",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "fR7keJ9w2Z",
          "name": "Kumguat",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Ofosf0vwWw",
          "name": "Peach",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "2jAonFMHh",
          "name": "Mango",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "_ahpyBqyD",
          "name": "Lemon, Passion & Peach",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "hfmoXSYaO3",
          "name": "Kumquat, Peach & Orange",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "sshciiL4h",
          "name": "Lemon, Peach & Lychee",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "KtqALumZJ",
      "name": "Flavour : BlackTea (Bubble Tea)",
      "display_name": "Flavour",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "hQJG2MS-F",
          "name": "Pearl ",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "9Hv0hz_870",
          "name": "Taro",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Vwy0UmzpT",
          "name": "Chocolate ",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "hNZ0tWJCAE",
          "name": "Matcha",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "P_hwswC2b",
          "name": "Strawberry",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "12mfozXtM",
          "name": "Mango",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "xwJYawCDm",
      "name": "Sugar Level: Bubble Tea",
      "display_name": "Sugar Level",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2Jg5p_s_3",
          "name": "Normal",
          "price": "",
          "quantity": 1,
          "prices": {},
          "status": null
        },
        {
          "_id": "maCT3yRJ7j",
          "name": "1/2 Sugar",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "KqzBu1FitV",
          "name": "1/4 Sugar",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "4yQ2jKOMWX",
          "name": "No Sugar",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "XlR5-ymlG",
      "name": "Extra : Pearls",
      "display_name": "Extra ",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "cNK3j-u6L",
          "name": "Black Tapioca (Pearls)",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "2Rxb5D_dY",
          "name": "Lychee Jelly",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "eHbDS0x_h",
          "name": "Passion Pearls",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "3Eg-GV6qH",
          "name": "Mango Popping Pearls",
          "price": "1",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "t-KYS6Col",
      "name": "Sub Pork Belly",
      "display_name": "Sub to",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "W51E-ggs9",
          "name": "Pork Belly Banh Mi",
          "price": "1",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "94Yn4-Rw9",
      "name": "Rice Paper Rolls Add-On",
      "display_name": "Add Sauce",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "_eXqVrlre",
          "name": "Hoisin Sauce (Small)",
          "price": ".5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "dMkvId6tkJ",
          "name": "Hoisin Sauce (Medium)",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "k4n7ACEMR",
          "name": "Hoisin Sauce (Large)",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "67ZHxsy5y",
          "name": "Mekong Sauce (Small)",
          "price": ".5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "NGP2xyZ0P",
          "name": "Mekong Sauce (Medium)",
          "price": "1",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "47uM1ttAme",
          "name": "Mekong Sauce (Large)",
          "price": "2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "grCq4je6E",
      "name": "Ice Cream",
      "display_name": "Size",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "_mBNMHZXg",
          "name": "125ml",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "8XFNQLj490",
          "name": "500ml",
          "price": "6.5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "ldReV09wS",
      "name": "Add On: (Pho) Spring Roll",
      "display_name": "Add-On",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "B9PYbfaWr",
          "name": "Spring Rolls (3 pieces)",
          "price": "8.5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "roglgrDHW",
      "name": "Add On: Drink",
      "display_name": "Drink Add-On",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "Qf79x7grn",
          "name": "Fresh Chilled Coconut",
          "price": "8",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "sZt5epD1Z",
          "name": "Capi Sparkling Grapefruit",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "tAOernqH2w",
          "name": "Cafe Sua Da",
          "price": "6.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "JqDvZ2_Gq0",
          "name": "Coke (330ml)",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "pL1P2dC5G",
          "name": "Sprite (330ml)",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "tYZLld__UK",
          "name": "Fanta (330ml)",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "L_aHnoaCJY",
          "name": "Lift (330ml)",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "gcJa1bGRkg",
          "name": "Mt. Franklin Water Still Water (600ml)",
          "price": "4.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "0zs1fYe9C",
          "name": "Mt. Franklin Sparkling Water (330ml)",
          "price": "5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "zpS0-FGYx",
      "name": "Spicy Level",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "m9a4NQYtJ",
          "name": "Mild",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "CdtlqHg1b",
          "name": "Hot",
          "price": "1.00",
          "quantity": 0,
          "prices": {}
        }
      ]
    }
  ];


function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createVietNameseMenu = () => {
    return {
        vietnameseMenu:updateIds(vietnameseMenu), vietnamese_option_sets:updateIds(vietnamese_option_sets)
    }
}

