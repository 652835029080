export const durationOptionsConstant = [
    {
      id: '1',
      text: '20 min',
      value: '20',
      isActive: false,
    },
    {
      id: '2',
      text: '30 min',
      value: '30',
      isActive: false,
    },
    {
      id: '3',
      text: '60 min',
      value: '60',
      isActive: false,
    },
    {
      id: '4',
      text: 'Rest of the Day',
      value: Number(24 * 60).toString() , //minutes in a day
      isActive: false,
    },
    {
      id: '5',
      text: 'Pause Indefinitely',
      value: "-1",
      isActive: false,
    },
  ];

  export const reasonOptionsConstant = [
    {
      id: '1',
      text: 'Too Busy',
      value: 'too busy',
      isActive: false,
    },
    {
      id: '2',
      text: 'Closing Early',
      value: 'closing early',
      isActive: false,
    },
  ];
  