import * as Yup from 'yup';
import { mergeWith, isPlainObject, cloneDeep } from 'lodash';

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of these keys: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => {
      return (
        isPlainObject(value) &&
        Object.keys(value).some(
          (k: any) => Array.isArray(value[k]) && value[k].length > 0
        )
      );
    },
  });
});

const treeDataTransformer = (value: any) => {
  const clonedValue = cloneDeep(value);
  const customizer = (objValue: any, srcValue: any) => {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  };
  const data = Object.values(clonedValue);
  if (!Array.isArray(data)) {
    return {};
  }

  if (data.length === 1) {
    return data[0];
  }

  return data.reduce((acc, current) => {
    return mergeWith(acc, current, customizer);
  }, data[0]);
};

export const ConditionValidationSchema = Yup.object().shape({
  id: Yup.string().required().label('ID'),
  type: Yup.string().oneOf(['item_quantity', 'order_amount']).label('Type'),
  tiers: Yup.array().of(
    Yup.object().shape({
      lower_limit: Yup.number()
        .min(1)
        .max(Yup.ref('upper_limit'))
        .label('Lower Limit'),
      upper_limit: Yup.number().min(1).label('Upper Limit'),
      free_items: Yup.object()
        .transform(treeDataTransformer)
        .shape({
          menu: Yup.array(),
          category: Yup.array(),
          dish: Yup.array(),
        })
        .noUnknown()
        // @ts-ignore
        .atLeastOneOf(['menu', 'category', 'dish'])
        .label('Free Items'),
      free_quantity: Yup.number().min(1).required().label('Free Quantity'),
    })
  ),
});

export const ConditionValidationConventionalDiscountSchema = Yup.object().shape({
  type: Yup.string().oneOf(['percentage', 'fixed_amount']).label('Type'),
  apply_to: Yup.string().oneOf(['specific_products', 'order_amount']).label('Apply To'),
  discount_value: Yup.number().required().label('Discount Value'),
});

const DateTimeRange = Yup.object().shape({
  start: Yup.number()
    .required()
    .lessThan(Yup.ref('end'))
    .label('Datetime range start'),
  end: Yup.number().required().label('Datetime range end'),
});

export const FreeItemPromoValidationSchema = Yup.object().shape({
  _id: Yup.string().required(),
  name: Yup.string().required().label('Name'),
  code: Yup.string().required().label('Promo Code'),
  type: Yup.string().oneOf(['free_item', 'conventional_discount']),
  description: Yup.string().nullable().label('Description'),
  notification: Yup.string().nullable().label('Notification'),
  // condition: ConditionValidationSchema.label('Condition'),
  condition: Yup.object().when('type', {
    is: 'free_item',
    then: ConditionValidationSchema,
  }).when('type', {
    is: 'conventional_discount',
    then: ConditionValidationConventionalDiscountSchema,
  }),
  restrictions: Yup.object().shape({
    service_type: Yup.array().label('Service Type'),
    payment_methods: Yup.array().label('Payment Methods'),
    order_times: Yup.array().label('Order Times'),
    once_per_customer: Yup.boolean().label('Once Per Customer'),
    authenticated_user_only: Yup.boolean().label('Authenticated User Only'),
    valid_datetime_range: Yup.array()
      .of(DateTimeRange)
      .label('Valid Datetime Ranges'),
    automatically_apply: Yup.boolean().label('Automatically Apply'),
  }),
});
