import * as React from "react";
import { Modal } from "@lib/components";
import { RotateLoader } from "@lib/components";

interface Props<T> {
	active: boolean;
	item: T | null;
	query: () => Promise<void>;
	close: () => void;
	children: (item: T) => React.ReactNode;
	outerContent?: (item: T) => React.ReactNode;
}

export class RestaurantItemModal<T> extends React.Component<Props<T>> {

	componentDidMount() {
		this.props.query();
	}

	componentDidUpdate() {
		this.props.query();
	}

	render() {
		const { close, children, item, active, outerContent } = this.props;
		return (
			<div>
				<Modal
					width={420}
					active={active}
					close={active ? close : () => { }}>
					{(!item) && (
						<div className="flex-center width100 height250">
							<RotateLoader size={3} />
						</div>
					)}
					{item && (children(item))}
				</Modal>
				{(item && outerContent) && outerContent(item)}
			</div>
		);
	}

}
