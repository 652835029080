import React, { useState } from "react";
import { Input } from "@lib/components";
import { IconPickerItem, IconList } from "react-fa-icon-picker";
import { observer, MobXProviderContext } from "mobx-react";

import {
	Checkbox,
	shadeAlternate,
	styled,
	Button,
	SelectAdv,
} from "@lib/components";

import { FaTrash } from "react-icons/fa";
import _get from "lodash/get";
import _findIndex from "lodash/findIndex";
import _isEmpty from "lodash/isEmpty";
import {
	SettingsInactiveIndicator,
	SettingsSuccessIndicator,
} from "../../../components";

type FieldType = T.Schema.Restaurant.Services.CustomCheckoutField;

const CustomCheckoutBoxPreview = styled.div`
  margin: auto;
  width: 400px;
  border-radius: 4px;
  position: relative;
  background: ${({ theme }) => shadeAlternate(0.04, theme.box.background)};
  color: ${({ theme }) => theme.box.text};
  .icon {
    font-size: 30px;
  }
`;

const FieldStatusWrapper = styled.div`
  border: 1px solid rgb(225, 225, 225);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 5px;
`;

const FieldActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 0.5rem;
`;

interface CustomCheckoutFieldPreviewProps {
	field: FieldType;
	index: number;
	editField: (id: string) => void;
	onDelete: (e: any) => any;
}

export const CustomCheckoutFieldPreview = observer(
	(props: CustomCheckoutFieldPreviewProps) => {
		const { store } = React.useContext(MobXProviderContext);
		const { theme } = store;
		const { field, index, editField } = props;
		const [mockupCheckbox, setMockupCheckbox] = useState(false);
		const [mockupValueSelect, setMockupValueSelect] = useState("");
		const [mockupInput, setMockupInput] = useState("");

		let computedDropDownOption = [{ value: "", label: "" }];

		if (field.dropdown_options && field.dropdown_options.length > 0) {
			computedDropDownOption = field.dropdown_options.map((field, index) => ({
				value: index.toString(),
				label: field.toString(),
			}));
		}

		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					marginBottom: "1.5rem",
				}}
			>
				<CustomCheckoutBoxPreview>
					<div
						className="flex-left p-4 cursor"
						onClick={() => editField(field._id)}
					>
						<div className="m-r-4">
							<IconPickerItem icon={field.icon as IconList} size={20} />
						</div>
						<div className="m-r-3">
							<p className="font-semi-bold lhp">
								{field.label}
								{field.required && (
									<span
										style={{
											color: "red",
											fontSize: "1.2rem",
											fontWeight: "bold",
										}}
									>
										{" "}
										*
									</span>
								)}
							</p>
							<p className="small lhp">{field.description}</p>
						</div>

						{field.type === "checkbox" && (
							<div
								className="flex-margin-left flex-wrap"
								onClick={(e) => e.stopPropagation()}
							>
								<Checkbox
									id={`mockup-checkbox-${index}`}
									name="mockup-checkbox"
									checked={mockupCheckbox}
									onChange={() => setMockupCheckbox(!mockupCheckbox)}
								/>
							</div>
						)}
					</div>

					{field.type === "dropdown" && (
						<div className="p-t-0 p-b-3 p-l-3 p-r-3">
							<SelectAdv
								type="single"
								options={computedDropDownOption}
								value={mockupValueSelect}
								onChange={(value) => {
									setMockupValueSelect(value);
								}}
							/>
						</div>
					)}

					{field.type === "input" && (
						<div className="p-t-0 p-b-3 p-l-3 p-r-3">
							<Input
								type="text"
								value={mockupInput}
								onChange={(e) => {
									setMockupInput(e.target.value);
								}}
							/>
						</div>
					)}
				</CustomCheckoutBoxPreview>

				<FieldActions>
					<FieldStatusWrapper>
						{props.field.enabled ? (
							<SettingsSuccessIndicator
								background={theme.s.status_colors.complete}
							/>
						) : (
							<SettingsInactiveIndicator />
						)}
						{props.field.enabled ? "Enabled" : "Disabled"}
					</FieldStatusWrapper>
					<Button
						type="button"
						color="white"
						size="sm"
						onClick={props.onDelete}
						className="flex-center"
						style={{ display: "flex !important" }}
					>
						<FaTrash className="m-r-1" />
						<span>Remove</span>
					</Button>
				</FieldActions>
			</div>
		);
	}
);
