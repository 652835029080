import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input, SelectAdv } from '@lib/components';
import { DataCurrenciesList } from '@lib/common';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';
import { LinkTag } from '@lib/components';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentStripe;

@inject('store')
@observer
export class SettingsFormPaymentsStripe extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.stripe || {
      enabled: false,
      label: '',
      services: [],
      secret_key: '',
      publishable_key: '',
      stripe_version: '',
      connect_account_id: '',
      currency: '',
      disable_email_receipt: false,
      custom_payment_email: '',
      stripe_layout: '',
      themes: '',
      min_order: 0,
    };
  }

  async checkAndRegisterDomain() {
    const r = this.injected.store.restaurant!;
    const restaurant_id = r!._id;
    const response = await this.injected.store.api.dashboard_restaurant_domain_verify({ restaurant_id });
    if (response.outcome) {
      UI.notification.error(response.message, { timeout: 6000 });
    }
  }

  render() {
    const { showMainSupport } = this.injected.store;
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.stripe = {...values, stripe_version: "v2"};
          const update = { $set: { 'settings.payments.stripe': r.settings.payments.stripe } };
          this.checkAndRegisterDomain();
          return { r, update };
        }}
        validators={{
          connect_account_id: values => {
            if (
              values.connect_account_id &&
              values.connect_account_id.length > 0 &&
              !values.connect_account_id.includes('acct_')
            )
              return { connect_account_id: 'This account ID is in the wrong format' };
          },
          min_order: values => {
            if (!values.min_order) {
              return { min_order: 'Minimum amount required for this payment method.' };
            } else if (values.min_order && values.min_order < 0)
              return { min_order: 'Minimum amount need to be greater than 0' };
          },
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">
              {showMainSupport && (
                <FormGroup>
                  <LinkTag
                    className="block font-semi-bold"
                    target="_blank"
                    href="https://support.cloudwaitress.com/how-to-guides/setup-stripe-payments"
                  >
                    Read the setup guide
                  </LinkTag>
                </FormGroup>
              )}

              <FastField
                name="enabled"
                render={({ field }: any) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="secret_key"
                render={({ field }: any) => (
                  <FormGroup
                    title="Stripe Secret Key"
                    help="Your Stripe secret API key, do not to share this with anyone"
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="publishable_key"
                render={({ field }: any) => (
                  <FormGroup
                    title="Stripe Publishable Key"
                    help="Your Stripe publishable API key that will be used to initiate online charges"
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />
              {/*<FastField*/}
              {/*  name="stripe_version"*/}
              {/*  render={({ field }: any) => (*/}
              {/*    <FormGroup*/}
              {/*      optional={true}*/}
              {/*      title="Stripe Version"*/}
              {/*      help="Choose the version of the stripe payment form"*/}
              {/*    >*/}
              {/*      <SelectAdv*/}
							{/*				type="single"*/}
							{/*				options={[*/}
							{/*					{label: 'Version 1', value: 'v1'},*/}
							{/*					{label: 'Version 2', value: 'v2'},*/}
              {/*        ]}*/}
							{/*				value={field.value ? field.value : 'v1'}*/}
							{/*				onChange={(option: string) => {*/}
							{/*					setFieldValue("stripe_version", option);*/}
							{/*				}}*/}
							{/*			/>*/}
              {/*    </FormGroup>*/}
              {/*  )}*/}
              {/*/>*/}

              <FastField
                name="currency"
                render={({ field }: any) => (
                  <FormGroup
                    title="Currency"
                    help="Select the currency you would like to accept Stripe charges in. Make sure you are authorized to accept charges in that currency and that it is supported by Stripe"
                  >
                    <SelectAdv
                      type="single"
                      options={DataCurrenciesList}
                      value={field.value}
                      onChange={(code: string) => setFieldValue('currency', code)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="disable_email_receipt"
                render={({ field }: any) => (
                  <FormGroup
                    title="Disable E-Mail Receipt"
                    help="Toggle this to disable Stripe payment receipts from being sent to your customer's e-mail"
                  >
                    <Switch
                      id="disable_email_receipt-switch"
                      checked={field.value || false}
                      onChange={e => setFieldValue('disable_email_receipt', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="custom_payment_email"
                render={({ field }: any) => (
                  <FormGroup
                    optional={true}
                    title="Enable Custom Payment Form"
                    help="Entering an e-mail address will enable the custom payment form at your-store-domain.com/online-payment"
                  >
                    <Input
                      {...field}
                      type="email"
                      placeholder="Enter E-Mail Address For Payment Notification"
                      value={field.value || ''}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="stripe_layout"
                render={({ field }: any) => (
                  <FormGroup
                    optional={true}
                    title="Layout"
                    help="Choose the layout of the stripe payment form"
                  >
                    <SelectAdv
											type="single"
											options={[
												{label: 'Default', value: 'tabs'},
												{label: 'Accordion with radio button', value: 'accordion_with_radio'},
												{label: 'Accordion without radio button', value: 'accordion_without_radio'}
                      ]}
											value={field.value ? field.value : 'tabs'}
											onChange={(option: string) => {
												setFieldValue("stripe_layout", option);
											}}
										/>
                  </FormGroup>
                )}
              />
              <FastField
                name="themes"
                render={({ field }: any) => (
                  <FormGroup
                    optional={true}
                    title="Themes"
                    help="Choose the themes of the stripe payment form"
                  >
                    <SelectAdv
											type="single"
											options={[
												{label: 'Default', value: 'stripe'},
												{label: 'Night', value: 'night'},
												{label: 'Flat', value: 'flat'},
												{label: 'Bubblegum', value: 'bubblegum'},
												{label: 'Ninety Five', value: 'ninety_five'},
												{label: 'Dark Blue', value: 'dark_blue'},
                      ]}
											value={field.value ? field.value : 'stripe'}
											onChange={(option: string) => {
												setFieldValue("themes", option);
											}}
										/>
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="Credit Card" setFieldValue={setFieldValue} />
              <FastField
                name="min_order"
                render={({ field }: any) => (
                  <FormGroup
                    title="Minimum Order Value ($)"
                    error={getFieldError(form, 'min_order')}
                    help="Only allow payments when order total is greater than or equal to this value "
                  >
                    <Input
                      {...field}
                      type="number"
                      value={field.value || 1}
                      step="0.01"
                      onChange={e => setFieldValue('min_order', e.target.value)}
                    />
                  </FormGroup>
                )}
              />
              <FastField
                name="connect_account_id"
                render={({ field }: any) => (
                  <FormGroup
                    optional={true}
                    title="SC Account ID"
                    error={getFieldError(form, 'connect_account_id')}
                    help="Admin use only"
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error} />}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
