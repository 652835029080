import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Input } from "@lib/components";
import { Switch } from "@lib/components";
import { UI } from "../../../../../../../core/ui";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { LinkTag } from "@lib/components";

interface Props {
	close?: () => void;
}
interface State { }

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["integrations"];

@inject("store") @observer
export class SettingsFormIntegrationGoogleAnalytics extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.integrations;
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.integrations = values;
					const update = { $set: { "settings.integrations": r.settings.integrations } };
					return { r, update };
				}}
				initialValues={this.initialValues}
				onSuccess={() => {
					if (this.props.close) {
						this.props.close();
					}
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error }) => {
					const { isSubmitting } = form;
					return (
						<div className="p-4">

							<FastField
								name="google_analytics.property_id"
								render={({ field }: any) => (
									<FormGroup
										title="Property / Tracking ID"
										help="Your unique analytics property ID. It resembles the following, 'UA-XXXXXXXX-X'">
										<Input type="text" {...field} value={field.value || ""} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);

	}

}
