import React, { useEffect, useState } from 'react';
import { MobXProviderContext, useObserver } from 'mobx-react';
import BumpItems from './bump-items';
import { NoItemOders, RotateLoader } from '@lib/components';
import styled from 'styled-components';
import localStorage from 'store';
import  { useSwipeable } from 'react-swipeable';


export type TStatusItem = {
  id: string;
  text: string;
  quantity: number;
  type: T.Schema.Order.OrderStatuses;
};
interface PropsBumpItem {
  fetchingDataBump: boolean;
}

const BumpViewWrapper = styled.div`
  * {
    font-family: Inter;
  }
`;
const DEFAULT_SERVICE = ['pickup', 'delivery', 'dine_in'];

export const getAllOrders = (store: any, restaurantId: string) => {
  const savedSettingBumpView = localStorage.get('settingBumpView');
  let savedActiveCard = savedSettingBumpView ? JSON.parse(savedSettingBumpView).card : 4;
  const serviceToFilter = localStorage.get('serviceToFilter');
  const orderServiceDisplay = serviceToFilter ? serviceToFilter : DEFAULT_SERVICE;

  return store.api.orders_bump_find({
    page: 1,
    limit: savedActiveCard,
    sort: {
      created: -1,
    },
    query: {
      'config.service': {
        $in: orderServiceDisplay,
      },
      status: {
        $in: ['confirmed'],
      },
      restaurant_id: restaurantId,
    },
  });
};

export const getBoard = (store: any, restaurantId: string) => {
  return store.api.orders_board_find({
    restaurant_id: restaurantId,
  });
};

const MOCK_ORDER_STATUS_DATA: TStatusItem[] = [
  {
    id: '1',
    text: 'Unconfirmed',
    quantity: 3,
    type: 'unconfirmed',
  },
  {
    id: '2',
    text: 'Confirmed',
    quantity: 3,
    type: 'confirmed',
  },
  {
    id: '3',
    text: 'Ready',
    quantity: 3,
    type: 'ready',
  },
  {
    id: '4',
    text: 'On-route',
    quantity: 3,
    type: 'on_route',
  },
  {
    id: '5',
    text: 'Completed',
    quantity: 3,
    type: 'complete',
  },
  {
    id: '6',
    text: 'Cancelled',
    quantity: 3,
    type: 'cancelled',
  },
];
const updateOrdersStore = (store: any, restaurantId: string, page: number) => {
  const savedSettingBumpView = localStorage.get('settingBumpView');
  let savedActiveCard = savedSettingBumpView ? JSON.parse(savedSettingBumpView).card : 4;
  const serviceToFilter = localStorage.get('serviceToFilter');
  const orderServiceDisplay = serviceToFilter ? serviceToFilter : DEFAULT_SERVICE;
  return store.api.orders_bump_find({
    page: page,
    limit: savedActiveCard,
    sort: {
      created: -1,
    },
    query: {
      'config.service': {
        $in: orderServiceDisplay,
      },
      status: {
        $in: ['confirmed'],
      },
      restaurant_id: restaurantId,
    },
  });
};
export const RestaurantOrdersBump: React.FC<PropsBumpItem> = ({ fetchingDataBump }) => {
  return useObserver(() => {
    const { store } = React.useContext(MobXProviderContext);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const restaurantId = store.restaurant._id;
    const savedSettingBumpView = localStorage.get('settingBumpView');
    let savedActiveMode = savedSettingBumpView ? JSON.parse(savedSettingBumpView).view_mode : "desktop_view";

    const swipeHandlers = savedActiveMode ? useSwipeable({
      onSwipedLeft: async () => {
        try {
          const response = await updateOrdersStore(store, restaurantId, currentPage + 1);
          setCurrentPage(currentPage + 1);
          store.updateOrders({
            items: response.items,
            count: response.count,
            page: currentPage + 1,
          });
        } catch (error) {
          console.error("Error updating orders:", error);
        }
      },
      onSwipedRight: async () => {
        try {
          const response = await updateOrdersStore(store, restaurantId, currentPage - 1);
          setCurrentPage(currentPage - 1);
          store.updateOrders({
            items: response.items,
            count: response.count,
            page: currentPage - 1,
          });
        } catch (error) {
          console.error("Error updating orders:", error);
        }
      },
    }) : null;
    const fetchData = async () => {
      setIsLoading(true);
      const orderData = await getAllOrders(store, restaurantId);
      store.updateOrders({
        items: orderData.items,
        count: orderData.count,
        page: 1,
      });
      if (orderData && orderData.hasOwnProperty('count')) {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (!fetchingDataBump) {
        fetchData();
      }
    }, [fetchingDataBump]);

    if (isLoading || fetchingDataBump) {
      return <RotateLoader size={4} />;
    }
    const serviceFilter = localStorage.get('serviceToFilter');
    const listOrderFilter = serviceFilter
      ? store.orders.items.filter(
          (order: any) => serviceFilter.includes(order.config.service) && order.status === 'confirmed'
        )
      : store.orders.items;

    return (
      <div {...(swipeHandlers ? swipeHandlers : {})}>
        <BumpViewWrapper>
        {listOrderFilter.length > 0 ? (
          <BumpItems
            listOrder={listOrderFilter}
            totalResponse={store.orders.count}
            orderStatusData={MOCK_ORDER_STATUS_DATA}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <NoItemOders />
        )}
      </BumpViewWrapper>
      </div>
    );
  });
};
