import React from 'react';
import { MobxComponent } from "../../../mobx/components";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IconSettings, IconClose } from "../../components/v2/icons";
import { Switch } from "@lib/components";
import { HeaderTitleWrapper, MenuItem, QuickSettingsContainer, QuickSettingsHeaderWrapper, QuickSettingsBodyWrapper, QuickSettingsFooterWrapper, QuickSettingsButton, QuickSettingsItemsContainer, QuickSettingsItemsWrapper, QuickSettingsItemWrapper } from "./layout.c";


interface Props extends WithTranslation {}
interface State {}

@inject("store") @observer
class QuickSettingsClass extends MobxComponent<Props, State> {
    constructor(props: Props) {
		super(props);
		this.state = {};
	}

    render() {
        const { store, t} = this.injected;
        const { organisation } = store;
        if(!organisation) return
        return (
        <QuickSettingsContainer className={store.view.quicksettings_active ? 'active': ''}>
            <QuickSettingsHeaderWrapper>
                <HeaderTitleWrapper>
                    <IconSettings />
                    <div>Quick Settings</div>
                </HeaderTitleWrapper>
                <MenuItem onClick={() => store.updateView({quicksettings_active: false})}><IconClose /></MenuItem>
            </QuickSettingsHeaderWrapper>
            <QuickSettingsBodyWrapper>
                <QuickSettingsItemsContainer>
                    <QuickSettingsItemsWrapper>
                        <QuickSettingsItemWrapper>
                            <span>Use Modern Layout</span>
                            <Switch
                            id="switch-layout"
                            checked={organisation.dashboard ? organisation.dashboard == 'v2': false}
                            onChange={(e) => {
                                store.updateOrganisation({
                                    dashboard: e.target.checked ?  'v2' : 'v1'
                                })
    
                             
                            }} />
                        </QuickSettingsItemWrapper>
                        <p>Ordering dashboard is currently using the classic layout.</p>
                    </QuickSettingsItemsWrapper>
                </QuickSettingsItemsContainer>
            </QuickSettingsBodyWrapper>
            <QuickSettingsFooterWrapper>
                <QuickSettingsButton 
                    onClick={() => {
                        store.updateView({quicksettings_active: false})
                        //store.
                        store.api.organisation_update({
                            _id: organisation._id,
                            update: {
                                $set: {
                                    dashboard: organisation.dashboard
                                }
                            }
                        })
                    }}>
                    Save Settings
                </QuickSettingsButton>
            </QuickSettingsFooterWrapper>
        </QuickSettingsContainer>
        )
    }
}
export const QuickSettings = withTranslation()(QuickSettingsClass);
