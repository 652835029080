import * as React from "react";
import { FastField } from "formik";
import { FormGroup, SelectAdv, Switch } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";

interface Props { }
interface State { }

type FormValues = {
	menu_nav_bar: T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["menu_nav_bar"];
	menu_selector_only: boolean;
};

@inject("store")
@observer
export class SettingsFormDesignMenuNavBar extends MobxComponent<Props, State> {
	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;

		this.initialValues = {
			menu_nav_bar: r.website.sections.menu_nav_bar,
			menu_selector_only: r.website.experimental_ui_1 || false,
		};
	}

	render() {
		const shouldShowSliderHighlightSelectedStyle = (
			formValues: FormValues
		) => {
			return (
				formValues.menu_nav_bar?.style === "slider" ||
				!formValues.menu_nav_bar?.hide_category_bar
			);
		};

		const shouldShowV2Fields = (formValues: FormValues) => {
			return formValues.menu_nav_bar?.menu_template === "v2";
		};

		const r = this.injected.store.restaurant!;

		const shouldShowWarningBreakCustomCssJs =
			r.website.inject?.html_body || r.website.inject?.html_head;

		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.website.sections.menu_nav_bar = values.menu_nav_bar;

					r.website.experimental_ui_1 = values.menu_selector_only;

					const update = {
						$set: {
							"website.sections.menu_nav_bar":
								r.website.sections.menu_nav_bar,
							"website.experimental_ui_1":
								r.website.experimental_ui_1,
						},
					};
					return { r, update };
				}}
				initialValues={this.initialValues}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">
							<FastField
								name="menu_nav_bar.menu_template"
								render={({ field }: any) => (
									<FormGroup
										title="Menu Template"
										help={
											<div>
												<p>
													Choose one to change how the
													menu navigation is
													displayed.
												</p>
												{shouldShowWarningBreakCustomCssJs && (
													<>
														<p
															style={{
																fontWeight: 600,
																color: "red",
																paddingTop: 15,
																paddingBottom: 5,
															}}
														>
															[WARNING] IMPORTANT
															NOTE
														</p>
														<p>
															Switching to the
															newer Modern
															template may break
															your custom
															JavaScript and CSS
															(if used).
														</p>
													</>
												)}
											</div>
										}
									>
										<SelectAdv
											type="single"
											id="menu_template"
											value={field.value || "v1"}
											options={[
												{
													value: "v1",
													label: "Classic",
												},
												{
													value: "v2",
													label: "Modern",
												},
											]}
											onChange={(options: string) => {
												setFieldValue(
													"menu_nav_bar.menu_template",
													options
												);
											}}
										/>
									</FormGroup>
								)}
							/>

							{shouldShowV2Fields(form.values) && (
								<>
									<FastField
										name="menu_nav_bar.style"
										render={({ field }: any) => (
											<FormGroup
												title="Menu Nav Bar Style"
												help="Choose the style for the menu navigation bar."
											>
												<SelectAdv
													type="single"
													id="menu_nav_style"
													value={
														field.value || "slider"
													}
													options={[
														{
															value: "slider",
															label: "Slider",
														},
														{
															value: "dropdown",
															label: "Drop-down",
														},
													]}
													onChange={(
														options: string
													) => {
														setFieldValue(
															"menu_nav_bar.style",
															options
														);
													}}
												/>
											</FormGroup>
										)}
									/>

									{shouldShowSliderHighlightSelectedStyle(
										form.values
									) && (
										<FastField
											name="menu_nav_bar.slider_highlight_selected_style"
											render={({ field }: any) => (
												<FormGroup
													title="Slider Highlight Selected Style"
													help="Choose the style for the highlight selected element in the slider (menu slider or category slider, if any)."
												>
													<SelectAdv
														type="single"
														id="slider_highlight_selected_style"
														value={
															field.value ||
															"bubble"
														}
														options={[
															{
																value: "bubble",
																label: "Bubble",
															},
															{
																value: "underline",
																label: "Underline",
															},
														]}
														onChange={(
															options: string
														) => {
															setFieldValue(
																"menu_nav_bar.slider_highlight_selected_style",
																options
															);
														}}
													/>
												</FormGroup>
											)}
										/>
									)}

									{/*

									<FastField
										name="menu_nav_bar.list_style"
										render={({ field }: any) => (
											<FormGroup
												title="List Style"
												help="Choose the type for elements in the list."
											>
												<SelectAdv
													type="single"
													id="list_style"
													value={
														field.value || "text"
													}
													options={[
														{
															value: "text",
															label: "Text",
														},
														// { value: "image", label: "Image" },
														// { value: "text-and-image", label: "Text and Image" },
													]}
													onChange={(
														options: string
													) => {
														setFieldValue(
															"menu_nav_bar.list_style",
															options
														);
													}}
												/>
											</FormGroup>
										)}
									/> */}

									<FastField
										name="menu_nav_bar.hide_category_bar"
										render={({ field }: any) => (
											<FormGroup
												title="Hide Category Bar"
												help="If enabled, the horizontal category bar will be removed."
											>
												<Switch
													id="hide_category_bar"
													checked={
														field.value || false
													}
													onChange={(e) =>
														setFieldValue(
															"menu_nav_bar.hide_category_bar",
															e.target.checked
														)
													}
												/>
											</FormGroup>
										)}
									/>
								</>
							)}

							{!shouldShowV2Fields(form.values) && (
								<FastField
									name="menu_selector_only"
									render={({ field }: any) => (
										<FormGroup
											title="Menu Selector Only"
											help="If enabled, the horizontal category bar will be removed and all items will be visible on a single screen. If you have multiple menus, a dropdown menu with a hamburger button will replace the menu select for mobile devices"
										>
											<Switch
												id={"menu_selector_only"}
												checked={field.value || false}
												onChange={(e) =>
													setFieldValue(
														"menu_selector_only",
														e.target.checked
													)
												}
											/>
										</FormGroup>
									)}
								/>
							)}

							{error && <FormGroup error={error} />}

							<Button
								full={true}
								color="primary"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting && (
									<RotateLoader size={2} color="white" />
								)}
								{!isSubmitting && "Save"}
							</Button>
						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
