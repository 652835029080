import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { RestaurantOrdersList } from "./list";
import { RestaurantOrdersBoard } from "./board";
import { RestaurantOrderModal } from "./modal";
import { Button, CommonText, Tooltip } from "@lib/components";
import { FaPlus, FaMinus, FaCog, FaQuestionCircle, FaSync, FaEye, FaHourglassHalf, FaPlay } from "react-icons/fa";
import { IoIosPause } from "react-icons/io";
import { runInAction } from "mobx";
import { RestaurantOrdersQuickSettings } from "./quick-settings";
import localStore from "store";
import { LalamoveOrderSearch } from './lalamove-order-search';
import { UI } from "../../../../../core/ui";
import { RestaurantUtils } from "@lib/common";
import { RestaurantOrdersBump, getAllOrders } from "./bump/bump";
import styled from "styled-components";
import { FilterIcon, ViewIcon } from "./bump-icon";
import { BumpViewSidebar } from "./bump/bump-view-sidebar";
import { FilterViewScreen } from "./filter-view-modal";
import { PauseServicesSidebar } from "./sidebars/pauseServicesSidebar";
import { CountdownClock } from "./countdownClock";
import { getPauseServices, getPauseTime } from "../../../../utils/pause_services";

interface Props extends WithTranslation {
	currentLayout?: 0 | 1 | 2;
}
interface State {
	quick_settings_active: boolean;
	bump_view_screen_active: boolean;
	pause_services_active: boolean;
	filter_active: boolean;
	fetching_data_bump: boolean;
	countdownFinished: boolean;
}

const OrderBumpWapper = styled.div`
	width: 100%;
`;
const OrderToolBarWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 12px 24px;
	background-color: #fff;
	gap: 8px;
	@media (max-width: 1000px) {
		justify-content: center;
		padding: 10px 8px;
		flex-wrap: wrap;
	}
`;
const ModeButtonWrapper = styled.div`
	@media (max-width: 1000px) {
		width: 100%;
	}
`;
const ControlButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	@media (max-width: 1000px) {
		width: 100%;
	}
`;


const LayoutViewWrapper = styled.div<{ customStyle: string }>`
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #F1F4F7;
	padding: ${({ customStyle }) => (customStyle === 'list' ? '2rem' : '0')};
`;
const ModeButtonStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F1F4F7;
	border-radius: 4px;
	min-width: 162px;
	button {
		width: 100%;
	}
	@media (max-width: 1000px) {
		min-width: 90px;
		flex: 1;
	}
`;
const OrderToolBarButtonStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	color: #4B4F5E;
	background-color: #F5F5F5;
	border-radius: 4px;
	min-width: 43px;
	button {
		width: 100%;
	}
`;

const BUMP_VIEW_LAYOUT = 2;
const PauseWarningWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 24px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	background: #BC362F;
`;
const PauseWarningLeft = styled.div`
	& + div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	svg {
		margin-right: 8px;
	}
`;

const RefreshBtnWrapper = styled.div`
	svg {
		color: #4B4F5E;
	}
`;
const PauseWarningRight = styled.div``;

@inject("store") @observer
class RestaurantOrdersClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			quick_settings_active: false,
			bump_view_screen_active: false,
			pause_services_active: false,
			filter_active: false,
			fetching_data_bump: false,
			countdownFinished: false
		};
	}

	changeView = (layout: 0 | 1 | 2) => {
		runInAction(() => {
			const { store } = this.injected;
			const r = store.restaurant!;
			if (store.ordersView.layout === layout) {
				return;
			}

			store.ordersView.layout = layout;
			store.ordersBoard.lists = {};
			store.orders.items = [];
			store.orders.count = 0;

			const settings = localStore.get("store-ordersView") || {};
			settings.layout = layout;
			localStore.set("store-ordersView", settings);
			if (layout === 0) {
				store.router.push(`/restaurant/${r._id}/orders`);
			}
			if (layout === 1) {
				store.router.push(`/restaurant/${r._id}/orders/list`);
			}
			if (layout === 2) {
				store.router.push(`/restaurant/${r._id}/orders/bump`);
			}
		});
	}

	componentDidMount(): void {
		const { currentLayout = 0 } = this.props;
		this.changeView(currentLayout)
	}

	changeBoardLayout = (size: 1 | -1) => {
		runInAction(() => {
			const { store } = this.injected;
			const current = store.ordersView.boardSize;
			const next = current + size;
			if (next > 5 || next < 2) {
				return;
			}
			if (!store.restaurant!.settings.services.delivery.enabled && next > 4) {
				return;
			}

			store.ordersView.boardSize = next as 2 | 3 | 4 | 5;

			const settings = localStore.get("store-ordersView") || {};
			settings.boardSize = next.toString();
			localStore.set("store-ordersView", settings);

		});
	}

	changeHideUnconfirmed = () => {
		runInAction(() => {
			const { store } = this.injected;
			const val = !store.ordersView.hideUnconfirmed;
			store.ordersView.hideUnconfirmed = val;
			const settings = localStore.get("store-ordersView") || {};
			settings.hideUnconfirmed = val;
			localStore.set("store-ordersView", settings);

		});
	}

	onSearchCompleted = (orderId: string) => {
		const { store } = this.injected;
		const r = store.restaurant;
		store.router.push(`/restaurant/${r._id}/orders?_id=${orderId}`);
	}

	onSearchFailed = () => {
		UI.notification.warning('Cannot find order with given Lalamove order ID');
	}
	handleFetchOrderBump = async () => {
		const { store } = this.injected;
		const r = store.restaurant;
		this.setState({ fetching_data_bump: true });
		const orderData = await getAllOrders(store, r._id as string);
		if (orderData && orderData.hasOwnProperty('count')) {
			this.setState({ fetching_data_bump: false });
		}
	}

	handleFilterViewScreenClose = () => {
		this.setState({ filter_active: false });
	}

	render() {

		const { store } = this.injected;
		const { showMainSupport, restrictions } = store;
		const { layout, boardSize, hideUnconfirmed } = store.ordersView;
		const r = store.restaurant!;
		const useAwaitingPayment = !!r.settings.business.using_awaiting_payment;

		let actualBoardSize = boardSize - (hideUnconfirmed && !useAwaitingPayment ? 1 : 0);
		actualBoardSize = actualBoardSize < 2 ? 2 : actualBoardSize;

		const containerClass = layout === 0 ? "center" : "";

		let quickSettingsEnabled = false;
		if (restrictions._.restaurantSettingsEnabled) {
			const sd = restrictions.restaurant.settings_detail;
			if (sd && sd.services) {
				quickSettingsEnabled = true;
			}
			else {
				quickSettingsEnabled = !!restrictions.restaurant.settings;
			}
		}
		const lalamoveEnabled = RestaurantUtils.settings.integrationLalamoveActive(r!);

		const pausingTime = getPauseTime(r);
		const services = getPauseServices(r);
		const { countdownFinished } = this.state;
		const getToolTipPauseBtn = (pauseLabel: string = "", resumeLabel: string = "") => {
			return (pausingTime <= 0 || countdownFinished) ? pauseLabel : resumeLabel;
		};
		const renderPauseServiceBtn = () => {
			return (
				<div>
					{(pausingTime <= 0 || countdownFinished) && (
						<>
							<Tooltip text="Pause Services" width={100} position="bottom">
								<IoIosPause />
							</Tooltip>
						</>
					)}
					{pausingTime > 0 && !countdownFinished && (
						<>
							<Tooltip text="Resume Services" width={120} position="bottom">
								<FaPlay />
							</Tooltip>
						</>
					)}
				</div>
			);
		};

		const handleResumeServices = async () => {
			this.setState({ countdownFinished: true });
			await store.api.restaurant_update_operation({
				_id: r._id || '',
				status: 'resume',
				duration: 0,
				services: [], // add any of the following: "pickup", "delivery", "dine_in", "booking"
				reason: "",
			});
			await store.service.restaurant.get();
		}
		const showCountdown = (layout === 0 || layout === 2) && !countdownFinished && pausingTime > 0;
		const pathNameLength = store.router.s.path.split("/").length - 1;
		const pathNameEnd = store.router.s.path.split("/")[pathNameLength];
		return (
			<div className={containerClass}>
				<div className="flex-l-r-center">
					<OrderToolBarWrapper className="flex-line centered">
						<ModeButtonWrapper>
							{restrictions._.restaurantOrderViews.length > 1 && (
								<div style={{ display: "flex" }}>
									{restrictions._.restaurantOrderViews.indexOf("board") !== -1 && (
										<ModeButtonStyle className="m-r-1">
											<Button
												size="sm"
												color={layout === 0 ? "primary" : "transparent"}
												className="p-lr-1 bg.grey round-button-sm"
												onClick={() => this.changeView(0)}>
												Orders
											</Button>
										</ModeButtonStyle>
									)}
									{restrictions._.restaurantOrderViews.indexOf("list") !== -1 && (
										<ModeButtonStyle className="m-lr-1" onClick={() => this.changeView(1)}>
											<Button
												size="sm"
												color={layout === 1 ? "primary" : "transparent"}
												className="p-lr-1 bg.grey round-button-sm"
											>
												List
											</Button>
										</ModeButtonStyle>
									)}

									{restrictions._.restaurantOrderViews.indexOf("bump") !== -1 &&
										(
											<ModeButtonStyle className="m-l-1">
												<Button
													size="sm"
													color={layout === 2 ? "primary" : "transparent"}
													className="p-lr-1 bg.grey"
													onClick={() => this.changeView(2)}>
													Bump Screen
												</Button>
											</ModeButtonStyle>
										)
									}

								</div>
							)}
						</ModeButtonWrapper>

						<ControlButtonWrapper>
							{lalamoveEnabled && (
								<LalamoveOrderSearch
									restaurantId={r._id || ''}
									onSearchCompleted={this.onSearchCompleted}
									onSearchFailed={this.onSearchFailed}
								/>
							)}
							{layout === 0 && (
								<div className="m-l-1" style={{ display: "flex" }}>
									<OrderToolBarButtonStyle>
										<Button
											size="sm"
											color={"transparent"}
											className="p-lr-1 no-round-right no-border-r"
											onClick={() => this.changeBoardLayout(-1)}>
											<FaMinus />
										</Button>
									</OrderToolBarButtonStyle>
									<OrderToolBarButtonStyle className="m-lr-1">
										<Button
											size="sm"
											color={"transparent"}
											className="p-lr-1 no-round no-border-r"
											onClick={() => this.changeBoardLayout(1)}>
											<FaPlus />
										</Button>
									</OrderToolBarButtonStyle>
									<OrderToolBarButtonStyle>
										<Button
											size="sm"
											color={"transparent"}
											className="p-lr-1 no-round-left"
											onClick={() => this.changeHideUnconfirmed()}>
											<FaEye />
										</Button>
									</OrderToolBarButtonStyle>
								</div>
							)}
							<div style={{ display: "flex" }}>
								{(layout === 0 || layout === 2) && (
									<OrderToolBarButtonStyle className="m-l-1">
										<Button
											size="sm"
											color={"transparent"}
											className="p-lr-1 no-round-left"
											onClick={() =>
												pausingTime > 0 && !countdownFinished ? handleResumeServices() : this.setState({ pause_services_active: true })
											}>
											{renderPauseServiceBtn()}
										</Button>
									</OrderToolBarButtonStyle>
								)}
							</div>


							<div className="m-l-1" style={{ display: "flex" }}>
								{(layout === 2 && (
									<>
										<OrderToolBarButtonStyle>
											<Tooltip text="View" width={65} position="bottom">
												<Button
													size="sm"
													color={"transparent"}
													className={showMainSupport ? "no-round-right no-border-r" : "p-lr-1"}
													onClick={() => this.setState({ bump_view_screen_active: true })}>
													<div style={{ display: "flex", alignItems: "center", gap: "8px" }}><ViewIcon /></div>
												</Button>
											</Tooltip>
										</OrderToolBarButtonStyle>
										<OrderToolBarButtonStyle className="m-lr-2">
											<Tooltip text="Filter" width={65} position="bottom">
												<Button
													size="sm"
													color={"transparent"}
													className={showMainSupport ? "no-round-right no-border-r" : "p-lr-1"}
													onClick={() => this.setState({ filter_active: true })}>
													<div style={{ display: "flex", alignItems: "center", gap: "8px" }}><FilterIcon /></div>
												</Button>
											</Tooltip>
										</OrderToolBarButtonStyle>
									</>
								))}
								{quickSettingsEnabled && (layout === 0 || layout === 1) && (
									<OrderToolBarButtonStyle className="m-r-1">
										<Tooltip text="Settings" width={65} position="bottom">
											<Button
												size="sm"
												color={"transparent"}
												className={showMainSupport ? "p-lr-1 no-round-right no-border-r" : "p-lr-1"}
												onClick={() => this.setState({ quick_settings_active: true })}>
												<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
													<FaCog />
												</div>
											</Button>
										</Tooltip>
										
									</OrderToolBarButtonStyle>
								)}
								{layout === 0 && (
									<OrderToolBarButtonStyle>
										<Tooltip text="Refresh" width={65} position="bottom">
											<Button
												size="sm"
												color={"transparent"}
												className={cn("", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
												onClick={store.service.order.get_board}>
												<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}>
													<FaSync />
												</RefreshBtnWrapper>
											</Button>
										</Tooltip>
										
									</OrderToolBarButtonStyle>
								)}
								{layout === 2 && (
									<OrderToolBarButtonStyle>
										<Tooltip text="Refresh" width={65} position="bottom">
											<Button
												size="sm"
												color={"transparent"}
												className={cn("", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
												onClick={this.handleFetchOrderBump}>
												<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}><FaSync /></RefreshBtnWrapper>
											</Button>
										</Tooltip>
									</OrderToolBarButtonStyle>
								)}
								{showMainSupport && (layout === 0 || layout === 1) && (
									<OrderToolBarButtonStyle className="m-l-1">
										<Tooltip text="Help" width={65} position="bottom">
											<a target="_blank" href="https://support.cloudwaitress.com/getting-started/managing-orders-and-bookings">
												<Button size="sm" color="transparent" className="no-round-left">
													<FaQuestionCircle />
												</Button>
											</a>
										</Tooltip>
									</OrderToolBarButtonStyle>
								)}
							</div>
						</ControlButtonWrapper>

					</OrderToolBarWrapper>

				</div>
				{showCountdown && <PauseWarningWrapper>
					<PauseWarningLeft>
						<CommonText size="semiMedium" weight={500} color="#F5F5F5">
							<FaHourglassHalf />
							{services} are paused
						</CommonText>
					</PauseWarningLeft>
					<PauseWarningRight>
						<CountdownClock endTime={r.settings.business.operations?.pause_end ?? 0} finishCountdown={(value: boolean) => { this.setState({ countdownFinished: value }) }} />
					</PauseWarningRight>
				</PauseWarningWrapper>}
				<LayoutViewWrapper customStyle={pathNameEnd}>

					{layout === 0 && <RestaurantOrdersBoard />}

					{layout === 1 && <RestaurantOrdersList />}

					{(layout === 2 && restrictions._.restaurantOrderViews.indexOf("bump") !== -1) && <OrderBumpWapper><RestaurantOrdersBump fetchingDataBump={this.state.fetching_data_bump} /></OrderBumpWapper>}
				</LayoutViewWrapper>

				<RestaurantOrderModal />

				<RestaurantOrdersQuickSettings
					active={this.state.quick_settings_active}
					close={() => this.setState({ quick_settings_active: false })}
				/>
				<BumpViewSidebar
					isOpen={this.state.bump_view_screen_active}
					close={() => this.setState({ bump_view_screen_active: false })}
				/>
				<PauseServicesSidebar
					isOpen={this.state.pause_services_active}
					close={() => this.setState({ pause_services_active: false })}
					finishCountdown={(value: boolean) => { this.setState({ countdownFinished: value }) }}
				/>
				<FilterViewScreen
					active={this.state.filter_active}
					close={this.handleFilterViewScreenClose}
				/>
			</div>
		);
	}

}

export const RestaurantOrders = withTranslation()(RestaurantOrdersClass);
