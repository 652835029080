export const setOverflowScrollRoot = (condition: boolean) => {
    const scrollRootElement = document.getElementById('scroll-root');
      if (scrollRootElement && condition) {
        scrollRootElement.style.overflowY = 'hidden';
        scrollRootElement.style.marginRight = '17px';
      }

      if (scrollRootElement && !condition) {
        scrollRootElement.style.overflowY = 'scroll';
        scrollRootElement.style.marginRight = '0px';
      }
};
