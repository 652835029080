import * as React from "react";
import { Button, styled } from "@lib/components";
import { FaTrashAlt, FaCopy } from "react-icons/fa";
import { SettingsPrintersForm } from "./printers.form";
import shortid from "shortid";
import { Modal } from "@lib/components";
import { ModalContent } from "@lib/components";
import cloneDeep from "lodash/cloneDeep";
import { RotateLoader } from "@lib/components";
import { Tooltip } from "@lib/components";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../../../../mobx/components";
import { logger, print_detail_list, Untrusive, SortType } from "@lib/common";
import { ConnectionIndicatorCircle } from "@lib/components";
import { Tag, TabSelect } from "@lib/components";
import {
	ItemListContent,
	ItemListItem,
	ItemListWrapper,
} from "@lib/components";
import { LinkTag } from "@lib/components";
import { UI } from "../../../../../../../core/ui";
import { transfromCheckoutFieldsToReceiptPrintOptions } from "../../../../../../../core/libs/receipt";
import { PrinterReceiptLogo } from "./printer-receipt-logo";

interface Props extends WithTranslation {}
interface State {
	tab: string;
	status: T.API.DashboardPrinterStatusResItem[];
	status_loading: boolean;
	panel_id: string | null;
	copy_index: number | null;
	copy_loading: boolean;
	remove_index: number | null;
	remove_loading: boolean;
}
const Input = styled('input')`
display: inline-block;
font-size: 12px;
font-weight: 600;
background: #ebebeb;
color: #212121;
padding: 0 10px;
border-radius: 3px;
border: none;
height: unset;
width: max-content;
width: 485px;
`;
@inject("store")
@observer

class SettingsPrintersClass extends MobxComponent<Props, State> {
	is_mounted: boolean = false;

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
			status: [],
			status_loading: true,
			panel_id: null,
			copy_index: null,
			copy_loading: false,
			remove_index: null,
			remove_loading: false,
		};
	}

	componentDidMount() {
		this.is_mounted = true;
		this.get_status(true);
	}

	componentWillUnmount() {
		this.is_mounted = false;
	}

	setPanelID = (panel_id: string | null) => {
		this.setState({ panel_id });
	};
	create = (
		printer: T.Schema.Restaurant.Printer.RestaurantPrinter | null
	): T.Schema.Restaurant.Printer.RestaurantPrinter => {
		const r = this.injected.store.restaurant!;
		if (printer === null) {
			const _id = shortid.generate();
			return {
				_id: _id,
				name: "",
				api_key: `${r._id}|${_id}|${r.api}`,
				auto_print_enabled: false,
				auto_print_fail_mail: "",
				header: "",
				footer: "",
				date_format: "",
				time_format: "",
				type: "escpos",
				escpos_type: "image",
				escpos_characters_per_line: 48,
				escpos_paper_width: 285,
				escpos_paper_height: 1,
				escpos_paper_scale_factor: 2,
				escpos_paper_density: "d24",
				escpos_feed_line_start: 0,
				escpos_feed_line_end: 0,
				escpos_font_size: {
					details_phone_address: {
						width: "1",
						height: "1",
					},
					items: {
						width: "1",
						height: "1",
					},
				},
				disabled: false,
				mqtt: {
					enabled: false,
					server: "",
					port: "",
					username: "",
					password: "",
					topics: "",
					qos: 2,
					copies: 1,
				},
				paper: {
					font_size: 14,
					height: 210,
					width: 72,
					margins: {
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
					},
				},
				receipt: {
					details: transfromCheckoutFieldsToReceiptPrintOptions(
						this.injected.store.restaurant!,
						print_detail_list()
					),
					highlighted_details: ["config.due"],
					dishes: {
						enabled: true,
						prices: true,
						option_prices: false,
						combo_choice_names: true,
						sort_type: SortType.ByCategory.toString(),
						subtitle: true
					},
					totals: true,
				},
			};
		}
		return printer;
	};
	copy = async (index: number | null) => {
		if (index === null) return;
		await this.saveRestaurant({
			successMsg: "Printer copied",
			process: (r) => {
				const printer = cloneDeep(r.settings.printers[index]);
				printer._id = shortid.generate();
				printer.name = `${printer.name} - Copy`;
				printer.api_key = `${r._id}|${printer._id}|${r.api}`;
				r.settings.printers.push(printer);
				return {
					update: {
						$set: { "settings.printers": r.settings.printers },
					},
				};
			},
			before: () => this.setState({ copy_loading: true }),
			onSuccess: () =>
				this.setState({ copy_loading: false, copy_index: null }),
			onFail: () => this.setState({ copy_loading: false }),
			onError: () => this.setState({ copy_loading: false }),
		});
	};
	remove = async (index: number | null) => {
		if (index === null) return;
		await this.saveRestaurant({
			successMsg: "Printer deleted",
			process: (r) => {
				r.settings.printers.splice(index, 1);
				return {
					update: {
						$set: { "settings.printers": r.settings.printers },
					},
				};
			},
			before: () => this.setState({ remove_loading: true }),
			onSuccess: () =>
				this.setState({ remove_loading: false, remove_index: null }),
			onFail: () => this.setState({ remove_loading: false }),
			onError: () => this.setState({ remove_loading: false }),
		});
	};

	list = () => {
		const { t, store } = this.injected;
		const { status } = this.state;
		const activePrinters = store.ably.printers;
		const r = store.restaurant!;

		if (r.settings.printers.length === 0) return null;

		return (
			<div className="m-t-4">
				<ItemListWrapper>
					<ItemListContent className="border-white-10">
						{r.settings.printers.map((item, i) => {
							const printerStatus = status.find(
								(device) => device.printerId === item._id
							);
							return (
								<ItemListItem
									key={i}
									onClick={() => this.setPanelID(item._id)}
								>
									<div className="p-lr-4 p-tb-2 flex-line centered">
										{!printerStatus && (
											<ConnectionIndicatorCircle
												status={
													item.disabled ||
													activePrinters.indexOf(
														item.api_key
													) === -1
														? "disconnected"
														: "connected"
												}
											/>
										)}

										{printerStatus && (
											<ConnectionIndicatorCircle
												status={
													item.disabled ||
													!printerStatus.online
														? "disconnected"
														: "connected"
												}
											/>
										)}

										<div className="m-l-3">
											<p className="big font-semi-bold m-b-1">
												{item.name}
											</p>
											<Tooltip
												text="Click to copy to clipboard"
												width={160}
												position="top"
											>

												<p
												>API Key:


													<Input
														id={`api_key_${item._id}`}
														type="text"
														className="cursor-text m-l-1"
														value={item.api_key}
														onClick={(e) => {
															e.stopPropagation()
															const input = document.querySelector(`#api_key_${item._id}`);
															if (input) {
																// @ts-ignore
																navigator.clipboard.writeText(input.value)
																UI.notification.success("Copied to clipboard");
															}
														}}

													/>


												</p>
											</Tooltip>

											{!printerStatus && (
												<p className="m-t-1">
													{item.disabled &&
														"Status: Disabled"}
													{!item.disabled &&
														`Status: ${
															activePrinters.indexOf(
																item.api_key
															) !== -1
																? "Connected"
																: "Disconnected"
														}`}
												</p>
											)}

											{printerStatus && (
												<p className="m-t-1">
													{item.disabled &&
														"Status: Disabled"}
													{!item.disabled &&
														`Status: ${
															printerStatus.online
																? "Connected"
																: `Disconnected (Last seen ${t(
																		"datetimeFromTimestamp",
																		{
																			value:
																				printerStatus.lastActive *
																				1000,
																		}
																  )})`
														}`}
												</p>
											)}
										</div>
									</div>
									<div>
										<Tooltip
											text="Delete"
											width={65}
											position="top"
										>
											<Button
												type="button"
												color="transparent"
												className="no-shadow"
												paddinglr={10}
												onClick={(e) => {
													e.stopPropagation();
													this.setState({
														remove_index: i,
													});
												}}
											>
												<FaTrashAlt />
											</Button>
										</Tooltip>
										<Tooltip
											text="Duplicate"
											width={65}
											position="top"
										>
											<Button
												type="button"
												color="transparent"
												className="no-shadow"
												paddinglr={10}
												onClick={(e) => {
													e.stopPropagation();
													this.setState({
														copy_index: i,
													});
												}}
											>
												<FaCopy />
											</Button>
										</Tooltip>
									</div>
								</ItemListItem>
							);
						})}
					</ItemListContent>
				</ItemListWrapper>
			</div>
		);
	};
	panel = () => {
		const r = this.injected.store.restaurant!;
		const item_id = this.state.panel_id;
		const active = item_id !== null;

		let initialValues = null;
		if (active) {
			if (item_id === "") {
				initialValues = this.create(null);
			} else {
				const item = r.settings.printers.find((p) => p._id === item_id);
				initialValues = this.create(item || null);
			}
		}

		return (
			<SettingsPrintersForm
				type={item_id === "" ? "create" : "edit"}
				initialValues={initialValues}
				close={() => this.setPanelID(null)}
			/>
		);
	};
	modal_copy = () => {
		const r = this.injected.store.restaurant!;
		const { copy_index, copy_loading } = this.state;
		const printer =
			copy_index !== null ? r.settings.printers[copy_index] : null;
		return (
			<Modal
				width="sm"
				close={() => this.setState({ copy_index: null })}
				closeButton={false}
				active={!!printer}
			>
				<ModalContent className="flex-l-r-center">
					<h4 className="">Copy Printer</h4>
					<p className="big underline">{printer && printer.name}</p>
				</ModalContent>
				<ModalContent className="flex-right">
					<Button
						type="button"
						className="m-r-2 width100 max100px"
						onClick={() => this.setState({ copy_index: null })}
					>
						Cancel
					</Button>
					<Button
						type="button"
						className="width100 max100px"
						color="primary"
						onClick={() => this.copy(copy_index)}
					>
						{copy_loading && (
							<RotateLoader size={2} color="white" />
						)}
						{!copy_loading && "Copy"}
					</Button>
				</ModalContent>
			</Modal>
		);
	};
	modal_delete = () => {
		const r = this.injected.store.restaurant!;
		const { remove_index, remove_loading } = this.state;
		const printer =
			remove_index !== null ? r.settings.printers[remove_index] : null;
		return (
			<Modal
				width="sm"
				close={() => this.setState({ remove_index: null })}
				closeButton={false}
				active={remove_index !== null}
			>
				<ModalContent className="flex-l-r-center">
					<h4 className="">Delete Printer</h4>
					<p className="big underline">{printer && printer.name}</p>
				</ModalContent>
				<ModalContent>
					<p className="lhp big">
						Are you sure you want to delete this printer. Once
						deleted, it cannot be restored
					</p>
				</ModalContent>
				<ModalContent className="flex-right">
					<Button
						type="button"
						className="m-r-2 width100 max100px"
						onClick={() => this.setState({ remove_index: null })}
					>
						Cancel
					</Button>
					<Button
						type="button"
						className="width100 max100px"
						color="primary"
						onClick={() => this.remove(remove_index)}
					>
						{remove_loading && (
							<RotateLoader size={2} color="white" />
						)}
						{!remove_loading && "Delete"}
					</Button>
				</ModalContent>
			</Modal>
		);
	};

	get_status = async (onInit: boolean = false) => {
		try {
			const { store } = this.injected;

			const r = this.injected.store.restaurant!;

			if (r.settings.printers.length === 0) return;

			Untrusive.start();
			this.setState({ status_loading: true });

			const res = await store.api.printer_status({
				restaurant_id: r._id,
				printers: r.settings.printers.map((p) => p._id),
			});

			// Cant happen
			if (res.outcome) {
				Untrusive.stop();
				return;
			}

			Untrusive.stop();

			if (this.is_mounted) {
				if (!onInit) {
					UI.notification.success("Printer status updated");
				}
				this.setState({
					status: res.status,
					status_loading: false,
				});
			}
		} catch (e) {
			UI.notification.error("Failed to retrieve printer status");
			Untrusive.stop();
			logger.captureException(e);
			this.setState({
				status_loading: false,
			});
		}
	};

	render() {
		const { showMainSupport } = this.injected.store;
		const printer_id = this.state.panel_id;
		const active = printer_id !== null;
		const { tab } = this.state;
		return (
			<div>
				<TabSelect
					id="notifications-tab-options"
					className="border-white-tb-10"
					hasBorder={true}
					screenWidth={this.injected.store.view.screen_width}
					onChange={(v) => this.setState({ tab: v.value })}
					value={tab}
					values={[
						{ label: "Printer Setup", value: "0" },
						{ label: "Receipt Logo", value: "1" },
					]}
				/>
				{tab === "0" && (
					<div className="p-4">
						<div className="flex">
							<Button
								color="primary"
								size="xs"
								className="p-lr-2 m-r-2"
								onClick={() =>
									active
										? this.setState({ panel_id: null })
										: this.setState({ panel_id: "" })
								}
							>
								Create Printer
							</Button>
							<Button
								color="primary"
								size="xs"
								className="p-lr-2"
								onClick={() => this.get_status()}
							>
								{this.state.status_loading ? (
									<RotateLoader size={2} color="white" />
								) : (
									"Update Status"
								)}
							</Button>
						</div>

						{this.list()}

						{showMainSupport && (
							<LinkTag
								className="block m-t-4 font-semi-bold"
								target="_blank"
								href="https://support.cloudwaitress.com/how-to-guides/setup-printing-for-windows"
							>
								Read our printing setup guide
							</LinkTag>
						)}

						{this.panel()}
						{this.modal_copy()}
						{this.modal_delete()}
					</div>
				)}

				{tab === "1" && <PrinterReceiptLogo />}
			</div>
		);
	}
}

export const SettingsPrinters = withTranslation()(SettingsPrintersClass);