import shortid from 'shortid';

export const mexicanMenu = [
    {
      "_id": "ewTxBIJrD",
      "name": "Mexican Menu",
      "display_name": "Mexican Menu",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": false,
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "eTj6qW4kN",
          "menu_id": "ewTxBIJrD",
          "name": "Entrees (online)",
          "display_name": "Entrees",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "q8b5tbqT2",
              "menu_id": "ewTxBIJrD",
              "category_id": "eTj6qW4kN",
              "name": "Corn chips and Guacamole",
              "display_name": "",
              "description": "Corn chips with homemade guacamole",
              "subtitle": "",
              "price": 11.5,
              "tags": [
                "kK-HAduK7w",
                "wyX2UiFBr",
                "FsJrVpcw1"
              ],
              "image": {
                "_id": "3982e655-c42f-4eb2-901a-ca4da2d38ddd",
                "name": "IMG_6663.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "6h355s4Cw",
              "menu_id": "ewTxBIJrD",
              "category_id": "eTj6qW4kN",
              "name": "Ceviche",
              "display_name": "",
              "description": "Fresh fish, lime cured and finished with coconut milk, cilantro and spring onions. ",
              "subtitle": "Served with corn chips",
              "price": 14.5,
              "tags": [
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "b651cd80-8017-4f02-9fde-bd0d24ff3ce1",
                "name": "IMG_6664.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Dq4-F5ISq",
              "menu_id": "ewTxBIJrD",
              "category_id": "eTj6qW4kN",
              "name": "Corn chips and salsa",
              "display_name": "",
              "description": "Corn chips with homemade salsa",
              "subtitle": "",
              "price": 11.5,
              "tags": [
                "kK-HAduK7w",
                "wyX2UiFBr",
                "FsJrVpcw1"
              ],
              "image": {
                "_id": "68920ffa-fdef-462c-ba28-bb95f956bb2e",
                "name": "IMG_6665.jpeg",
                "modifiers": "-/crop/1127x1266/0,0/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "xQ0DPjszx",
              "menu_id": "ewTxBIJrD",
              "category_id": "eTj6qW4kN",
              "name": "Chicken Flautas",
              "display_name": "",
              "description": "Tortillas filled with marinated chicken in salsa verde finished with mole sauce",
              "subtitle": "",
              "price": 14,
              "tags": [
                "wyX2UiFBr",
                "FsJrVpcw1"
              ],
              "image": {
                "_id": "11e056cd-8b68-49ac-b9a5-b6bd2c3d88cc",
                "name": "IMG_6668.jpeg",
                "modifiers": "-/crop/1214x662/0,0/-/preview/"
              },
              "status": "no-stock",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RSDigQp-M",
              "menu_id": "ewTxBIJrD",
              "category_id": "eTj6qW4kN",
              "name": "Chilli Con Queso",
              "display_name": "",
              "description": "Mexican cheese fondue topped with salsa and jalapenos",
              "subtitle": "",
              "price": 13,
              "tags": [
                "kK-HAduK7w",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "a460c4a4-6ef7-4234-862b-d891f659e8c4",
                "name": "IMG_6670.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HQAPrRwk_",
              "menu_id": "ewTxBIJrD",
              "category_id": "eTj6qW4kN",
              "name": "Jalepeno poppers- 5 pcs",
              "display_name": "",
              "description": "Served with chipotle mayo and cheese sauce",
              "subtitle": "",
              "price": 15,
              "tags": [
                "kK-HAduK7w"
              ],
              "image": {
                "_id": "731d4e22-cb85-45bb-87c5-75f64b8e4253",
                "name": "IMG_0039.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YZjobfCXi",
              "menu_id": "ewTxBIJrD",
              "category_id": "eTj6qW4kN",
              "name": "Mexican spicy wings- 3 Pcs",
              "display_name": "",
              "description": "Served with habanero mayo",
              "subtitle": "",
              "price": 15,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SjfzRgQRy",
              "menu_id": "ewTxBIJrD",
              "category_id": "eTj6qW4kN",
              "name": "Fried Calamari ",
              "display_name": "",
              "description": "Home made fried calamari served with chipotle sauce",
              "subtitle": "",
              "price": 15,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "MDbw79GvY",
          "menu_id": "ewTxBIJrD",
          "name": "Cancun Nachos ",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "hTXaItPl1",
              "menu_id": "ewTxBIJrD",
              "category_id": "MDbw79GvY",
              "name": "Beef Nachos",
              "display_name": "",
              "description": "Served with Sour Cream, Salsa and Guacamole and Chips",
              "subtitle": "",
              "price": 18,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "5e41bd5b-b3ae-4951-90ec-b07dd3b4406c",
                "name": "tempImageIt7eWA.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "obfWYeQqh",
              "menu_id": "ewTxBIJrD",
              "category_id": "MDbw79GvY",
              "name": "Veg Nachos ",
              "display_name": "",
              "description": "Served with Sour Cream, Salsa and Guacamole and Chips",
              "subtitle": "",
              "price": 18,
              "tags": [
                "kK-HAduK7w",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "5e41bd5b-b3ae-4951-90ec-b07dd3b4406c",
                "name": "tempImageIt7eWA.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YbzNWMHil",
              "menu_id": "ewTxBIJrD",
              "category_id": "MDbw79GvY",
              "name": "Chorizo Nachos  ",
              "display_name": "",
              "description": "Served with Sour Cream, Salsa and Guacamole and Chips",
              "subtitle": "",
              "price": 19,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "5e41bd5b-b3ae-4951-90ec-b07dd3b4406c",
                "name": "tempImageIt7eWA.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "R-kpMBDCf",
          "menu_id": "ewTxBIJrD",
          "name": "Mexican Street Tacos (online)",
          "display_name": "Mexican Street Tacos",
          "description": "Gluten Free Available (GFA)",
          "dishes": [
            {
              "type": "standard",
              "_id": "nLDZ_aw2w",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Beef Tacos",
              "display_name": "",
              "description": "Slow cooked beef in cascable sauce finished with caramelized onions",
              "subtitle": "",
              "price": 8,
              "tags": [
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "b9d60017-4816-4717-b6bb-d24fe2e34ad7",
                "name": "IMG_6772.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HW3u9YDn6",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Crunchy Chicken Tacos",
              "display_name": "",
              "description": "Legendary cruncy chicken, chipotle mayo",
              "subtitle": "",
              "price": 8,
              "tags": [
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "07372416-7490-4a54-9469-1bd4c2d8eeca",
                "name": "IMG_6773.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "4JTgSyTcS",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Chorizo and Potatoes Tacos",
              "display_name": "",
              "description": "Spicy chorizo, potatoes topped with chilli con queso",
              "subtitle": "",
              "price": 8,
              "tags": [
                "wyX2UiFBr",
                "FsJrVpcw1"
              ],
              "image": {
                "_id": "37fafb31-717b-4bb2-b080-d85fde8920f7",
                "name": "IMG_6774.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YRwaVKcJC",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Pork Belly Tacos",
              "display_name": "",
              "description": "Pork belly in pibil sauce topped with pineappke salsa",
              "subtitle": "",
              "price": 8,
              "tags": [
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "39fb1765-2d30-4902-b9be-58860b4e4af0",
                "name": "IMG_6775.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "pklRZTyP-",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Vegetarian Tacos",
              "display_name": "",
              "description": "Vegetable medley chipotle sauce and topped with salsa and guacamole",
              "subtitle": "",
              "price": 8,
              "tags": [
                "kK-HAduK7w",
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "fed12b7e-eaf4-4867-a7bb-33ef10edd31b",
                "name": "IMG_6776.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "KeQVtnefg",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Beer Battered Fish Tacos",
              "display_name": "",
              "description": "Corona battered fish, lemon & habanero mayo",
              "subtitle": "",
              "price": 8,
              "tags": [
                "FsJrVpcw1"
              ],
              "image": {
                "_id": "b102c2bd-59e1-44d7-b5bd-0d08e6435940",
                "name": "IMG_6777.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ZgR5q9CZ7",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Seared Prawn Tacos",
              "display_name": "",
              "description": "Pan seared prawn (3) cooked in tequila sauce",
              "subtitle": "",
              "price": 8,
              "tags": [
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "500beda0-f283-4857-92a8-624d72f5c951",
                "name": "IMG_6603.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "combo",
              "_id": "ivN_riDh0",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Any 2 Tacos",
              "display_name": "",
              "description": "No Seafood",
              "subtitle": "",
              "price": 15,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [
                {
                  "_id": "CAN2flhk0",
                  "name": "First Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU"
                  ],
                  "selected": null,
                  "lpo": 0
                },
                {
                  "_id": "F50f4ODglJ",
                  "name": "Second Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU"
                  ],
                  "selected": null,
                  "lpo": 0
                }
              ],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "combo",
              "_id": "Cw1acoGVK",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Any 3 Tacos",
              "display_name": "",
              "description": "No Seafood",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [
                {
                  "_id": "CAN2flhk0",
                  "name": "First Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU"
                  ],
                  "selected": null,
                  "lpo": 0
                },
                {
                  "_id": "F50f4ODglJ",
                  "name": "Second Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU"
                  ],
                  "selected": null,
                  "lpo": 0
                },
                {
                  "_id": "7yVNBZMd8",
                  "name": "Third Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU"
                  ],
                  "selected": null,
                  "lpo": 0
                }
              ],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "combo",
              "_id": "zFJzt-Wz3",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Any 2 Pcs Tacos",
              "display_name": "",
              "description": "With Seafood",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [
                {
                  "_id": "CAN2flhk0",
                  "name": "First Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU",
                    "eLtcjz0m4V",
                    "Cln62Y0JZf"
                  ],
                  "selected": null,
                  "lpo": 0
                },
                {
                  "_id": "F50f4ODglJ",
                  "name": "Second Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU",
                    "eLtcjz0m4V",
                    "Cln62Y0JZf"
                  ],
                  "selected": null,
                  "lpo": 0
                }
              ],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "combo",
              "_id": "FJQd9nIbk",
              "menu_id": "ewTxBIJrD",
              "category_id": "R-kpMBDCf",
              "name": "Any 3 Pcs Tacos",
              "display_name": "",
              "description": "With Seafood",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [
                {
                  "_id": "CAN2flhk0",
                  "name": "First Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU",
                    "eLtcjz0m4V",
                    "Cln62Y0JZf"
                  ],
                  "selected": null,
                  "lpo": 0
                },
                {
                  "_id": "F50f4ODglJ",
                  "name": "Second Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU",
                    "eLtcjz0m4V",
                    "Cln62Y0JZf"
                  ],
                  "selected": null,
                  "lpo": 0
                },
                {
                  "_id": "7yVNBZMd8",
                  "name": "Third Tacos",
                  "dishes": [
                    "6cja8p_a47",
                    "KljaIRRlGL",
                    "Df_VDCUA0F",
                    "yhZZRCCDp-",
                    "UPgHdMlKZU",
                    "eLtcjz0m4V",
                    "Cln62Y0JZf"
                  ],
                  "selected": null,
                  "lpo": 0
                }
              ],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "Vlb6WRAD6",
          "menu_id": "ewTxBIJrD",
          "name": "Mexican Street Tacos (Hidden)",
          "display_name": "",
          "description": "Gluten Free Available (GFA)",
          "dishes": [
            {
              "type": "standard",
              "_id": "6cja8p_a47",
              "menu_id": "ewTxBIJrD",
              "category_id": "Vlb6WRAD6",
              "name": "Beef Tacos",
              "display_name": "",
              "description": "Slow cooked beef in cascable sauce finished with caramelized onions",
              "subtitle": "",
              "price": 0,
              "tags": [
                "FsJrVpcw1"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "KljaIRRlGL",
              "menu_id": "ewTxBIJrD",
              "category_id": "Vlb6WRAD6",
              "name": "Crunchy Chicken Tacos",
              "display_name": "",
              "description": "Legendary cruncy chicken, chipotle mayo",
              "subtitle": "",
              "price": 0,
              "tags": [
                "FsJrVpcw1"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Df_VDCUA0F",
              "menu_id": "ewTxBIJrD",
              "category_id": "Vlb6WRAD6",
              "name": "Chorizo and Potatoes Tacos",
              "display_name": "",
              "description": "Spicy chorizo, potatoes topped with chilli con queso",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "yhZZRCCDp-",
              "menu_id": "ewTxBIJrD",
              "category_id": "Vlb6WRAD6",
              "name": "Pork Belly Tacos",
              "display_name": "",
              "description": "Pan seared pork belly in pibil sauce topped with pineappke salsa",
              "subtitle": "",
              "price": 0,
              "tags": [
                "FsJrVpcw1"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UPgHdMlKZU",
              "menu_id": "ewTxBIJrD",
              "category_id": "Vlb6WRAD6",
              "name": "Vegetarian Tacos",
              "display_name": "",
              "description": "Vegetable medley chipotle sauce and topped with salsa and guacamole",
              "subtitle": "",
              "price": 0,
              "tags": [
                "kK-HAduK7w",
                "FsJrVpcw1"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "eLtcjz0m4V",
              "menu_id": "ewTxBIJrD",
              "category_id": "Vlb6WRAD6",
              "name": "Beer Battered Fish Tacos",
              "display_name": "",
              "description": "Corona battered fish, lemon & habanero mayo",
              "subtitle": "",
              "price": 0,
              "tags": [
                "FsJrVpcw1"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Cln62Y0JZf",
              "menu_id": "ewTxBIJrD",
              "category_id": "Vlb6WRAD6",
              "name": "Seared Prawn Tacos",
              "display_name": "",
              "description": "Pan seared prawn (3) cooked in tequila sauce",
              "subtitle": "",
              "price": 0,
              "tags": [
                "FsJrVpcw1"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "ZzqDYFSzJ",
          "menu_id": "ewTxBIJrD",
          "name": "Mexican Jarritos ",
          "display_name": "",
          "description": "Mexican flavour Sodas",
          "dishes": [
            {
              "type": "standard",
              "_id": "oNhqX3vhA",
              "menu_id": "ewTxBIJrD",
              "category_id": "ZzqDYFSzJ",
              "name": "Mexican Cola ",
              "display_name": "",
              "description": "Jarrittos flavoured soda",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "fc8d1912-b42d-4f83-b689-04db0ce23505",
                "name": "IMG_0094.webp",
                "modifiers": null
              },
              "status": "no-stock",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "aT0BjQUQ4",
              "menu_id": "ewTxBIJrD",
              "category_id": "ZzqDYFSzJ",
              "name": "Mango Jarritos ",
              "display_name": "",
              "description": "Jarrittos flavoured soda",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "cd92eda0-5fc7-439e-b02b-f02a314a7798",
                "name": "IMG_0090.webp",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "zV6KuhV0X",
              "menu_id": "ewTxBIJrD",
              "category_id": "ZzqDYFSzJ",
              "name": "Lime Jarritos  ",
              "display_name": "",
              "description": "Jarrittos flavoured soda",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "a1dd2482-5c14-4902-ba3e-0c59da1cb8df",
                "name": "IMG_0091.webp",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "IORf0CCMi",
              "menu_id": "ewTxBIJrD",
              "category_id": "ZzqDYFSzJ",
              "name": "Pineapple Jarritos   ",
              "display_name": "",
              "description": "Jarrittos flavoured soda",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "910210df-777a-47d1-a63c-7df17dc8e817",
                "name": "IMG_0092.webp",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "vliq2wZCM",
              "menu_id": "ewTxBIJrD",
              "category_id": "ZzqDYFSzJ",
              "name": "Mandarin Jarritos   ",
              "display_name": "",
              "description": "Jarrittos flavoured soda",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "21c4ead5-265b-44b6-8160-e022fb8b1f97",
                "name": "IMG_0093.webp",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "ufsRhiIgK",
          "menu_id": "ewTxBIJrD",
          "name": "Chimichangas (online)",
          "display_name": "Chimichangas",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "rHc9Jx1-u",
              "menu_id": "ewTxBIJrD",
              "category_id": "ufsRhiIgK",
              "name": "Beef Chimichanga's 1 Pc",
              "display_name": "",
              "description": "Slow cooked Beef in adobo sauce wrapped in tortilla and beans served with slaw",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "a60c55aa-9925-47fd-88b8-f4121060e9ab",
                "name": "tempImageVASXrU.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Ibe6kuVXG",
              "menu_id": "ewTxBIJrD",
              "category_id": "ufsRhiIgK",
              "name": "Chicken Chimichanga's 1 Pc",
              "display_name": "",
              "description": "Slow cooked chicken in chipotle sauce wrapped in tortilla and beans served with slaw",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "46719c2a-85b3-4161-aa2d-e6611f968081",
                "name": "tempImagewjT1VW.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "N-CRy69ru",
              "menu_id": "ewTxBIJrD",
              "category_id": "ufsRhiIgK",
              "name": "Mushroom and Potato Chimichanga's 1 Pc",
              "display_name": "",
              "description": "Cooked in cascabel sauce wrapped in tortilla and beans served with slaw",
              "subtitle": "",
              "price": 22,
              "tags": [
                "kK-HAduK7w"
              ],
              "image": {
                "_id": "5406cfce-6f59-4d39-b7bf-a9c86beababc",
                "name": "tempImagek0wAMz.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "RWo-C-554",
          "menu_id": "ewTxBIJrD",
          "name": "Salads (online)",
          "display_name": "Salads",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "loB_NZFMr",
              "menu_id": "ewTxBIJrD",
              "category_id": "RWo-C-554",
              "name": "Mexican Ceasar Salad",
              "display_name": "",
              "description": "Cos lettuce, garlic herb marinated chicken strips, tortilla croutons, corn finished with caesar dressing served in tortilla basket",
              "subtitle": "",
              "price": 18,
              "tags": [
                "FsJrVpcw1"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dKVKPqAJK",
              "menu_id": "ewTxBIJrD",
              "category_id": "RWo-C-554",
              "name": "Green Salad",
              "display_name": "",
              "description": "Mix lettuce, tomatoes, pickled vegetables, pumpkin seed finished with green goddess dressing",
              "subtitle": "",
              "price": 17,
              "tags": [
                "kK-HAduK7w",
                "wyX2UiFBr"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "nuJWtC8gZ",
          "menu_id": "ewTxBIJrD",
          "name": "Cancun Tostada (online)",
          "display_name": "Cancun Tostada",
          "description": "Served with slaw and rice (GFA)",
          "dishes": [
            {
              "type": "standard",
              "_id": "JP_FXTTPy",
              "menu_id": "ewTxBIJrD",
              "category_id": "nuJWtC8gZ",
              "name": "Lamb Tostada",
              "display_name": "",
              "description": "Slow cooked lamb shoulder in lamb stock served in crispy corn tortilla finished with pickled vegetables and cheese",
              "subtitle": "",
              "price": 22,
              "tags": [
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "ed807cc7-b45d-4ee9-97ff-63e57c60794c",
                "name": "tempImagerwdF9B.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "7LQLi2EPj",
              "menu_id": "ewTxBIJrD",
              "category_id": "nuJWtC8gZ",
              "name": "Veg Tostada",
              "display_name": "",
              "description": "Vegetable medley served on crispy corn tortilla finished with pickled vegetables and cheese",
              "subtitle": "",
              "price": 20,
              "tags": [
                "kK-HAduK7w",
                "wyX2UiFBr",
                "FsJrVpcw1"
              ],
              "image": {
                "_id": "0a3f0ea1-bd42-4921-8f13-9fe50049c6f4",
                "name": "tempImageFYMiyk.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EB0n7q-lG",
              "menu_id": "ewTxBIJrD",
              "category_id": "nuJWtC8gZ",
              "name": "Prawn Tostada",
              "display_name": "",
              "description": "Pan seared prawns (5) served on a crispy corn tortilla finished with pickled vegetables and cheese",
              "subtitle": "",
              "price": 23,
              "tags": [
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "bebd4ceb-edd6-42ac-98b8-73c3f0e81b9e",
                "name": "tempImagemLFQDE.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "S-4NpxuHj",
              "menu_id": "ewTxBIJrD",
              "category_id": "nuJWtC8gZ",
              "name": "Beef Tostada",
              "display_name": "",
              "description": "Pulled beef cooked in casable sauce served on crispy corn tortilla finished with pickled vegetables and cheese",
              "subtitle": "",
              "price": 20,
              "tags": [
                "FsJrVpcw1",
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "64f28c6a-8142-4057-89cd-e459f61d0eb1",
                "name": "tempImage99pQDP.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "EHzpUcBli",
          "menu_id": "ewTxBIJrD",
          "name": "Cancun Burritos (online)",
          "display_name": "Cancun Burritos",
          "description": "All Burritos served with slaww, salsa, sour cream and guacamole",
          "dishes": [
            {
              "type": "standard",
              "_id": "nHNRpWDy1",
              "menu_id": "ewTxBIJrD",
              "category_id": "EHzpUcBli",
              "name": "Chicken Burritos",
              "display_name": "",
              "description": "Slow cooked chicken in pibil sauce, wrapped in flour tortilla with rice, beans, con queso and grated cheese",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": {
                "_id": "0ecec80d-87fe-46e5-a11d-258a4490836a",
                "name": "IMG_6872.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "GfYg8Ny0-",
              "menu_id": "ewTxBIJrD",
              "category_id": "EHzpUcBli",
              "name": "Beef Burritos",
              "display_name": "",
              "description": "Slow cooked beef in adobo sauce wrapped in flour tortilla with rice, beans and cheese",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": {
                "_id": "422afbf5-d622-4269-a497-7913a523dfe8",
                "name": "IMG_6870.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "bVOqxeECu",
              "menu_id": "ewTxBIJrD",
              "category_id": "EHzpUcBli",
              "name": "Vegetarian Burritos",
              "display_name": "",
              "description": "Slow cooked vegetable medley in chipotle sauce wrapped in flour tortilla, rice beans and  con queso",
              "subtitle": "",
              "price": 25,
              "tags": [
                "kK-HAduK7w"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "CL4IsVBZ2",
          "menu_id": "ewTxBIJrD",
          "name": "Cancun Quesadillas (online)",
          "display_name": "Cancun Quesadillas",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "CmudK9hr5",
              "menu_id": "ewTxBIJrD",
              "category_id": "CL4IsVBZ2",
              "name": "Chicken Quesadillas",
              "display_name": "",
              "description": "Chicken folded in tortilla chilli cheese, tomato rice, slaw and salsa ",
              "subtitle": "",
              "price": 21,
              "tags": [],
              "image": {
                "_id": "c3cae6f3-0967-428a-9979-594f6bfc5352",
                "name": "IMG_6874.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "mF-MLxB6n",
              "menu_id": "ewTxBIJrD",
              "category_id": "CL4IsVBZ2",
              "name": "Braised Beef Quesadillas",
              "display_name": "",
              "description": "Pulled beef folded in tortilla with chilli sauce, tomato rice, slaw and salsa ",
              "subtitle": "",
              "price": 21,
              "tags": [],
              "image": {
                "_id": "bdc8449b-3e17-4776-86e8-3d7473fe9ec9",
                "name": "IMG_6873.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ZTZ3YcBsP",
              "menu_id": "ewTxBIJrD",
              "category_id": "CL4IsVBZ2",
              "name": "Vegetarian Quesadillas",
              "display_name": "",
              "description": "Vegetable medley folded in tortilla with cheese, tomato rice, slaw and salsa",
              "subtitle": "",
              "price": 21,
              "tags": [],
              "image": {
                "_id": "a2dbf0c0-fd0e-4932-b6ed-e888e852937e",
                "name": "IMG_6874.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "hZVRZuOp5",
              "menu_id": "ewTxBIJrD",
              "category_id": "CL4IsVBZ2",
              "name": "Pork Belly Quesadillas",
              "display_name": "",
              "description": "Pork belly cooked in pibil sauce folded in tortilla with cheese, tomato rice, slaw and salsa ",
              "subtitle": "",
              "price": 21,
              "tags": [],
              "image": {
                "_id": "c50421e6-fafa-454e-8ba2-01d2b3666e19",
                "name": "IMG_6874.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "LMVB0nqhF",
          "menu_id": "ewTxBIJrD",
          "name": " Fajitas (online)",
          "display_name": " Fajitas",
          "description": "All fajitas are served with  tomato salsa, rice, beans, sour cream and guacamole (GFA)",
          "dishes": [
            {
              "type": "standard",
              "_id": "w-48GXcto",
              "menu_id": "ewTxBIJrD",
              "category_id": "LMVB0nqhF",
              "name": "Chicken Fajitas",
              "display_name": "",
              "description": "Chicken cooked in chipotle sauce served with 3 flour tortillas, Salsa, Rice, Sour Cream and Guacamole ",
              "subtitle": "",
              "price": 28,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "b5896da1-da9e-4140-a829-4081aae54563",
                "name": "IMG_7425.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wYfosguat"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "zwwT0NV86",
              "menu_id": "ewTxBIJrD",
              "category_id": "LMVB0nqhF",
              "name": "Beef Fajitas",
              "display_name": "",
              "description": "Slow cooked beef in adobo sauce served with 3 flour tortillas, Salsa, Rice, Sour Cream and Guacamole ",
              "subtitle": "",
              "price": 28,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "28592ee3-b4b4-4f2a-83c5-be08ba39e408",
                "name": "IMG_7425.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wYfosguat"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "5tiu_lBsp",
              "menu_id": "ewTxBIJrD",
              "category_id": "LMVB0nqhF",
              "name": "Vegetarian Fajitas",
              "display_name": "",
              "description": "Vegetable medley served with 3 flour tortillas, Salsa, Rice, Sour Cream and Guacamole ",
              "subtitle": "",
              "price": 28,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "58183a1c-cbaf-4a33-963a-216d1edc0d20",
                "name": "IMG_7425.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wYfosguat"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Ic6Mmkhw4",
              "menu_id": "ewTxBIJrD",
              "category_id": "LMVB0nqhF",
              "name": "Surf and Turf Fajita (Beef+Prawns)",
              "display_name": "",
              "description": "Beef and Prawns cooked in Adobo sauce served with 3 flour tortillas, Salsa, Rice, Sour Cream and Guacamole ",
              "subtitle": "",
              "price": 35,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": {
                "_id": "58183a1c-cbaf-4a33-963a-216d1edc0d20",
                "name": "IMG_7425.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "wYfosguat"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "DnwYkl_Im",
          "menu_id": "ewTxBIJrD",
          "name": "Dessert (online)",
          "display_name": "Dessert",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3RLVx6u4O",
              "menu_id": "ewTxBIJrD",
              "category_id": "DnwYkl_Im",
              "name": "Ice Cream Sundae (Choc)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "cuT9NFrF1"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "u6lbU-wuB",
              "menu_id": "ewTxBIJrD",
              "category_id": "DnwYkl_Im",
              "name": "Ice Cream Sundae (Strawberry)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "cuT9NFrF1"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "fV7zxb3Hb",
              "menu_id": "ewTxBIJrD",
              "category_id": "DnwYkl_Im",
              "name": "Ice Cream Sundae (Caramel)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "cuT9NFrF1"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "octHwFEFd",
              "menu_id": "ewTxBIJrD",
              "category_id": "DnwYkl_Im",
              "name": "Churros- 5 pcs",
              "display_name": "",
              "description": "Deep fried pastry coated in cinnamon sugar served",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "34b7d165-9c8d-409e-b5d4-07ec6e5cedb9",
                "name": "IMG_0066.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "M8FK2hM6Y"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "fNf2FW7nX",
              "menu_id": "ewTxBIJrD",
              "category_id": "DnwYkl_Im",
              "name": "Banana chimichanga's",
              "display_name": "",
              "description": "Banana wrapped in soft flour tortilla with caramel sauce deep fried served with vanilla ice cream",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "9bc5065b-105c-4061-a91c-bacb15332993",
                "name": "IMG_0067.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EevJPUBwE",
              "menu_id": "ewTxBIJrD",
              "category_id": "DnwYkl_Im",
              "name": "Mexican cheesecake",
              "display_name": "",
              "description": "Baked cheesecake with chaeta sauce",
              "subtitle": "",
              "price": 14,
              "tags": [
                "wyX2UiFBr"
              ],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "E_TqOj751",
          "menu_id": "ewTxBIJrD",
          "name": "Gift Vouchers (online) ",
          "display_name": "Gift Vouchers",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "XbePyD-3RU",
              "menu_id": "ewTxBIJrD",
              "category_id": "E_TqOj751",
              "name": "Gift Voucher $15",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 15,
              "tags": [],
              "image": {
                "_id": "f428bb5b-327c-4ada-82a4-5572a2eaf01d",
                "name": "tempImagebSXhVx.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "z90rDP4Lg6",
              "menu_id": "ewTxBIJrD",
              "category_id": "E_TqOj751",
              "name": "Gift Voucher $20",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "f1186863-ea75-4fb5-8fff-abc52514c26d",
                "name": "tempImagerTlSH2.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "aPWWEQN_D0",
              "menu_id": "ewTxBIJrD",
              "category_id": "E_TqOj751",
              "name": "Gift Voucher $25",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": {
                "_id": "87ab6678-a916-4bef-a841-188ac665283d",
                "name": "tempImage9oyKEt.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ryJgkhHvN1",
              "menu_id": "ewTxBIJrD",
              "category_id": "E_TqOj751",
              "name": "Gift Voucher $30",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 30,
              "tags": [],
              "image": {
                "_id": "5b2c1f28-8f69-482d-9bef-c634e46b1da3",
                "name": "tempImagemFiSdJ.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "cuT9NFrF1"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "COqmIyVujX",
              "menu_id": "ewTxBIJrD",
              "category_id": "E_TqOj751",
              "name": "Gift Voucher $50",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 50,
              "tags": [],
              "image": {
                "_id": "5b43c64a-8c24-4deb-a481-8f0d0d53d606",
                "name": "tempImageeSHLpa.heic",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "bVVFo6L-G",
          "menu_id": "ewTxBIJrD",
          "name": "Soft drinks ",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "mMT3p33QK",
              "menu_id": "ewTxBIJrD",
              "category_id": "bVVFo6L-G",
              "name": "Coke Can- 330ml ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "O6fY07D2U",
              "menu_id": "ewTxBIJrD",
              "category_id": "bVVFo6L-G",
              "name": "Sprite Can- 330ml ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "vsqDPJjzT",
              "menu_id": "ewTxBIJrD",
              "category_id": "bVVFo6L-G",
              "name": "Coke Zero Can- 330ml  ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ErquE3U1E",
              "menu_id": "ewTxBIJrD",
              "category_id": "bVVFo6L-G",
              "name": "Fanta Can- 330ml  ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "JA_FEg9j9",
              "menu_id": "ewTxBIJrD",
              "category_id": "bVVFo6L-G",
              "name": "Sprite Zero Can- 330ml  ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        }
      ],
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    }
  ]

export const mexican_option_sets = [
    {
      "_id": "wYfosguat",
      "name": "sf. Extras",
      "display_name": "Extras",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "lNpygTc3-",
          "name": "Salsa",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "fT13A0ZfMI",
          "name": "Guacamole",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "KRFH8q7MkG",
          "name": "Salsa Verde",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "bvDrC6Q1Kk",
          "name": "Flour Tortillas (each)",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "LtsYrpKQw5",
          "name": "Corn Tortillas (each)",
          "price": "1.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "DKim5e2E5",
          "name": "Chilli Con queso",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "MPDuTuvhTN",
          "name": "Tomato Rice",
          "price": "3",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "-16pI1u3J3",
          "name": "Beans",
          "price": "3",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "cuT9NFrF1",
      "name": "d. Ice Cream Sundae",
      "display_name": "Size",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "kKRRqxobq",
          "name": "Single",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "PhZ_qGkwTe",
          "name": "Medium",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "BUoehSZsYm",
          "name": "Large",
          "price": "4",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "M8FK2hM6Y",
      "name": "d. Churros Sauce",
      "display_name": "Sauce",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "5bRNeZlAS",
          "name": "Caramel Sauce",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "vEnG20mcY",
          "name": "Chocolate Sauce",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "Bw3YOj6H_",
      "name": "Soft drinks ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "daR7NIAJb",
          "name": "Coke can",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "kmUyKD5VH",
          "name": "Sprite can",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "W-OT5L_kR",
          "name": "Coke Zero can ",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "uCA16MJtu",
          "name": "Sprite Zero can ",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "7eh6xX9XS",
          "name": "Fanta Can",
          "price": "5",
          "quantity": 0,
          "prices": {}
        }
      ]
    }
  ];


function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createMexicanMenu = () => {
    return {
        mexicanMenu:updateIds(mexicanMenu), mexican_option_sets:updateIds(mexican_option_sets)
    }
}

