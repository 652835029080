import shortid from 'shortid';

export const cafeMenu = [
    {
      "_id": "XUh4dKl5d",
      "name": "Cafe Menu",
      "display_name": "Cafe Menu",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": false,
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "Y_vkWDgH6c",
          "menu_id": "XUh4dKl5d",
          "name": "All Day Menu",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "2876381",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Bacon & Egg Roll",
              "display_name": "",
              "description": "Bacon, fried egg, roasted tomato, spinach, aioli & chilli jam",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191609",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "d7dac831-efc0-4c42-af24-e14f8447751d",
                "name": "B_and_E_4.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "xBr9uaQbH",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Burekas",
              "display_name": "",
              "description": "House made Turkish pastry filled with spinach and feta, served with a soft boiled egg , grated tomato and Zhug (V)",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "b7bc2a05-4415-4637-9d72-40a3732ede0f",
                "name": "burekas.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876379",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Eggs On Toast",
              "display_name": "",
              "description": "Eggs your way on sourdough",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/037f110c-c851-4ad6-ba57-13036ddae6ef_productImg.JPG",
                "name": "",
                "not_uc": true
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191598",
                "205336",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876380",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Sourdough Toast",
              "display_name": "",
              "description": "2 slices, butter and your choice of house made jam, peanut butter, vegemite or honey * ",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/89b9c33d-bbdc-4809-82c1-0174ecf35222_productImg.jpg",
                "name": "",
                "not_uc": true
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191604",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "BtiEVhPXq",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Acai Bowl",
              "display_name": "",
              "description": "(pronounced ah-sah-EE) - made from pureed frozen acai berries topped with a variety of fresh fruit, granola and shredded coconut.  Consider adding peanut butter",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "b721f7f1-7670-4e43-acb1-afe28561f257",
                "name": "Acai_Bowl.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "54YWW5LGJ"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "r5JQHfQpr",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Muesli Bowl",
              "display_name": "",
              "description": "Served with seasonal fruit, and your choice of yoghurt or milk (V)",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": {
                "_id": "cb759f2c-2640-4331-8e57-5c645981c404",
                "name": "Museli.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": ""
            },
            {
              "type": "standard",
              "_id": "2876382",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Avocado Smash",
              "display_name": "",
              "description": "Avocado, mint and Danish feta smash, sourdough, zaatar, poached egg and roasted cherry tomatoes *(V)",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191677",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "e98d7317-5385-499b-a5e5-5f3494740111",
                "name": "avo_on_toast.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "oPVR5s9mH",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Schnitzel Challah Bread Sandwich",
              "display_name": "",
              "description": "Chicken schnitzel with house made matbucha, fried eggplant, lettuce, aioli & mango pickle sauce on house made Challah bread",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "853f9050-c202-482e-ac88-14d9d8e07e44",
                "name": "Challah_Schnitzel_A.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191601",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "qq-otuJfP",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Falafel Challah Bread Sandwich",
              "display_name": "",
              "description": "Humus, matbucha, fried eggplant, lettuce and freshly  made falafel, white tahini and mango pickle on house made Challah bread  (V)(VG)",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "61e7f426-e226-4f0d-a717-3393d0bf1924",
                "name": "Falafel_Challah.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191601",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "TutPjQHPu",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Salami Omelette Challah Bread Sandwich",
              "display_name": "",
              "description": "Grilled salami, house made matbucha and an omelette on house made Challah bread",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "6c100da2-1379-45d3-9a5a-fd0862ee48e3",
                "name": "Challah_Salami_2.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191601",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "o8sruntGP",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Sabich Croissant",
              "display_name": "",
              "description": "Hummus, eggplant, boiled egg, tomato salad, pickles, harissa, hazelnut dukkha and zaatar on a fresh croissant (V)",
              "subtitle": "",
              "price": 21,
              "tags": [],
              "image": {
                "_id": "94169d97-07ea-42f4-9ed8-e5f7b4623bea",
                "name": "sabich_1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191601",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "vPuqTbFDN",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Malawach Sabich",
              "display_name": "",
              "description": "Hummus, eggplant, boiled egg, tomato salad, pickles,  harissa, hazelnut dukkha & zaatar on a roti",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "b6eb5df9-ace5-4ddb-ba67-973bf3cc04ee",
                "name": "Malawach_sabich_roti.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "zAY_cp75Q",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Malawach Shakshuka",
              "display_name": "",
              "description": "Tomato ragout, baked egg and herbs on a roti",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "cd777f2f-dd80-4b9e-b5c4-3029a23c94a4",
                "name": "malawach_shakshuka_roti.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876386",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Egyptian Falafels",
              "display_name": "",
              "description": "Egyptian falafels served on creamy house made hummus, with warm seasoned chickpeas, tomato salad, white tahini, herbs, harissa, hazelnut dukkha and a flame charred pita bread *(V)(VG)",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191681",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "677f74f0-8bc9-4845-9384-e6906dd8da0e",
                "name": "falafel_new.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "2876387",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Israeli Chicken Shawarma",
              "display_name": "",
              "description": "Grilled chicken marinated with middle eastern spices, hummus,  mango pickle, white tahini, zaatar and a flame charred pita  bread *",
              "subtitle": "",
              "price": 26,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191682",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "868203bb-75de-4d04-82bf-d828baa108a4",
                "name": "Chicken_Shawarma_min.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "2876385",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Hummus-Shuka",
              "display_name": "",
              "description": "The perfect combination of hummus and shakshuka with  hazelnut dukkha and sourdough bread *(V)   Consider adding Sujuk (Turkish Sausage)",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191680",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "30648af4-cd71-42a6-88ad-69387c009a73",
                "name": "Hummus_shuka_1.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "2876384",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Shakshuka",
              "display_name": "",
              "description": "Tomato ragout with aromatic herbs, baked eggs, Danish feta,  and sourdough bread, *(V)  Consider adding Sujuk (Turkish Sausage)",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191679",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "8414fcfc-0978-4118-831a-746eb666a45c",
                "name": "Shakshuka.jpg",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "2876383",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Israeli Sabich Plate",
              "display_name": "",
              "description": "Hummus, eggplant, white tahini, boiled egg, Israeli pickles,  tomato salad, harissa, pickled mango, hazelnut dukkha, zaatar and a flame charred pita bread *(V)",
              "subtitle": "",
              "price": 25,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/8810310b-4b85-462a-880a-ecc44e3dffc7_productImg.jpg",
                "name": "",
                "not_uc": true
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191678",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2982694",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Tender and Fragrant Slow Cooked Lamb",
              "display_name": "",
              "description": "Served on a bed of house made creamy hummus, white tahini, olive oil and a sprinkle of zaatar and pine nuts with a flamed  charred pita bread on the side *",
              "subtitle": "",
              "price": 28,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "199676",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "a16462b1-3137-4acc-b4a2-327a31b174d9",
                "name": "lamb.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "2876391",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Spiced Portobello Mushrooms",
              "display_name": "",
              "description": "Served with creamy house made hummus, paprika oil, white tahini and a flame charred pita bread *  Consider adding an egg ",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191686",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "ec8c2216-7658-452b-a1b7-b5a0c71799b8",
                "name": "mushrooms_1.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "-qudvlHI5",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Turkish Eggs",
              "display_name": "",
              "description": "2 poached eggs on spiced Greek yoghurt, garlic, dill, and a chilli burnt butter served with sourdough *(V)",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "2b2ffea0-0b83-4569-900c-9ec9def54206",
                "name": "Turkish_Eggs.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876394",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Haloumi Sandwich",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 15,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191601",
                "191688"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2982737",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Schnitzel in Tel Aviv",
              "display_name": "",
              "description": "Crumbed chicken schnitzel, hummus, a side salad, olives and twice cooked potatoes ",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "199677",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "dc560be5-5b50-4d1a-87e3-5e1875a3a966",
                "name": "Tel_Aviv_Schnitzel.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "rdtgGsIG9",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Fresh Salad",
              "display_name": "",
              "description": "Kale, spinach, red radish, golden beetroot pickle, roasted pecan nuts, snap peas, all drizzled with a delicious citrus, honey tahini dressing",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": {
                "_id": "f6a84d32-930c-4c13-9483-271c56abb816",
                "name": "Fresh_Salad.jpeg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "4NppaZpoj"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876388",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Uncle Eli's Cauliflower",
              "display_name": "",
              "description": "Hummus, cauliflower roasted with middle eastern spices, toasted pine nuts, roasted cherry tomatoes, zaatar, drizzled with mango pickle and tahini and a flame charred pita bread ",
              "subtitle": "",
              "price": 24,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191683",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "e4e62070-e45c-4bf5-b3ed-71aea9729ff5",
                "name": "Eli_Cauliflower.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "QgJcoyNg3",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Hummus Plate",
              "display_name": "",
              "description": "Creamy house made hummus, served with roasted cherry tomatoes, white tahini, a drizzle of paprika oil and a flame charred pita bread ",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "4NppaZpoj"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876389",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Burekas Feast",
              "display_name": "",
              "description": "House made Turkish pastry filled with spinach & feta. Served with hard-boiled egg, labneh with zaatar and Israeli salad, hummus with a sprinkle of dukkah, olives & pickles",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/fc6059ed-9ede-4665-a267-629cfb8174bd_productImg.jpg",
                "name": "",
                "not_uc": true
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191684",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876390",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "Lamb Burger",
              "display_name": "",
              "description": "Israeli style lamb pattie on a panini bun, green tahini, roasted tomato, mint slaw and pickles. Served with a side of home fries & aioli",
              "subtitle": "",
              "price": 27,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/c895bb4a-7a1d-403c-89c0-4d34d04bf7d8_productImg.png",
                "name": "",
                "not_uc": true
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191685",
                "191610"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876395",
              "menu_id": "XUh4dKl5d",
              "category_id": "Y_vkWDgH6c",
              "name": "B.L.T",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 15,
              "tags": [],
              "image": {
                "_id": "85255f04-5092-4503-ad74-e51202f4a79a",
                "name": "BLT.jpeg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191601",
                "191689"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": true,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 2876456
        },
        {
          "_id": "lviI0eo4OG",
          "menu_id": "XUh4dKl5d",
          "name": "Pastries",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "2876399",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Banana Bread",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "af276f59-ce5b-492f-8fe1-6c6f2fdae3f3",
                "name": "Banana_Bread.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191601",
                "191605"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893371",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Halva Brownie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "92892dbc-8f56-4d65-84a0-641eb18a659a",
                "name": "halva_brownie1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3126329",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Cheese Cake ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "38ed0808-9caa-415e-8ef7-2c0a9a94f968",
                "name": "Cheese_Cake.jpeg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893370",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Scone",
              "display_name": "",
              "description": "Served with jam and cream",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "2f8d4ad3-d698-4a35-bc82-8f198b1df79d",
                "name": "scones1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893367",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Savoury Scone",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "301adf00-b5f6-4b96-9467-a766a53d76aa",
                "name": "savoury_scones.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "LEWBunni7",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Doughnut",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "428dbb5e-79b2-4464-b46e-5e4ebef42546",
                "name": "doughnut.png",
                "modifiers": null
              },
              "status": "no-stock",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893364",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Almond & Coconut Cake",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "838a92ff-63c6-4c68-9424-36d7090c05e3",
                "name": "Almond___Coconut.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dt9rT6yCD",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Salted Almond Crust",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": {
                "_id": "8354c9e6-7ed0-46e3-8067-82de6ac4a226",
                "name": "440213831_2785719048270288_5903647207475413339_n.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893368",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Lady Finger Baklava ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3.5,
              "tags": [],
              "image": {
                "_id": "c6d36bf4-55e6-493a-becc-615af5666830",
                "name": "Lady_Finegr_Baklava.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2979073",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Triangle Baklava ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": {
                "_id": "90f41674-604d-4e76-ba0d-68bffa8a25a1",
                "name": "Triangle_Baklava.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893363",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Danish",
              "display_name": "",
              "description": "Flavours vary",
              "subtitle": "",
              "price": 8.5,
              "tags": [],
              "image": {
                "_id": "14334895-1627-47d6-b381-69c7002f15ed",
                "name": "Danish1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "X81M6elWE",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Cinnamon Scroll",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.5,
              "tags": [],
              "image": {
                "_id": "b7bb916f-93f1-4595-b06f-dce6362a3bcd",
                "name": "cinnamon_scroll.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893376",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Raisin Scroll",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "400e96cd-6686-467c-81a3-0ca0033fd1a1",
                "name": "Raisin_Scroll.jpeg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893382",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Muffin of the day",
              "display_name": "",
              "description": "Flavours vary",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "fbe30af8-a171-4294-b70b-af228f550cbd",
                "name": "Muffins_1.png",
                "modifiers": null
              },
              "status": "no-stock",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876398",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Jam Croissant",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.5,
              "tags": [],
              "image": {
                "_id": "4c7edd95-fde8-40e5-8118-a9ef67ab711b",
                "name": "jam_crossiant.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893365",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Chocolate Croissant",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.5,
              "tags": [],
              "image": {
                "_id": "f56dc68a-3b78-437b-88ac-d1b8c6c20cc2",
                "name": "chocolate_croissant.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2910774",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Pistachio Croissant ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "a53e2bbb-7a01-48c7-a527-4223647ad3ad",
                "name": "Pistachio_Crossaint1.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "2876396",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Ham and Cheese Croissant",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 12.5,
              "tags": [],
              "image": {
                "_id": "ede4291a-7985-4872-aa97-849b0e3f9396",
                "name": "Ham_and_Cheese_Croissant1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191601"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Lmcp6PEL6",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Spinach and Halloumi Croissant",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 12.5,
              "tags": [],
              "image": {
                "_id": "93ad42d5-f5fa-4bb7-87c2-d6e2af860550",
                "name": "440446145_2661336890711457_142409852192897099_n.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191601"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876397",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Plain Croissant",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "6109ae47-4e52-4ff2-9e4a-855524a2a58b",
                "name": "Plain_Croissant.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191601"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893383",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Sirloin Sausage Roll",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "fd877098-a833-4cc8-a74c-8097491a80d9",
                "name": "Sausage_Roll1.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "2893378",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Frittata",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "bbd4dd94-766b-49d4-a36c-cafb1dcd1a52",
                "name": "Frittata.jpeg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893380",
              "menu_id": "XUh4dKl5d",
              "category_id": "lviI0eo4OG",
              "name": "Quiche",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "3135385b-cd94-4fbc-bc72-099d2194ab6b",
                "name": "Quiche.jpeg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 2876456
        },
        {
          "_id": "dBwteCo5Le",
          "menu_id": "XUh4dKl5d",
          "name": "Sweet Nectar Treats",
          "display_name": "",
          "description": "Delicious gluten free and vegan desserts",
          "dishes": [
            {
              "type": "standard",
              "_id": "3095113",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Peanut Butter Cup",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "d7c70c22-b819-4ab8-8494-a5ca3caa0bd3",
                "name": "Peanut_Butter_Cup.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "3095114",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Lemon Tart",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "b4ae06bd-f5cb-478e-b043-f9e283312b82",
                "name": "Lemon_Tart.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "3095115",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Raspberry Kiss",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "2b918271-7b5a-469f-80c8-b6a3570a3cf3",
                "name": "Raspberry_Kiss.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "3095116",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Snickers Crunch",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "6293ed97-612a-4828-8d7a-4520db33edca",
                "name": "29cc8fda_55f1_47e1_a376_7b3e02a27110.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "3095117",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Rocky Road",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "10f2aadd-7969-4969-9cbb-f233d1f0edd9",
                "name": "f27007e7_7de8_4959_b9be_e6a5c6b49758.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "3095118",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Marz Slice ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/067dba23-3dd9-4f9c-8304-6d2683e21b81.png",
                "name": "",
                "not_uc": true
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3095119",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Salted Caramel",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "b4b05d37-537a-45b0-8c6f-df89f7b292e2",
                "name": "Salted_Caramel.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "3095120",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Bounty",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/4536226a-5fef-4c75-ab3c-71aa3ae8940f.png",
                "name": "",
                "not_uc": true
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3095121",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Ferrero Rawcher",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/37f8743f-3672-47f8-b1eb-adb48434ecec.png",
                "name": "",
                "not_uc": true
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3095122",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "White Choc Raspberry",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "9d434377-5ee1-4571-b328-0c92e7180b17",
                "name": "White_Choc_Raspberry.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "3095123",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Mango Weis",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/d752f34e-fc4a-456f-bfd5-b56120d6e008.png",
                "name": "",
                "not_uc": true
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3095124",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Choc Peppermint",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "c0b9ff1b-7466-49bb-a004-628a1ea9fa19",
                "name": "Choc_Peppermint.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "3095125",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Peanut Butter Brownie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "status": "no-stock",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "1945ba73-5219-4a6b-b2c1-795ab8a330da",
                "name": "Peanut_Butter_Brownie.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "QgqfPVDPS",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Rocky Road Bar",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "b8cd9bb3-d42e-4168-8d98-6e5cb2d13352",
                "name": "Rocky_Road.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "6AcKLS48V",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Vanilla Lemon Curd",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "f77e46b9-3c23-4e64-8b76-be2436062203",
                "name": "Lemon_Curd.png",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Y8CB-6Ckl",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Snickers Bar",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "f8a52aec-b7ee-4f22-8c8a-b5ce5d21d5b0",
                "name": "New_Snickers_Bar.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "uP2Ux200b",
              "menu_id": "XUh4dKl5d",
              "category_id": "dBwteCo5Le",
              "name": "Berry Ripe Bar",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "669f6629-625d-4d75-b5b3-4ea1a8b59478",
                "name": "Berry_Ripe.png",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 2876460
        },
        {
          "_id": "DBZk3QjXVt",
          "menu_id": "XUh4dKl5d",
          "name": "Coffee",
          "display_name": "",
          "description": "Proudly serving The Little Marionette coffee",
          "dishes": [
            {
              "type": "standard",
              "_id": "2876437",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4,
              "tags": [],
              "image": {
                "_id": "f04a3c96-8f0d-49e7-9651-8e9a7a40ecd2",
                "name": "5.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876431",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Macchiato",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4,
              "tags": [],
              "image": {
                "_id": "8626a46f-1878-4d3c-b39c-f86f24b20720",
                "name": "6.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876433",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Long Black",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "f09cbe68-8813-49ad-bcf5-a27e650a1b5f",
                "name": "Untitled_design__10_.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191590",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191590"
            },
            {
              "type": "standard",
              "_id": "2876430",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "00a18d4c-e360-4df3-ace0-769a143b2790",
                "name": "3.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191590",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191590"
            },
            {
              "type": "standard",
              "_id": "2876439",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Piccolo Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "7a7a5e00-168c-47a7-96a1-00863374fae0",
                "name": "3.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595",
                "191590"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191590"
            },
            {
              "type": "standard",
              "_id": "2876429",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Flat White",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "daf18c2d-ebd5-4e53-829e-a60b86c1defe",
                "name": "4.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191590",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191590"
            },
            {
              "type": "standard",
              "_id": "2876428",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Cappuccino",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "beace8de-2527-4398-b865-ba0e5b8bb36c",
                "name": "2.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191590",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191590"
            },
            {
              "type": "standard",
              "_id": "2876435",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "5c19e5ca-08a3-4cdb-9b52-71997618fe01",
                "name": "7.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191611",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191611",
              "total_points": 0
            },
            {
              "type": "standard",
              "_id": "2876440",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Babycino",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 2,
              "tags": [],
              "image": {
                "_id": "c5de2bf2-71f8-4ee8-b963-31dcdb54aecd",
                "name": "Babycino_min.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876434",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Hot Choc",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "010f2063-1fda-4196-b45d-b3b1fb8e3627",
                "name": "2.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191590",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191590"
            },
            {
              "type": "standard",
              "_id": "2876441",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Chai Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "2a44f005-8abd-404d-999f-1156ddfddf4f",
                "name": "4.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "204312",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "204312"
            },
            {
              "type": "standard",
              "_id": "2876443",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Dirty Chai ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "48cc4b85-130d-48d0-b9c4-1f0b3d8d7a19",
                "name": "4.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191611",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191611"
            },
            {
              "type": "standard",
              "_id": "2876442",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Turkish Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "20610810-1a10-42f4-acd8-df1aa73979ae",
                "name": "Turkish_Coffee.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876432",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Mug Sachleb",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "b83c4f47-1242-4a40-8fc3-b907d9f43a3c",
                "name": "Salep.png",
                "modifiers": null
              },
              "status": "no-stock",
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876444",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Green Matcha Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "1bfca151-d091-492b-9bb0-c8858886e0af",
                "name": "matcha.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595",
                "l2CkIrEUl"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "l2CkIrEUl"
            },
            {
              "type": "standard",
              "_id": "3095171",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Taro Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191612",
                "191591"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "51a61681-a5fe-4884-a05b-fc291e74a355",
                "name": "taro.png",
                "modifiers": null
              },
              "option_set_primary": "191612"
            },
            {
              "type": "standard",
              "_id": "2876436",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Turmeric Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "047a5bf3-a4f0-4c8a-8d66-dbf0c5cbd0fb",
                "name": "Tumeric_Latte.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191613",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191613"
            },
            {
              "type": "standard",
              "_id": "2876445",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Red Velvet Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "4d1615b0-e52c-43de-82ea-32be55d9b142",
                "name": "Red_Velvet_Latte.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191614",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191614"
            },
            {
              "type": "standard",
              "_id": "2876447",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "White Hot Choc",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": {
                "_id": "6281a544-b726-435e-96b1-57cda6b60037",
                "name": "White_hot_chocolate.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191590",
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "191590"
            },
            {
              "type": "standard",
              "_id": "2876446",
              "menu_id": "XUh4dKl5d",
              "category_id": "DBZk3QjXVt",
              "name": "Affogato",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 2876457
        },
        {
          "_id": "1RHf3BA6-K",
          "menu_id": "XUh4dKl5d",
          "name": "Tea",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "2876448",
              "menu_id": "XUh4dKl5d",
              "category_id": "1RHf3BA6-K",
              "name": "English Breakfast",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "73310bb5-ebbf-4190-a967-1506f8f5f25f",
                "name": "English_Breakfast.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876449",
              "menu_id": "XUh4dKl5d",
              "category_id": "1RHf3BA6-K",
              "name": "Earl Grey ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "624a80e5-1fc0-4df2-8581-522323b72288",
                "name": "16.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876450",
              "menu_id": "XUh4dKl5d",
              "category_id": "1RHf3BA6-K",
              "name": "Lemongrass & Ginger",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.99,
              "tags": [],
              "image": {
                "_id": "98fce442-d12f-4712-b042-9e3ab4c4c3e8",
                "name": "12.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876454",
              "menu_id": "XUh4dKl5d",
              "category_id": "1RHf3BA6-K",
              "name": "Peppermint ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "6699ad00-b90f-4a08-88cb-d87caaaaf8a7",
                "name": "13.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876452",
              "menu_id": "XUh4dKl5d",
              "category_id": "1RHf3BA6-K",
              "name": "Green Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "6286437e-bb55-46fb-ba44-e026c3e4ca05",
                "name": "14.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876453",
              "menu_id": "XUh4dKl5d",
              "category_id": "1RHf3BA6-K",
              "name": "Chai Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "8e2676c2-91a7-4984-bce2-8c9ea047673b",
                "name": "11.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191591",
                "191594",
                "191595"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 2876457
        },
        {
          "_id": "v_BA8O0DWJ",
          "menu_id": "XUh4dKl5d",
          "name": "Cold Drinks",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "2876403",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Small Sparkling (250mL)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": "Small Sparkling"
            },
            {
              "type": "standard",
              "_id": "2876404",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Large Sparkling (1L)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": "Large Sparkling"
            },
            {
              "type": "standard",
              "_id": "2876402",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Spring Water 600mL",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893337",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Coke",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "2893338",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Coke Zero",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876405",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Lemon Lime Bitters",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.99,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876406",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Ginger Beer",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "e64442ea-58fe-4e9e-b38b-4de1ac2cf077",
                "name": "ET_Ginger_Beer1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876411",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Sparkling Lime",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "f736dcf9-3b6c-43c3-98c7-1c8c828c66dd",
                "name": "ET_Lime.png",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876408",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Sparkling Lisbon Lemon",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "e9c5d870-eed3-44bf-8222-4c878d0963c6",
                "name": "ET_Lisbon_Lemon.png",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876410",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Sparkling Peach",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "fb704466-007d-4604-b94d-84d42cc76895",
                "name": "ET_Peach.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191596",
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876409",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Sparkling Raspberry",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "202b941c-8182-4ebf-a630-d853d6a30f8b",
                "name": "ET_Raspberry.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876412",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Sparkling Watermelon",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "a5c1a69a-c399-40c0-b53a-028b42fb3d74",
                "name": "ET_Watermelon.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876421",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Carrot & Ginger",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "7f0325c3-ffc4-4992-a7fd-74571687a1c6",
                "name": "ET_Carrot.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876422",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Green Power",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "45630e7c-e541-43d7-9551-569d274d26a5",
                "name": "ET_Green1.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876423",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Orange Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "156698f2-5a25-4023-bb8d-e697940f4349",
                "name": "ET_OJ.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876424",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Cloudy Apply Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "bdd46b03-be0a-453c-9444-cccf7461192a",
                "name": "ET_Cloudy_Apple.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ziX6ALY0E",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Apple Mango Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "d643251c-1f3d-4726-bb10-7aa10d2ce9b5",
                "name": "E___T_Apple_Mango.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876425",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Karmarama",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "05a74187-00f9-4f05-b512-840c414acc5b",
                "name": "ET_Karma.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876426",
              "menu_id": "XUh4dKl5d",
              "category_id": "v_BA8O0DWJ",
              "name": "Kick Starter",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": {
                "_id": "0899e5ad-029d-469d-8eba-c925af752d9a",
                "name": "ET_Kickstarter.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 2876458
        },
        {
          "_id": "6JnfEIGa_w",
          "menu_id": "XUh4dKl5d",
          "name": "Iced Drinks",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "2876419",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Milkshakes",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": {
                "_id": "919a34b3-0d4c-464b-9c38-7221f353b035",
                "name": "Milkshake_1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "_gR0lEe6U",
                "191592",
                "VFxoEjib7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "_gR0lEe6U"
            },
            {
              "type": "standard",
              "_id": "2876420",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Kids Milkshakes",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "_gR0lEe6U"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "option_set_primary": "_gR0lEe6U"
            },
            {
              "type": "standard",
              "_id": "2876414",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "191615",
                "205533"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876418",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "205533",
                "193293"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876416",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Chocolate",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "193293"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876413",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Long Black",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "205533"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876415",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "205533",
                "193293"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876417",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Matcha Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "193293"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "GuK5OPWdZ",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Taro Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "193293"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "GltdnJVZE",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Red Velvet Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "193293"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2893340",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Chai",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592"
              ],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3095140",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Frappe",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "205325",
                "205326"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "c4368e96-041c-4957-b0fc-168c7a2933ce",
                "name": "Freezoccino.png",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "2893347",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Iced Dirty Chai Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191592",
                "205533"
              ],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "2893339",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Butterfly Iced Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "2893345",
              "menu_id": "XUh4dKl5d",
              "category_id": "6JnfEIGa_w",
              "name": "Green Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "191597"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 2876457
        },
        {
          "_id": "EoJMTb9K0Y",
          "menu_id": "XUh4dKl5d",
          "name": "Retail",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "2876494",
              "menu_id": "XUh4dKl5d",
              "category_id": "EoJMTb9K0Y",
              "name": "Coffee Beans (1Kg)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 50,
              "tags": [],
              "image": {
                "_id": "40b64e4f-cb56-43e4-bd0a-eb559b406552",
                "name": "Coffee_Beans_1Kg.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876492",
              "menu_id": "XUh4dKl5d",
              "category_id": "EoJMTb9K0Y",
              "name": "Coffee Beans (250g)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "4df33c1d-5b26-4ba8-bbda-b0c4374ea6b4",
                "name": "Coffee_Beans_250g.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2876493",
              "menu_id": "XUh4dKl5d",
              "category_id": "EoJMTb9K0Y",
              "name": "Decaf Coffee Beans (250g)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "b327fe09-874a-465f-b0ba-12e2ff92d80a",
                "name": "Coffee_Beans_250g.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3121597",
              "menu_id": "XUh4dKl5d",
              "category_id": "EoJMTb9K0Y",
              "name": "Hummus Tub Large ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/07b76569-df4b-4e49-bf1f-04855cf4d566_productImg.jpeg",
                "name": "",
                "not_uc": true
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3121601",
              "menu_id": "XUh4dKl5d",
              "category_id": "EoJMTb9K0Y",
              "name": "Hummus Tub Small",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 9,
              "tags": [],
              "image": {
                "_id": "https://storage.googleapis.com/abacuscorev2live.appspot.com/4569508/P/a7059715-b3d0-47ca-be7a-d1bf8c6ea782_productImg.jpeg",
                "name": "",
                "not_uc": true
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 2876460
        }
      ],
      "abacus_sync": true,
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    }
  ]

export const cafeOptionSet = [
    {
      "_id": "191590",
      "name": "Coffee Size: S/L ($4.50/$5.00)",
      "display_name": "Size: S/L ($4.50/$5.00)",
      "show_in_menu": true,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908640",
          "name": "Small",
          "price": "4.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908802",
          "name": "Large",
          "price": "5",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191611",
      "name": "Mocha, Dirty Chai Size: S/L ($5.00/$5.50)",
      "display_name": "Size: S/L/ ($5.00/$5.50)",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908804",
          "name": "Small",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908805",
          "name": "Large",
          "price": "5.5",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191612",
      "name": "Taro Latte Size: S/L ($5.00/$7.00)",
      "display_name": "Size: S/L ($5.00/$7.00)",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908806",
          "name": "Small",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908807",
          "name": "Large",
          "price": "7",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "l2CkIrEUl",
      "name": "Matcha Latte Size: S/L ($5.00/$7.00) ",
      "display_name": "Size: S/L/Mug ($5.00/$7.00) ",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "0IFqdKUlGc",
          "name": "Small",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "JhcN9iw99C",
          "name": "Large",
          "price": "7",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191614",
      "name": "Red Velvet Latte Size: S/L ($5.00/$7.00)",
      "display_name": "Size: S/L ($5.00/$7.00)",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908810",
          "name": "Small",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908811",
          "name": "Large",
          "price": "7",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191613",
      "name": "Turmeric Latte Size: S/L ($5.00/$7.00)",
      "display_name": "Size: S/L ($5.00/$7.00)",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908808",
          "name": "Small",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908809",
          "name": "Large",
          "price": "7",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "204312",
      "name": "Chai Latte Size: S/L ($4.50/$5.00)",
      "display_name": "Size: S/L ($4.50/$5.00)",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1991112",
          "name": "Small",
          "price": "4.3",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1991113",
          "name": "Large",
          "price": "4.9",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191591",
      "name": "Milk Options: (Hot Bevs)",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": 1,
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908642",
          "name": "Full Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908646",
          "name": "Skim Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908647",
          "name": "Soy Milk",
          "price": "0.8",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908641",
          "name": "Almond Milk",
          "price": "0.8",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908645",
          "name": "Oat Milk",
          "price": "0.8",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908643",
          "name": "Lactose Free Milk",
          "price": "0.8",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908644",
          "name": "Macadamia Milk",
          "price": "0.8",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908648",
          "name": "NO Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191592",
      "name": "Milk Options: (Cold Drinks)",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "m11ZkW1z3",
          "name": "Regular",
          "price": "",
          "quantity": 1,
          "prices": {},
          "status": null
        },
        {
          "_id": "1908650",
          "name": "Full Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908654",
          "name": "Skim Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908655",
          "name": "Soy Milk",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908649",
          "name": "Almond Milk",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908653",
          "name": "Oat Milk",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908651",
          "name": "Lactose Free Milk",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908652",
          "name": "Macadamia Milk",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908656",
          "name": "NO Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191615",
      "name": "Ice Cream Options:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908813",
          "name": "With Ice Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908814",
          "name": "NO Ice Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "193293",
      "name": "Ice Cream Options: (Iced Choc/Latte) ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1924678",
          "name": "NO Ice Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1924677",
          "name": "With Ice Cream",
          "price": "2",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191594",
      "name": "Sugar Options:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908665",
          "name": "Sugar",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908666",
          "name": "1/2 Sugar",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908667",
          "name": "1/4 Sugar",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908668",
          "name": "Equal",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908669",
          "name": "1/2 Equal",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191595",
      "name": "Hot Bev Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908671",
          "name": "Extra Shot (Strong)",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908670",
          "name": "Weak",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908672",
          "name": "Very Hot",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908673",
          "name": "Warm",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908674",
          "name": "Decaf",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908675",
          "name": "3/4 Full",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908676",
          "name": "1/2 Full",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1998385",
          "name": "Riz",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1998386",
          "name": "Double Riz",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908680",
          "name": "Caramel (Syrup)",
          "price": "0.7",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908812",
          "name": "Chocolate (Syrup)",
          "price": ".7",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908681",
          "name": "Hazelnut (Syrup)",
          "price": ".7",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908682",
          "name": "Vanilla (Syrup)",
          "price": ".7",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908683",
          "name": "Lemon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908684",
          "name": "Honey",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908685",
          "name": "Extra Chocolate",
          "price": ".7",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908688",
          "name": "Ice Cream",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908689",
          "name": "Keep Cup",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908690",
          "name": "NO Chocolate",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908691",
          "name": "NO Cinnamon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908692",
          "name": "NO Froth",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908693",
          "name": "NO Lid",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908694",
          "name": "Ice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908697",
          "name": "H.M.O.S",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908698",
          "name": "C.M.O.S",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191596",
      "name": "M.Y.O Juice:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": 4,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908704",
          "name": "Apple",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908705",
          "name": "Beetroot",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908706",
          "name": "Carrot",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908707",
          "name": "Celery",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908708",
          "name": "Cucumber",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908709",
          "name": "Ginger",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908710",
          "name": "Kale",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908711",
          "name": "Lemon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908712",
          "name": "Orange",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908713",
          "name": "Pineapple",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908714",
          "name": "Watermelon",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191597",
      "name": "Cold Bev Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908715",
          "name": "NO Ice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908716",
          "name": "Extra Ice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908719",
          "name": "Ice on Side",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908723",
          "name": "Lemon Slice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908724",
          "name": "Lime Slice",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191598",
      "name": "Egg Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": 1,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908727",
          "name": "Fried",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908728",
          "name": "Poached",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908729",
          "name": "Scrambled",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191601",
      "name": "Toasted or Fresh:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": 1,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908748",
          "name": "Toasted",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908749",
          "name": "Fresh",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191602",
      "name": "Sauce Options: (Bacon & Egg Roll)",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": 1,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1962126",
          "name": "Aioli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908750",
          "name": "BBQ Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908751",
          "name": "Tomato Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908752",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191604",
      "name": "Condiments:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908756",
          "name": "Light Spread",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908757",
          "name": "Butter",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908758",
          "name": "NO Butter",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908760",
          "name": "Strawberry Jam",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908761",
          "name": "Honey",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908762",
          "name": "Peanut Butter",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908763",
          "name": "Nutella",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908764",
          "name": "Vegemite",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191605",
      "name": "Banana Bread (Condiments)",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": 1,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908767",
          "name": "Butter",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908768",
          "name": "NO Butter",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191609",
      "name": "Bacon & Egg Roll Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1908781",
          "name": "NO Bacon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908784",
          "name": "NO Aioli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911391",
          "name": "NO Chilli Jam",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908782",
          "name": "NO Fried Egg",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911390",
          "name": "NO Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908783",
          "name": "NO Tomato",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2010664",
          "name": "Crispy Bacon",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191677",
      "name": "Avocado Smash Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1911400",
          "name": "NO Cherry Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962127",
          "name": "NO Danish Fetta",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911401",
          "name": "NO Mint",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911398",
          "name": "NO Poached Egg",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911396",
          "name": "NO Zaatar",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191678",
      "name": "Israeli Sabich Plate Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1911402",
          "name": "NO Hummus",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911406",
          "name": "NO Boiled Egg",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911403",
          "name": "NO Eggplant",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911412",
          "name": "NO Harissa",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911415",
          "name": "NO Hazelnut Dukkah",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911408",
          "name": "NO Israeli Pickles",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911414",
          "name": "NO Pickled Mango",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911416",
          "name": "NO Pita Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911405",
          "name": "NO Tahini",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911411",
          "name": "NO Tomato Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191679",
      "name": "Shakshuka Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1911422",
          "name": "NO Baked Eggs",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911425",
          "name": "NO Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911424",
          "name": "NO Danish Fetta",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911427",
          "name": "NO Herbs",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962128",
          "name": "NO Sourdough",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191680",
      "name": "Hummus-Shuka Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1911430",
          "name": "NO Hazelnut Dukkah",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "6wnMThtUJ",
          "name": "NO Sourdough",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1911429",
          "name": "Vegan",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191681",
      "name": "Egyptian Falafel Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "FEZ46t8OJ",
          "name": "NO Hummus",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "H9iHbph3C",
          "name": "NO Chickpeas",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1911436",
          "name": "NO Harissa",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911437",
          "name": "NO Hazelnut Dukkah",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911434",
          "name": "NO Herbs",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911435",
          "name": "NO Pita Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911432",
          "name": "NO Tahini",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911433",
          "name": "NO Tomato Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191682",
      "name": "Chicken Shawarma Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1913864",
          "name": "NO Mango Pickle",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913866",
          "name": "NO Pita Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913867",
          "name": "NO Spices",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913865",
          "name": "NO White Tahini",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191683",
      "name": "Uncle Eli Cauliflower Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1913870",
          "name": "NO Cherry Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913872",
          "name": "NO Mango Pickle",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913869",
          "name": "NO Pine Nuts",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913874",
          "name": "NO Pita Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913873",
          "name": "NO Tahini",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913871",
          "name": "NO Zaatar",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191684",
      "name": "Burekas Feast Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1913875",
          "name": "NO Boiled Egg",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913880",
          "name": "NO Dukkah",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913879",
          "name": "NO Hummus",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913878",
          "name": "NO Israeli Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913876",
          "name": "NO Labneh",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913881",
          "name": "NO Olives",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913882",
          "name": "NO Pickles",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913877",
          "name": "NO Zaatar",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191685",
      "name": "Lamb Burger Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1913885",
          "name": "Bunless",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913891",
          "name": "NO Fries",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913892",
          "name": "NO Aioli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913886",
          "name": "NO Green Tahini",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913890",
          "name": "NO Harissa",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913888",
          "name": "NO Mint Slaw",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913889",
          "name": "NO Pickles",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913887",
          "name": "NO Roasted Tomato",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191686",
      "name": "Portobello Mushrooms Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1913893",
          "name": "NO Hummus",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913894",
          "name": "NO Mushrooms",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913895",
          "name": "NO Onions Rings",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913896",
          "name": "NO Paprika Oil",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1913897",
          "name": "NO Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "199676",
      "name": "Slow Cooked Lamb Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1962133",
          "name": "NO Hummus",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962134",
          "name": "NO White Tahini",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962135",
          "name": "NO Olive Oil",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962136",
          "name": "NO Zaatar",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962137",
          "name": "NO Pita Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "199677",
      "name": "Schnitzel in Tel Aviv Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1962138",
          "name": "NO Hummus",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962139",
          "name": "NO Bread Sticks",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962143",
          "name": "NO Harissa",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962144",
          "name": "NO Israeli Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962141",
          "name": "NO Pickles",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962140",
          "name": "NO Pita Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962142",
          "name": "NO Zaatar",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191688",
      "name": "Haloumi S/W Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1911373",
          "name": "NO Basil Pesto",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911371",
          "name": "NO Coriander",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911374",
          "name": "NO Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1911375",
          "name": "NO Sundried Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "191689",
      "name": "B.L.T Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1909369",
          "name": "NO Aioli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1909365",
          "name": "NO Bacon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1909366",
          "name": "NO Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1909367",
          "name": "NO Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1909368",
          "name": "NO Tomato",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2010665",
          "name": "Crispy Bacon",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "205336",
      "name": "Eggs On Toast Condiments ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1996551",
          "name": "Butter",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996552",
          "name": "Vegemite ",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996553",
          "name": "Jam",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996554",
          "name": "Honey",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "Qe25EzxFP",
          "name": "NO Butter",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "4NppaZpoj",
      "name": "Side Add Ons:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "NqAcIwBJ-",
          "name": "Israeli Chicken Shawarma",
          "price": "7.00",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "PpNXFoAeO",
          "name": "Schnitzel",
          "price": "7.00",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "-kMOFgQE3",
          "name": "Spiced Mushrooms",
          "price": "6.00",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "i-DKV5_Td",
          "name": "Tender Lamb",
          "price": "9.00",
          "quantity": 0,
          "prices": {}
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "205325",
      "name": "Frappe",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": 1,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1996505",
          "name": "Iced Latte (includes a shot of coffee)",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996506",
          "name": "Salted Caramel Latte",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996507",
          "name": "Chai Latte",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996508",
          "name": "White Chocolate",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996509",
          "name": "Vanilla Latte",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "xbrD0JFvc",
          "name": "Taro Latte",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "uN6VWYMzy",
          "name": "Red Velvet Latte",
          "price": "0",
          "quantity": 0,
          "prices": {}
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "205326",
      "name": "Frappe Milk Option",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": 1,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1996512",
          "name": "Skinny Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996513",
          "name": "Soy",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996514",
          "name": "Almond",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996515",
          "name": "Oat",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996516",
          "name": "Maca Milk",
          "price": "1",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "205533",
      "name": "Iced Drinks Mods",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "1997743",
          "name": "Decaf",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1997744",
          "name": "Extra shot",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1997745",
          "name": "Weak",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1998384",
          "name": "Double Riz",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1997746",
          "name": "Caramel",
          "price": "0.6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1997747",
          "name": "Hazelnut ",
          "price": "0.6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1997748",
          "name": "Vanila",
          "price": "0.6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2010662",
          "name": "Honey",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "VFxoEjib7",
      "name": "Shot of Coffee",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "T64w1DlBX",
          "name": "Shot of Coffee",
          "price": ".50",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "191610",
      "name": "Food Add Ons:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "BbXD1YvTV",
          "name": "Challah instead",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1908822",
          "name": "Gluten Free instead",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996545",
          "name": "Pita Instead",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1996555",
          "name": "Extra Gluten free Bread",
          "price": "4",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908823",
          "name": "Extra Pita Bread",
          "price": "4",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908824",
          "name": "Extra Sourdough",
          "price": "4",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "-8ZneKjZR",
          "name": "Extra Challah Bread",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1908815",
          "name": "Bacon",
          "price": "6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2010667",
          "name": "Crispy Bacon",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "m3zdnxdYp",
          "name": "Schnitzel",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1908816",
          "name": "Sujuk (Turkish Sausage)",
          "price": "6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "z7whl1gCV",
          "name": "Tender Lamb",
          "price": "9",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1908831",
          "name": "Falafel",
          "price": "3.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908828",
          "name": "Hummus",
          "price": "6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1962132",
          "name": "Egg",
          "price": "3.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908830",
          "name": "Egg (Soft Boiled)",
          "price": "3.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908819",
          "name": "Avocado",
          "price": "6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908829",
          "name": "Eggplant",
          "price": "6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908821",
          "name": "Mushrooms",
          "price": "6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "oB62R_OHV",
          "name": "Spiced Mushrooms",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1908832",
          "name": "Pickles",
          "price": "3.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908825",
          "name": "Roasted Tomato",
          "price": "3.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "wM-zBS5oQ",
          "name": "Shawarma",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1908820",
          "name": "Tabouli",
          "price": "3.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908826",
          "name": "Spinach",
          "price": "3.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908827",
          "name": "Feta Cheese",
          "price": "3.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908817",
          "name": "Haloumi",
          "price": "6",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "xyfm8cEed",
          "name": "Swiss Cheese",
          "price": "3.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1908834",
          "name": "Aioli",
          "price": "2.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908833",
          "name": "Chilli Jam",
          "price": "2.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908835",
          "name": "Harissa",
          "price": "2.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908838",
          "name": "Jam & Butter",
          "price": "2.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908837",
          "name": "Mango Pickles",
          "price": "2.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "1908836",
          "name": "Zhug",
          "price": "2.5",
          "quantity": 0,
          "status": null
        }
      ],
      "abacus_hide_option_set_name": true
    },
    {
      "_id": "_gR0lEe6U",
      "name": "Milkshake Flavours",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "4E2dSsGGj",
          "name": "Chocolate",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "tMqO3a7wm",
          "name": "Vanilla",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "TTYIBdhwQ",
          "name": "Caramel",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "54YWW5LGJ",
      "name": "Acai Bowl Add On",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "utCmKX0Ar",
          "name": "Peanut Butter",
          "price": "2",
          "quantity": 0,
          "prices": {},
          "print_name": "Peanut Butter"
        }
      ]
    }
  ];


function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createCafeMenu = () => {
    return {
        cafeMenu:updateIds(cafeMenu), cafeOptionSet:updateIds(cafeOptionSet)
    }
}

