import _findIndex from 'lodash/findIndex';
import _capitalize from 'lodash/capitalize';
import _cloneDeep from 'lodash/cloneDeep';
import { RootStore } from '../../mobx/store';

export interface ReceiptDetailOption {
	_id: string;
	label: string;
	enabled: boolean;
}

export function transfromCheckoutFieldsToReceiptPrintOptions(
	restaurant: T.Schema.Restaurant.RestaurantSafeSchema,
	staticOptions?: Array<ReceiptDetailOption>,
) {
	const services = restaurant!.settings.services;
	let customFieldList: Array<ReceiptDetailOption> = [];
	let orderCreatedDetails = staticOptions ? staticOptions : _cloneDeep(restaurant!.settings.notifications.email.customer.order_created.details);

	for (const serviceName in services) {
		// @ts-ignore
		const serviceDetail = services[serviceName] as T.Schema.Restaurant.Services.RestaurantService;
		const fields = serviceDetail.custom_checkout_fields;
		const serviceNameDisplay = serviceName.split('_').map((segment) => _capitalize(segment)).join(' ');
		const searchKey = `${serviceName}.custom.field.`;

		if (fields && fields.length) {

			// Remove the old custom checkout field options that have been removed
			const idList = fields.map((field) => `${searchKey}${String(field._id)}`);
			orderCreatedDetails = orderCreatedDetails.filter((option) => {
				return (!option._id.startsWith(searchKey) || idList.includes(option._id));
			});


			// Update the label and Add the new fields based on id
			const currentIds = orderCreatedDetails.map(option => option._id);

			for (const field of fields) {
				const _id = `${searchKey}${String(field._id)}`;
				const label = `${String(field.label)} [${serviceNameDisplay} Service]`

				const index = currentIds.findIndex((id) => id === _id);

				if (index === -1) {
					customFieldList.push({
						_id,
						label,
						enabled: true,
					})
				} else {
					orderCreatedDetails[index].label = label;
				}
			}
		}
	}

	return orderCreatedDetails.concat(customFieldList);
}

interface UpdatingExpression {
	[key: string]: Array<ReceiptDetailOption>;
}

export async function updateRestaurantNotificationOptions(store: RootStore) {
	const restaurant = store.restaurant!;
	const printerCount = restaurant.settings.printers.length;

	const finalizedOptions = transfromCheckoutFieldsToReceiptPrintOptions(restaurant);
	let expression: UpdatingExpression = {};

	expression['settings.notifications.email.customer.order_created.details'] = finalizedOptions;

	if (printerCount) {
		for (let i = 0; i < printerCount; i++) {

			const printerDetails = _cloneDeep(restaurant!.settings.printers[i].receipt.details);

			expression[`settings.printers.${i}.receipt.details`] = transfromCheckoutFieldsToReceiptPrintOptions(
				restaurant,
				printerDetails,
			);
		}
	}

	const result = await store.api.restaurant_update({
		_id: restaurant!._id,
		update: {
			$set: expression,
		},
	});

	if (result.outcome === 0) await store.service.restaurant.get();
}
