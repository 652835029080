import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import cloneDeep from "lodash/cloneDeep";
import { TabSelect } from "@lib/components";
import { Input } from "@lib/components";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { inject, observer } from "mobx-react";
import { Checkbox } from "@lib/components";
import { RestaurantUtils } from "@lib/common";
import { LinkTag } from "@lib/components";

interface Props { }
interface State {
	tab: string;
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["accounts"];

@inject("store") @observer
export class SettingsFormAccounts extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.state = {
			tab: "0",
		};
		this.initialValues = cloneDeep(r.settings.accounts);
	}

	render() {
		const { showMainSupport } = this.injected.store;
		const { tab } = this.state;
		const guest_match_keys = [
			{ key: "details.name", name: "Name" },
			{ key: "details.phone", name: "Phone" },
			{ key: "details.email", name: "E-mail" },
		];
		return (
			<div>

				<TabSelect
					id="accounts-tab-options"
					className="border-white-tb-10"
					hasBorder={true}
					screenWidth={this.injected.store.view.screen_width}
					onChange={(v) => {
						this.setState({ tab: v.value });
					}}
					value={tab}
					values={[
						{ label: "Guest", value: "0" },
						{ label: "Facebook", value: "1" },
						{ label: "Google", value: "2" },
					]} />

				<RestaurantForm<FormValues>
					submit={async (r, values) => {
						r.settings.accounts = values;
						if (!values.guest) {
							values.guest = { match_keys: RestaurantUtils.settings.defaultGuestMatchKeys() };
						}
						else if (!values.guest.match_keys) {
							values.guest.match_keys = RestaurantUtils.settings.defaultGuestMatchKeys();
						}
						const update = { $set: { "settings.accounts": values } };
						return { r, update };
					}}
					initialValues={this.initialValues}
					onError={() => UI.notification.error("An error occurred")}
					onSuccessMessage="Settings Updated"
				>
					{({ form, error }) => {
						const { isSubmitting, setFieldValue } = form;
						return (
							<div className="p-4">

								<FormGroup>

									{tab === "0" && (
										<div>
											<FastField
												name="guest.match_keys"
												render={({ field }: any) => (
													<FormGroup title="Guest Promo Match Fields">
														<p className="lhp">Guest accounts are tracked in the system by a customer's details. You can customize what details are used to match and track guest customers. For example, disabling 'Name' means that guests will only be matched across orders if their "Phone" and "E-Mail" are identical</p>
														{guest_match_keys.map((v, i) => (
															<Checkbox
																key={i}
																id={i + v.key}
																checked={(field.value || RestaurantUtils.settings.defaultGuestMatchKeys()).indexOf(v.key) !== -1}
																label={v.name}
																className="m-t-3"
																onChange={() => {
																	const value = field.value ? [...field.value] : RestaurantUtils.settings.defaultGuestMatchKeys();
																	const index = value.indexOf(v.key);
																	if (index === -1)
																		value.push(v.key);
																	else
																		value.splice(index, 1);
																	setFieldValue("guest.match_keys", value);
																}}
															/>
														))}
													</FormGroup>
												)}
											/>
										</div>
									)}

									{tab === "1" && (
										<div>
											{showMainSupport && (
												<FormGroup>
													<LinkTag className="block font-semi-bold" target="_blank" href="https://support.cloudwaitress.com/how-to-guides/setup-facebook-login">Read the setup guide</LinkTag>
												</FormGroup>
											)}
											<FastField
												name="facebook.app_id"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Facebook Developer App ID"
														help="Enter your app ID to allow customers to login via Facebook. You must have the login addon enabled on your Facebook app">
														<Input type="text" {...field} />
													</FormGroup>
												)}
											/>
										</div>
									)}

									{tab === "2" && (
										<div>
											<FastField
												name="google.api_client_id"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Google OAuth Client ID"
														help="Enter your Google OAuth client ID to allow customers to login via their Google account">
														<Input type="text" {...field} />
													</FormGroup>
												)}
											/>
										</div>
									)}
								</FormGroup>

								{error && <FormGroup error={error} />}

								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>

							</div>
						);
					}}
				</RestaurantForm>

			</div>
		);
	}

}
