import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import { darken, lighten } from "polished";
import { FaDownload } from "react-icons/fa";
import { MobxComponent } from "../../../../../mobx/components";
import {
	Protable,
	styled,
	Tooltip,
	Button,
	CreditCardIcon,
	Box,
	TooltipText,
} from "@lib/components";
import {
	exportColumns,
	cloneDeepSafe,
	getLocalDate,
	getLocalTime,
} from "@lib/common";
import { withTranslation, WithTranslation } from "react-i18next";
import { PayoutModal } from './payout-modal';

interface Props extends WithTranslation {}
interface State {
    selectedPayout: T.Schema.Stripe.StripePayout | null;
    isOpenDownloadModal: boolean;
}

export const ItemButton = styled(Button)`
	color: ${({ theme }) => darken(0.6, theme.box.background)};
	&:hover {
		color: ${({ theme }) => theme.box.text};
	}
`;

@inject("store")
@observer
class PayoutListClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
            selectedPayout: null,
            isOpenDownloadModal: false
        };
	}

	componentDidMount() {
		
	}

    openDownloadModal = (selectedPayout: T.Schema.Stripe.StripePayout) => {
		this.setState({ selectedPayout, 
            isOpenDownloadModal: true 
        });
	};

	closeDownloadModal = () => {
		this.setState({ isOpenDownloadModal: false });
	};

	render() {
		const { store, t, theme } = this.injected;
		const r = store.restaurant!;
		const tz = r.settings.region.timezone;
        const payouts = store.stripePayouts;

        const columns = [
			{
				title: "Created",
				render: (row: T.Schema.Stripe.StripePayout) => (
					<>
						<p className="font-semi-bold m-b-1">
							{getLocalDate(row.created * 1000, tz)}
						</p>
						<p>{getLocalTime(row.created * 1000, tz)}</p>
					</>
				),
			},
			{
				title: "Amount",
				render: (row: T.Schema.Stripe.StripePayout) => (
					<span>{(+row.amount / 100).toFixed(2)}</span>
				),
			},
			{
				title: "Currency",
				render: (row: T.Schema.Stripe.StripePayout) => (
					<span>{row.currency.toUpperCase()}</span>
				),
			},
			{
				title: "Status",
				render: (row: T.Schema.Stripe.StripePayout) => (
					<span>{row.status}</span>
				),
			},
			{
				title: "",
				render: (row: T.Schema.Stripe.StripePayout) => (
					<Tooltip
                        text={`Download Report (CSV)`}
                        width={150}
                        position="top"
                        className="m-1"
                    >
                        <ItemButton
                            type="button"
                            color="white"
                            className="no-border width32"
                            paddinglr={5}
                            onClick={async (e) => {
                                e.stopPropagation();
                                // call download
                                this.openDownloadModal(row);
                            }}
                        >
                            <FaDownload />
                        </ItemButton>
                    </Tooltip>
				),
			}
        ];

        const queryFilter = {
			sortKeys: [],
			filters: [],
		};

		const fetchData = async (data: T.API.RestaurantQueryRequest) => {
			data.query.restaurant_id = store.restaurant!._id;
			const response = await store.api.stripe_connect_payout_list(data);

			if (response.outcome) throw new Error(response.message);

            store.updateStripePayouts({
				items: response.items,
				count: response.count,
				page: data.page
			});
		};

		const filterConfigs = {
			dateRange: true,
			noSearch: true,
			noSort: true,
		};

        const onClick = async () => {};

		return (
			<>
                <Protable<T.Schema.Stripe.StripePayout>
					region={r.settings.region}
					items={payouts.items}
					count={payouts.count}
					exportUrl={``}
					screenWidth={store.view.screen_width}
					query={queryFilter}
					onClick={onClick}
					fetch={fetchData}
					columns={columns}
					filterConfigs={filterConfigs}
				/>
                { this.state.selectedPayout && store.restaurant &&
                    <PayoutModal
                        payout={this.state.selectedPayout}
                        restaurant={store.restaurant}
                        showModal={this.state.isOpenDownloadModal}
                        onClose={this.closeDownloadModal}>
                    </PayoutModal>
                }
			</>
		);
	}
}

export const PayoutList = withTheme(
	// @ts-ignore
	withTranslation()(PayoutListClass)
);
