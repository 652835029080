import React from "react";
import { FormGroup } from "@lib/components";
import { Input } from "@lib/components";
import { InputBox } from "@lib/components";
import { Button } from "@lib/components";
import { FaBars, FaTrashAlt } from "react-icons/fa";
import { Tooltip } from "@lib/components";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import shortid from "shortid";
import { Select } from "@lib/components";
import { WebsiteUtils } from "@lib/common";

interface ComponentProps {
	values: T.Schema.Website.WebsiteTopNavLink[];
	onChange: (values: T.Schema.Website.WebsiteTopNavLink[]) => void;
}

type ItemType = T.Schema.Website.WebsiteTopNavLink;
type ListType = ItemType[];
type HandleChange = (i: number, key: keyof ItemType, value: any) => void;
type HandleRemove = (i: number) => void;
type HandleSort = (data: { oldIndex: number; newIndex: number }) => void;

interface ListProps {
	items: ListType;
	change: HandleChange;
	remove: HandleRemove;
}
interface ItemProps {
	item: ItemType;
	itemIndex: number;
	itemsLength: number;
	change: HandleChange;
	remove: HandleRemove;
}

const DragHandle = SortableHandle(() => {
	return (
		<InputBox className="no-round width40 min40 flex-center cursor-move">
			<p className="big">
				<FaBars />
			</p>
		</InputBox>
	);
});

const defaultLinkIds = WebsiteUtils.settings.defaultTopNavLinkIds();
const defaultLinks = WebsiteUtils.settings.defaultTopNavLinks();

const SortableItem = SortableElement((props: ItemProps) => {
	const { itemIndex, change, remove, item } = props;
	const isDefaultLinkType = defaultLinkIds.indexOf(item._id) !== -1;
	return (
		<div key={itemIndex} className="flex-line" style={{ alignItems: "flex-end" }}>

			<DragHandle />

			<div className="flex-line" style={{ alignItems: "flex-end" }}>
				<FormGroup
					className="m-b-0"
					no_border={true}>
					<Input
						type="text"
						className="no-round"
						style={{ width: "140px" }}
						value={item.name}
						onChange={(e) => change(itemIndex, "name", e.target.value)} />
				</FormGroup>
			</div>

			<div className="flex-line" style={{ alignItems: "flex-end" }}>
				<FormGroup
					className="m-b-0"
					no_border={true}>
					<Input
						type={isDefaultLinkType ? "text" : "url"}
						className="no-round"
						style={{ width: "240px" }}
						disabled={isDefaultLinkType}
						placeholder={isDefaultLinkType ? "" : "https://..."}
						value={(() => {
							if (!isDefaultLinkType) {
								return item.link;
							}
							if (item._id === "order") {
								return `Store Page / Location Section`;
							}
							return defaultLinks.find((l) => l._id === item._id)!.name + " Section";
						})()}
						onChange={(e) => change(itemIndex, "link", e.target.value)} />
				</FormGroup>
			</div>

			<Tooltip text="Delete" width={65} position="right">
				<Button type="button" className="no-round" paddinglr={5} color="white" onClick={() => remove(itemIndex)}>
					<FaTrashAlt />
				</Button>
			</Tooltip>

		</div>
	);
});

const SortableItemContainer = SortableContainer((props: ListProps) => {
	return (
		<div>
			{props.items.map((item, i) => (
				<SortableItem
					key={`item-${i}`}
					index={i}
					item={item}
					itemIndex={i}
					itemsLength={props.items.length}
					change={props.change}
					remove={props.remove}
				/>
			))}
		</div>
	);
});

export class WebsiteTopNavLinks extends React.Component<ComponentProps, { addLinkType: string }> {

	constructor(props: ComponentProps) {
		super(props);
		this.state = {
			addLinkType: "",
		};
	}

	remove = (i: number) => {
		const values = ([...this.props.values]);
		values.splice(i, 1);
		this.props.onChange(values);
	}

	change: HandleChange = (i, key, value) => {
		const values = [...this.props.values];
		values[i][key] = value;
		this.props.onChange(values);
	}

	onSortEnd: HandleSort = ({ oldIndex, newIndex }) => {
		const { onChange, values } = this.props;
		onChange(arrayMove(values, oldIndex, newIndex));
	}

	addLink = () => {
		const { addLinkType } = this.state;
		if (!addLinkType) return;
		const values = [...this.props.values];
		if (defaultLinkIds.indexOf(addLinkType) !== -1) {
			values.push({ ...defaultLinks.find((l) => l._id === addLinkType)! });
		}
		else {
			values.push({
				_id: shortid.generate(),
				name: "",
				link: "",
			});
		}
		this.props.onChange(values);
	}

	reset = () => {
		this.props.onChange(WebsiteUtils.settings.defaultTopNavLinks());
	}

	render() {

		const { values } = this.props;

		return (
			<div>

				<div className="flex-line centered">

					<Select
						value={this.state.addLinkType}
						className="no-round-right width150"
						placeholder="Select link type"
						options={[
							...defaultLinks.map((l) => ({ label: l.name, value: l._id })),
							{
								label: "Custom",
								value: "custom",
							},
						]}
						onChange={(e) => this.setState({ addLinkType: e.target.value })}
					/>

					<Button type="button" onClick={this.addLink} className="no-round no-shadow">
						Add Link
					</Button>

					<Button type="button" onClick={this.reset} className="no-round-left no-shadow">
						Reset To Default
					</Button>

				</div>

				{values.length > 0 && (
					<>
						<div className="flex-line m-t-4" style={{ alignItems: "flex-end" }}>
							<div style={{ width: "40px", minWidth: "40px" }} />
							<div className="flex-line" style={{ alignItems: "flex-end" }}>
								<FormGroup
									style={{ width: "140px" }}
									className="m-b-0"
									title="Link Text"
									small_title={true}
									no_border={true} />
								<FormGroup
									style={{ width: "240px" }}
									className="m-b-0"
									title="Links To"
									small_title={true}
									no_border={true} />
							</div>
						</div>
						<SortableItemContainer
							lockAxis="y"
							helperClass="zindex-100"
							pressDelay={50}
							useDragHandle={true}
							items={values}
							change={this.change}
							remove={this.remove}
							onSortEnd={this.onSortEnd}
						/>
					</>
				)}

			</div>
		);

	}

}
