import * as React from "react";
import { Provider } from "mobx-react";
import ThemeWrapper from "./theme";
import App from "./app";

export default ({ store }: { store: any }) => {
	return (
		<Provider store={store}>
			<ThemeWrapper>
				<App />
			</ThemeWrapper>
		</Provider>
	);
};


