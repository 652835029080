import React from 'react';

export function SwishCertificatesHelp() {
  return (
    <p style={{ fontSize: '14px', lineHeight: '18px' }}>
      TLS certificates will be used to communicate with Swish API server.
      <br />
      For Swish Production environment the certificates and be obtained from &nbsp;
      <a
        style={{ color: 'blue' }}
        href="https://portal.swish.nu/company/login?redirectPath=%2Fcompany%2Fcertificates"
        rel="noopener"
      >
        Swish Certificate Management
      </a>
      .
      <br />
      For Swish Sandbox environment, test certificates are provided to you after &nbsp;
      <a
        style={{ color: 'blue' }}
        href="https://developer.swish.nu/documentation/environments#user-enrolment"
        rel="noopener"
      >
        enrolment
      </a>
      .
      <br />
      Please copy the content of certificates and paste them here. Make sure you don't add extra spaces or newlines. Normally, the extension of certificate file is .pem and private key file is .key
    </p>
  );
}
