import * as React from "react";
import Transition from "react-transition-group/Transition";
import { MobxComponent } from "../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { UI } from "../../../core/ui/index";
import { RotateLoader } from "@lib/components";

const duration = 300;
const transitionStyles: T.React.CSSTransitionStates = {
	entering: {
		opacity: 0,
		display: "flex",
	},
	entered: {
		opacity: 1,
		display: "flex",
	},
	exiting: {
		opacity: 0,
		display: "flex",
	},
	exited: {
		opacity: 0,
		display: "none",
	},
};

interface Props { }
interface State { }

@inject("store") @observer
export default class CoverLoader extends MobxComponent<Props, State> {

	componentDidMount() {
		UI.helpers.removeElement("initial-cover-loader");
	}

	render() {
		const { active, opacity, title, message } = this.injected.store.loader;
		return (
			<Transition in={active} timeout={duration}>
				{(transitionState: string) => {
					const currentStyle = transitionStyles[transitionState];
					return (
						<div className="cover-loader" style={currentStyle}>
							<div className="cover-loader-background" style={{ opacity: opacity || 1 }} />
							<div className="cover-loader-content">
								<RotateLoader style={{ marginBottom: "20px" }} />
								<h1 className="title">{title}</h1>
								<p className="message">{message}</p>
							</div>
						</div>
					);
				}}
			</Transition>
		);
	}

}
