import * as React from "react";
const autobind = require("class-autobind").default;
import Quill from "quill";
import { Button } from "@lib/components";

const Embed = Quill.import("blots/block/embed");

// CREATE AND REGISTER HR
class Hr extends Embed {
	static create(value: any) {
		const node = super.create(value);
		node.setAttribute("style", "height:0px; margin-top:10px; margin-bottom:10px;");
		return node;
	}
}
Hr.blotName = "hr";
Hr.className = "ql-hr";
Hr.tagName = "hr";
Quill.register({ "formats/hr": Hr });

// CREATE AND REGISTER SIZES
const SizeClass = Quill.import("attributors/class/size");
SizeClass.whitelist = ["small", "default", "large", "huge"];
Quill.register(SizeClass, true);

// HANDLERS
function hrHandler(this: any) {
	const cursorPosition = this.quill.getSelection().index;
	this.quill.insertEmbed(cursorPosition, "hr", "null");
	this.quill.setSelection(cursorPosition + 1);
}
function sizeHandler(this: any, val: string) {
	if (val) {
		this.quill.format("size", val);
	}
	else {
		this.quill.format("size", "default");
	}
}

// TOOLBAR
const CustomToolbar = (props: { id: string }) => {
	return (
		<div
			id={props.id}
			style={{
				position: "sticky",
				top: "58px",
				background: "#e8e8e8",
				zIndex: 5,
			}}>
			<select className="ql-size">
				<option className="ql-size-default" value="default">Default</option>
				<option className="ql-size-small" value="small">Small</option>
				<option className="ql-size-large" value="large">Large</option>
				<option className="ql-size-huge" value="huge">Huge</option>
			</select>
			<button className="ql-bold" />
			<button className="ql-italic" />
			<button className="ql-underline" />
			<select className="ql-align">
				<option />
				<option value="center" />
				<option value="right" />
				<option value="justify" />
			</select>
			<button className="ql-hr">
				---
			</button>
		</div>
	);
};

interface Props {
	id: string;
	initialValue?: string | null;
	onChange: (val: string) => void;
	templateText?: string;
	templatePrompt?: string;
}

export class HTMLEditor extends React.Component<Props> {

	editor?: Quill;

	constructor(props: Props) {
		super(props);
		autobind(this);
	}

	componentDidMount() {
		const { id, initialValue } = this.props;
		if (typeof window !== "undefined") {
			this.editor = new Quill(`#${id}-editor`, {
				theme: "snow",
				modules: {
					toolbar: {
						container: `#${id}-toolbar`,
						handlers: {
							size: sizeHandler,
							hr: hrHandler,
						},
					},
				},
			});
			this.editor.root.innerHTML = initialValue || "";
			this.editor.on("text-change", this.onChange);
		}
	}

	componentWillUnmount() {
		if (this.editor) {
			this.editor.off("text-change", this.onChange);
		}
	}

	onChange() {
		if (this.editor) {
			this.props.onChange(this.editor.root.innerHTML);
		}
	}

	clear() {
		if (this.editor) {
			this.editor.setText("");
			this.editor.root.innerHTML = "";
			this.props.onChange("");
		}
	}

	render() {
		const { id, templatePrompt, templateText } = this.props;
		return (
			<div className="ql-content">
				<CustomToolbar id={`${id}-toolbar`} />
				<div id={`${id}-editor`} />
				<Button
					type="button"
					size="xs"
					color="primary-inverse"
					className="m-t-3"
					onClick={this.clear}>
					Clear
				</Button>
				{templateText && (
					<Button
						type="button"
						size="xs"
						color="primary-inverse"
						className="m-t-3 m-l-2"
						onClick={() => {
							let proceed = true;
							if (templatePrompt) {
								proceed = confirm(templatePrompt);
							}
							if (proceed && this.editor) {
								this.editor.root.innerHTML = templateText;
							}
						}}>
						Use Template
					</Button>
				)}
				{this.props.children}
			</div>
		);
	}

}
