import shortid from 'shortid';

export const coffeeShopMenu = [
    {
      "_id": "B_nnB_UT1",
      "name": "All Day Breakfast",
      "display_name": "",
      "description": "",
      "conditions": {
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "Ej-TujbGt",
          "menu_id": "B_nnB_UT1",
          "name": "Real  Breakfast",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "aalfrp-O4",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Hash Brown Stack",
              "display_name": "",
              "description": "Served with hollandaise sauce, crispy bacon, fresh avocado, poached eggs",
              "subtitle": "",
              "price": 17.9,
              "tags": [],
              "image": {
                "_id": "9961bbd8-c4f7-4b7e-8d61-5cc0b68f0439",
                "name": "Bean_Origin_88.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "6jSSxAlXM",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Spinach Corn Fritters",
              "display_name": "",
              "description": "Poached eggs, grilled halloumi, avocado salsa, sundried tomato dressing",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "c6f596d1-03fb-49f8-a1ae-ed36e0eca1e0",
                "name": "Bean_Origin_92.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "uzh6poe5H",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Eggs Benedict",
              "display_name": "",
              "description": "Spinach, sourdough toast, poached eggs, creamy hollandaise sauce",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "226870d0-2d79-4a6a-b9d0-2d1734c3a435",
                "name": "Bean_Origin_97.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "KDYOLtWPg",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "E3bG3FN0X",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Bean origin   Omelette",
              "display_name": "",
              "description": "Bacon, Spanish chorizo, cheddar cheese, spinach, fresh avocado, sourdough toast",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "c569a9f4-18a7-402f-8670-130fc9d3e1c9",
                "name": "Bean_Origin_90.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "yKwJiF-qZ",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Gourmet Vegetarian Omelet",
              "display_name": "",
              "description": "Gourmet Vegetarian Omelet",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "eb775d33-8495-4834-b4ca-8732d8edcc6b",
                "name": "Bean_Origin_95.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HKpKKqzI8",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Smashed Avocado",
              "display_name": "",
              "description": "Served with poached eggs, sourdough toast, Danish fetta, balsamic reduction.",
              "subtitle": "",
              "price": 18.9,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "cc585ed3-7d54-4f60-a7cc-e58f17186b63",
                "name": "Bean_Origin_93.jpg",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "1_C-Jd5WO",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Protein Breakfast on Toast",
              "display_name": "",
              "description": "Poached eggs, Spanish chorizo, crispy bacon, fresh avocado.",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "1f9eb381-2e61-4b7a-b016-4d896d49a088",
                "name": "Bean_Origin_87.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EEJS99GRT",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Eggs On Toast",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 12,
              "tags": [],
              "image": {
                "_id": "c7746f90-91b8-448b-afe9-3b8c3a1ce03a",
                "name": "Bean_Origin_12.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe",
                "bcQb-k_v-"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Ae_ZkIhGo",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Eggs Florentine",
              "display_name": "",
              "description": "Grilled mushroom, spinach, sourdough toast, poached eggs, creamy hollandaise sauce",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "1263fd2b-f4a0-4717-80e9-9278223f8127",
                "name": "Bean_Origin_94.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "umNcjBUM7",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "French Toast",
              "display_name": "",
              "description": "Served with maple syrup, caramelized banana, fresh strawberries, whipped cream.",
              "subtitle": "",
              "price": 18.9,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "56af8214-ab7e-4f35-9ddc-62d69f12d337",
                "name": "Bean_Origin_2.jpg",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "wsPptjwxb",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Big Breakfast with Toast",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 23.9,
              "tags": [],
              "image": {
                "_id": "a5ee7945-99d8-42e2-9abd-0d0734b2935c",
                "name": "Bean_Origin_84.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "e-cDrQg5-",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Belgium Waffles",
              "display_name": "",
              "description": "Served with bacon, fresh banana and strawberries, vanilla ice cream and maple syrup.",
              "subtitle": "",
              "price": 21.9,
              "tags": [],
              "image": {
                "_id": "882af0be-a07c-4228-9104-e03654b57d4d",
                "name": "Bean_Origin_1.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "d82Zz1fNu",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Eggs Atlantic",
              "display_name": "",
              "description": "Smoked salmon, spinach, sourdough toast, poached eggs, creamy hollandaise sauce",
              "subtitle": "",
              "price": 24.3,
              "tags": [],
              "image": {
                "_id": "1461064d-91cb-4329-a062-f6bf293ed1ce",
                "name": "Bean_Origin_98.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "9AOzUOMzG",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Grilled Mushroom and Haloumi on Toast",
              "display_name": "",
              "description": "Sourdough, poached eggs, sundried tomato dressing",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "3c3fc4c0-fe06-49d1-bdcb-152fd36c1316",
                "name": "Bean_Origin_89.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "zm3L0izIJ",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "American pancakes",
              "display_name": "",
              "description": "Served with mixed berries compote and vanilla ice cream.",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "10d63bca-d721-44d4-9781-7e7354a0bb38",
                "name": "Bean_Origin_1__1_.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "-FAq3GP88",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Mexican Breakfast",
              "display_name": "",
              "description": "sourdough toast, chilli con crone, chorizo, fried eggs, avocado.",
              "subtitle": "",
              "price": 21.9,
              "tags": [],
              "image": {
                "_id": "222e1d79-9d7b-455e-af75-a959ec5cda86",
                "name": "Bean_Origin_86.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "-YIo9m4E2",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Aussie Omelete",
              "display_name": "",
              "description": "Bacon, ham, tomatoes, cheese with sourdough toast",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "e108c322-6c2d-43d8-9e4c-e077b31871a9",
                "name": "Bean_Origin_91.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rCOIfIKvA",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Surf and Turf Benedict",
              "display_name": "",
              "description": "Sourdough toast, spinach, poached eggs, pulled beef, prawns, hollandaise sauce",
              "subtitle": "",
              "price": 23.4,
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "image": {
                "_id": "4b4802fb-7c1a-4913-b689-68c8d3820652",
                "name": "Bean_Origin_96.jpg",
                "modifiers": null
              }
            },
            {
              "type": "standard",
              "_id": "QhYGo5Vy2",
              "menu_id": "B_nnB_UT1",
              "category_id": "Ej-TujbGt",
              "name": "Breakfast Bruchetta",
              "display_name": "",
              "description": "Grilled tomatoes, bacon, poached eggs, avocado, basil mayo on sourdough toast",
              "subtitle": "",
              "price": 21.9,
              "tags": [],
              "image": {
                "_id": "2a372ae2-4cfc-41c9-beca-c51e77db9d87",
                "name": "Bean_Origin_85.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "S4bIZajoF",
          "menu_id": "B_nnB_UT1",
          "name": "Light Breakfast",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "iRaHWDerp",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Toast with Spreads",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "4c61c902-8dfc-43de-8ac0-d0e44228f4ad",
                "name": "Toast_with_spreads.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "gSTDMyJcN",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EDXfEVPy5",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Ham Cheese Croissant",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.5,
              "tags": [],
              "image": {
                "_id": "b9ec3706-7ccb-4269-8a17-97bec2160801",
                "name": "Bean_Origin_16.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "1y9x6rPeC",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Raisin Toast",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "db4a02a9-d811-48f9-bf8d-dbbd6c0a042e",
                "name": "Bean_Origin_65.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "gSTDMyJcN"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "nfz7_zgU0",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Bacon Egg and Cheese Roll",
              "display_name": "",
              "description": "with tomato relish",
              "subtitle": "",
              "price": 11.9,
              "tags": [],
              "image": {
                "_id": "53a58cfb-8b4c-4924-9bd4-5e8bdc5f3b53",
                "name": "Bean_Origin_75.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "GvgjT_Ayg",
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "jIsEw7BZj",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Egg and Chorizo Brioche Roll",
              "display_name": "",
              "description": "with cheese and BBQ sauce",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "14aba768-0446-4e8b-af05-ed19f2b04c6a",
                "name": "Bean_Origin_101.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "GvgjT_Ayg",
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "eW2uT4IGl",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Eggs Benedict Roll",
              "display_name": "",
              "description": "Served with avocado",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "3fc22ae8-e0ad-4e2d-b036-444c70150e6d",
                "name": "Bean_Origin_76.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "GvgjT_Ayg",
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "A9e-iK4y5",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Brekky Burger",
              "display_name": "",
              "description": "Brioche bun,Mix leaves,bacon,egg,avocado,hashbrown and sweet chilli mayo sauce",
              "subtitle": "",
              "price": 14.9,
              "tags": [],
              "image": {
                "_id": "dee9203b-05f7-4262-b8d5-8988c2945a5d",
                "name": "Bean_Origin_103.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "GvgjT_Ayg",
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "JJq4ULrHg",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Fruit Salad and Yoghurt",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 11.9,
              "tags": [],
              "image": {
                "_id": "d04a1323-2434-44b6-90a7-11b6cb574739",
                "name": "Bean_Origin_58.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "KvuLTAr2N",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Toasted Muesli Fruit and Yoghurt",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "245af556-dc6d-43f2-8ecc-35c91a517753",
                "name": "Bean_Origin_59.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "CCDhML8__",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Brekky Burrito",
              "display_name": "",
              "description": "Egg, bacon, chorizo, cheddar cheese, tomato relish",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "ab44c5d1-1d6e-43ad-95d1-3ad560d3cf45",
                "name": "Bean_Origin_79.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "GvgjT_Ayg"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "VEqCznyGr",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Ham Cheese Tomato Sandwich",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": {
                "_id": "552c735a-ca31-4350-9a5d-0cb31be30ae8",
                "name": "Bean_Origin_17.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "gSTDMyJcN",
                "U9TN5OIM2",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "wSGzKEFxp",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Meat lover brekky wrap",
              "display_name": "",
              "description": "Pulled beef,ham,bacon,chorizo,fried egg,cheddar cheese and bbq ",
              "subtitle": "",
              "price": 15.5,
              "tags": [],
              "image": {
                "_id": "500390af-413f-4373-9b3a-7b0c9ae5aa3a",
                "name": "Meat_lovers_brekky_wrap.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "WtLqe3xyH"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "TNx86BK-9",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Plain Croissant",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "accb02d7-6f5a-47d7-bb00-19c1a60a20b6",
                "name": "Bean_Origin_11.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "gSTDMyJcN",
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "nB0Cdm7Gf",
              "menu_id": "B_nnB_UT1",
              "category_id": "S4bIZajoF",
              "name": "Bean origin Breakfast roll",
              "display_name": "",
              "description": "Meat patty,egg,bacon,cheese,grilled onion and bbq",
              "subtitle": "",
              "price": 15.9,
              "tags": [],
              "image": {
                "_id": "4fd22833-a764-40c1-8b39-c74416f3f912",
                "name": "Bean_Origin_102.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "GvgjT_Ayg",
                "U9TN5OIM2",
                "WtLqe3xyH",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "0zJDKPc7b",
          "menu_id": "B_nnB_UT1",
          "name": "Cakes and Slices",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "n21PY6Y2K",
              "menu_id": "B_nnB_UT1",
              "category_id": "0zJDKPc7b",
              "name": "Chocolate Brownie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "fa23e728-7215-400b-b006-d2adbc06e9ec",
                "name": "Bean_Origin_7.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "x6hC-XY2E",
              "menu_id": "B_nnB_UT1",
              "category_id": "0zJDKPc7b",
              "name": "Caramel Slice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4,
              "tags": [],
              "image": {
                "_id": "ad50db39-39ca-4a08-a96c-f2b2e792a53f",
                "name": "Bean_Origin_5.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "8t0L9QeIy",
              "menu_id": "B_nnB_UT1",
              "category_id": "0zJDKPc7b",
              "name": "Fresh Baked Muffin",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "ddd1ef1d-f967-4422-bc0b-448858b2762c",
                "name": "Bean_Origin_9.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "nB52gL_jc",
              "menu_id": "B_nnB_UT1",
              "category_id": "0zJDKPc7b",
              "name": "Raspberry and Almond Friand",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "56479018-6dda-4a76-8c75-a34df8b1e56a",
                "name": "Bean_Origin_6.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "N6-JyQ9HV",
              "menu_id": "B_nnB_UT1",
              "category_id": "0zJDKPc7b",
              "name": "Coconut Slice (GF)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [
                "Xi-wx9ZBL"
              ],
              "image": {
                "_id": "26a37b10-98eb-40e0-99d6-9239bd2d2736",
                "name": "Bean_Origin_8.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "zPkg8sJz9",
              "menu_id": "B_nnB_UT1",
              "category_id": "0zJDKPc7b",
              "name": "Banana Bread",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "a62a9ae0-35e5-4184-b337-fbcd75cc61f2",
                "name": "Bean_Origin_10.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "9Qujw4x8c",
              "menu_id": "B_nnB_UT1",
              "category_id": "0zJDKPc7b",
              "name": "Oat and Chia Seed Slice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "b3a3c3f4-8c56-4c7e-b7d3-6ec926c39dde",
                "name": "Bean_Origin_15.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "iKZYhlwql",
          "menu_id": "B_nnB_UT1",
          "name": "Coffee",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "lBMWpaHsz",
              "menu_id": "B_nnB_UT1",
              "category_id": "iKZYhlwql",
              "name": "Cappuccino",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "7744b159-8f29-4bbe-9566-e0d752948af7",
                "name": "Bean_Origin_22.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "gxcInixkp",
              "menu_id": "B_nnB_UT1",
              "category_id": "iKZYhlwql",
              "name": "Cafe Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "95a6a3af-bff3-4556-82aa-3775d925826c",
                "name": "Bean_Origin_25.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "KydC7zbLl",
              "menu_id": "B_nnB_UT1",
              "category_id": "iKZYhlwql",
              "name": "Flat White",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "d2ca2113-a012-4151-8b10-481fd6e599e9",
                "name": "Bean_Origin_25.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dvr72Cqlm",
              "menu_id": "B_nnB_UT1",
              "category_id": "iKZYhlwql",
              "name": "Long Black",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "d7ca4a06-a71b-4fe5-9174-11d0d47dc362",
                "name": "Bean_Origin_23.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "7tq2_NHIn",
              "menu_id": "B_nnB_UT1",
              "category_id": "iKZYhlwql",
              "name": "Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4,
              "tags": [],
              "image": {
                "_id": "c1fac372-64c7-4874-83c7-4129190b74ec",
                "name": "Espresso.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "XPtlXNVYh",
              "menu_id": "B_nnB_UT1",
              "category_id": "iKZYhlwql",
              "name": "Ristretto",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "309527ed-0e65-4b3e-8921-cc2685e89432",
                "name": "Espresso.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ddpFj5M36",
              "menu_id": "B_nnB_UT1",
              "category_id": "iKZYhlwql",
              "name": "Macchiato",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "3e5a6551-0910-4256-a6e5-15c90edec89c",
                "name": "Bean_Origin_28.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "gsIwc22Iy",
              "menu_id": "B_nnB_UT1",
              "category_id": "iKZYhlwql",
              "name": "Piccolo Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "8b174110-75c8-4f6c-b07c-11117d48781d",
                "name": "Bean_Origin_29.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "jLhhRND1V",
          "menu_id": "B_nnB_UT1",
          "name": "Hot Drinks",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "0Cb2JakD9",
              "menu_id": "B_nnB_UT1",
              "category_id": "jLhhRND1V",
              "name": "Hot chocolate",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "30163c4b-d5dc-4609-84c4-f75d2b17d954",
                "name": "Bean_Origin_24.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "94mRO01vt",
              "menu_id": "B_nnB_UT1",
              "category_id": "jLhhRND1V",
              "name": "Cafe Mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "85aac176-5e8f-4f57-878a-657af1db0f5d",
                "name": "Bean_Origin_26.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "MD3JEmdiS",
              "menu_id": "B_nnB_UT1",
              "category_id": "jLhhRND1V",
              "name": "White Choc Mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "86569942-c33c-43e0-a9f6-7f7a478b1134",
                "name": "Bean_Origin_26.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EdpVJL9G7",
              "menu_id": "B_nnB_UT1",
              "category_id": "jLhhRND1V",
              "name": "Chai Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "4b9943ac-a5da-43a6-86dc-00bfeb78d87f",
                "name": "Bean_Origin_27.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "PqD_Btxfr",
              "menu_id": "B_nnB_UT1",
              "category_id": "jLhhRND1V",
              "name": "Tea Pot",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": {
                "_id": "f570f233-b23f-4d21-a5ae-d4372e397584",
                "name": "Tea.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E4XN1B4yE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "DrXnHe8dM",
              "menu_id": "B_nnB_UT1",
              "category_id": "jLhhRND1V",
              "name": "Dirty chai",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.4,
              "tags": [],
              "image": {
                "_id": "92782269-bd23-4283-a8c6-b2da3dcd3a2a",
                "name": "Bean_Origin_27.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rYT0WU9SV",
              "menu_id": "B_nnB_UT1",
              "category_id": "jLhhRND1V",
              "name": "Matcha Chai",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "3pbWtA4Gv"
              ]
            }
          ]
        },
        {
          "_id": "OK6RIuQ2x",
          "menu_id": "B_nnB_UT1",
          "name": "Freshly Squeezed Juice",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "FyY6Y4Ja8",
              "menu_id": "B_nnB_UT1",
              "category_id": "OK6RIuQ2x",
              "name": "Orange Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "e636bbc6-0185-42d0-b94f-fb7606603391",
                "name": "Bean_Origin_49.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "k_pNUs2oS",
              "menu_id": "B_nnB_UT1",
              "category_id": "OK6RIuQ2x",
              "name": "Orange, Kiwi and Apple",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "e72ccefe-ccb4-4c75-8bd9-b5852a572718",
                "name": "Bean_Origin_53.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "kTqJh7z5q",
              "menu_id": "B_nnB_UT1",
              "category_id": "OK6RIuQ2x",
              "name": "Watermelon, Mint, Ginger and Pear",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "c813a94f-d120-4833-a641-b126361253ca",
                "name": "Watermelon_mint_ginger_pear_juice.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "iuR4RBduH",
              "menu_id": "B_nnB_UT1",
              "category_id": "OK6RIuQ2x",
              "name": "Spinach, Cucumber, Pear and Green Apple",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "e9270298-aed9-4c6f-983e-a182e01c2294",
                "name": "Bean_Origin_47.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Z0tlyTgT8",
              "menu_id": "B_nnB_UT1",
              "category_id": "OK6RIuQ2x",
              "name": "Apple, Carrot and Ginger",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "c144da1b-5698-472d-94e6-acd8b1972f9e",
                "name": "Bean_Origin_52.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "jOOwun2LO",
          "menu_id": "B_nnB_UT1",
          "name": "Cold Drinks",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "U2BJKPzcq",
              "menu_id": "B_nnB_UT1",
              "category_id": "jOOwun2LO",
              "name": "Affogato",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.2,
              "tags": [],
              "image": {
                "_id": "1230d231-56c7-4942-85ee-daba82959ec8",
                "name": "Affogato.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "vdlVHOxjk",
              "menu_id": "B_nnB_UT1",
              "category_id": "jOOwun2LO",
              "name": "Iced Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "68fad1ef-1377-4067-899a-e5b5b4db0d9b",
                "name": "Bean_Origin_54.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "pHOb7etRb",
              "menu_id": "B_nnB_UT1",
              "category_id": "jOOwun2LO",
              "name": "Iced Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "fdd12f05-90e0-4f17-a619-5580e0831c88",
                "name": "Bean_Origin_48.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RbXSJzEwn",
              "menu_id": "B_nnB_UT1",
              "category_id": "jOOwun2LO",
              "name": "Iced Chocolate",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "35886f64-25b9-43c8-9d31-378c90a577e0",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE",
                "bmiPgtKoU"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UpmQNAh7x",
              "menu_id": "B_nnB_UT1",
              "category_id": "jOOwun2LO",
              "name": "Iced mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "bcb02535-9f4e-4314-bed3-fb3ff85652ec",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE",
                "bmiPgtKoU"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "FuQhge7G9",
              "menu_id": "B_nnB_UT1",
              "category_id": "jOOwun2LO",
              "name": "Iced Long Black",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "d58f33d7-c665-4c7f-bcf2-820e2b84ab1a",
                "name": "Bean_Origin_51.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Y7Y5P9T2U",
              "menu_id": "B_nnB_UT1",
              "category_id": "jOOwun2LO",
              "name": "Iced Chai",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "37d02535-853b-45bc-80d7-9c3521d9005f",
                "name": "Bean_Origin_55.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "5TngvotbE",
              "menu_id": "B_nnB_UT1",
              "category_id": "jOOwun2LO",
              "name": "Iced Matcha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "QNZyK4f0E",
          "menu_id": "B_nnB_UT1",
          "name": "Shakes",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "jLhx0H9_A",
              "menu_id": "B_nnB_UT1",
              "category_id": "QNZyK4f0E",
              "name": "Thickshakes",
              "display_name": "",
              "description": "Banana, Strawberry, Vanilla, Caramel, Chocolate, Coffee, lime",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "861f50e8-a18d-43dd-b488-e3e25b83a0ce",
                "name": "Bean_Origin.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "JMJ9blCSF",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "FwX3XTGSu",
              "menu_id": "B_nnB_UT1",
              "category_id": "QNZyK4f0E",
              "name": "Milkshakes",
              "display_name": "",
              "description": "Banana, Strawberry, Vanilla, Caramel, Chocolate, Coffee, lime",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "9a010e65-fee4-4df9-99fb-5c295e5c2863",
                "name": "Bean_Origin_37.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE",
                "JMJ9blCSF"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "I5xI7M_LZ",
          "menu_id": "B_nnB_UT1",
          "name": "Espresso Frappes",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "Xnd2LmhHY",
              "menu_id": "B_nnB_UT1",
              "category_id": "I5xI7M_LZ",
              "name": "Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "8dfd01a9-320b-4636-989c-2f22a8a14864",
                "name": "Bean_Origin_54.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dO6cOHjy0",
              "menu_id": "B_nnB_UT1",
              "category_id": "I5xI7M_LZ",
              "name": "Caramel Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "ce734a41-232a-4519-8214-befbca30583e",
                "name": "Bean_Origin_54.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "wwkvJGB9-",
              "menu_id": "B_nnB_UT1",
              "category_id": "I5xI7M_LZ",
              "name": "Hazelnut Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "c16d48cf-3521-48a9-bc61-525180000482",
                "name": "Bean_Origin_55.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "VosSRi-f0",
              "menu_id": "B_nnB_UT1",
              "category_id": "I5xI7M_LZ",
              "name": "Cafe Mocha Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "d5d8432a-60e8-42e5-974a-bf1424e5144d",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "EVBTtqDh_",
              "menu_id": "B_nnB_UT1",
              "category_id": "I5xI7M_LZ",
              "name": "White Mocha Frappe",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "4c3d5b18-5c21-4f77-9050-c507756f89bb",
                "name": "Bean_Origin_55.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "OsX-so5NA",
          "menu_id": "B_nnB_UT1",
          "name": "Delicious Smoothies",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "eOpUJTXzn",
              "menu_id": "B_nnB_UT1",
              "category_id": "OsX-so5NA",
              "name": "Mango and banana ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "5c8605b3-6d6a-4934-80ce-7387be542934",
                "name": "Bean_Origin_44.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UGUoLKpIM",
              "menu_id": "B_nnB_UT1",
              "category_id": "OsX-so5NA",
              "name": "Mixed Berries Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "7cea4fc1-c06f-44d9-bc30-a62a229979e5",
                "name": "Bean_Origin_45.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YI2m7nHcq",
              "menu_id": "B_nnB_UT1",
              "category_id": "OsX-so5NA",
              "name": "Banana and Honey Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "0f3823c8-dcd7-434d-a864-0c5edcbd9ed3",
                "name": "Bean_Origin_43.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "jcQASpaWB",
              "menu_id": "B_nnB_UT1",
              "category_id": "OsX-so5NA",
              "name": "Strawberry and Kiwi Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "34793a31-c99e-4a4d-a12d-39f48e080498",
                "name": "Bean_Origin_42.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        }
      ]
    },
    {
      "_id": "zu3XERQlE",
      "name": "Burgers ",
      "display_name": "",
      "description": "",
      "conditions": {
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "KllFSrLCj",
          "menu_id": "zu3XERQlE",
          "name": "Burgers (served with Chips)",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "QOrPjQj6T",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Gourmet Beef Burger",
              "display_name": "",
              "description": "Mixed leaf, tomatoes, beetroot, grilled onions, cheese and BBQ sauce.",
              "subtitle": "",
              "price": 18.9,
              "tags": [],
              "image": {
                "_id": "c58a4cfe-a12b-4fe6-8d18-5dab41963849",
                "name": "Bean_Origin_31.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "s-da2lCE3",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Crispy Chicken Burger",
              "display_name": "",
              "description": "Mixed leaf, tomatoes, red onions, cheese, beetroot and ranch sauce.",
              "subtitle": "",
              "price": 18.9,
              "tags": [],
              "image": {
                "_id": "2cbf43f5-0ea3-4025-a65a-a643cecd206d",
                "name": "Crispy_chicken_burger.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HiKwFHDoi",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "American Burger",
              "display_name": "",
              "description": "Mixed leaf, bacon, beef patty, grilled onions, tomatoes, cheese and creamy sweet mustard sauce.",
              "subtitle": "",
              "price": 20.9,
              "tags": [],
              "image": {
                "_id": "445d7cf7-1dff-48a4-a01d-ef21c478941f",
                "name": "American_burger.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "828AiGtpo",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Steak  and Bacon Burger",
              "display_name": "",
              "description": "Mixed leaf, scotch fillet, bacon, grilled onions, cheese, tomatoes, beetroot and creamy garlic sauce.",
              "subtitle": "",
              "price": 20.9,
              "tags": [],
              "image": {
                "_id": "f4a1d056-8900-45c0-9ec2-afad3489ecf0",
                "name": "Bean_Origin_33.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "n7qNUJwVg",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Spiced Crumbed Chicken Burger",
              "display_name": "",
              "description": "Mixed leaf, tomatoes, jalapenos, onion rings and creamy hot chilli sauce.",
              "subtitle": "",
              "price": 18.9,
              "tags": [
                "H6ofNHFXQ"
              ],
              "image": {
                "_id": "6d5934fb-44bf-4f16-845f-ffd028408ddb",
                "name": "Bean_Origin_41.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "FdPKEDUP2",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Vegetarian Burger",
              "display_name": "",
              "description": "Mixed leaf, tomatoes, onions, roasted peppers, grilled halloumi and creamy garlic sauce.",
              "subtitle": "",
              "price": 18.9,
              "tags": [
                "Tk7Zh-iVz"
              ],
              "image": {
                "_id": "6f7d3bc1-4b14-4723-8e5f-dcab05663adf",
                "name": "Bean_Origin_61.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2WNUBKnvq",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Greek Lamb Burger",
              "display_name": "",
              "description": "Mixed leaf, tomatoes, roast peppers, onions, fetta and garlic sauce",
              "subtitle": "",
              "price": 20.9,
              "tags": [],
              "image": {
                "_id": "ccaa9908-fe8b-4ff7-b3f2-3aed9950e70b",
                "name": "Lamb_burger1.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "xnIU-x6sk",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Grilled Chicken and Haloumi Burger",
              "display_name": "",
              "description": "Grilled chicken, mixed leaf, tomatoes, haloumi, avocado with ranch sauce",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "75274eb4-1135-4992-9e3e-fe18069ca19b",
                "name": "Bean_Origin_30.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YYk1V4fTJ",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Chicken BLT Burger",
              "display_name": "",
              "description": "Grilled chicken, cos lettuce, tomatoes, bacon, cheese and ranch sauce",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "21491437-d076-4a67-b5fd-de777bd7e475",
                "name": "Chicken_BLT_burger.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "62kY4kAC6",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Meat Lover Cheese Burger",
              "display_name": "",
              "description": "beef patty, Spanish chorizo, bacon, grilled onions, cheese, sweet mustard and tomato sauce",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "a00c8c9d-953a-497d-bdca-a6fd97f71894",
                "name": "Bean_Origin_57.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ajk2IMmdy",
              "menu_id": "zu3XERQlE",
              "category_id": "KllFSrLCj",
              "name": "Mexican Beef Burger",
              "display_name": "",
              "description": "beef patty, lettuce, tomatoes, cheese, grilled onions, jalapenos, chilli mayo",
              "subtitle": "",
              "price": 20.9,
              "tags": [],
              "image": {
                "_id": "e36648e2-c524-42b1-a7f6-f6f9ce7a5f39",
                "name": "Bean_Origin_56.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "3GQJEqXnw",
          "menu_id": "zu3XERQlE",
          "name": "Sandwiches, Wraps and Salads",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "wLRpGS5x9",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Toasted Chicken Cheese Avocado Sandwich",
              "display_name": "",
              "description": "Chicken, cheese, avocado, basil mayo",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "980b366c-7284-4af4-a905-4b8e22d3f439",
                "name": "Bean_Origin_18.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "XA9TEiLOn",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ncBTSlIZu",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Falafel and Hummus Wrap",
              "display_name": "",
              "description": "Mixed leaf, cucumber, cheese, sliced tomatoes, roast capsicum, sweet chili sauce",
              "subtitle": "",
              "price": 14.9,
              "tags": [],
              "image": {
                "_id": "03f2e851-4283-4888-9111-cd8c4c37b918",
                "name": "Bean_Origin_74.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UBVGClbM0",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Smoked Salmon and fetta cheese Turkish roll",
              "display_name": "",
              "description": "Smoked salmon, mixed leaf, French capers, cucumber, red onions, fetta cheese",
              "subtitle": "",
              "price": 15.9,
              "tags": [],
              "image": {
                "_id": "df95532a-7793-440e-b701-b1dda074ade0",
                "name": "Bean_origin_22.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "XA9TEiLOn",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "opGJA_Woc",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Garlic Prawns and Avocado salad",
              "display_name": "",
              "description": "Mixed leaf, cucumber, tomatoes, red onions, fresh avocado, garlic and roast pepper dressing",
              "subtitle": "",
              "price": 21.9,
              "tags": [],
              "image": {
                "_id": "496d10f6-f67f-423f-afc6-f017a673fb5e",
                "name": "Bean_origin_109.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "e2EklSRJS",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Chicken salad sandwich",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "4aa5ceaf-204f-485e-a4d1-cb8770b79c66",
                "name": "Bean_origin_76.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "XA9TEiLOn",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "5Y3QPgzE_",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Ham salad sandwich",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "d9165ccd-442e-41e3-afb3-2f88fcaef0d4",
                "name": "Bean_origin_71.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "XA9TEiLOn",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "wEoGxbcqF",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Steak Sandwich",
              "display_name": "",
              "description": "Sourdough,mixed leaf, sliced tomatoes, cheddar cheese, sliced beetroot, grilled onions, bbq",
              "subtitle": "",
              "price": 15.9,
              "tags": [],
              "image": {
                "_id": "cb03aafc-0d6d-4776-b412-32c6ae60d40b",
                "name": "Bean_Origin_20.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "jw7eQMXhp",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Blat on Turkish Roll",
              "display_name": "",
              "description": "Bacon, lettuce, tomatoes, avocado, garlic sauce",
              "subtitle": "",
              "price": 14.9,
              "tags": [],
              "image": {
                "_id": "793fa01d-9156-4603-b4cd-150dc0b57d74",
                "name": "BLAT_on_turkish.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UpSqSm9qQ",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "BBQ Beef Wrap",
              "display_name": "",
              "description": "Mixed leaf, grilled onions, tomatoes, carrot, cheddar cheese",
              "subtitle": "",
              "price": 14.9,
              "tags": [],
              "image": {
                "_id": "ee277dc2-4a6b-4520-b46f-a3ff07833322",
                "name": "Bean_Origin_70.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ilHW3yQJj",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Falafel and Crumbed Mushroom Salad",
              "display_name": "",
              "description": "Mixed leaf, cucumber, tomatoes, red onions, fetta cheese, avocado, balsamic dressing.",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "a2f0d204-bbbe-4b3f-9bc2-387e42c3b186",
                "name": "Falafal_and_crumbed_mushroom_salad.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "uXYF2GzIn",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Grilled Chicken Caesar Salad",
              "display_name": "",
              "description": "Lettuce, bacon, croutons, parmesan cheese, Caesar sauce",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "d372d766-cfbb-4236-acc4-7a7b78327277",
                "name": "Grilled_chicken_ceaser_salad.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UFaToPzoL",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Garlic Prawns Caesar Wrap",
              "display_name": "",
              "description": "Lettuce, tomatoes, parmesan cheese, bacon",
              "subtitle": "",
              "price": 15.9,
              "tags": [],
              "image": {
                "_id": "eb76245b-91c3-48be-ac8f-89cf4c562238",
                "name": "Garlic_prawn_ceaser_wrap.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "MqVTeHmIQ",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Ham Cheese and Tomatoes Sandwich",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": {
                "_id": "036f9bfa-d339-4979-96a2-f3747978e071",
                "name": "Ham_cheese_tomato_sandwich.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "XA9TEiLOn",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "j6fUSUfD2",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Beef and Haloumi Salad",
              "display_name": "",
              "description": "Mixed leaf, cucumber, roast capsicum, fresh avocado, garlic sauce",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "4abe219f-f099-4f03-824a-32c81dc25669",
                "name": "Beerf_and_haloumi_salad.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Zd3Pj6mm2",
              "menu_id": "zu3XERQlE",
              "category_id": "3GQJEqXnw",
              "name": "Calamari Caesar Salad",
              "display_name": "",
              "description": "Cos lettuce,parmesan cheese,croutons,bacon,egg,calamari rings,caesar dressing",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "w1Uj-Tqq6",
          "menu_id": "zu3XERQlE",
          "name": "Gourmet Main Meal Menu",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "8LPIvd7Ue",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Chicken Schnitzel Chips Salad",
              "display_name": "",
              "description": "Served with gravy",
              "subtitle": "",
              "price": 17.9,
              "tags": [],
              "image": {
                "_id": "f252c0b9-03c6-4500-a5e8-a46fb854884f",
                "name": "Chicken_schnitzel.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YoRDWmZ3-",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Pulled Beef Nachos",
              "display_name": "",
              "description": "Served with chili con crone",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "ab22686c-8043-432f-b206-921f9d33a6ab",
                "name": "Bean_Origin_60.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "QnQECqc4M",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Lasagna",
              "display_name": "",
              "description": "with chips and salad.",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "3e74381e-f92c-4d21-97d2-90284d937c12",
                "name": "Bean_Origin_77.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "lUOCOtWhd",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Salt Pepper Calamari",
              "display_name": "",
              "description": "with chips and salad with tarte sauce",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": {
                "_id": "096481b5-a7e8-49cc-8c22-69a419f61196",
                "name": "Bean_Origin_69.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "DAD2EQ_rz",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Crumbed Fish",
              "display_name": "",
              "description": "with chips and salad",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": {
                "_id": "f402faab-0453-46df-8c4f-9ac928d1e434",
                "name": "Crumbed_fish.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "s8UpqYlIF",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Grilled Fish",
              "display_name": "",
              "description": "with chips and salad",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": {
                "_id": "7df3518b-4df7-4c9e-bb9f-700527243e3c",
                "name": "Grilled_fish.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "QAHhD4NVk",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Chicken Parmigiana",
              "display_name": "",
              "description": "With chips and salad.",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": {
                "_id": "09641838-78ce-4d9e-9c26-191fa41734cf",
                "name": "Chicken_parmigiana.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "aFtHj39yP",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Chorizo and Mushroom Penne Pasta",
              "display_name": "",
              "description": "with creamy napolitana sauce",
              "subtitle": "",
              "price": 20.9,
              "tags": [],
              "image": {
                "_id": "d4237f90-f6fe-453a-a612-d7da9d47a42a",
                "name": "Chorizo_and_mushroom_penne_pasta.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "0_ATKn7UL",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Chicken Bacon Avocado Stack",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "df86bc5c-bbea-4d8c-9c59-441d2488e39d",
                "name": "Chicken_bacon_avo_stack.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "hynyvtYb9",
              "menu_id": "zu3XERQlE",
              "category_id": "w1Uj-Tqq6",
              "name": "Chicken carbonara penne pasta",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20.9,
              "tags": [],
              "image": {
                "_id": "a07d8b58-b4f7-4c6e-9db1-a588081d96a7",
                "name": "Chicken_carbonara_penne_pasta.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "IJ5WIWavn",
          "menu_id": "zu3XERQlE",
          "name": "Side Dishes",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "cZDzHi9Tv",
              "menu_id": "zu3XERQlE",
              "category_id": "IJ5WIWavn",
              "name": "Bowl of Chips",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "dcc88c79-c6e7-42b3-ae5a-52ede84f9c53",
                "name": "Chips.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HeFC66gdy",
              "menu_id": "zu3XERQlE",
              "category_id": "IJ5WIWavn",
              "name": "Bowl of Wedges",
              "display_name": "",
              "description": "Served with sour cream and sweet chilli sauce.",
              "subtitle": "",
              "price": 12,
              "tags": [],
              "image": {
                "_id": "af5f411a-2565-4c63-b7a4-fa3c1935d48f",
                "name": "Bean_Origin_80.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "KphgOrC_w",
              "menu_id": "zu3XERQlE",
              "category_id": "IJ5WIWavn",
              "name": "Onion Rings",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 10,
              "tags": [],
              "image": {
                "_id": "626b56f3-2dfa-4b4a-9db7-8a24894914a4",
                "name": "Bean_Origin_81.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Hk8eImfIC",
              "menu_id": "zu3XERQlE",
              "category_id": "IJ5WIWavn",
              "name": "Loaded Beef and Gravy Chips",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 14,
              "tags": [],
              "image": {
                "_id": "667bcbb4-2716-4b93-975a-be74c8faf797",
                "name": "Roast_beef_with_gravy_and_chips.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "5uQ29DE6G",
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "83eEVijgw",
              "menu_id": "zu3XERQlE",
              "category_id": "IJ5WIWavn",
              "name": "Potato Scollops (3per serve)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "ebd0d8d1-071a-4bd6-b8b7-eb78710972b9",
                "name": "Bean_Origin_82.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "1wOy4d-vl",
          "menu_id": "zu3XERQlE",
          "name": " Drink Fridge",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "WqAoAmaxQ",
              "menu_id": "zu3XERQlE",
              "category_id": "1wOy4d-vl",
              "name": "Soft Drinks  Can",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "2cllCia2q"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UoK_IU4l8",
              "menu_id": "zu3XERQlE",
              "category_id": "1wOy4d-vl",
              "name": "Soft Drinks",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "7fadbWDTZ"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "qGhQgIGU8",
              "menu_id": "zu3XERQlE",
              "category_id": "1wOy4d-vl",
              "name": "330ml Sparkling Water",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "8x3nh0An4",
              "menu_id": "zu3XERQlE",
              "category_id": "1wOy4d-vl",
              "name": "Bottle Orange Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dCcnlLzoZ",
              "menu_id": "zu3XERQlE",
              "category_id": "1wOy4d-vl",
              "name": "500ml Peach Iced Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "74xTUN39s",
              "menu_id": "zu3XERQlE",
              "category_id": "1wOy4d-vl",
              "name": "600ml Water",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 3.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "qE3pAAuF4",
              "menu_id": "zu3XERQlE",
              "category_id": "1wOy4d-vl",
              "name": "Lemon Lime Bitter",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "C2Uxvy_tH",
              "menu_id": "zu3XERQlE",
              "category_id": "1wOy4d-vl",
              "name": "Ginger Beer",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "3pbWtA4Gv"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "7oiF-oCE1",
          "menu_id": "zu3XERQlE",
          "name": "Freshly Squeezed Juice",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "fA7OZi3pe3",
              "menu_id": "zu3XERQlE",
              "category_id": "7oiF-oCE1",
              "name": "Orange Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "6225bae9-2d11-4e6b-a02b-b31e476cc957",
                "name": "Bean_Origin_49.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "JVqoSNiECG",
              "menu_id": "zu3XERQlE",
              "category_id": "7oiF-oCE1",
              "name": "Orange, Kiwi and Apple",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "89a275ce-40f1-458a-8bca-2c24411959e8",
                "name": "Bean_Origin_53.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "s_10Y0_RWv",
              "menu_id": "zu3XERQlE",
              "category_id": "7oiF-oCE1",
              "name": "Watermelon, Mint, Ginger and Pear",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "f2a8922a-f83b-42b6-bcda-c196d510421e",
                "name": "Watermelon_mint_ginger_pear_juice.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "g1Z1WL_3YW",
              "menu_id": "zu3XERQlE",
              "category_id": "7oiF-oCE1",
              "name": "Spinach, Cucumber, Pear and Green Apple",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "e18d8906-5fa4-4d1e-9c27-01aa6dac0a86",
                "name": "Bean_Origin_47.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "5Wh2FEQvzk",
              "menu_id": "zu3XERQlE",
              "category_id": "7oiF-oCE1",
              "name": "Apple, Carrot and Ginger",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "07c291f5-f0ea-49d8-b04d-7ed963d25381",
                "name": "Bean_Origin_52.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "tG5MReINp",
          "menu_id": "zu3XERQlE",
          "name": "Cold Drinks",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "8hKryws3bc",
              "menu_id": "zu3XERQlE",
              "category_id": "tG5MReINp",
              "name": "Affogato",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "b8cc5fc2-b283-4c4b-8587-de6bbc1775f1",
                "name": "Affogato.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RtIOrkay0v",
              "menu_id": "zu3XERQlE",
              "category_id": "tG5MReINp",
              "name": "Iced Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "e6a3b62e-09a2-41e9-ac09-8715a6a79ec3",
                "name": "Bean_origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "AtJk3fnRkN",
              "menu_id": "zu3XERQlE",
              "category_id": "tG5MReINp",
              "name": "Iced Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "dfa4623e-00bf-452a-aa06-4dba266503f6",
                "name": "Bean_Origin_48.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "peurnKZggM",
              "menu_id": "zu3XERQlE",
              "category_id": "tG5MReINp",
              "name": "Iced Chocolate",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "f3045dc7-142a-4d99-8c33-d3bf08238c60",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UIxslGI89f",
              "menu_id": "zu3XERQlE",
              "category_id": "tG5MReINp",
              "name": "Iced mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "e5275971-5537-4a60-a215-fbc06ebd26ee",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "b06Gpuplvo",
              "menu_id": "zu3XERQlE",
              "category_id": "tG5MReINp",
              "name": "Iced Long Black",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "b2d574d6-6439-44d3-a9c9-60bc867635dc",
                "name": "Bean_Origin_51.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "CT4yf5FdO",
              "menu_id": "zu3XERQlE",
              "category_id": "tG5MReINp",
              "name": "Iced Chai",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "53a7d72e-e078-45a8-86a3-2ed2ea406fb3",
                "name": "Bean_Origin_55.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "bxXH6FyMz",
              "menu_id": "zu3XERQlE",
              "category_id": "tG5MReINp",
              "name": "Iced Matcha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "FHNg33AjD",
          "menu_id": "zu3XERQlE",
          "name": "Espresso Frappes",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "x7uAtAcP69",
              "menu_id": "zu3XERQlE",
              "category_id": "FHNg33AjD",
              "name": "Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "877fae27-f88f-413e-9717-5993f5c72c09",
                "name": "Bean_Origin_54.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "bTkpTu-9Wb",
              "menu_id": "zu3XERQlE",
              "category_id": "FHNg33AjD",
              "name": "Caramel Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "a0402fc7-5498-4061-a45d-31b7702bcd60",
                "name": "Bean_Origin_54.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "raBkaiFTX_",
              "menu_id": "zu3XERQlE",
              "category_id": "FHNg33AjD",
              "name": "Hazelnut Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "118a9c46-7c97-4af1-b67b-83d19e96bae9",
                "name": "Bean_Origin_54.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "GHpFq-M-2l",
              "menu_id": "zu3XERQlE",
              "category_id": "FHNg33AjD",
              "name": "Cafe Mocha Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "51b9573e-1d93-4af9-a316-adfae759f835",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2xdCDIes7S",
              "menu_id": "zu3XERQlE",
              "category_id": "FHNg33AjD",
              "name": "White Mocha Frappe",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "d848aa07-ef0b-44b5-a9ae-70aeb01ac998",
                "name": "Bean_Origin_55.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "klJocgwTr",
          "menu_id": "zu3XERQlE",
          "name": "Delicious Smoothies",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "QsT1FRK5UM",
              "menu_id": "zu3XERQlE",
              "category_id": "klJocgwTr",
              "name": "Mango and banana ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "4d3c37f7-7603-4084-a386-500d08b16ff1",
                "name": "Bean_Origin_44.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "OSM_ftPcQO",
              "menu_id": "zu3XERQlE",
              "category_id": "klJocgwTr",
              "name": "Mixed Berries Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "44bbe6e6-2d5f-4720-91a8-63bcc950e024",
                "name": "Bean_Origin_45.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UgpuOyQxFp",
              "menu_id": "zu3XERQlE",
              "category_id": "klJocgwTr",
              "name": "Banana and Honey Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "0b5ee4df-42a3-42d2-a490-0b890998c452",
                "name": "Bean_Origin_43.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "7YLu_Y3nup",
              "menu_id": "zu3XERQlE",
              "category_id": "klJocgwTr",
              "name": "Strawberry and Kiwi Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "60c8791e-a055-40c5-ac72-e589bd85c123",
                "name": "Bean_Origin_42.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        }
      ]
    },
    {
      "_id": "f5O6xv7Tu",
      "name": "Kids Menu",
      "display_name": "",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": false,
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "zPMN4Bktc",
          "menu_id": "f5O6xv7Tu",
          "name": "Kids Menu",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "e3crh2_f_",
              "menu_id": "f5O6xv7Tu",
              "category_id": "zPMN4Bktc",
              "name": "Kids Breakfast",
              "display_name": "",
              "description": "one-piece white toast,scrambled eggs",
              "subtitle": "",
              "price": 10.2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "5q5FtUvRu",
              "menu_id": "f5O6xv7Tu",
              "category_id": "zPMN4Bktc",
              "name": "Kids Pancake",
              "display_name": "",
              "description": "two pancakes,vanilla ice cream and maple syrup",
              "subtitle": "",
              "price": 12.2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "3pbWtA4Gv"
              ]
            },
            {
              "type": "standard",
              "_id": "Drzox9h4V",
              "menu_id": "f5O6xv7Tu",
              "category_id": "zPMN4Bktc",
              "name": "Kids Waffles",
              "display_name": "",
              "description": "one piece waffle,vanilla ice cream and maple syrup",
              "subtitle": "",
              "price": 10.2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "U9TN5OIM2"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "3pbWtA4Gv"
              ]
            },
            {
              "type": "standard",
              "_id": "0-LUTnjw2",
              "menu_id": "f5O6xv7Tu",
              "category_id": "zPMN4Bktc",
              "name": "Kids Cheese Burger",
              "display_name": "",
              "description": "brioche bun,meat patty,cheese,tomato sauce and chips",
              "subtitle": "",
              "price": 12.2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "51sLrORvv",
              "menu_id": "f5O6xv7Tu",
              "category_id": "zPMN4Bktc",
              "name": "Chicken Nuggets and Chips",
              "display_name": "",
              "description": "5 chicken nuggets,chips and tomato sauce",
              "subtitle": "",
              "price": 10.2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E1Z4OhTT3"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "3pbWtA4Gv"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        }
      ],
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    },
    {
      "_id": "rNLYgLMP9",
      "name": "Drinks Menu",
      "display_name": "",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": false,
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "jYQ749f-0",
          "menu_id": "rNLYgLMP9",
          "name": "Coffee",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "b5g4xeUUhB",
              "menu_id": "rNLYgLMP9",
              "category_id": "jYQ749f-0",
              "name": "Cappuccino",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "bc8c6de9-a08d-4b07-a906-b5a81873ba7b",
                "name": "Bean_Origin_22.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "temDXrpHgx",
              "menu_id": "rNLYgLMP9",
              "category_id": "jYQ749f-0",
              "name": "Cafe Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "7ba27d87-b951-4969-ad47-fab245a7dcbb",
                "name": "Bean_Origin_25.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ZpU6vWUky-",
              "menu_id": "rNLYgLMP9",
              "category_id": "jYQ749f-0",
              "name": "Flat White",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "f1ad2c15-03a2-4b7a-a2aa-e9aa15607fba",
                "name": "Bean_Origin_25.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "hBu55ZWkcM",
              "menu_id": "rNLYgLMP9",
              "category_id": "jYQ749f-0",
              "name": "Long Black",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "aadbe4af-fd82-49f7-bec9-db26bc9962c0",
                "name": "Bean_Origin_23.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "M2rFhdockt",
              "menu_id": "rNLYgLMP9",
              "category_id": "jYQ749f-0",
              "name": "Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "b703f7bf-acf0-45f0-ab32-a9abb40f9e0a",
                "name": "Espresso.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "gh5fKh2V51",
              "menu_id": "rNLYgLMP9",
              "category_id": "jYQ749f-0",
              "name": "Ristretto",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "763297d0-e0ea-41ab-aa67-dd31bb90def1",
                "name": "Espresso.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "lYPxUqxBQY",
              "menu_id": "rNLYgLMP9",
              "category_id": "jYQ749f-0",
              "name": "Macchiato",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "0747f8a7-7291-4b4c-bf25-692d1c9c0773",
                "name": "Bean_Origin_28.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "B0FkCxJ3cA",
              "menu_id": "rNLYgLMP9",
              "category_id": "jYQ749f-0",
              "name": "Piccolo Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "e6813e82-975c-477b-aaa6-7c63643d039e",
                "name": "Bean_Origin_29.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "fBlEVJZWD",
          "menu_id": "rNLYgLMP9",
          "name": "Hot Drinks",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "m_M7OjuHJn",
              "menu_id": "rNLYgLMP9",
              "category_id": "fBlEVJZWD",
              "name": "Hot chocolate",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "a140846b-6c88-46c5-baa9-04af41636583",
                "name": "Bean_Origin_24.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "q5dL-5-oSa",
              "menu_id": "rNLYgLMP9",
              "category_id": "fBlEVJZWD",
              "name": "Cafe Mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "4510d573-8580-4c79-afe6-5be0512249e3",
                "name": "Bean_Origin_26.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "vkRYq3j_Kr",
              "menu_id": "rNLYgLMP9",
              "category_id": "fBlEVJZWD",
              "name": "White Choc Mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "f56c9bd6-f524-4568-a1fc-1ced8b2adab7",
                "name": "Bean_Origin_26.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "7AuSyfAZk9",
              "menu_id": "rNLYgLMP9",
              "category_id": "fBlEVJZWD",
              "name": "Chai Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "61efc7ec-a3da-4610-a62b-35c3271fb73c",
                "name": "Bean_Origin_27.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "oLH3QAZh0",
              "menu_id": "rNLYgLMP9",
              "category_id": "fBlEVJZWD",
              "name": "Dirty Chai",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": {
                "_id": "b2faca73-ce62-41fd-813b-b11ffc3b7dce",
                "name": "Bean_Origin_27.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI",
                "6W85G1Mh9"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "V7kOuR8bL",
              "menu_id": "rNLYgLMP9",
              "category_id": "fBlEVJZWD",
              "name": "Tea Pot",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.5,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "E4XN1B4yE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Ay3QFGSd_",
              "menu_id": "rNLYgLMP9",
              "category_id": "fBlEVJZWD",
              "name": "Babyccino ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 2,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2dSi8NQku",
              "menu_id": "rNLYgLMP9",
              "category_id": "fBlEVJZWD",
              "name": "Matcha Chai",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.3,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "6W85G1Mh9",
                "t0xTYGbnI"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "ovbEdq6lq",
          "menu_id": "rNLYgLMP9",
          "name": "Freshly Squeezed Juice ",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "IJ2lj8TbCw",
              "menu_id": "rNLYgLMP9",
              "category_id": "ovbEdq6lq",
              "name": "Orange Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "91727f5b-b139-45d4-ab02-77c57c53039b",
                "name": "Bean_Origin_53.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "uo3_nwkypY",
              "menu_id": "rNLYgLMP9",
              "category_id": "ovbEdq6lq",
              "name": "Orange, Kiwi and Apple",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "e8e31663-9bb6-4d8e-a91c-2a9a3c38cdc5",
                "name": "Bean_Origin_49.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "5fL3dH754U",
              "menu_id": "rNLYgLMP9",
              "category_id": "ovbEdq6lq",
              "name": "Watermelon, Mint, Ginger and Pear",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "bbc4f5b3-7d7e-4b5c-9c29-3bdab7fa6e42",
                "name": "Watermelon_mint_ginger_pear_juice.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "CoI9aAyqZ_",
              "menu_id": "rNLYgLMP9",
              "category_id": "ovbEdq6lq",
              "name": "Spinach, Cucumber, Pear and Green Apple",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "1b4d153d-3de0-46b6-b6a9-e7339ccc454c",
                "name": "Bean_Origin_47.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "kLl5xyd05Q",
              "menu_id": "rNLYgLMP9",
              "category_id": "ovbEdq6lq",
              "name": "Apple, Carrot and Ginger",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "19bc9e8d-a0bf-41a6-9547-2da2dcc3002a",
                "name": "Bean_Origin_52.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "TWsoKWU9j",
          "menu_id": "rNLYgLMP9",
          "name": "Cold Drinks ",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "j_QX-Hy1_h",
              "menu_id": "rNLYgLMP9",
              "category_id": "TWsoKWU9j",
              "name": "Affogato",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "18490657-711d-45e7-937a-da2c0c083511",
                "name": "Affogato.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "t0xTYGbnI",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Y6-HSRu6JB",
              "menu_id": "rNLYgLMP9",
              "category_id": "TWsoKWU9j",
              "name": "Iced Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "eeab709c-39ac-404a-bfa6-f10f48def670",
                "name": "Bean_Origin_46.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "0bhaTZFixg",
              "menu_id": "rNLYgLMP9",
              "category_id": "TWsoKWU9j",
              "name": "Iced Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "552d43d4-0373-41ac-a9f8-fc1021ec181f",
                "name": "Bean_Origin_48.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "g69wjnbefp",
              "menu_id": "rNLYgLMP9",
              "category_id": "TWsoKWU9j",
              "name": "Iced Chocolate",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "db269b3e-9976-4e02-931b-6780fc87d1b2",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HsvxZuiYcL",
              "menu_id": "rNLYgLMP9",
              "category_id": "TWsoKWU9j",
              "name": "Iced mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "c713f24b-3e80-4997-adba-2219221240d6",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RoSvfG6a_p",
              "menu_id": "rNLYgLMP9",
              "category_id": "TWsoKWU9j",
              "name": "Iced Long Black",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "1563e479-c483-49f7-9304-77fc49e3e4b3",
                "name": "Bean_Origin_51.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RtgQ1R4Uk",
              "menu_id": "rNLYgLMP9",
              "category_id": "TWsoKWU9j",
              "name": "Iced Chai",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "3b91114e-f652-45c6-902f-327c478b4ea8",
                "name": "Bean_Origin_55.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE",
                "bmiPgtKoU"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "4FZxJHE8O",
              "menu_id": "rNLYgLMP9",
              "category_id": "TWsoKWU9j",
              "name": "Iced Matcha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "bmiPgtKoU",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "rlI8CUnfu",
          "menu_id": "rNLYgLMP9",
          "name": "Shakes",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "j0f7ocQ3mW",
              "menu_id": "rNLYgLMP9",
              "category_id": "rlI8CUnfu",
              "name": "Thickshakes",
              "display_name": "",
              "description": "BANANA, STRAWBERRY, VANILLA, CARAMEL,CHOCOLATE,LIME",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "12465ff3-9d43-44c0-b1c8-b85562873a08",
                "name": "Bean_Origin_36.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "JMJ9blCSF",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "B02toPh4A",
              "menu_id": "rNLYgLMP9",
              "category_id": "rlI8CUnfu",
              "name": "Milkshakes ",
              "display_name": "",
              "description": "BANANA, STRAWBERRY, VANILLA, CARAMEL,CHOCOLATE,LIME",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "12465ff3-9d43-44c0-b1c8-b85562873a08",
                "name": "Bean_Origin_36.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "f9Mlw8EIU",
                "JMJ9blCSF",
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "9d4x7qFEm",
          "menu_id": "rNLYgLMP9",
          "name": "Espresso Frappes ",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "uqmtYpd2vs",
              "menu_id": "rNLYgLMP9",
              "category_id": "9d4x7qFEm",
              "name": "Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "1776b394-15eb-4c76-bdc7-d6ca94a4640a",
                "name": "Bean_Origin_54.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Yk5UwjCy6E",
              "menu_id": "rNLYgLMP9",
              "category_id": "9d4x7qFEm",
              "name": "Caramel Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "be99d1a2-79c0-4d45-9629-c59ed99e59bb",
                "name": "Bean_Origin_54.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "JaGbaraFH7",
              "menu_id": "rNLYgLMP9",
              "category_id": "9d4x7qFEm",
              "name": "Hazelnut Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "f82090c4-b836-496d-87b0-65c9a3b53165",
                "name": "Bean_Origin_46.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UJZAXfgKvt",
              "menu_id": "rNLYgLMP9",
              "category_id": "9d4x7qFEm",
              "name": "Cafe Mocha Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "0391f2a0-0290-4d17-b350-b67d3c8abc68",
                "name": "Bean_Origin_50.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "B5QEYbOX6C",
              "menu_id": "rNLYgLMP9",
              "category_id": "9d4x7qFEm",
              "name": "White Mocha Frappe",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "4f155007-df93-49f7-9bce-d8f1d0f546b0",
                "name": "Bean_Origin_55.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "aUfFb5fED",
          "menu_id": "rNLYgLMP9",
          "name": "Delicious Smoothies ",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "S3t3p4lOl-",
              "menu_id": "rNLYgLMP9",
              "category_id": "aUfFb5fED",
              "name": "Mango and banana ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "e14eb8d6-856f-4116-a8f8-5638d2e51d6d",
                "name": "Bean_Origin_44.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dZ982RzIUA",
              "menu_id": "rNLYgLMP9",
              "category_id": "aUfFb5fED",
              "name": "Mixed Berries Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "424cb5e4-e21b-40ca-b4b5-cdd31d678ca6",
                "name": "Bean_Origin_45.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "T28a7hxQPi",
              "menu_id": "rNLYgLMP9",
              "category_id": "aUfFb5fED",
              "name": "Banana and Honey Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "cf707c2f-400c-4d55-bc89-1f7efe183fad",
                "name": "Bean_Origin_43.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "UhxJz-Tkg",
              "menu_id": "rNLYgLMP9",
              "category_id": "aUfFb5fED",
              "name": "Strawberry and Kiwi Smoothie",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8,
              "tags": [],
              "image": {
                "_id": "cc088423-6fa6-4bae-9802-002b87ba6de8",
                "name": "Bean_Origin_42.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "VRX3w-LEE"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        }
      ],
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    },
    {
      "_id": "us6yx49rb",
      "name": "Todays Specials",
      "display_name": "",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": false,
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "ntlABGSxt",
          "menu_id": "us6yx49rb",
          "name": "Todays  Specials",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "L_ygAWOXb",
              "menu_id": "us6yx49rb",
              "category_id": "ntlABGSxt",
              "name": "Bacon Egg Cheese Roll With Medium Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 13.5,
              "tags": [],
              "image": {
                "_id": "3563541e-c504-4d1b-84e6-3c707d666c0c",
                "name": "Bacon_and_egg_roll_with_coffee.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "GvgjT_Ayg",
                "U9TN5OIM2",
                "Xp0KZrDnb",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "sgczeKz1i",
              "menu_id": "us6yx49rb",
              "category_id": "ntlABGSxt",
              "name": "Fresh Baked Muffin With Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.5,
              "tags": [],
              "image": {
                "_id": "569d39a8-d0d6-4b54-b37e-7943ee5d4972",
                "name": "Bean_Origin_9.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "Xp0KZrDnb"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "GvheY4HJM",
              "menu_id": "us6yx49rb",
              "category_id": "ntlABGSxt",
              "name": "Ham Burger with Chips and can of Drink",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 19.9,
              "tags": [],
              "image": {
                "_id": "f7dce264-f768-46ce-849d-a4606f9b8c85",
                "name": "Beef_burger_with_drink.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "JPN-j_KFD",
              "menu_id": "us6yx49rb",
              "category_id": "ntlABGSxt",
              "name": "Chicken Schnitzel Roll with Chips and Gravy",
              "display_name": "",
              "description": "Mixed leaf,tomato,cheese ,gravy and pepper mayo ",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "c7c225ed-0a4d-4686-92da-ac7691cf0605",
                "name": "Bean_Origin110.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HD-5fY1d9",
              "menu_id": "us6yx49rb",
              "category_id": "ntlABGSxt",
              "name": "Beef Gravy Roll With Chips",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "903e5cba-c293-40f8-b376-0eb615421271",
                "name": "Beef_gravy_roll.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "5uQ29DE6G",
                "WtLqe3xyH",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "u5kj2rCCJ",
              "menu_id": "us6yx49rb",
              "category_id": "ntlABGSxt",
              "name": "Grilled Chicken Lemon Pepper Burger",
              "display_name": "",
              "description": "Mixed leaf,tomato,onion and pepper mayo",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "LWfDMUy4f",
                "WtLqe3xyH",
                "5uQ29DE6G",
                "LO3nAutVe"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        }
      ],
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    }
  ]

export const coffeeShopOptionSet = [
    {
      "_id": "JMJ9blCSF",
      "name": "Thickshakes Flavours",
      "display_name": "Choices",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "uxuuLczkN",
          "name": "Chocolate",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "91c_aKalT",
          "name": "Banana",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "1Qoah347Mx",
          "name": "Strawberry",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "mMoHS4U3_t",
          "name": "Vanilla",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "gg07e6hs2J",
          "name": "Lime",
          "price": "",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "acPQAYO9t",
          "name": "Caramel",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "F67VrKsth",
          "name": "Coffee",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "6W85G1Mh9",
      "name": "coffee Sizes",
      "display_name": "Choice of Size",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "AGz2iXsex",
          "name": "Small  (Cup)",
          "price": "",
          "quantity": 1,
          "status": null
        },
        {
          "_id": "goJnUx6Tya",
          "name": "Medium  (Mug)",
          "price": ".70",
          "quantity": 0
        },
        {
          "_id": "30b6bpIK21",
          "name": "Large (Take Away Cup Only)",
          "price": "1.30",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "t0xTYGbnI",
      "name": "Coffee Extras",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": 0,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "nFNJqN9QeQ",
          "name": "Extra Shot",
          "price": "0.6",
          "quantity": 0
        },
        {
          "_id": "UdRNM-QNA",
          "name": "Caramel",
          "price": "0.6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "5iOH-SM5W",
          "name": "Hazelnut",
          "price": "0.6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1dOL1irB-N",
          "name": "Vanilla",
          "price": "0.6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "fbCWxyJZ2G",
          "name": "Soy Milk",
          "price": "0.6",
          "quantity": 0
        },
        {
          "_id": "uJQctRaV6S",
          "name": "Almond",
          "price": "0.6",
          "quantity": 0
        },
        {
          "_id": "QUK4owNsj",
          "name": "Lactose Free",
          "price": "0.6",
          "quantity": 0
        },
        {
          "_id": "94aorkVtq",
          "name": "Oat Milk",
          "price": "0.6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "S2slzirAi",
          "name": "Skim",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "pIgVqZYos",
          "name": "Decaf",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "lGipf0CCm",
          "name": "Extra Hot",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "rxNz-tHuW",
          "name": "Warm",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "k-q1i5ViS",
          "name": "Take Away Cup",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "cUedn8v7y",
          "name": "Sugar",
          "price": "",
          "quantity": 0,
          "prices": {},
          "status": null
        },
        {
          "_id": "zbsNPKFY8",
          "name": "Equal",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "KdiKJJC1Y",
          "name": "1/2  Strength",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "miTpXYJ2-",
          "name": "1/2 Full",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1z0p8rKs9",
          "name": "3/4 Full",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "U9TN5OIM2",
      "name": "Breakfast Extras",
      "display_name": "Choice of Extra",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "g0izLGx_u",
          "name": "Sliced Fresh Tomatoes",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Uz8YRND5-",
          "name": "Mushroom",
          "price": "5",
          "quantity": 0
        },
        {
          "_id": "kYxwqzvmE",
          "name": "Smoked Salmon",
          "price": "5",
          "quantity": 0
        },
        {
          "_id": "14IvaHzkZ5",
          "name": "Grilled Haloumi",
          "price": "5",
          "quantity": 0
        },
        {
          "_id": "cGt2jxuJDI",
          "name": "Spanish Chorizo",
          "price": "4.2",
          "quantity": 0
        },
        {
          "_id": "PGRYWUSxY5",
          "name": "Grilled Tomatoes",
          "price": "4.2",
          "quantity": 0
        },
        {
          "_id": "kiQjtHPSQp",
          "name": "Spinach",
          "price": "4.2",
          "quantity": 0
        },
        {
          "_id": "WmtW_ylbp4",
          "name": "Bacon",
          "price": "4.2",
          "quantity": 0
        },
        {
          "_id": "c1s0fvOdc",
          "name": "Hashbrown",
          "price": "3",
          "quantity": 0
        },
        {
          "_id": "_XDuqygQJ",
          "name": "Avocado",
          "price": "4.2",
          "quantity": 0
        },
        {
          "_id": "emwxALZfCS",
          "name": "Egg",
          "price": "3",
          "quantity": 0
        },
        {
          "_id": "Cn5KBy59eK",
          "name": "Hollandaise Sauce",
          "price": "3",
          "quantity": 0
        },
        {
          "_id": "37SFth63T",
          "name": "Piece of Toast",
          "price": "2.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "D5XZIqtbG",
          "name": "Chicken",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "s-E2u8Bux",
          "name": "Roast Beef",
          "price": "4.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "foxMDuDWW",
          "name": "Garlic Prawns",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "DCd8X1m_Q",
          "name": "Chips",
          "price": "4.2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "bmiPgtKoU",
      "name": "Cold Drinks  Size",
      "display_name": "Choice of Size",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "wZkhJy0Uo",
          "name": "Small",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "-2DpzfcAn",
          "name": "Large",
          "price": "1",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "f9Mlw8EIU",
      "name": "Thickshake Size",
      "display_name": "Choice of Size",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "W4y5SQkXP",
          "name": "Small",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "14bDcl2OM",
          "name": "Large",
          "price": "1",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "gSTDMyJcN",
      "name": "Choice of Spread",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "Q-aKKywhD",
          "name": "Fruit Jam",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "gqgV2scxes",
          "name": "Honey",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "J2rVvXM5UG",
          "name": "Peanut Butter",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "vYwLXSRpuY",
          "name": "Vegemite",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "N7JtWqHoR",
          "name": "Butter",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "_1sw2OHko",
          "name": "No Butter",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "LO3nAutVe",
      "name": "Choice of Toast",
      "display_name": "Choices of Toast",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "RzoVcvROZ",
          "name": "Multigrain",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "-VD1oB1NF",
          "name": "Sourdough",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "okh1ezoctj",
          "name": "White",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "YyCxJkFiA",
          "name": "Turkish",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "hs7LSIySx",
          "name": "Wrap",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "APqwirzibD",
          "name": "Gluten Free",
          "price": "2",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "bcQb-k_v-",
      "name": "Egg Preparation",
      "display_name": "Choice of Egg Preparation",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "HTPSHNZn6",
          "name": "Fried",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "63I7_7BlL0",
          "name": "Poached",
          "price": "",
          "quantity": 0
        },
        {
          "_id": "Lkr7_LRTw",
          "name": "Scrambled",
          "price": "",
          "quantity": 0
        }
      ]
    },
    {
      "_id": "Xp0KZrDnb",
      "name": "choice of coffee with deal",
      "display_name": "choice of coffee",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "NSDhTEF40",
          "name": "Cappuccino",
          "price": "0.00",
          "quantity": 0
        },
        {
          "_id": "U5mrqh3rQ",
          "name": "Latte",
          "price": "0.00",
          "quantity": 0
        },
        {
          "_id": "_kcfjnd4l",
          "name": "Flat white",
          "price": "0.00",
          "quantity": 0
        },
        {
          "_id": "1SLEPEGcl",
          "name": "Hot Chocolate",
          "price": "0.00",
          "quantity": 0
        },
        {
          "_id": "SOdo_VRfh",
          "name": "long Black",
          "price": "0.00",
          "quantity": 0
        },
        {
          "_id": "Ayz0TUx1k",
          "name": "Chai Latte",
          "price": "0.00",
          "quantity": 0
        },
        {
          "_id": "r5DB6zcTi",
          "name": "Mocha",
          "price": "0.00",
          "quantity": 0
        },
        {
          "_id": "JBlxBCdFX",
          "name": "Dirty Chai",
          "price": "0.00",
          "quantity": 0
        },
        {
          "_id": "E-mEHKN9C",
          "name": "White Chocolate Mocha",
          "price": "0.00",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "fdjH2Vg5z",
          "name": "Matcha Chai",
          "price": "0.00",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "PRsTIgw2x",
          "name": "Skim",
          "price": "0.00",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "xmGknyOqh",
          "name": "Almond Milk",
          "price": "0.60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "iaMQm4i--",
          "name": "Oat Milk",
          "price": "0.60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "JuJGztVqWI",
          "name": "Lactose Free",
          "price": "0.60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "6G1bcEBbhE",
          "name": "Soy",
          "price": "0.60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "XUOHw-Ywy",
          "name": "Caramel",
          "price": "0.60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "rUrBbJLhGm",
          "name": "Vanilla",
          "price": "0.60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "GWICkI5v9P",
          "name": "Hazelnut",
          "price": "0.60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "LOfGvTmlj",
          "name": "Extra Shot",
          "price": "0.60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "tRinWz9gd",
          "name": "Decaf",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "h8iKkwt4o",
          "name": "Sugar",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "uaPnRxK1F",
          "name": "1/2 Strength",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "EV3q6ruRyR",
          "name": "Take Away Cup",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "VkHwHeJxT",
          "name": "Extra Hot",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "GvgjT_Ayg",
      "name": "bacon and egg option",
      "display_name": "Choose one",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "9I6xrK1vA",
          "name": "Normal",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "DkTE0hs63D",
          "name": "With LOT (Extra Egg and Bacon)",
          "price": "5.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "mpLtCNobB1",
          "name": "With Hashbrown",
          "price": "3",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "VRX3w-LEE",
      "name": " Cold Drinks Extras",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "eXQ3v65o-",
          "name": "Soy Milk",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "sOvQiATZE",
          "name": "Almond",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "wGsEVwfSx7",
          "name": "Lactose Free",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "BH8REaerm",
          "name": "Oat Milk",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1e3nZAC41",
          "name": "Extra Shot",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "vOaKgToWW",
          "name": "Caramel",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "uEl6J8amj",
          "name": "Vanilla",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Nxh3nOKWr",
          "name": "Hazelnut",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "6-auYi_Ev",
          "name": "Extra Flavour",
          "price": ".6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "jvQvwC-4O",
          "name": "Decaf",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "fU9Uck-2u",
          "name": "Skim",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "uGuHOtdAw",
          "name": "No Coffee",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "tvdqBpYlK",
          "name": "Take Away Cup",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "V86cdUEIP",
          "name": "No Cream",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "P-GPXek7c",
          "name": "No Ice Cream",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "5CP80jB2G",
          "name": "No Ice",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "_xLZrxnZ6",
          "name": "1/2 Strength",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "LWfDMUy4f",
      "name": "Burger Choice",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "lHAJ9Vzia",
          "name": "Normal",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "hMX5Pu04HA",
          "name": "With LOT (Extra Egg and Bacon)",
          "price": "5.2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "WtLqe3xyH",
      "name": "Burger Extras",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "f_k6qj9GT",
          "name": "Avocado",
          "price": "4.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "j5SWWW_q0F",
          "name": "Bacon",
          "price": "4.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "egq0sCxN6K",
          "name": " Cheese",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "hfj8ahpOwG",
          "name": "Grilled Onions",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "r25UndV6M",
          "name": "Gluten Free Bread",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "mbDIAmtnZy",
          "name": "Gravy",
          "price": "3.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "CxabIU8Zv",
          "name": " Egg",
          "price": "3",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "_O1y0JwjD",
          "name": " Chicken",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "SBZhn-f02",
          "name": "Piece Of Steak",
          "price": "8",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "E9vfjcFPU",
          "name": " Beef Patty",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Rnnrw1jSu",
          "name": "Chips",
          "price": "4.2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "5uQ29DE6G",
      "name": "Sides Extras",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "fa0dNlhKx",
          "name": "Gravy",
          "price": "3.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "gEy9Mr4pXE",
          "name": " Garlic Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "m3ns5Rvazy",
          "name": "Basil Mayo",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "fk2JD9nea",
          "name": "Peri Peri Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1H5nYrXYtl",
          "name": "Sweet Chilli Mayo",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "NZYoOP6EnT",
          "name": "Sweet Mustard Mayo",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "DLcFTap5x",
          "name": "Ranch Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "_QcOVWKC_",
          "name": "Caesar Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "LvVqEdqIW",
          "name": "Tomato Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "H2SkQkPbh",
          "name": "BBQ Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "pUGtvrRcU",
          "name": "Hollandaise Sauce",
          "price": "3",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "IMRJX3pb-",
          "name": "Tarte Sauce",
          "price": "2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "bpMISSDLt",
          "name": "Sour Cream",
          "price": "2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "XA9TEiLOn",
      "name": "Choice of Toast",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "9zSVOn0dZ",
          "name": "Multigrain",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "BiPu1SV6As",
          "name": "Sourdough",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "ZZ2DbpvYwk",
          "name": "White",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "nWqwMoqNt_",
          "name": "Turkish",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "iiHwz_cKy",
          "name": "Wrap",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "7KXQff1uV",
          "name": "Gluten Free Bread",
          "price": "2",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "E1Z4OhTT3",
      "name": "Lunch Extras",
      "display_name": "Extras",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "3F8arh1BG",
          "name": "Add Chips",
          "price": "4.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "PH3OorcaKg",
          "name": "Add Salad",
          "price": "4.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "v6d37HN9PP",
          "name": "Gravy",
          "price": "3.2",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "e9zt7jCrp",
          "name": "Chicken",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "GCb_BTadd",
          "name": "Piece Of Steak ",
          "price": "8",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "GG3Wcr_IR",
          "name": "Beef Patty",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "iCDKeGo2G",
          "name": "Garlic Prawns",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "jWLCJIc6I",
          "name": "Egg",
          "price": "3",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "E4XN1B4yE",
      "name": "Tea Extras",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "_V5eJW24K",
          "name": "English Breakfast",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "U-QgdZma11",
          "name": "Green",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "aaYI2Wlqz5",
          "name": "Peppermint",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Z03-lup_0",
          "name": "Chamomile",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "V4DihsU4lW",
          "name": "Lemongrass Ginger",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "xXleiL_Kv",
          "name": "Chai Tea",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Vjn1HRpwa",
          "name": "Earl Grey",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "eaD6kkc9z",
          "name": "Full Cream Milk",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "UVIYke3Rnf",
          "name": "Skim Milk",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "W3EvvqpceJ",
          "name": "Soy",
          "price": ".60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "VAmmLYWrn",
          "name": "Lactose free",
          "price": ".60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "3-giByoJ7u",
          "name": "Almond",
          "price": ".60",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "VO0r4Yqlo",
          "name": "Oat",
          "price": ".60",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "KDYOLtWPg",
      "name": "Eggs Benedict Option",
      "display_name": "Choose One",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "4s4jZthPt",
          "name": "Bacon",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "MRlTwjmZU1",
          "name": "Ham",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "7fadbWDTZ",
      "name": "Soft Drinks ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "wHkqdzwv2",
          "name": "Coke ",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "h0fSgopY5",
          "name": "No Sugar Coke",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "aZqlOtqLM",
          "name": "Vanilla Coke",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "ZpScB0yK_",
          "name": "Sprite",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "7TPXULKou",
          "name": "Fanta",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "2dd7TvATH",
          "name": "Powerade",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "1R4veDcZ7",
          "name": "Mother Energy Drink",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "2cllCia2q",
      "name": "Soft Drinks Can ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "JvxZbOChh",
          "name": "Coke ",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "YLJvrR92Tf",
          "name": "No Sugar",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "T2s1R6zCD",
          "name": "Sprite",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "k93obFGdp",
          "name": "Fanta",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    }
  ];


function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createCoffeeShopMenu = () => {
    return {
        coffeeShopMenu:updateIds(coffeeShopMenu), coffeeShopOptionSet:updateIds(coffeeShopOptionSet)
    }
}

