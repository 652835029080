import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { Switch } from "@lib/components";
import { Textarea } from "@lib/components";

interface Props { }
interface State { }
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["about_us"] & { features_text: string; };

@inject("store") @observer
export class WebsiteFormAboutUs extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const w = this.injected.store.website!;
		this.state = {};
		this.initialValues = {
			...w.sections.about_us,
			features_text: w.sections.about_us.features.reduce((t, a, i) => {
				if (i === 0) return t + a;
				return `${t}\n${a}`
			}, ""),
		};
	}

	render() {
		return (
			<WebsiteForm<FormValues>
				submit={async (w, values) => {

					values.features = (values.features_text || "").split("\n")

					if (!values.features[0]) {
						values.features = [];
					}

					w.sections.about_us = values;

					// @ts-ignore
					delete w.sections.about_us.features_text;

					const update = { $set: { "sections.about_us": values } };

					return { w, update };

				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="Hide Section">
										<Switch
											id="section.hidden"
											checked={field.value}
											onChange={(e) => setFieldValue("hidden", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="side_by_side"
								render={({ field }: any) => (
									<FormGroup title="Side By Side Layout">
										<Switch
											id="section.side_by_side"
											checked={field.value}
											onChange={(e) => setFieldValue("side_by_side", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="text"
								render={({ field }: any) => (
									<FormGroup title="Text">
										<Textarea {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="features_text"
								render={({ field }: any) => (
									<FormGroup title="Feature Points" help="One per line, we recommend you write 6 points">
										<Textarea {...field} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</WebsiteForm>
		);
	}

}
