import React, { useEffect, useMemo, useState } from 'react';
import _flatten from 'lodash/flatten';
import { MobXProviderContext } from 'mobx-react';
import {
  FastField,
  Field,
  FormikProps,
  FastFieldProps,
  FieldProps,
} from 'formik';

import { RestaurantUtils } from '@lib/common';
import { FreeItemPromo, ConventionalDiscountPromo } from '../promos/type';
import {
  FormGroup,
  ButtonGroup,
  FormTreeSelect,
  SelectAdv,
  InputGroup,
  Input,
  Switch,
} from '@lib/components';

type Props = {
  form: FormikProps<FreeItemPromo | ConventionalDiscountPromo>;
  getFieldError: (
    form: FormikProps<FreeItemPromo | ConventionalDiscountPromo>,
    field: string
  ) => T.ObjectAny | string | undefined | null;
};

const getDeals = (store: any, brandId: string) => {
  return store.api.getWalletlyDeal({
    brandId
  });
};

export function ConditionDiscountPromoTab({ form, getFieldError }: Props) {
  const { store } = React.useContext(MobXProviderContext);
  const [deals, setDeals] = useState([]);
  const loyalty_providers: T.Schema.Restaurant.RestaurantSettings['loyalty_providers'] = store.restaurant.settings?.loyalty_providers;
  const menuTree = useMemo(() => {
    return RestaurantUtils.menu.getTreeFilter(store.restaurant!, () => true);
  }, [store.restaurant]);

  useEffect(() => {
    if (loyalty_providers?.walletly?.enabled && loyalty_providers?.walletly?.brand_id) {
      getDeals(store, loyalty_providers?.walletly?.brand_id).then((data: any) => {
        if (data.data) setDeals(data.data);
      });
    }
  }, []);
  // @ts-ignore

  const applyTo = form.values.condition.apply_to;
  const discountType = form.values.condition.type;
  const currency = store.intl.s.currency;
  const normalizeTreeData = (input: any) => {
    if (!input) return [];

    const restaurant: T.Schema.Restaurant.RestaurantSchema = store.restaurant!;
    const menus = restaurant.menus;
    let dishIds: string[] = [];

    for (const menuId of Object.keys(input)) {
      const selection = input[menuId];
      const menu = menus.find(m => m._id === menuId);
      if (!menu) continue;

      if (selection.menu.length > 0) {
        menu.categories.forEach(category =>
          category.dishes.forEach(dish => dishIds.push(dish._id))
        );
        continue;
      }

      if (selection.category.length > 0) {
        menu.categories.forEach(category => {
          if (selection.category.includes(category._id)) {
            category.dishes.forEach(dish => dishIds.push(dish._id));
          }
        });
      }

      if (selection.dish.length > 0) {
        dishIds = dishIds.concat(selection.dish);
      }
    }

    return dishIds;
  };

  return (
    <div className="p-4">
      <Field name="condition.type">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Type"
            help="What kind of discount is it?"
            error={getFieldError(form, 'condition.type')}
          >
            <ButtonGroup
              size={'xs'}
              selected={field.value}
              options={[
                {
                  value: 'percentage',
                  name: 'Percentage',
                },
                {
                  value: 'fixed_amount',
                  name: 'Fixed Amount',
                },
              ]}
              buttonClassName="p-lr-2"
              onSelect={v => form.setFieldValue('condition.type', v.value)}
              width="auto"
            />
          </FormGroup>
        )}
      </Field>

      <Field
        name="condition.discount_value"
        render={({ field }: any) => (
          <FormGroup
            title="Discount Value"
            help="How much discount will be given"
            error={getFieldError(form, 'condition.discount_value')}
          >
            <InputGroup
              iconHtml={discountType === 'percentage' ? <p className="font-semi-bold">%</p> : <p className="font-semi-bold">{currency.symbol}</p>}            >
              <Input type="number" step={currency.step} min={0} {...field} />
            </InputGroup>
          </FormGroup>
        )}
      />

      <Field name="condition.apply_to">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Apply To"
            help="Where the discount will be applied?"
            error={getFieldError(form, 'condition.type')}
          >
            <ButtonGroup
              className="m-b-4"
              size={'xs'}
              selected={field.value}
              options={[
                {
                  value: 'specific_products',
                  name: 'Specific Products',
                },
                {
                  value: 'order_amount',
                  name: 'Order Amount',
                },
              ]}
              buttonClassName="p-lr-2"
              onSelect={v => form.setFieldValue('condition.apply_to', v.value)}
              width="auto"
            />
          </FormGroup>
        )}
      </Field>

      {applyTo === 'specific_products' && (
        <FastField name="condition.eligible_items">
          {({ field }: FieldProps) => (
            <FormGroup
              title="Eligible Items"
              help="Select items to which the discount will apply. If there is no selection, the discount will apply to all items."
              error={getFieldError(form, 'condition.eligible_items')}
            >
              <FormTreeSelect
                id={`eligible_items`}
                selected={normalizeTreeData(field.value)}
                nodes={menuTree}
                typePriority={['menu', 'category', 'dish']}
                onChange={(_, prioritizedSelected) =>
                  form.setFieldValue(
                    'condition.eligible_items',
                    prioritizedSelected
                  )
                }
              />
            </FormGroup>
          )}
        </FastField>
      )}
      {applyTo === 'specific_products' && (
        <FastField name="condition.apply_multiple">
          {({ field }: FastFieldProps) => (
            <FormGroup
              title="Apply Multiple"
              help="In case of multiple applicable items, turning this off will only apply the discount to one line item with the highest price. Default setting is on."
              error={getFieldError(form, 'condition.apply_multiple')}
            >
              <Switch
                id="condition.apply_multiple"
                checked={field.value === undefined ? true : field.value}
                onChange={e =>
                  form.setFieldValue(
                    'condition.apply_multiple',
                    e.target.checked
                  )
                }
              />
            </FormGroup>
          )}
        </FastField>
      )}

      <Field
        name="condition.deal"
        render={({ field }: any) => (
          <FormGroup
            optional={true}
            title="Walletly Deal"
            help="Select the deal to link this. Prior setup of a deal in Walletly is required."
          >
            <SelectAdv
              type="single"
              value={field.value}
              onChange={(options: string) => {
                form.setFieldValue('condition.deal', options);
              }}
              options={deals.map((deal: any) => {
                return {
                  label: deal.name,
                  value: deal._id,
                };
              })}
            />
          </FormGroup>
        )}
      />
    </div>
  );
}
