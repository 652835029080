import { observable, action } from "mobx";
import autobind from "autobind-decorator";
import { RootStore } from "../store";
import { CoreUtils } from "@lib/common";
import { darken } from "polished";

interface ThemeGenerateOpts {
	primary: string;
}

@autobind
export class ThemeStore {

	store: RootStore;
	@observable s: T.Core.BaseTheme.ThemeInterface;

	constructor(store: RootStore) {
		this.store = store;
		this.s = this.generate({ primary: "#333" });
	}

	@action init = (reseller: T.Schema.Reseller.ResellerSchema) => {

		this.s = this.generate({
			primary: reseller.admin.colors.primary,
		});

		const fc = reseller.admin.favicon_code;

		if (fc && fc.length > 0) {
			const faviconString = fc.reduce((a, v) => a += v, "");
			document.head.insertAdjacentHTML("beforeend", faviconString);
		}

	}

	generate = (opts: ThemeGenerateOpts): T.Core.BaseTheme.ThemeInterface => {
		return {
			font: {
				header: "Lato,Inter,Verdana,Helvetica,sans-serif;",
				body: "Lato,Inter,Verdana,Helvetica,sans-serif;",
				size: 14,
			},

			colors: {
				primary: opts.primary, // "#ee5252"
				primary_text: "white",
				background: "#333",
				text: "#383838",
				success: "#51a351",
				warning: "#f89406",
				error: "#bd362f",
			},

			color: {
				primary: opts.primary, // "#ee5252"
				primary_text: "white",
				background: "#333",
				text: "#383838",
				success: "#51a351",
				warning: "#f89406",
				error: "#bd362f",
			},

			status_colors: {
				complete: "#51A351",
				onroute: "#00BFFF",
				ready: "#0080FF",
				confirmed: "#B7D024",
				unconfirmed: "#efbb1e",
				awaiting_payment: "#efbb1e",
				cancelled: "#BD362F",
			},

			button: {
				height: 38,
			},

			box: {
				background: "white",
				text: "#212121",
				border: darken(0.16, "white"),
			},

			input: {
				background: "white",
				text: "#212121",
				border: darken(0.16, "white"),
				height: 38,
				fontSize: 14,
			},

			top_nav: {
				zindex: 10,
				height: 58,
				background_color: "white",
				text_color: "#383838",
			},

			side_nav: {
				zindex: 9,
				width: 70,
				background_color: "white",
				text_color: "#383838",
			},

			slide_panel: {
				zindex: 9,
				background_color: "white",
				text_color: "#383838",
			},

			footer: {
				background: "white",
				text: "#212121",
			},

			content_width: {
				xs: 360,
				sm: 480,
				md: 720,
				lg: 1140,
				xl: 1360,
			},

			breakpoints: CoreUtils.ui.breakpoints,
		};
	}

}
