import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components/index";
import { withTranslation, WithTranslation } from "react-i18next";
import { logger } from "@lib/common";
import { UI } from "../../../../../core/ui";
import { RotateLoader } from "@lib/components";
import { Button } from "@lib/components";
import { BlockInfo } from "@lib/components";
import { Modal } from "@lib/components";
import { ModalContent, ModalTitle } from "@lib/components";
import { APIForm } from "./form";
import { ItemListWrapper, ItemListContent, ItemListItem } from "@lib/components";
import { FaTrashAlt } from "react-icons/fa";
import { Tag } from "@lib/components";
import { LinkTag } from "@lib/components";

interface Props extends WithTranslation { }
interface State {
	modal_active: boolean;
	modal_index: number;
	delete_index: number | null;
	delete_loading: boolean;
}

@inject("store") @observer
class DashboardAPIClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			modal_active: false,
			modal_index: -1,
			delete_index: null,
			delete_loading: false,
		};
	}

	componentWillMount() {
		const { store } = this.injected;
		store.updateApis({ loading: true, error: "" });
	}

	componentDidMount() {
		this.get();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (this.state.modal_active && !prevState.modal_active) {
			const modal = document.getElementById("api-form-modal");
			if (modal) {
				setTimeout(() => {
					if (modal.scroll) {
						modal.scroll({ top: 0, left: 0, behavior: "auto" });
					}
				}, 100);
			}
		}
	}

	get = async () => {
		const { store } = this.injected;
		store.updateApis({ loading: true, error: "", items: [] });
		try {
			const response = await store.api.api_find({});
			const response2 = await store.api.restaurants();
			if (response.outcome || response2.outcome) {
				store.updateApis({
					loading: false,
					error: response.outcome ? response.message : "Something went wrong, try again or contact us",
					items: [],
				});
			}
			else {
				store.updateRestaurants({
					items: response2.items,
				});
				store.updateApis({
					loading: false,
					error: "",
					items: response.items,
				});
			}
		}
		catch (e) {
			logger.captureException(e);
			store.updateApis({
				loading: false,
				error: "Something went wrong, try again or contact us",
				items: [],
			});
		}
	}

	delete_api = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		const { items } = store.apis;
		try {
			this.setState({ delete_loading: true });
			const res = await store.api.api_delete({ _id: items[index]._id });
			if (res.outcome) {
				UI.notification.error(res.message);
				this.setState({ delete_loading: false });
			}
			else {
				UI.notification.success("API deleted");
				const newItems = [...items];
				this.setState({
					delete_index: res.outcome ? index : null,
					delete_loading: false,
				});
				newItems.splice(index, 1);
				store.updateApis({ items: newItems });
			}
		}
		catch (e) {
			logger.captureException(e);
			this.setState({ delete_loading: false });
		}
	}

	loading = () => {
		return (
			<div className="m-t-12">
				<RotateLoader size={3} />
			</div>
		);
	}

	error = () => {
		const { store } = this.injected;
		const { error } = store.apis;
		return (
			<div className="m-t-12 text-center">
				<p className="big error-text m-b-4">{error}</p>
				<Button color="primary" size="sm" onClick={this.get}>
					Try Again
				</Button>
			</div>
		);
	}

	content = () => {
		const { store } = this.injected;
		const { items } = store.apis;
		const { restrictions } = store;
		return (
			<div>

				<div className="flex-l-r-center m-b-7">
					<div>
						<h2 className="">API Keys</h2>
						{store.showMainSupport && <LinkTag href="https://apidocs.cloudwaitress.com" target="_blank" className="block m-t-2">View Documentation</LinkTag>}
					</div>
					{restrictions.api && restrictions.api.create && (
						<Button
							color="primary"
							round={true}
							size="xs"
							onClick={() => this.setState({ modal_active: true, modal_index: -1 })}>
							Create API Key
						</Button>
					)}
				</div>

				{items.length === 0 && (
					<div className="text-center m-t-12">
						<BlockInfo className="flex-center max200 center">
							No api keys found
						</BlockInfo>
					</div>
				)}

				{items.length !== 0 && (
					<ItemListWrapper>
						<ItemListContent>
							{items.map((s, i) => (
								<ItemListItem key={i} onClick={() => {
									if (restrictions.api && restrictions.api.edit) {
										this.setState({ modal_index: i, modal_active: true });
									}
								}}>
									<div className="p-lr-4 p-tb-2">
										<p className="m-b-1 big font-semi-bold">{s.name}</p>
										<p className="small">API Key <Tag onClick={(e) => e.stopPropagation()} className="cursor-text m-l-1 p-lr-2">{s.api_key}</Tag></p>
									</div>
									<div>
										<Button
											type="button"
											color="white"
											className="no-border"
											style={{ height: "62px" }}
											paddinglr={8}
											onClick={(e) => {
												if (restrictions.api && restrictions.api.delete) {
													e.stopPropagation();
													this.setState({ delete_index: i });
												}
											}}>
											{restrictions.api && restrictions.api.delete && <FaTrashAlt />}
										</Button>
									</div>
								</ItemListItem>
							))}
						</ItemListContent>
					</ItemListWrapper>
				)}

			</div>
		);
	}

	modal = () => {
		const { store } = this.injected;
		const { items } = store.apis;
		const { modal_active, modal_index } = this.state;

		const type = modal_index === -1 ? "create" : "edit";

		let edit_id = "";
		let initialValues: T.Schema.API.APICESchema = {
			name: "",
			restaurants: [],
		};

		if (type === "edit") {
			const found = items[modal_index];
			edit_id = found._id;
			initialValues = {
				name: found.name,
				restaurants: found.restaurants,
			};
		}

		return (
			<Modal
				id="api-form-modal"
				width={520}
				active={modal_active}
				alignTop={true}
				close={() => this.setState({ modal_active: false, modal_index: -1 })}>
				<ModalTitle className="round-top text-center">
					<h3>{type === "create" ? "Create New Key" : "Edit Key"}</h3>
				</ModalTitle>
				<ModalContent>
					<APIForm
						edit_id={edit_id}
						restaurants={store.restaurants.items.map((r) => ({ value: r._id, label: r.name }))}
						initialValues={initialValues}
						back={() => this.setState({ modal_active: false, modal_index: -1 })}
					/>
				</ModalContent>
			</Modal>
		);

	}

	modal_delete = () => {
		const { delete_index, delete_loading } = this.state;
		const { store } = this.injected;
		const { restrictions } = store;
		const { items } = store.apis;

		let active = false;
		let item;
		if (delete_index !== null) {
			active = true;
			item = items[delete_index];
		}

		if (!restrictions.api || !restrictions.api.delete) {
			return null;
		}

		return (
			<Modal
				width="sm"
				close={() => this.setState({ delete_index: null })}
				active={active}>
				<ModalContent>
					<h4 className="m-b-1">Delete API Key</h4>
					<p className="underline">{item ? item.name : ""}</p>
				</ModalContent>
				<ModalContent>
					<p className="lhp big">Are you sure you want to delete this api key. Once deleted, it cannot be recovered</p>
				</ModalContent>
				<ModalContent className="flex-right">
					<Button className="m-r-2 width100 max128" onClick={() => this.setState({ delete_index: null })}>Cancel</Button>
					<Button className="width100 max100px" color="primary" onClick={() => this.delete_api(delete_index)}>
						{delete_loading && <RotateLoader size={2} color="white" />}
						{!delete_loading && "Delete"}
					</Button>
				</ModalContent>
			</Modal>
		);
	}

	render() {
		const { store } = this.injected;
		const { loading, error } = store.apis;
		return (
			<div>
				{loading && this.loading()}
				{(!loading && error) && this.error()}
				{(!loading && !error) && this.content()}
				{this.modal()}
				{this.modal_delete()}
			</div>
		);
	}

}

export const DashboardAPI = withTranslation()(DashboardAPIClass);
