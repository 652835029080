import * as React from "react";
import { AuthLayout } from "../layout";
import { BoxHeading, BoxSection } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../mobx/components/index";
import { FormGroupIcon } from "@lib/components";
import { Input } from "@lib/components";
import { Button } from "@lib/components";
import { Link } from "../../../components/router/Link";
import { UI } from "../../../../core/ui";
import { validators } from "@lib/common";
import { logger } from "@lib/common";
import { FaLock } from "react-icons/fa";

interface Props { }
interface State {
	loading: boolean;
	password: string;
	confirm_password: string;
}

@inject("store") @observer
export class AuthPasswordResetChange extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false,
			password: "",
			confirm_password: "",
		};
	}

	load = (loading: boolean) => {
		this.setState({ loading });
	}

	onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		// VARS
		e.preventDefault();
		const { store } = this.injected;
		try {

			const { password, confirm_password } = this.state;

			const { token, _id } = store.router.s.query;

			if (validators.password(password).error) {
				return UI.notification.error("Minimum password length is 5 characters");
			}
			else if (password !== confirm_password) {
				return UI.notification.error("Passwords do not match");
			}

			this.load(true);

			const data = await store.api.user_password_reset_change({ _id, token, password });

			this.load(false);

			if (data.outcome) {
				UI.notification.error(data.message, { timeout: 6000 });
			}
			else {
				UI.notification.success("Password changed successfully", { timeout: 6000 });
				store.router.push("/login");
			}

		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, try again or contact us", { timeout: 6000 });
			this.load(false);
		}
		return null;
	}

	onChange = <T extends keyof State>(e: React.ChangeEvent<HTMLInputElement>) => {
		const newState = UI.helpers.handleChange(e);
		this.setState(newState as { [P in T]: State[P]; });
	}

	render() {
		const { loading, password, confirm_password } = this.state;
		const { query } = this.injected.store.router.s;
		const valid = !!query.token && !!query._id;
		return (
			<AuthLayout loading={loading}>
				<BoxHeading className="text-center">
					<h3>Reset Password</h3>
				</BoxHeading>
				{!valid && (
					<BoxSection>
						<p className="big lhp text-center">Invalid password reset link. Please follow the link in the email, request another reset link or contact us</p>
					</BoxSection>
				)}
				{valid && (
					<form onSubmit={this.onSubmit}>

						<BoxSection>

							<FormGroupIcon icon={<FaLock />} no_border={true}>
								<Input
									type="password"
									name="password"
									placeholder="Password"
									value={password}
									onChange={this.onChange}
									required={true}
								/>
							</FormGroupIcon>

							<FormGroupIcon icon={<FaLock />} no_border={true}>
								<Input
									type="password"
									name="confirm_password"
									placeholder="Confirm Password"
									value={confirm_password}
									onChange={this.onChange}
									required={true}
								/>
							</FormGroupIcon>

							<Button color="primary" type="submit" full={true}>
								Reset Password
							</Button>

						</BoxSection>

						<BoxSection className="text-center">
							<p>Remember your password - <Link to="/login">Login</Link></p>
						</BoxSection>

					</form>
				)}
			</AuthLayout>
		);
	}

}
