import shortid from 'shortid';

export const dimsum_menu = [{
      "_id": "k16p5KZe2",
      "name": "Dimsum Menu",
      "display_name": "",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": false,
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "bqI768pvB",
          "menu_id": "k16p5KZe2",
          "name": "Dumplings",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "Y2nJLYY5x",
              "menu_id": "k16p5KZe2",
              "category_id": "bqI768pvB",
              "name": "Har Gau Dumplings",
              "display_name": "",
              "description": "Steamed. Prawn & bamboo shoot. 4 pcs.",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "19ecd8f5-5ef9-4873-911a-6807673b3eb2",
                "name": "Har_Gau_Dumplings.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "eTGtJxDmW",
                "fhSy5Tr_r"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "g0ONhWXFA",
              "menu_id": "k16p5KZe2",
              "category_id": "bqI768pvB",
              "name": "Chicken Siu Mai",
              "display_name": "",
              "description": "Steamed. 4 pcs.",
              "subtitle": "",
              "price": 17,
              "tags": [
                "sbuiIJlzus"
              ],
              "image": {
                "_id": "02f23502-a01c-46a6-a11b-1236828bac3a",
                "name": "Chicken_Siu_Mai.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "C3tkjiTup",
              "menu_id": "k16p5KZe2",
              "category_id": "bqI768pvB",
              "name": "Chicken Lemongrass Dumplings",
              "display_name": "",
              "description": "Steamed. 4 pcs.",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": {
                "_id": "8cbc0ec5-b840-4560-a0b1-fba4910b65b5",
                "name": "Chicken_Lemongrass_Dumplings.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3Jj_I6WUu",
              "menu_id": "k16p5KZe2",
              "category_id": "bqI768pvB",
              "name": "Prawn & Kimchi Dumplings",
              "display_name": "",
              "description": "Steamed. 4 pcs.",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "7bae35d0-86be-4af4-9b82-bb74befc8345",
                "name": "Prawn___Kimchi_Dumplings.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ek9iWSy5b",
              "menu_id": "k16p5KZe2",
              "category_id": "bqI768pvB",
              "name": "Mix Veggie Dumplings",
              "display_name": "",
              "description": "Steamed. 4 pcs.",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": {
                "_id": "416c0fc6-c0b2-48bb-b6f7-3989b3b1f81b",
                "name": "Mix_Veggie_Dumplings.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "J7dAMcusWK",
              "menu_id": "k16p5KZe2",
              "category_id": "bqI768pvB",
              "name": "Crab, Scallop & Prawn Dumplings",
              "display_name": "",
              "description": "Steamed. 4 pcs.",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "5310a1fa-8b9a-4081-ae85-a6aed2c8c5ad",
                "name": "Crab__Scallop___Prawn_Dumplings.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "mZ-gXh0Yjm",
              "menu_id": "k16p5KZe2",
              "category_id": "bqI768pvB",
              "name": "Lobster, Ginger & Shallot Dumplings",
              "display_name": "",
              "description": "Steamed. 4 pcs.",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": {
                "_id": "04f3a806-c5a3-49ea-aa51-6f82a68026da",
                "name": "Lobster__Ginger___Shallot_Dumplings.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "vWtqC3z51Q",
              "menu_id": "k16p5KZe2",
              "category_id": "bqI768pvB",
              "name": "Pan Fried Pork Gyoza",
              "display_name": "",
              "description": "Fried. 4 pcs.",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "51f35060-010a-4767-bc2e-3929a2b4daf2",
                "name": "Pan_Fried_Pork_Gyoza.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "gjPJi8-79",
          "menu_id": "k16p5KZe2",
          "name": "Bao Buns",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "gYwW_ixkO",
              "menu_id": "k16p5KZe2",
              "category_id": "gjPJi8-79",
              "name": "Pork Belly Bao",
              "display_name": "",
              "description": "Pickled cucumber, coriander, hoisin sauce. 3 pcs.",
              "subtitle": "",
              "price": 21,
              "tags": [],
              "image": {
                "_id": "2c95765a-d8eb-4341-ba51-e39840b4d346",
                "name": "Pork_Belly_Bao.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "U92xQMgXD",
              "menu_id": "k16p5KZe2",
              "category_id": "gjPJi8-79",
              "name": "Crisp Soft Shell Crab Bao",
              "display_name": "",
              "description": "Chilli tamarind, coriander, mayo. 3 pcs.",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "a453deb1-b04b-4230-a77b-44558e3ed1e2",
                "name": "Crisp_Soft_Shell_Crab_Bao.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "TLb7cjYYX",
              "menu_id": "k16p5KZe2",
              "category_id": "gjPJi8-79",
              "name": "Karaage Chicken Bao",
              "display_name": "",
              "description": "Chilli soy mayo, cabbage. 3 pcs.",
              "subtitle": "",
              "price": 21,
              "tags": [
                "sbuiIJlzus"
              ],
              "image": {
                "_id": "fb1d0bc2-a825-41d7-92d2-8f52b50060f6",
                "name": "Karaage_Chicken_Bao.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "yievcnhSW",
              "menu_id": "k16p5KZe2",
              "category_id": "gjPJi8-79",
              "name": "Pulled Duck",
              "display_name": "",
              "description": "Shallots, cabbage & coriander, hoisin sauce. 3 pcs.",
              "subtitle": "",
              "price": 22,
              "tags": [],
              "image": {
                "_id": "ebc85329-d06f-4296-be53-d3e67dab7dbd",
                "name": "Pulled_Duck.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "uuXUI6NvP",
          "menu_id": "k16p5KZe2",
          "name": "Extras",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "ZsJKBDX9j",
              "menu_id": "k16p5KZe2",
              "category_id": "uuXUI6NvP",
              "name": "Karaage Chicken",
              "display_name": "",
              "description": "With black garlic chilli aioli & lime",
              "subtitle": "",
              "price": 25,
              "tags": [
                "sbuiIJlzus"
              ],
              "image": {
                "_id": "81a6d601-bd3f-469a-9611-953246b02c95",
                "name": "Karaage_Chicken.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YroXVpB7L",
              "menu_id": "k16p5KZe2",
              "category_id": "uuXUI6NvP",
              "name": "Aromatic Duck Spring Roll",
              "display_name": "",
              "description": "4 pcs.",
              "subtitle": "",
              "price": 18,
              "tags": [
                "sbuiIJlzus"
              ],
              "image": {
                "_id": "596db120-5ad6-402d-a4fc-3205c0f32a06",
                "name": "Aromatic_Duck_Spring_Roll.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "JmSEJ_Azd",
              "menu_id": "k16p5KZe2",
              "category_id": "uuXUI6NvP",
              "name": "Mixed Veggie Spring Roll",
              "display_name": "",
              "description": "4 pcs.",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "1b13fe5a-1ac4-413f-b36d-4d9e7a84c1dc",
                "name": "Mixed_Veggie_Spring_Roll.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "F0VEpwvj_",
          "menu_id": "k16p5KZe2",
          "name": "Dessert",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "PCsPnI2nC",
              "menu_id": "k16p5KZe2",
              "category_id": "F0VEpwvj_",
              "name": "Nutella Dumplings",
              "display_name": "",
              "description": "4 pcs.",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "6029de6c-9624-49ce-b02e-4308b532557d",
                "name": "Nutella_Dumplings.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "dc-E8WHnm",
              "menu_id": "k16p5KZe2",
              "category_id": "F0VEpwvj_",
              "name": "Pineapple & Coconut Dumpling",
              "display_name": "",
              "description": "4 pcs.",
              "subtitle": "",
              "price": 18,
              "tags": [
                "sbuiIJlzus"
              ],
              "image": {
                "_id": "62265e1c-ff4e-4fda-b0c8-66d95441b168",
                "name": "Pineapple___Coconut_Dumpling.jfif",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "hBARHUq_3",
              "menu_id": "k16p5KZe2",
              "category_id": "F0VEpwvj_",
              "name": "Duo Duo Pandan & Coconut Deep Fried Ice Cream",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 20,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        }
      ],
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    }]

export const dimsum_option_sets = [
  {
    "_id": "eTGtJxDmW",
    "name": "Sizes",
    "display_name": "",
    "show_in_menu": false,
    "conditions": {
      "required": true,
      "multi_select": false,
      "quantity_select": false,
      "min_options": "",
      "max_options": "",
      "free_amount": ""
    },
    "options": [
      {
        "_id": "WsyQFUvJh",
        "name": "S",
        "price": "",
        "quantity": 0,
        "prices": {}
      },
      {
        "_id": "T-RoT7ycV7",
        "name": "M",
        "price": "",
        "quantity": 0,
        "prices": {}
      },
      {
        "_id": "N18x4tFZp",
        "name": "L",
        "price": "",
        "quantity": 0,
        "prices": {}
      }
    ]
  },
  {
    "_id": "fhSy5Tr_r",
    "name": "Extras",
    "display_name": "",
    "show_in_menu": false,
    "conditions": {
      "required": false,
      "multi_select": true,
      "quantity_select": false,
      "min_options": "",
      "max_options": "",
      "free_amount": ""
    },
    "options": [
      {
        "_id": "jZcA_yNlP",
        "name": "Extra Chilli",
        "price": "1",
        "quantity": 0,
        "prices": {}
      },
      {
        "_id": "_YoHmyP8z-",
        "name": "Extra Sauce",
        "price": "1",
        "quantity": 0,
        "prices": {}
      }
    ]
  }
];


function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createDimSumMenu = () => {
    return {
        dimsum_menu:updateIds(dimsum_menu), dimsum_option_sets:updateIds(dimsum_option_sets)
    }
}

