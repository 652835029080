import React from 'react'
import styled from "styled-components";
import ReactTooltip from "react-tooltip"
export const LayoutContainer = styled.div`
    display: flex;
    align-items: flex-start;
`

export const SidebarContainer = styled.div`
    width: 60px;
    display: none;
    align-items: flex-start;
    align-self: stretch;
    border-right: 1px solid var(--secondary-light-grey-1, #DFDFDF);
    background: var(--neutral-white, #FEFEFE);
    margin: 0;
    padding: 0;
    min-height: 100vh;
    flex-direction: column;
    position: fixed;
    z-index: 100;

    &.active {
        width: 300px;
        & .sidebar-menu-wrapper {
            
            & .menu-item-wrapper {
                width: 100%;
                & > *:not(svg) {
                    display: block;
                }
            }
        }
        & .sidebar-logo-wrapper {
            justify-content: space-between;
            & span, & .menu-item-wrapper {
                display: block;
            }
        }
    }

    & .menu-item-wrapper {
        & > *:not(svg) {
            display: none;
        }
    }

    @media (min-width: 768px) {
        display: flex;
        transition: width .2s ease;
    }
`

export const SidebarLogoWrapper = styled.div<{isHaveLogo: boolean}>`
    display: flex;
    padding: ${({ isHaveLogo }) => (isHaveLogo ? '8px 8px 7px' : '36px 8px 19px')}; 
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;

    & span {
        color: var(--secondary-dark-grey-1, #4B4F5E);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }

    & span, & .menu-item-wrapper {
        display: none;
    }
`
const MenuWrapper = styled.div`
    display: flex;
    padding: 0px 8px 8px 8px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0;
`

export const SidebarMenuWrapper = styled(MenuWrapper)``

const MenuItemWrapper = styled.div`
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    transition: all .3s ease-in-out;
    cursor: pointer;

    & > svg path {
       fill: #4B4F5E;
       transition: all .3s ease-in-out;
    }

    &.active, &:hover, &:active, &:focus {
        background: var(--primary-accent-wisp-pink-1, #FFF3F3);
        transition: all .3s ease-in-out;
        & > svg path {
            fill: #FB2F2F;
            transition: all .3s ease-in-out;
        }
    }

    & > *:not(svg) {
        color: var(--secondary-dark-grey-1, #4B4F5E);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
`

interface MenuItemProps {
    onClick?: React.MouseEventHandler<HTMLElement>,
    active?: boolean
    tip?: string;
}

export const MenuItem = (props: React.PropsWithChildren<MenuItemProps>) => {
    return (
        <MenuItemWrapper
            onClick={props.onClick} 
            className={`menu-item-wrapper ${props.active ? 'active' : ''}`}
            data-tip={props.tip}>
            {props.children}
            <ReactTooltip place="right" effect="solid" multiline={false} />
        </MenuItemWrapper>
    )
}

export const MainContainer = styled.div`
    width: 100%;
    transition: width .3s ease-in-out;
    position: relative;
    
    @media (min-width: 768px) {
        padding-left: 60px;
        transition: width .3s ease-in-out;
    }
    
`

export const HeaderContainer = styled.div`
    display: none;
    opacity: 0;
    width: 100%;
    padding: 6px 24px 6px 0;
    align-items: center;
    gap: 24px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    justify-content: space-between;
    transition: all .3s ease-in-out;
    border-bottom: 1px solid #DFDFDF;

    @media (min-width: 768px) {
        display: flex;
        opacity: 1;
        transition: all .3s ease-in-out;
    }
`
export const HeaderMenuWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0;
    padding: 0;

    & > span {
        color: var(--secondary-dark-grey-1, #4B4F5E);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
    }
`

export const HeaderMobile = styled.div`
    display: flex;
    opacity: 1;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--secondary-light-grey-1, #DFDFDF);
    background: var(--neutral-white, #FEFEFE);
    position: relative;
    transition: all .3s ease-in-out;
    flex-wrap: nowrap;

    @media (min-width: 768px) {
        display: none;
        opacity: 0;
        transition: all .3s ease-in-out;
    }
`

export const HeaderMobileTitleWrapper = styled.div`
    display: flex;
    padding-right: 0px;
    align-items: center;
    gap: 8px;
`
export const HeaderTitleWrapper =  styled(HeaderMobileTitleWrapper)``

export const HeaderMobileLogoWrapper = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

export const HeaderMobileTitle = styled.h3`
    color: var(--secondary-dark-grey-1, #4B4F5E);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
`

export const HeaderMobileMenuContainer = styled.div`
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--neutral-white, #FEFEFE);
    height: 0px;
    position: absolute;
    z-index: 9999999;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    transition: opacity 2s ease-in-out;
    border-bottom: 1px solid #ebebeb;

    &.active {
        display: flex;
        height: 100vh;
        opacity: 1;
        transition: opacity 2s ease-in-out;
    }
`

export const HeaderMobileMenuUpperWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`
export const HeaderMobileMenuLowerWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`

export const HeaderMobileMenuTitleWrapper = styled.div`
    display: flex;
    padding: 12px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`

export const HeaderMobileMenuWrapper = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    & .menu-item-wrapper {
        width: 100%;
    }
`

export const QuickSettingsContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 424px;
    width: 100%;
    background: var(--neutral-white, #FEFEFE);
    position: absolute;
    right: -9999px;
    top: 0;
    z-index: 100;
    height: 100vh;
    transition: all .5s ease;

    &.active {
        right: 0px;
        transition: all .5s ease;
    }
`

export const QuickSettingsHeaderWrapper = styled.div`
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`
export const QuickSettingsBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    background: var(--neutral-white, #FEFEFE);
    width: 100%;
`

export const QuickSettingsFooterWrapper = styled.div`
    display: flex;
    padding: 12px 16px 24px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-top: 1px solid var(--secondary-light-grey-1, #DFDFDF);
    background: var(--neutral-white, #FEFEFE);
`


export const ButtonV2 = styled.button`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--primary-red-orange-1, #FB2F2F);
    border:1px solid var(--primary-red-orange-1, #FB2F2F);
    color: var(--neutral-gray, #F1F4F7);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    appearance: none;
    box-shadow: unset;
    cursor: pointer;
`

export const QuickSettingsButton = styled.button`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--primary-red-orange-1, #FB2F2F);
    border:1px solid var(--primary-red-orange-1, #FB2F2F);
    color: var(--neutral-gray, #F1F4F7);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    appearance: none;
    box-shadow: unset;
    cursor: pointer;
`

export const QuickSettingsItemsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
    width: 100%;
`

export const QuickSettingsItemsWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    width: 100%;

    & p {
        color: var(--secondary-dark-grey-1, #4B4F5E);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
    }
`

export const QuickSettingsItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    justify-content: space-between;

    & span {
        color: var(--secondary-dark-1, #0C202A);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }
`

export const DashboardAlert = styled.div`
    position: fixed;
    top: 60px;
    left: 0px;
    z-index: 8;
    display: flex;
    width: 100%;
    padding: 8px 24px;
    align-items: center;
    gap: 24px;
    background: rgba(229, 172, 0, 1);
`

export const DashboardAlertWrapper = styled.div`
    width: 100%;
    padding-left: calc(70px + 1rem);
    padding-right: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const DashboardAlertText = styled.div`
    color: var(--secondary-dark-1, #0C202A);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`

export const DashboardAlertButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`

export const DashboardAlertButtonPrimary = styled(ButtonV2)`
    padding: 8px 24px;
`
export const DashboardAlertButtonDefault = styled(ButtonV2)`
    background: var(--neutral-gray, #F1F4F7);
    border:1px solid var(--neutral-gray, #F1F4F7);
    color: var(--secondary-dark-grey-1, #4B4F5E);
    padding: 8px 24px;
`