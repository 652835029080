import * as React from "react";
import { AuthLayoutComponents } from "./layout.c";
import { BoxLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/components/index";

interface Props {
	loading: boolean;
	children: React.ReactNode;
}
interface State {
	pose_state: "visible" | "hidden";
}

@inject("store") @observer
export class AuthLayout extends MobxComponent<Props, State> {

	timeout: any;

	constructor(props: Props) {
		super(props);
		this.state = { pose_state: "hidden" };
	}

	componentDidMount() {
		this.timeout = setTimeout(() => this.setState({ pose_state: "visible" }), 200);
		if (this.injected.store.auth.token) {
			this.injected.store.router.push("/");
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		const { children, loading } = this.props;
		const reseller = this.injected.store.reseller!;
		const logo_auth = reseller.admin.logo_auth;
		const backgroundImage = reseller?.admin.images?.background;
		const backgroundColorTint = reseller?.admin.colors?.backgroundTint;
		let loginBoxStyle = reseller?.admin.login_box_style ?? "classic";
		return (
			<AuthLayoutComponents.Wrapper bg_color={backgroundColorTint} bg_img={backgroundImage}>
				<AuthLayoutComponents.Main pose={this.state.pose_state} >
					<AuthLayoutComponents.BoxWrapper boxStyle={loginBoxStyle}>
						<AuthLayoutComponents.Content>
							<AuthLayoutComponents.Logo>
								<img src={logo_auth} className="res-img" />
							</AuthLayoutComponents.Logo>
							<AuthLayoutComponents.ContentBox boxStyle={loginBoxStyle}>
								<BoxLoader active={loading} boxStyle={loginBoxStyle} />
								{children}
							</AuthLayoutComponents.ContentBox>
						</AuthLayoutComponents.Content>
					</AuthLayoutComponents.BoxWrapper>
				</AuthLayoutComponents.Main>
			</AuthLayoutComponents.Wrapper>
		);
	}

}
