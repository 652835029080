import React from 'react';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Field, FastField, FieldProps, FastFieldProps } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, SelectAdv, Input } from '@lib/components';
import { PaymentBaseFields } from './base';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentPayMongo;

const paymentMethods = {
  billease: 'BillEase',
  card: 'Cards',
  dob: 'BPI Online Banking',
  dob_ubp: 'UnionBank Online Banking',
  gcash: 'GCash',
  grab_pay: 'GrabPay',
  paymaya: 'PayMaya',
};

const validationSchema = Yup.object().shape({
  secret_key: Yup.string().required(),
  public_key: Yup.string().required(),
  enabled_payment_methods: Yup.array().min(1).required(),
});

@inject('store')
@observer
export class SettingsFormPaymentsPayMongo extends MobxComponent<Props, {}> {
  initialValues: FormValues;
  pmOptions: { label: string; value: string }[];

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.paymongo || {
      enabled: false,
      label: 'PayMongo',
      services: [],
      public_key: '',
      secret_key: '',
      enabled_payment_methods: [],
    };
    this.pmOptions = Object.keys(paymentMethods).map(key => ({
      label: paymentMethods[key as keyof typeof paymentMethods],
      value: key,
    }));
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.paymongo = {
            ...(r.settings.payments.paymongo || {}),
            ...values,
          };
          const update = {
            $set: {
              'settings.payments.paymongo': r.settings.payments.paymongo,
            },
          };

          return { r, update };
        }}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
        onSuccess={async () => {
          const { store } = this.injected;
          const r = store.restaurant!;
          const webhookResponse = await store.api.restaurantRegisterPayMongoWebhook({ restaurantId: r._id });
          if (webhookResponse.outcome === 0) {
            const response = await store.api.restaurant({ _id: r._id });
            if (response.outcome === 0) {
              const restaurant = response.restaurant;
              store.setRestaurant(restaurant);
            }
          }
        }}
      >
        {({ form, getFieldError }) => {
          const { isSubmitting, setFieldValue, errors } = form;
          return (
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: FastFieldProps<boolean>) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using PayMongo payment method.">
                    <Switch
                      id="paymongo-enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="public_key"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="PayMongo Public Key"
                    small_title
                    no_border
                    error={getFieldError(form, 'public_key')}
                  >
                    <Input
                      type="text"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('public_key', e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="secret_key"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="PayMongo Secret Key"
                    small_title
                    no_border
                    error={getFieldError(form, 'secret_key')}
                  >
                    <Input
                      type="text"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('secret_key', e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="enabled_payment_methods"
                render={({ field }: FieldProps<string>) => (
                  <FormGroup
                    title="Payment Methods"
                    small_title
                    no_border
                    error={getFieldError(form, 'enabled_payment_methods')}
                  >
                    <SelectAdv
                      type="multi"
                      id="enabled_payment_methods"
                      value={field.value || []}
                      options={this.pmOptions}
                      onChange={(selectedValues: string[]) => {
                        setFieldValue('enabled_payment_methods', selectedValues);
                      }}
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="PayMongo" setFieldValue={setFieldValue} />

              {errors && Object.keys(errors).length > 0 && (
                <FormGroup no_border error="Validation failed. Please check PayMongo configurations again." />
              )}

              <Button full color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
