import React from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import styled, { withTheme } from 'styled-components';
import { MobxComponent } from "../../../../../mobx/components";
import { Modal, TimerContainer, Timer, TimerButton } from "@lib/components";
import { ModalContent, ModalTitle } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Button, SelectAdv, Table, TableBody, TableCell, TableRow } from "@lib/components";
import Lalamove from "@lib/lalamove";
import { Quotation, QuotationCreateParams } from "@lib/lalamove/types/resources/Quotations";
import { UI } from "../../../../../core/ui";
import { withTranslation } from "react-i18next";
import { padStart } from "lodash";

interface Props {
  order: T.Schema.Order.OrderSchema;
  restaurant: T.Schema.Restaurant.RestaurantSchema,
  active: boolean;
  close: () => void;
}

interface State {
  error: string;
  service: string;
  loading: boolean;
  quote: Quotation | null;
  quoteParams: QuotationCreateParams | null;
  minutes: number;
  seconds: number;
  expiration_time: number;
}

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectWrapper = styled.div`
	width: 100%;
	padding-bottom: 0.5rem;
	border-bottom: 1px dashed #d6d6d6;
`;

const TableWrapper = styled.div`
	tr {
		border-bottom: 1px dashed #d6d6d6 !important;
	}
`;

const ErrorWrapper = styled.div`
	margin-top: 0.5rem;
`;

@inject("store") @observer
class LalamoveSchedulingClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: "",
      service: "",
      loading: false,
      quote: null,
      quoteParams: null,
      minutes: 5,
      seconds: 0,
      expiration_time: 0
    };
  }

  interval: any = null
  transformServiceTypes = () => {
    const { restaurant } = this.props;
    const market = restaurant.settings.services.delivery.providers.lalamove!.market;
    const allServiceTypes = Lalamove.serviceTypesByMarket(market);
    const serviceTypes = restaurant.settings.services.delivery.providers.lalamove?.service_types || [];
    return allServiceTypes.filter(item => serviceTypes.includes(item.value));
  }

  constructQuotationParams = (serviceType: any): QuotationCreateParams => {
    const { order, restaurant } = this.props;
    const orderConfig = order.config;
    const location = restaurant.location;
    const lalamoveConfig = restaurant.settings.services.delivery.providers.lalamove!;
    const locale = Lalamove.localeByMarket(lalamoveConfig.market);

    let scheduleAt;
    if (order.config.due === 'later' && order.delivery_in) {
      scheduleAt = moment(order.delivery_in.timestamp).toISOString()
    }

    return {
      data : {
        serviceType,
        specialRequests: [],
        scheduleAt: scheduleAt,
        stops: [
          {
            coordinates: {
              lat: location.map_data.type !== "custom" ? location.map_data.lat.toString() : '',
              lng: location.map_data.type !== "custom" ? location.map_data.lng.toString() : '',
            },
            address: location.address
          },
          {
            coordinates: {
              lat: orderConfig.lat.toString(),
              lng: orderConfig.lng.toString()
            },
            address: orderConfig.destination
          }
        ],
        language: locale,
      }
    };
  }

  createQuotation = async (serviceType: any) => {
    const { store } = this.injected;
    const { restaurant } = this.props;

    this.setState({ loading: true })
    const params = this.constructQuotationParams(serviceType);
    if(typeof params.data.scheduleAt != 'string') {
      delete params.data.scheduleAt;
    }
    const response = await store.api.create_lalamove_quotation({
      restaurantId: restaurant._id,
      params,
    });
    if (response.outcome === 1) {
      this.setState({
        error: response.message,
        quote: null,
        quoteParams: null,
        loading: false,
      });
    } else if (response.quote) {
      UI.notification.success('Lalamove quotation created');
      const expirationTime = new Date();
      expirationTime.setMinutes(expirationTime.getMinutes() + 5)
      this.setState({
        quote: response.quote,
        quoteParams: params,
        error: '',
        loading: false,
        expiration_time: expirationTime.getTime()
      }, () => this.timer());
    }
  }

  createDelivery = async () => {
    const { store } = this.injected;
    const { quote, quoteParams, error } = this.state;
    if (!quote || !quoteParams) {
      UI.notification.warning('Lalamove quotation is not available. Please choose a service type to continue.');
      return;
    }

    if(error) {
      UI.notification.warning(error);
      return;
    }

    const { order, restaurant, close } = this.props;
    
    // Reset Lalamove data in order config
    // Create brand new Lalamove order
    const payload: T.API.DashboardCreateLalamoveDeliveryRequest = {
      orderId: order._id,
      restaurantId: restaurant._id,
      quote,
      quoteParams,
    }

    this.setState({ loading: true })
    const response = await store.api.create_lalamove_delivery(payload);
    if (response.outcome === 1) {
      UI.notification.error('Failed to create Lalamove delivery please try again.');
    } else {
      UI.notification.success('Lalamove delivery created.')
    }
    
    this.setState({
      error: '',
      quote: null,
      quoteParams: null,
      loading: false,
    });

    close();
  }

  timer() {
		this.interval = setInterval(() => {
		const now = new Date().getTime();
		const distance = this.state.expiration_time - now;
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);
		

    if(distance < 0) {
			this.setState({
				minutes: 0,
				seconds: 0
			});
			clearInterval(this.interval);
      this.setState({error: 'Lalamove quotation expired'})
		} else {
			this.setState({
				minutes,
				seconds
			});
		}
		}, 1000);
	}

  render() {
    const { t, theme } = this.injected;
    const { active, close } = this.props;
    const { service, loading, quote, error, minutes, seconds } = this.state;
    const serviceTypes = this.transformServiceTypes();
    const isLessThanTenSeconds = minutes == 0 && seconds < 10;
    return (
      <Modal
        width="sm"
        alignTop={true}
        active={active}
        close={close}
      >
        <ModalTitle>
          <h4>Lalamove Delivery Scheduling</h4>
        </ModalTitle>

        <ModalContent>
          {quote && <TimerContainer>
            <Timer>
							<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.9998 24.6666C19.8665 24.6666 24.6665 19.8666 24.6665 14C24.6665 8.13329 19.8665 3.33329 13.9998 3.33329C8.13317 3.33329 3.33317 8.13329 3.33317 14C3.33317 19.8666 8.13317 24.6666 13.9998 24.6666ZM13.9998 0.666626C21.3332 0.666626 27.3332 6.66663 27.3332 14C27.3332 21.3333 21.3332 27.3333 13.9998 27.3333C6.6665 27.3333 0.666504 21.3333 0.666504 14C0.666504 6.66663 6.6665 0.666626 13.9998 0.666626ZM20.6665 13.3333V15.3333H12.6665V7.33329H14.6665V13.3333H20.6665Z"
								fill={isLessThanTenSeconds ? '#EA4335': 'black'}/>
							</svg>
							<div>
								<span className={isLessThanTenSeconds ? 'danger': ''}>00:{padStart(minutes.toString(), 2, '0')}:{padStart(seconds.toString(), 2, '0')}</span>
								<p className={isLessThanTenSeconds ? 'danger': ''}>
									{minutes == 0 && seconds == 0 ? t("store.modals.checkout.delivery_estimation.details.lalamove_estimation_expired"):
									 t("store.modals.checkout.delivery_estimation.details.lalamove_price")}
								</p>
							</div>

						</Timer>
            { (minutes == 0 && seconds == 0) &&<TimerButton onClick={(e) =>  {
							e.preventDefault();
              this.createQuotation(service);
						}}>
							{ t("store.modals.checkout.delivery_estimation.details.lalamove_new_estimation") }
						</TimerButton> }
          </TimerContainer> }
          <SelectWrapper>
            <SelectAdv
              type="single"
              value={service}
              options={serviceTypes}
              placeholder="Deliver via... (Required field)"
              onChange={(option: string) => {
                this.setState({ service: option })
                this.createQuotation(option);
              }}
            />
          </SelectWrapper>

          <TableWrapper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="small">Delivery Fee</TableCell>
                  {!loading && (
                    <TableCell className="small">
                      {quote?.data.priceBreakdown.total ? `${quote?.data.priceBreakdown.total} ${quote?.data.priceBreakdown.currency}` : 0}
                    </TableCell>
                  )}
                  {loading && (
                    <TableCell>
                      <RotateLoader size={1} color={theme.colors.primary} />
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableWrapper>

          {error && (
            <ErrorWrapper className="error-text" style={{ fontSize: '0.85rem' }}>
              {t("store.modals.checkout.delivery_estimation.quotation_error")}
            </ErrorWrapper>
          )}
        </ModalContent>

        <ModalContent>
          <ButtonGroup>
            <Button
              size="xs"
              type="button"
              onClick={close}
              disabled={loading}
              color="primary-inverse"
            >
              Cancel
            </Button>

            <Button
              size="xs"
              type="button"
              color="primary"
              disabled={loading}
              onClick={this.createDelivery}
              style={{ marginLeft: "10px" }}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </ModalContent>
      </Modal>
    );
  }
}

// @ts-ignore
export const LalamoveScheduling = withTheme(withTranslation()(LalamoveSchedulingClass));
