import * as React from "react";
import { FastField, FormikProps } from "formik";
import {
	FormGroup,
	styled,
	Tooltip,
	Button,
	RotateLoader,
	TabSelect,
	Input,
	Switch,
	FieldDineInTables,
  Checkbox,
} from "@lib/components";
import { copy2Clipboard, RestaurantUtils } from "@lib/common";
import {
	ServiceOrderTimeFields,
	ServiceAutoStatusFields,
	ServiceHoursField,
	CustomCheckoutField,
} from "./base";
import { MobxComponent } from "../../../../../../../mobx/components";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import shortid from "shortid";
import { updateRestaurantNotificationOptions } from "../../../../../../../core/libs/receipt";

interface Props {}
interface State {
	tab: string;
}
type FormValues =
	T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["dine_in"];

const WarningBox = styled.div`
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
	position: relative;
	padding: 0.75rem 1.25rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	margin-top: 1rem;
`;

@inject("store")
@observer
export class SettingsFormServicesDineIn extends MobxComponent<Props, State> {
	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
		};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.services.dine_in;
	}

	copyTableRows = () => {
		const texts = document.getElementsByClassName(
			"input__InputComponent-bXkjDh"
		);

		if (texts.length > 2 && texts.length % 3 === 0) {
			let rows = "Index,Table name,Order URL\n";
			let index = 1;
			for (let text = 0; text < texts.length; text += 3) {
				rows += `${index},${(texts[text] as HTMLInputElement).value},${
					(texts[text + 2] as HTMLInputElement).value
				}\n`;
				index++;
			}
			copy2Clipboard(rows);
		}
	};

	generateTableField = () => ({
		_id: shortid.generate(),
		name: "",
		password: "",
	});

	addTable = (
		form: FormikProps<T.Schema.Restaurant.Services.RestaurantServiceDineIn>,
		field: any
	) => {
		form.setFieldValue("tables", [
			...field.value,
			this.generateTableField(),
		]);
	};

	addMultipleTables = (
		form: FormikProps<T.Schema.Restaurant.Services.RestaurantServiceDineIn>,
		field: any
	) => {
		let quantity = prompt(`How many tables you want to add?`);

		if (quantity === null) return;

		if (!/^[0-9]+$/.test(quantity)) {
			alert("You did not enter a numeric number. Please try again!");
			return;
		}

		const newTableFields: T.Schema.Restaurant.Services.DineInTable[] = [];

		for (let i = 0; i < Number(quantity); i++) {
			newTableFields.push(this.generateTableField());
		}

		form.setFieldValue("tables", [...field.value, ...newTableFields]);
	};

	render() {
		const { tab } = this.state;
		const { reseller, restaurant } = this.injected.store;
		const storeHost = reseller!.store_host;
		const storeURL = restaurant!.domain
			? `https://${restaurant!.domain}`
			: `https://${restaurant!.subdomain}.${storeHost}`;
		const customer_info = [
			{ key: 'name', name: 'Name' },
			{ key: 'phone', name: 'Phone' },
			{ key: 'email', name: 'E-mail' },
			];
		return (
			<div>
				<TabSelect
					id="accounts-tab-options"
					className="border-white-tb-10"
					hasBorder={true}
					screenWidth={this.injected.store.view.screen_width}
					onChange={(v) => this.setState({ tab: v.value })}
					value={tab}
					values={[
						{ label: "General", value: "0" },
						{ label: "Tables", value: "1" },
						{ label: "Order Timings", value: "2" },
						{ label: "Available Hours", value: "3" },
						{ label: "Wait Times & Auto Statuses", value: "4" },
						{ label: "Custom Checkout Fields", value: "5" },
						{ label: "Customer Info", value: "6" },
					]}
				/>

				<RestaurantForm<FormValues>
					submit={async (r, values) => {
						r.settings.services.dine_in = values;
						const update = {
							$set: {
								"settings.services.dine_in":
									r.settings.services.dine_in,
							},
						};
						return { r, update };
					}}
					initialValues={this.initialValues}
					onSuccess={async () =>
						await updateRestaurantNotificationOptions(
							this.injected.store
						)
					}
					onError={() => UI.notification.error("An error occurred")}
					onSuccessMessage="Settings Updated"
				>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue } = form;
						return (
							<div className="p-4">
								<FormGroup>
									{tab === "0" && (
										<div>
											<FastField
												name="enabled"
												render={({ field }: any) => (
													<FormGroup
														title="Enabled"
														help="Allows customers to place orders that can be collected at one of your locations"
													>
														<Switch
															id="enable-switch"
															checked={
																field.value
															}
															onChange={(e) =>
																setFieldValue(
																	"enabled",
																	e.target
																		.checked
																)
															}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="display_name"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Display Name"
														help="Override the service name. This will override the translation as well. Defaults to Dine In."
													>
														<Input
															type="text"
															{...field}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="payments.optional_customer_info"
												render={({ field }: any) => (
													<FormGroup
														title="Make Customer Information Optional"
														help="Customers will not be required to provide their basic information when placing a dine-in order. Info includes name, e-mail address and phone number. Some payment methods will ignore this setting e.g. Apple Pay."
													>
														<Switch
															id="customer-info-switch"
															checked={
																!!field.value
															}
															onChange={(e) =>
																setFieldValue(
																	"payments.optional_customer_info",
																	e.target
																		.checked
																)
															}
														/>
													</FormGroup>
												)}
											/>
											<WarningBox>
												IMPORTANT: Some payment methods
												might not work if customer
												information is not provided.
												Customers will be prompted of
												what info is required to proceed
												with their chosen payment
												method.
											</WarningBox>
											<br />
											<FastField
												name="notes"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Notes"
														help="This will be shown to customers if they choose this order method"
													>
														<Input
															type="text"
															{...field}
														/>
													</FormGroup>
												)}
											/>
										</div>
									)}
									{tab === "1" && (
										<div>
											<FastField
												name="tables"
												render={({ field }: any) => (
													<FormGroup
														help=""
														error={getFieldError(
															form,
															"tables"
														)}
													>
														<Tooltip
															text="Create new Table"
															width={120}
															position="bottom"
														>
															<Button
																type="button"
																color="primary-inverse"
																size="xs"
																onClick={() =>
																	this.addTable(
																		form,
																		field
																	)
																}
																className="m-r-2"
															>
																Add Table
															</Button>
														</Tooltip>
														<Tooltip
															text="Quickly add multiple new tables"
															width={200}
															position="bottom"
														>
															<Button
																type="button"
																color="primary-inverse"
																size="xs"
																onClick={() =>
																	this.addMultipleTables(
																		form,
																		field
																	)
																}
																className="m-r-2"
															>
																Add multiple
																Tables
															</Button>
														</Tooltip>
														{field.value &&
															field.value
																.length !==
																0 && (
																<Tooltip
																	text="Copy list in CSV format"
																	width={150}
																	position="bottom"
																>
																	<Button
																		type="button"
																		color="primary-inverse"
																		size="xs"
																		onClick={
																			this
																				.copyTableRows
																		}
																		className="m-r-2"
																	>
																		Copy as
																		CSV
																	</Button>
																</Tooltip>
															)}
														{field.value &&
															field.value
																.length !==
																0 && (
																<div className="m-t-4">
																	<FieldDineInTables
																		baseURL={
																			storeURL
																		}
																		values={
																			field.value
																		}
																		onChange={(
																			tables
																		) =>
																			setFieldValue(
																				"tables",
																				tables
																			)
																		}
																	/>
																</div>
															)}
													</FormGroup>
												)}
											/>
										</div>
									)}
									{tab === "2" && (
										<ServiceOrderTimeFields
											service="dine_in"
											form={form}
										/>
									)}
									{tab === "3" && (
										<ServiceHoursField
											service="dine_in"
											error={(field) =>
												getFieldError(form, field) as
													| string
													| null
													| undefined
											}
										/>
									)}
									{tab === "4" && (
										<ServiceAutoStatusFields
											service="dine_in"
											values={form.values}
										/>
									)}
									{tab === "5" && (
										<CustomCheckoutField form={form} />
									)}
									{tab === '6' && (
										<div>
										<FastField
											name="customer.required_info"
											render={({ field }: any) => (
											<FormGroup title="Required Customer Info">
												{customer_info.map((v, i) => (
												<Checkbox
													key={i}
													id={i + v.key}
													checked={
													(field.value || RestaurantUtils.services.defaultCustomerInfo()).indexOf(v.key) !==
													-1
													}
													label={v.name}
													className="m-t-3"
													onChange={() => {
													const value = field.value
														? [...field.value]
														: RestaurantUtils.services.defaultCustomerInfo();
													const index = value.indexOf(v.key);
													if (index === -1) value.push(v.key);
													else value.splice(index, 1);
													setFieldValue('customer.required_info', value);
													}}
												/>
												))}
												<p className="lhp m-t-2">
												You may choose which field will be required when placing orders. Please be aware that some
												or all fields may be required to process certain payment methods. Unchecking the info
												field means it will show as `Optional` on the checkout form.
												</p>
											</FormGroup>
											)}
										/>
										</div>
									)}
								</FormGroup>

								{error && <FormGroup error={error} />}

								<Button
									full={true}
									color="primary"
									type="submit"
									disabled={isSubmitting}
								>
									{isSubmitting && (
										<RotateLoader size={2} color="white" />
									)}
									{!isSubmitting && "Save"}
								</Button>
							</div>
						);
					}}
				</RestaurantForm>
			</div>
		);
	}
}
