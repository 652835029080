import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentElavon;

@inject('store')
@observer
export class SettingsFormPaymentsElavon extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.elavon || {
      enabled: false,
      label: '',
      services: [],
      ssl_merchant_id: '',
      ssl_user_id: '',
      ssl_pin: '',
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.elavon = values;
          const update = { $set: { 'settings.payments.elavon': r.settings.payments.elavon } };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: any) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FormGroup>
                <div>
                  <div style={{ marginBottom: '10px' }}>
                    <p
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: 'red',
                        marginBottom: '10px',
                      }}
                    >
                      IMPORTANT
                    </p>
                    <p style={{ fontSize: '16px', lineHeight: '18px' }}>
                      Elavon requires the IP address of the API server to be added to the whitelist. Please log into the
                      Elavon portal and add the following IP address to the whitelist: 35.240.159.64
                    </p>
                  </div>
                </div>
              </FormGroup>

              <FastField
                name="ssl_merchant_id"
                render={({ field }: any) => (
                  <FormGroup
                    title="Merchant ID"
                    help="Your Elavon-assigned Converge account ID, do not to share this with anyone"
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="ssl_user_id"
                render={({ field }: any) => (
                  <FormGroup title="User ID" help="Your Elavon Converge User ID">
                    <Input type="text" {...field} autoSave="true" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="ssl_pin"
                render={({ field }: any) => (
                  <FormGroup
                    title="Terminal ID or PIN"
                    help="Your Elavon Terminal ID or PIN, the identifier of the terminal that will process the transactions"
                  >
                    <Input type="text" {...field} autoSave="true" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="Credit Card" setFieldValue={setFieldValue} />

              {error && <FormGroup error={error} />}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
