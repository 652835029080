import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentPayway;

@inject('store')
@observer
export class SettingsFormPaymentsPayWay extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.payway || {
      enabled: false,
      label: 'ABA Pay',
      services: [],
      testing: false,
      merchant_id: '',
      api_key: '',
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.payway = values;

          let update;
          if (values.enabled) {
            r.settings.business.using_awaiting_payment = true;
            update = {
              $set: {
                'settings.payments.payway': r.settings.payments.payway,
                'settings.business.using_awaiting_payment': true,
              },
            };
          } else {
            update = {
              $set: {
                'settings.payments.payway': r.settings.payments.payway,
              },
            };
          }

          return { r, update };
        }}
        validators={{
          merchant_id: values => {
            if (values.enabled && !values.merchant_id)
              return {
                merchant_id: 'Merchant ID is required field!',
              };
          },

          RequestId: values => {
            if (values.enabled && !values.api_key)
              return {
                RequestId: 'API key is required field',
              };
          },
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: any) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using PayWay payment method.">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="testing"
                render={({ field }: any) => (
                  <FormGroup
                    title="Testing Environment"
                    help="Enable this to use the PayWay demo environment to test and experiment with your integration."
                  >
                    <Switch
                      id="testing-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('testing', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="merchant_id"
                render={({ field }: any) => (
                  <FormGroup
                    title="Merchant ID"
                    help="The merchant ID given by PayWay when setting up an account."
                    error={getFieldError(form, 'merchant_id')}
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="api_key"
                render={({ field }: any) => (
                  <FormGroup
                    title="API key"
                    help="The secret key given by PayWay when setting up an account."
                    error={getFieldError(form, 'api_key')}
                  >
                    <Input type="text" {...field} autoSave="true" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="payway" setFieldValue={setFieldValue} />

              {error && <FormGroup error={error} />}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
