import * as React from "react";
import { FastField } from "formik";
import { FormGroup, Input, Switch } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { ColorPicker } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["alert"];

@inject("store") @observer
export class SettingsFormDesignAlert extends MobxComponent<Props, State> {
	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.state = {};
		this.initialValues = r.website.sections.alert || {
			colors: {
				background: "black",
				text: "white",
			},
			text: "",
			bold: false,
		};
	}

	render() {
		const col_props = {
			className: "col",
			small_title: true,
			no_border: true,
		};
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.website.sections.alert = values;
					const update = { $set: { "website.sections.alert": values } };
					return { r, update };
				}}
				initialValues={this.initialValues}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FormGroup
								title="Colors"
								contentClassName="grid-2 sm sm-gap max300">
								<FastField
									name="colors.background"
									render={({ field }: any) => (
										<FormGroup title="Background" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("colors.background", color)} />
										</FormGroup>
									)}
								/>
								<FastField
									name="colors.text"
									render={({ field }: any) => (
										<FormGroup title="Text" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("colors.text", color)} />
										</FormGroup>
									)}
								/>
							</FormGroup>

							<FastField
								name="text"
								render={({ field }: any) => (
									<FormGroup title="Alert Text" help="The text to display inside your alert">
										<Input {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="bold"
								render={({ field }: any) => (
									<FormGroup title="Bold Alert Text">
										<Switch
											id="bold_alert_text"
											checked={field.value || false}
											onChange={(e) => setFieldValue("bold", e.target.checked)} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
