export const PaymentMethods = [
  'cash',
  'card',
  'stripe',
  'paypal',
  'cardconnect',
  'paygate_payweb',
  'poli_pay',
  'ipay88',
  'bambora_apac',
  'bambora_na',
  'pesapal',
  'checkout',
  'checkout_apple_pay',
  'checkout_google_pay',
  'stripe_digital_wallet',
  'razor_pay',
  'gravity',
  'ath_movil',
  'authorized',
  'elavon',
  'red_dot',
  'gkash',
  'sgepay',
  'payway',
  'bancontact',
  'swish',
  'cabbagepay',
  'fiserv',
  'viva_wallet',
  'paymongo',
];

/* 3rd-Party Integrated Payment Providers
 * Used to differentiate custom payment methods as their ID is randomly generated.
 * Any payment method IDs that aren't here are regarded as custom payment methods.
 */
export const IntegratedPaymentMethods = [
  'stripe',
  'paypal',
  'cardconnect',
  'paygate_payweb',
  'poli_pay',
  'ipay88',
  'bambora_apac',
  'bambora_na',
  'pesapal',
  'checkout',
  'checkout_apple_pay',
  'checkout_google_pay',
  'stripe_digital_wallet',
  'razor_pay',
  'gravity',
  'ath_movil',
  'authorized',
  'elavon',
  'red_dot',
  'gkash',
  'sgepay',
  'payway',
  'bancontact',
  'swish',
  'cabbagepay',
  'fiserv',
  'viva_wallet',
  'paymongo',
];

export const ThreeDsPaymentMethods = [
  'authorized',
  'stripe',
  'checkout',
  'checkout_apple_pay',
  'pesapal',
  'checkout_google_pay',
  'stripe_digital_wallet',
  'razor_pay',
  'sgepay',
  'swish',
  'cabbagepay',
  'fiserv',
  'viva_wallet',
  'paymongo',
];

export const RefundablePaymentMethods = ['swish', 'fiserv', 'bambora_na', 'paymongo'];
export type RefundablePaymentMethodTypes = 'swish' | 'fiserv' | 'bambora_na' | 'paymongo';
/**
 *
 * When adding a new payment method that using Awaiting Payment status,
 * please add it to the array bellow,
 *
 * Also add logic when users Enable that method,
 * we should also set value `settings.business.using_awaiting_payment = true`;
 * If not, the order dashboard will not show the awaiting payment columns
 *
 * Where to add: in the submit function of Formik form
 * which used for that payment method dashboard setting
 *
 *
 * Ref:
 * src/dashboard/src/react/ui/restaurant/views/settings/forms/payments/gkash.tsx
 * https://gitlab.com/cloudwaitressofficial/cloudwaitress/-/commit/df0e5b59107c51094dcf773343241827b52995eb
 * CW-681 Hide Awaiting Payment Section - https://cloudwaitress.atlassian.net/browse/CW-681
 *
 */

export const PaymentMethodsUsingAwaitingPayment = ['gkash', 'checkout', 'sgepay', 'payway'];

export const RequireCustomerInfoPaymentMethods = [
  // 'stripe',
  'bancontact',
  'checkout_apple_pay',
  'checkout_google_pay',
  'paypal',
  'cardconnect',
  'paygate_payweb',
  'poli_pay',
  'ipay88',
  'bambora_apac',
  'bambora_na',
  'pesapal',
  'checkout',
  'razor_pay',
  'gravity',
  'ath_movil',
  'authorized',
  'elavon',
  'red_dot',
  'gkash',
  'sgepay',
  'payway',
  'swish',
  'fiserv',
  'viva_wallet',
];

export const RequireCustomerInfoPaymentSubMethods = [
  'card'
];

type PaymentMethodTypes = T.Schema.Restaurant.Payments.RestaurantPaymentTypes;

// Define aliases of a given payment method.
// An alias of a payment method follows the same payment flow of the original payment method.
// It may vary in name or some descriptive attributes.
export const PaymentMethodAliases: { [key in PaymentMethodTypes]?: { key: PaymentMethodTypes; label: string }[] } = {};

function getPaymentMethodAlias(key: string, aliasKey: string) {
  return (PaymentMethodAliases[key] || []).find(alias => alias.key === aliasKey);
}

export function hasPaymentMethodAlias(key: string, aliasKey: string) {
  return !!getPaymentMethodAlias(key, aliasKey);
}

export function getPaymentMethodName(key: string, aliasKey: string, t: any) {
  if (PaymentMethods.indexOf(key) === -1) {
    return key;
  }

  const methodAlias = getPaymentMethodAlias(key, aliasKey);
  if (methodAlias) {
    return t(`constants.payment.method.${aliasKey}`);
  }

  return t(`constants.payment.method.${key}`);
}
