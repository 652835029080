import React from 'react';
import { PromoType } from '../type';
import { promosByType } from '../util';
import { RotateLoader, Modal, ModalContent, Button } from '@lib/components';

export const PromoDeletionModal = (props: {
  restaurant: T.Schema.Restaurant.RestaurantSchema;
  type: PromoType;
  removeId: string | null;
  removeLoading: boolean;
  onClose: () => void;
  onRemove: (removeId: string | null, type: PromoType) => void;
  onCancel: () => void;
}) => {
  const { type, removeId, removeLoading, onClose, onCancel, onRemove } = props;
  const promos = promosByType(props.restaurant, type);
  const promo = promos.find(promo => promo._id === removeId);
  if (!promo) return null;
  return (
    <Modal
      width="sm"
      close={onClose}
      closeButton={false}
      active={removeId !== null}
    >
      <ModalContent className="flex-l-r-center">
        <h4 className="">Delete Promo</h4>
        <p className="big underline">{promo.name}</p>
      </ModalContent>
      <ModalContent>
        <p className="lhp big">
          Are you sure you want to delete this promo. Once deleted, it cannot be
          restored
        </p>
      </ModalContent>
      <ModalContent className="flex-right">
        <Button
          type="button"
          className="m-r-2 width100 max100px"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className="width100 max100px"
          color="primary"
          onClick={() => onRemove(removeId, type)}
        >
          {removeLoading && <RotateLoader size={2} color="white" />}
          {!removeLoading && 'Delete'}
        </Button>
      </ModalContent>
    </Modal>
  );
};
