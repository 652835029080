import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup } from '@lib/components';
import { Input } from '@lib/components';
import { Button } from '@lib/components';
import { RotateLoader } from '@lib/components';
import { Switch } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { ModalContent } from '@lib/components';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { DateTimeRanges } from '@lib/components';
import { Modal } from '@lib/components';
import { InputGroup } from '@lib/components';
import { FormTreeSelect } from '@lib/components';
import { ListPaymentsOptions, RestaurantUtils } from '@lib/common';
import { SelectAdv } from '@lib/components';
import { ListOrderTimesOptions, ListServicesOptions } from '@lib/common';
import { computed } from 'mobx';

type FormValues = T.Schema.Restaurant.Promo.RestaurantPromo;
interface Props {
  type: 'edit' | 'create';
  initialValues: FormValues | null;
  close: () => void;
}
interface State {
  tab: string;
}

@inject('store')
@observer
export class SettingsPromosForm extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: '0',
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialValues && !prevProps.initialValues) {
      this.setState({ tab: '0' });
    }
  }

  getPaymentMethods(
    r: T.Schema.Restaurant.RestaurantSchema | null
  ): Array<{ value: string; label: string }> {
    let payment_methods = new Array<{ value: string; label: string }>();
    if (r && r.settings.payments) {
      Object.keys(r.settings.payments).forEach(
        (payment_method, index, list) => {
          // @ts-ignore
          if (
            r.settings.payments[payment_method]?.label &&
            r.settings.payments[payment_method]?.enabled
          ) {
            payment_methods.push({
              value: payment_method,
              // @ts-ignore
              label: r.settings.payments[payment_method]?.label || '',
            });
          }
        }
      );
    }
    return payment_methods;
  }

  normalizeTreeData = (input: any) => {
    if (!input) return [];
    const restaurant: T.Schema.Restaurant.RestaurantSchema = this.injected.store.restaurant!;
    const menus = restaurant.menus;
    let dishIds: string[] = [];
    for (const menuId of Object.keys(input)) {
      const selection = input[menuId];
      const menu = menus.find(m => m._id === menuId);
      if (!menu) continue;

      if (selection.menu.length > 0) {
        menu.categories.forEach(category =>
          category.dishes.forEach(dish => dishIds.push(dish._id))
        );
        continue;
      }

      if (selection.category.length > 0) {
        menu.categories.forEach(category => {
          if (selection.category.includes(category._id)) {
            category.dishes.forEach(dish => dishIds.push(dish._id));
          }
        });
      }

      if (selection.dish.length > 0) {
        dishIds = dishIds.concat(selection.dish);
      }
    }

    return dishIds;
  };

  render() {
    const { type, initialValues, close } = this.props;
    const currency = this.injected.store.intl.s.currency;
    const R = this.injected.store.restaurant!;
		const promoList: any[] = [...R.promos, ...(R.free_item_promos || []), ...(R.conventional_discount_promos || [])];
		const promoCodeList = promoList.map(promo => promo.code.toLowerCase());
    

    return (
      <Modal width="md" alignTop={true} active={!!initialValues} close={close}>
        <ModalContent>
          <h3 className="">
            {type === 'create' ? 'Create Promo' : 'Edit Promo'}
          </h3>
          {type === 'edit' && initialValues && (
            <p className="m-t-1">{initialValues.name}</p>
          )}
        </ModalContent>

        <RestaurantForm<FormValues>
          submit={async (r, item) => {
            const existing = r.promos.findIndex(p => p._id === item._id);
            if (existing === -1) {
              r.promos.push(item);
            } else {
              r.promos[existing] = item;
            }
            const update = { $set: { promos: r.promos } };
            return { r, update };
          }}
          validators={{
            name: values => {
              if (!values.name) return { name: 'This field is required' };
            },
            valid_times: values => {
              if (values.valid_times.length > 0) {
                for (let valid_time of values.valid_times) {
                  if (!valid_time.start || !valid_time.end)
                    return {
                      valid_times:
                        'This field should contain correct date-time ranges',
                    };

                  if (valid_time.start > valid_time.end)
                    return {
                      valid_times:
                        'The End Time must be always greater than the Start Time',
                    };
                }
              }
            },
						code: values => {
              if (!values.code) return { code: 'This field is required' };
							if (type === 'create' && promoCodeList.some(e => e === values.code.toLocaleLowerCase()))
								return { code: 'This code is already taken' };
							},
            max_amount: values => {
              if (!values.max_amount || values.max_amount < 1) {
                return {
                  max_amount: "Value must be greater than or equal to 1"
                }
              }
            }
					}}
          initialValues={initialValues}
          onSuccess={close}
          onSuccessMessage={type === 'edit' ? 'Promo edited' : 'Promo created'}
        >
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
              <div>
                <ModalContent>
                  <FastField
                    name="name"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Name"
                        help="A name for your promotion, will be displayed to the customer when checking out"
                        error={getFieldError(form, 'name')}
                      >
                        <Input type="text" {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="code"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Promo Code"
                        help="The promotional code that your customers must enter to use the promotion. Case-insensitive"
                        error={getFieldError(form, 'code')}
                      >
                        <Input type="text" {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="fixed_discount"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Fixed Price Discount"
                        help="Enter a fixed value discount that will be applied to the customers cart"
                      >
                        <InputGroup
                          iconHtml={
                            <p className="font-semi-bold">{currency.symbol}</p>
                          }
                        >
                          <Input
                            type="number"
                            step={currency.step}
                            min={0}
                            required={true}
                            {...field}
                          />
                        </InputGroup>
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="percent_discount"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Percent Discount"
                        help="Enter a % discount that will be applied to the customers cart"
                      >
                        <InputGroup
                          iconHtml={<p className="font-semi-bold">%</p>}
                        >
                          <Input
                            type="number"
                            step={0.01}
                            min={0}
                            max={100}
                            required={true}
                            {...field}
                          />
                        </InputGroup>
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="free_delivery"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Free Delivery"
                        help="Enabling this will reduce any delivery fee associated with the order to $0"
                      >
                        <Switch
                          id="promo-free-delivery"
                          checked={field.value}
                          onChange={e =>
                            setFieldValue('free_delivery', e.target.checked)
                          }
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="min_order"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Minimum Order"
                        help="The minimum value of the customers cart before this promotion can apply, doesn't include other fees"
                      >
                        <InputGroup
                          iconHtml={
                            <p className="font-semi-bold">{currency.symbol}</p>
                          }
                        >
                          <Input
                            type="number"
                            step={currency.step}
                            min={0}
                            {...field}
                          />
                        </InputGroup>
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="max_uses"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Maximum Number Of Uses"
                        help="The maximum number of times this promotion can be used totally, leave empty for unlimited"
                      >
                        <Input type="number" step={'1'} min={1} {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="max_amount"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Maximum Amount"
                        help="The maximum amount that this promo can be in total for the order"
                        error={getFieldError(form, 'max_amount')}
                      >
                        <InputGroup
                          iconHtml={
                            <p className="font-semi-bold">{currency.symbol}</p>
                          }
                        >
                          <Input
                            type="number"
                            step={'0.01'}
                            {...field}
                          />
                        </InputGroup>
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="services"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Services"
                        help="Select which services this promo will be valid for. Leave empty for it to be valid for all services"
                      >
                        <SelectAdv
                          type="multi"
                          value={field.value}
                          onChange={(options: string[]) => {
                            setFieldValue('services', options);
                          }}
                          options={ListServicesOptions}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="payment_methods"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Payment Methods"
                        help="Select which payment methods this promo will be valid for. Leave empty for it to be valid for all payment methods."
                      >
                        <SelectAdv
                          type="multi"
                          value={field.value}
                          onChange={(options: string[]) => {
                            setFieldValue('payment_methods', options);
                          }}
                          options={this.getPaymentMethods(R)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="times"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Order Times"
                        help="Select which order times this promo will be valid for, i.e. orders due immediately or pre-orders. Leave empty for it to be valid at all times"
                      >
                        <SelectAdv
                          type="multi"
                          value={field.value || []}
                          onChange={(options: string[]) => {
                            setFieldValue('times', options);
                          }}
                          options={ListOrderTimesOptions}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="once_per_customer"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Once Per Customer"
                        help="Allows a customer to only redeem this promotion only once. If a customer is not logged in, it resets if their browser cookies are cleared"
                      >
                        <Switch
                          id="promo-once-per-customer"
                          checked={field.value}
                          onChange={e =>
                            setFieldValue('once_per_customer', e.target.checked)
                          }
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="logged_in_only"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Logged In Customers Only"
                        help="This promo can only be redeemed by customers who have created an account and logged in"
                      >
                        <Switch
                          id="promo-logged_in_only"
                          checked={field.value}
                          onChange={e =>
                            setFieldValue('logged_in_only', e.target.checked)
                          }
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="valid_times"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Valid Dates / Times"
                        help="Set specific date-time ranges when this promotion can be used. Ignore this if the promotion is valid at all times"
                        error={getFieldError(form, 'valid_times')}
                      >
                        <Button
                          type="button"
                          color="primary-inverse"
                          size="xs"
                          onClick={() => {
                            form.setFieldValue('valid_times', [
                              ...field.value,
                              {
                                start: '',
                                end: '',
                              },
                            ] as T.Core.Business.BusinessDateTimeRanges);
                          }}
                        >
                          Add Date Time Range
                        </Button>
                        <DateTimeRanges
                          formats={R.settings.region.formats}
                          locale={R.settings.region.locale}
                          timezone={R.settings.region.timezone}
                          values={field.value}
                          onChange={values =>
                            setFieldValue('valid_times', values)
                          }
                          allowNone={true}
                          isHasParentModal={!!initialValues}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="auto_apply"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Automatically Apply Promotion"
                        help="Automatically apply the promotion to a customers order if the above requirements are met. The promo may be removed from the cart manually."
                      >
                        <Switch
                          id="promo-auto-apply"
                          checked={field.value}
                          onChange={e =>
                            setFieldValue('auto_apply', e.target.checked)
                          }
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="limit_to_dishes"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Limit To Items"
                        help="Use this to limit the promo code to certain items. If limited to specific items, the percent and fixed discount will be applied separately to every matching items"
                      >
                        <FormTreeSelect
                          id={`limit_to_dishes`}
                          selected={field.value || []}
                          nodes={RestaurantUtils.menu.getTreeFilter(
                            this.injected.store.restaurant!,
                            () => true
                          )}
                          onChange={values =>
                            setFieldValue('limit_to_dishes', values)
                          }
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="free_dishes"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Free Items"
                        help="Use this to designate items that can be added for free to a customers cart if the promo conditions above are matched"
                      >
                        <FormTreeSelect
                          id={`free_dishes`}
                          selected={Array.isArray(field.value) ? field.value : []}
                          nodes={RestaurantUtils.menu.getTreeFilter(
                            this.injected.store.restaurant!,
                            dish => dish.type !== 'combo'
                          )}
                          onChange={values =>
                            setFieldValue('free_dishes', values)
                          }
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="free_same_only"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Free Same Item Only"
                        help="If this option is enabled, the free quantity will only be applied if ordering multiple of the same item. For example you could have all your pizzas as part of the free items, but a customer would only get a free pizza if they buy 2 of the same type"
                      >
                        <Switch
                          id="free_same_only"
                          checked={field.value}
                          onChange={e =>
                            setFieldValue('free_same_only', e.target.checked)
                          }
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="free_qty"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Free Quantity"
                        help="The maximum quantity of items that can be redeemed for free with the above free items. Defaults to 1"
                      >
                        <Input
                          type="number"
                          step={'1'}
                          min={1}
                          {...field}
                          value={field.value || ''}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="free_required_purchase_qty"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Free Required Purchase Quantity"
                        help="Will only allow free items if this minimum purchase quantity has been met. Defaults to 1"
                      >
                        <Input
                          type="number"
                          step={'1'}
                          min={1}
                          {...field}
                          value={field.value || ''}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="disabled"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Disable Promotion"
                        help="Disable the usage of this promo code without deleting it"
                      >
                        <Switch
                          id="promo-disabled"
                          checked={field.value}
                          onChange={e =>
                            setFieldValue('disabled', e.target.checked)
                          }
                        />
                      </FormGroup>
                    )}
                  />
                </ModalContent>

                <ModalContent>
                  {error && <FormGroup error={error} />}
                  <Button
                    full={true}
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </ModalContent>
              </div>
            );
          }}
        </RestaurantForm>
      </Modal>
    );
  }
}
