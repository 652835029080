require("intl");
require("animate.css/source/_base.css");
require("animate.css/source/fading_entrances/fadeInDown.css");
require("animate.css/source/fading_exits/fadeOutDown.css");
require("react-quill/dist/quill.snow.css");
require("react-day-picker/lib/style.css");
require("intro.js/introjs.css");
require("./styles/app.scss");
import * as Sentry from "@sentry/browser";
import { config } from "./config";
import smoothscroll from "smoothscroll-polyfill";
import amplitude from "amplitude-js";
import { constants } from "./core/constants";
import { logger } from '@lib/common';

const stopRedirect = ["/login", "/reset-password-confirm", "/register", "/reset-password"];

document.addEventListener("visibilitychange", function () {	
	if (!document.hidden && !stopRedirect.includes(this.location.pathname)) {
		const value = localStorage.getItem(constants.storage_keys.auth_token);
		if (value && value.length == 2) {
			window.location.href = "/login";
		}
	}  
});

if (config.isProduction)
	localStorage.debug = "INFO,WARN,ERROR";
else
	localStorage.debug = "INFO,WARN,ERROR,DEV";

Sentry.init({
	dsn: process.env.NODE_ENV === "production" ? "https://ed36db08341b4194a84fa72a8db0d7c1@sentry.io/1385798" : "",
	enabled: process.env.NODE_ENV === "production",
	environment: process.env.NODE_ENV === "production" ? "production" : "development",
	integrations: [
		new Sentry.Integrations.Breadcrumbs(),
		// new Sentry.Integrations.InboundFilters(),
		new Sentry.Integrations.TryCatch(),
		new Sentry.Integrations.UserAgent(),
	],
	attachStacktrace: true,
	ignoreErrors: [
		// Random plugins/extensions
		"top.GLOBALS",
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		"originalCreateNotification",
		"canvas.contentDocument",
		"MyApp_RemoveAllHighlights",
		"http://tt.epicplay.com",
		"Can\"t find variable: ZiteReader",
		"jigsaw is not defined",
		"ComboSearch is not defined",
		"http://loading.retry.widdit.com/",
		"atomicFindClose",
		// Facebook borked
		"fb_xd_fragment",
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
		// reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268
		"bmi_SafeAddOnload",
		"EBCallBackMessageReceived",
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		"conduitPage",
	],
	blacklistUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
	],
});

Sentry.configureScope((scope) => {
	scope.setTag("version", "1.0.0");
});

amplitude.getInstance().init(config.services.amplitude.api_key, undefined, {
	domain: window.location.hostname,
	includeGclid: true,
	includeReferrer: true,
	includeUtm: true,
});

smoothscroll.polyfill();

window.onunhandledrejection = (e: any) => {
	console.log("Unhandled Promise Rejection", e);
  if (e instanceof Error) {
    Sentry.captureException(e);
  }
  logger.captureException(e);
};

require("./render");
