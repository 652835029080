import shortid from 'shortid';

export const indianMenu = [{
      "_id": "S1JgD8AGm",
      "name": "Indian Menu",
      "display_name": "Indian Menu",
      "description": "",
      "conditions": {
        "times": [],
        "services": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": 0,
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "CYn08BjDX",
          "menu_id": "S1JgD8AGm",
          "name": "Picked For You",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "6o6WKVeXM",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Butter Chicken ",
              "display_name": "",
              "price": 22,
              "description": "Smothered chicken breast sunk in velvety butter and tomato sauce",
              "subtitle": "",
              "image": {
                "_id": "b2ed9097-5d41-4fff-94c1-77fdebb9aff0",
                "name": "CL1.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkZyjPRzQ",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SwDI98tJT",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Pappadum ",
              "display_name": "",
              "description": "Thin crispy Indian crackers served with refreshing mint sauce",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "208252d0-bb0b-4b58-b3d7-5dd8c10d0686",
                "name": "EN1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "DWKi2nrTh",
                "tsR6yhMN"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rWecnFJQs",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Chicken Tikka Masala",
              "display_name": "",
              "price": 23,
              "description": "Saucy combination of onions, bell peppers, tomatoes, and ground cashews poured over cooked chicken breast tikka.",
              "subtitle": "",
              "image": {
                "_id": "68f2ca0a-3a1a-4295-8a51-a7547d46bdb2",
                "name": "CL5.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkZyjPRzQ",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "IW4XtVCKG",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Saagwala",
              "display_name": "",
              "price": 24,
              "description": "A zesty mixture of whole spices with cooked fenugreek, ginger, and garlic partnered with succulent spinach curry. (Can be served Dairy Free on request) ",
              "subtitle": "",
              "image": {
                "_id": "035d800f-82e1-42cf-a080-1a42cf1b9541",
                "name": "CL7.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "C2AF404MA",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Coconut Curry",
              "display_name": "",
              "price": 23,
              "description": "Authentic South Indian taste in seasoned onion and coconut curry with whole spices",
              "subtitle": "",
              "image": {
                "_id": "4c2de8c7-e8ae-460b-aa47-f8e1bd30b2db",
                "name": "CL8.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "y5AWYvRtf",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Samosa [3 Pieces]",
              "display_name": "",
              "description": "Traditional homemade pastry filled with spice potatoes and peas, served with mint sauce.",
              "subtitle": "",
              "price": 12,
              "tags": [
                "SJs4D8RGX",
                "rkKbuICfX"
              ],
              "image": {
                "_id": "ad9b96e3-b2b5-40ee-beea-648dc4137833",
                "name": "It_s_Mirchi__3_.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "DWKi2nrTh",
                "tsR6yhMN"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "f8S4fGSxr",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Spinach Onion Pakora",
              "display_name": "",
              "description": "Slice onion and fresh spinach fried in a mildly spiced batter",
              "subtitle": "",
              "price": 12,
              "tags": [
                "SJs4D8RGX",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "image": {
                "_id": "1509d785-53aa-4b7b-9297-f805406f2ee8",
                "name": "EN6.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "TaDdKeISe",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Chicken 65",
              "display_name": "",
              "description": "Rice flour-coated crispy chicken or cauliflower pieces served with sautéed onion, capsicum & a tangy sauce",
              "subtitle": "",
              "price": 19,
              "tags": [
                "rJWi4vICM7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "4a6eaafc-ceb5-41e8-bb23-f7e616669163",
                "name": "EN7GOBI.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "AQ3Qi-FL2",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Lentil Vegetable ",
              "display_name": "",
              "description": "Fresh vegetables and lentils sauteed and tempered into a healthy, wholesome curry ",
              "subtitle": "",
              "price": 22,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "0ad18951-a925-481b-8c9f-77665ce4e6a2",
                "name": "PB4.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ZPxdfVWVn",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Eggplant Potato",
              "display_name": "",
              "description": "A mild and versatile gourmet dish made from eggplants & diced potatoes",
              "subtitle": "",
              "price": 23,
              "tags": [
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HJh4P8AGm"
              ],
              "image": {
                "_id": "dd49274e-32a4-4279-ae2a-36428348ba78",
                "name": "PB8.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "MLcALWhI3",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Shahi Veg",
              "display_name": "",
              "price": 22.9,
              "description": "Vegetable cooked in a thick aromatic tomato gravy and topped with fresh cream.",
              "subtitle": "",
              "image": {
                "_id": "dba8c161-51b0-4a56-8bf2-6200d4f3d112",
                "name": "VE1_Veg.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Hocz4HEsQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Palak Paneer",
              "display_name": "",
              "price": 24,
              "description": "Fresh spinach leaves simmered with paneer, roasted spices and a touch of cream",
              "subtitle": "",
              "image": {
                "_id": "790671ea-0c88-4c0b-9d66-6bf2b7790214",
                "name": "VE4.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "r6iJa70CY",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Garlic & Cheese Naan",
              "display_name": "",
              "price": 7,
              "description": "All-purpose flour kneaded with cheese and garlic to make fluffy dainty naan",
              "subtitle": "",
              "image": {
                "_id": "96d85f24-e01c-4dd6-b438-a05a470ebc8b",
                "name": "BR3.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ZQSCUrFpV",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Saffron Rice ",
              "display_name": "",
              "price": 6,
              "description": "Yellow basmati rice with crushed saffron",
              "subtitle": "",
              "image": {
                "_id": "b1b4dd2a-4870-444b-9635-5585c8e1af91",
                "name": "RI4.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_taTI0mDc",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Chocolate Brownie",
              "display_name": "",
              "description": "Chocolaty, gooey, and delicious. These ‘healthier’ brownies are packed with dried fruit pieces and chopped nuts and can be enjoyed by vegans, vegetarians and veteran brownie-lovers alike.",
              "subtitle": "",
              "price": 15,
              "tags": [
                "ByBoVDI0z7",
                "ryDhpL-iX"
              ],
              "image": {
                "_id": "a520c966-6d41-4a71-aac1-2a011ec7f50c",
                "name": "DE3.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "M5D_a8smy",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Kombucha Raspberry Lemonade",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "a20447a4-1eaa-434d-96e0-c92ea85c4b89",
                "name": "65.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "8lZV9VUml",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Kombucha Ginger Lemon",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "88569eea-d551-4095-a834-cdea2106ea17",
                "name": "64.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ZOmXTBQKm",
              "menu_id": "S1JgD8AGm",
              "category_id": "CYn08BjDX",
              "name": "Mango Lassi",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.5,
              "tags": [],
              "image": {
                "_id": "79a335af-bf59-40fd-afd1-d95ea9879d80",
                "name": "66.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "E24xLD0Yd",
          "menu_id": "S1JgD8AGm",
          "name": "Super Saver",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "combo",
              "_id": "BkHRkKZsQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "E24xLD0Yd",
              "name": "House Special",
              "display_name": "",
              "price": 69.9,
              "description": "Your choice of entree, 2 curries, 2 rice, and 2 naan breads. Served with raita, mint sauce and 4 Pappadums **No offers or discounts can be apply to this item.",
              "subtitle": "**No offers or discounts can be apply to this item.",
              "image": {
                "_id": "962c814d-2618-45c0-b41c-bb8ade3849c3",
                "name": "WhatsApp_Image_2024_11_10_at_2_14_37_PM.jpeg",
                "modifiers": "-/preview/"
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [
                {
                  "_id": "30vfmeM5S",
                  "name": "Entree",
                  "dishes": [
                    "IZX7FDxxp"
                  ],
                  "selected": null,
                  "lpo": 0
                },
                {
                  "_id": "J7wIpNjUO",
                  "name": "4 Pappadum",
                  "dishes": [
                    "HM9k8xdcy"
                  ],
                  "selected": null,
                  "lpo": 5
                },
                {
                  "_id": "UjYbHBRAI",
                  "name": "Raita",
                  "dishes": [
                    "r1KoBDCMQ"
                  ],
                  "selected": null,
                  "lpo": 5
                },
                {
                  "_id": "ePPPiuEer",
                  "name": "2 Mint Sauce",
                  "dishes": [
                    "lfMq4hArd"
                  ],
                  "selected": null,
                  "lpo": 0
                },
                {
                  "_id": "BkCS-tbiQ",
                  "name": "1st Curry",
                  "dishes": [
                    "Syct6I0Gm",
                    "S1tSgv0GX",
                    "Byqugv0GQ",
                    "ByTkSPQ2X",
                    "ByubEDXhQ",
                    "rylbRDaXm",
                    "SkBU4vX2Q",
                    "B1fKVPm2Q",
                    "B15ZzPRG7",
                    "r17NM_bi7",
                    "H16MfvAGm",
                    "HytvMPCMX",
                    "SJDPT80GQ",
                    "SyinBwXhQ",
                    "grvG92FY",
                    "xnSi67zS",
                    "u-6K8V-O",
                    "Btb_7Ve-",
                    "Epxrc_4v",
                    "SJ8iGPCMm",
                    "HJnemwCfm",
                    "SyZHsPCzX",
                    "rkzput62m",
                    "S1-G7P0MX",
                    "Hy8dXP0fm",
                    "BJIvXPAGX",
                    "rkeq7PAG7",
                    "SkK27PAMX",
                    "H1Ss7DCfX",
                    "HJyAGwCfm"
                  ],
                  "lpo": 17.9,
                  "selected": null
                },
                {
                  "_id": "H1e2UJY-iQ",
                  "name": "2nd Curry",
                  "dishes": [
                    "B15ZzPRG7",
                    "r17NM_bi7",
                    "H16MfvAGm",
                    "HytvMPCMX",
                    "SJDPT80GQ",
                    "SyinBwXhQ",
                    "SJ8iGPCMm",
                    "HJnemwCfm",
                    "SyZHsPCzX",
                    "rkzput62m",
                    "S1-G7P0MX",
                    "Hy8dXP0fm",
                    "BJIvXPAGX",
                    "rkeq7PAG7",
                    "SkK27PAMX",
                    "H1Ss7DCfX",
                    "HJyAGwCfm",
                    "Syct6I0Gm",
                    "S1tSgv0GX",
                    "Byqugv0GQ",
                    "ByTkSPQ2X",
                    "ByubEDXhQ",
                    "rylbRDaXm",
                    "SkBU4vX2Q",
                    "B1fKVPm2Q",
                    "grvG92FY",
                    "xnSi67zS",
                    "u-6K8V-O",
                    "Btb_7Ve-",
                    "Epxrc_4v"
                  ],
                  "lpo": 17.9,
                  "selected": null
                },
                {
                  "_id": "Hy1I-F-sQ",
                  "name": "1st Rice Choice",
                  "dishes": [
                    "rJRB4HdmQ"
                  ],
                  "lpo": 1,
                  "selected": null
                },
                {
                  "_id": "K00UJPA0P",
                  "name": "2nd Rice Choice",
                  "dishes": [
                    "rJRB4HdmQ"
                  ],
                  "selected": null,
                  "lpo": 1
                },
                {
                  "_id": "S14KbKbiX",
                  "name": "1st Naan Choice",
                  "dishes": [
                    "rkQVVHumX"
                  ],
                  "lpo": 1,
                  "selected": null
                },
                {
                  "_id": "LHXiTTYqj",
                  "name": "2nd Naan Choice",
                  "dishes": [
                    "rkQVVHumX"
                  ],
                  "selected": null,
                  "lpo": 1
                },
                {
                  "_id": "_K8hSSqEn",
                  "name": "Drinks Add-on",
                  "dishes": [
                    "jrecyJ9w0"
                  ],
                  "selected": null,
                  "lpo": 0
                }
              ],
              "option_set_blacklist": [
                "SyY-ePWsm",
                "BkZyjPRzQ",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "6XQvXvUcW",
              "menu_id": "S1JgD8AGm",
              "category_id": "E24xLD0Yd",
              "name": "Meal Deal for Two",
              "display_name": "",
              "description": "Contains: - 4pc vegetarian mini samosas - Choice of two curries (chicken or vegetable) - One naan bread plain or garlic - Two serves of rice Butter Chicken Chicken Tikka Masala Chicken Khorma Pumpkin, Chickpeas & Spinach Salan Aloo Baingan Bagarey Paneer Butter Masala Palak Paneer Aloo Gobhi Tadka Dahl",
              "subtitle": "",
              "price": 42.9,
              "tags": [],
              "image": {
                "_id": "https://ucarecdn.com/fe0f5982-67a5-4b26-8441-eb75ddaae1cd/-/progressive/yes/2.jpeg",
                "name": "",
                "not_uc": true
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "-AQa-AmNI",
                "qvD8edFWR",
                "PGwagiVW7",
                "mlCMKD-b5"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "ru3h-zulf",
          "menu_id": "S1JgD8AGm",
          "name": "Entrees",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "HM9k8xdcy",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Pappadum",
              "display_name": "",
              "description": "Thin crispy Indian crackers served with refreshing mint sauce",
              "subtitle": "",
              "price": 5,
              "tags": [],
              "image": {
                "_id": "208252d0-bb0b-4b58-b3d7-5dd8c10d0686",
                "name": "EN1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "DWKi2nrTh",
                "tsR6yhMN"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "zfPP_NUmB",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Samosa [3 Pieces]",
              "display_name": "",
              "description": "Traditional homemade pastry filled with spice potatoes and peas, served with mint sauce",
              "subtitle": "",
              "price": 12,
              "tags": [
                "SJs4D8RGX",
                "rkKbuICfX"
              ],
              "image": {
                "_id": "ad9b96e3-b2b5-40ee-beea-648dc4137833",
                "name": "It_s_Mirchi__3_.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "DWKi2nrTh",
                "tsR6yhMN"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RxyErDinY",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Samosa Chaat",
              "display_name": "",
              "description": "Crispy homemade potato-filled pastry mixed with mint sauce, tamarind sauce, red onions, coriander & assorted  spices",
              "subtitle": "",
              "price": 16,
              "tags": [
                "rkKbuICfX"
              ],
              "image": {
                "_id": "63fc96b5-30dd-4b69-a27c-8a3de387926e",
                "name": "1.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "i0ehu6KHc",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Cauliflower Florets",
              "display_name": "",
              "description": "Cauliflower florets coated in a chickpeas batter & fried",
              "subtitle": "",
              "price": 12,
              "tags": [
                "SJs4D8RGX",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "image": {
                "_id": "28c38d60-254b-42ca-9b40-a87a6e73ecfb",
                "name": "EN3.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "B1mXYIRfX",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Chicken Tikka",
              "display_name": "",
              "price": 16,
              "description": "Boneless chicken marinated and roasted to perfection",
              "subtitle": "",
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "SJs4D8RGX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "image": {
                "_id": "8c62d21a-1240-454e-b321-90de74dca3b9",
                "name": "EN4.png",
                "modifiers": null
              },
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "nolwSLIx6",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Spinach Onion Pakora",
              "display_name": "",
              "description": "Slice onion and fresh spinach fried in a mildly spiced batter",
              "subtitle": "",
              "price": 12,
              "tags": [
                "SJs4D8RGX",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "image": {
                "_id": "1509d785-53aa-4b7b-9297-f805406f2ee8",
                "name": "EN6.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "6MxA8R8Mr",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Chicken 65",
              "display_name": "",
              "description": "Rice flour-coated crispy chicken or cauliflower pieces served with sautéed onion, capsicum & a tangy sauce",
              "subtitle": "",
              "price": 19,
              "tags": [
                "rJWi4vICM7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "4a6eaafc-ceb5-41e8-bb23-f7e616669163",
                "name": "EN7GOBI.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "DYrXWudw9",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Gobi 65",
              "display_name": "",
              "description": "Rice flour-coated crispy chicken or cauliflower pieces served with sautéed onion, capsicum & a tangy sauce",
              "subtitle": "",
              "price": 19,
              "tags": [
                "rJWi4vICM7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "8a61e002-76b3-4ce1-9507-7ffe36938f7f",
                "name": "EN7GOBI.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Bku5tLRfX",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Tawa Mushrooms",
              "display_name": "",
              "price": 17,
              "description": "Fresh cottage cheese or chunky mushrooms sauteed with cumin, onions, capsicums, and roasted spices",
              "subtitle": "",
              "image": {
                "_id": "ba5bb317-e822-489f-9bdf-1e7d3af4d401",
                "name": "EN8MUSH.png",
                "modifiers": null
              },
              "tags": [
                "rJWi4vICM7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HkIFsI-oX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "BJeqZwRfm",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Tawa Paneer",
              "display_name": "",
              "price": 17,
              "description": "Fresh cottage cheese or chunky mushrooms sauteed with cumin, onions, capsicums, and roasted spices",
              "subtitle": "",
              "image": {
                "_id": "83b93b47-6813-4413-b920-a6dbd2a26630",
                "name": "EN8PANEER.png",
                "modifiers": null
              },
              "tags": [
                "rJWi4vICM7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HkIFsI-oX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "gDdzkbWO6",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Fresh Broccoli Florets",
              "display_name": "",
              "description": "Fresh broccoli deep fried in a spicy batter",
              "subtitle": "",
              "price": 9.9,
              "tags": [
                "SJs4D8RGX",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "image": {
                "_id": "a1ba496a-d852-491f-ad5b-1241fe473239",
                "name": "Fresh-BROCCOLI-florets.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "d5fizSIu",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Eggplant Fritters",
              "display_name": "",
              "description": "A classic from the Bengali cuisine, the baingan pakora was know as a food for royals.",
              "subtitle": "",
              "price": 12.5,
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX",
                "rkKbuICfX",
                "ByBoVDI0z7"
              ],
              "image": {
                "_id": "ac5c7d8f-dbb6-4b67-a178-55d5909ff91f",
                "name": "Eggplant-fritters.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "31XcEHruN",
              "menu_id": "S1JgD8AGm",
              "category_id": "ru3h-zulf",
              "name": "Spinach Onion Pakora",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "HyEYr80fX",
          "menu_id": "S1JgD8AGm",
          "name": "Classics",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "SJ8iGPCMm",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Butter Chicken (GF) (SF)",
              "display_name": "",
              "price": 22,
              "description": "Smothered chicken breast sunk in velvety butter and tomato sauce",
              "subtitle": "",
              "image": {
                "_id": "b2ed9097-5d41-4fff-94c1-77fdebb9aff0",
                "name": "CL1.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkZyjPRzQ",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HJnemwCfm",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Mango Chicken (GF) (SF) (NF) ",
              "display_name": "",
              "price": 24,
              "description": "Fricasseed chicken breast coated in savory mango sauce. (Can be served Dairy Free on request)",
              "subtitle": "",
              "image": {
                "_id": "586f4d45-4b17-4739-aba4-bff4fbb38001",
                "name": "CL2.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkZyjPRzQ",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "BJIvXPAGX",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Vindaloo (GF) (SF) (NF)",
              "display_name": "",
              "price": 21,
              "description": "A spicy vinegar-tinged hailing from the West Coast of India prepared with cooked meat, covered in a combination of chili peppers, tomatoes, garlic, and ginger",
              "subtitle": "",
              "image": {
                "_id": "bf93d12a-3ea5-4d16-9123-298ede9b9946",
                "name": "CL3.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SkK27PAMX",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Jalfrezi (GF) (SF) (NF)",
              "display_name": "",
              "price": 23,
              "description": "Juicy chicken cooked with sliced onions and capsicums in roasted Indian spices",
              "subtitle": "",
              "image": {
                "_id": "8e5f3163-d58e-4a04-b827-8f8a7df8a9d5",
                "name": "CL4.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HJyAGwCfm",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Chicken Tikka Masala (GF) (SF) ",
              "display_name": "",
              "price": 23,
              "description": "Saucy combination of onions, bell peppers, tomatoes, and ground cashews poured over cooked chicken breast tikka",
              "subtitle": "",
              "image": {
                "_id": "68f2ca0a-3a1a-4295-8a51-a7547d46bdb2",
                "name": "CL5.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkZyjPRzQ",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Hy8dXP0fm",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Korma (GF) (SF)",
              "display_name": "",
              "price": 22,
              "description": "Groundnuts topped with the tanginess of tomatoes in silky fennel and curry leaves’ Indian Gravy (Can be served Dairy Free on request)",
              "subtitle": "",
              "image": {
                "_id": "64b2fc6e-c4f8-4c51-a65e-b410860c5692",
                "name": "CL6.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rkeq7PAG7",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Saagwala (GF) (NF)",
              "display_name": "",
              "price": 24,
              "description": "A zesty mixture of whole spices with cooked fenugreek, ginger, and garlic partnered with succulent spinach curry. (Can be served Dairy Free on request) ",
              "subtitle": "",
              "image": {
                "_id": "035d800f-82e1-42cf-a080-1a42cf1b9541",
                "name": "CL7.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rkzput62m",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Coconut Curry (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 23,
              "description": "Authentic South Indian taste in seasoned onion and coconut curry with whole spices",
              "subtitle": "",
              "image": {
                "_id": "4c2de8c7-e8ae-460b-aa47-f8e1bd30b2db",
                "name": "CL8.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "S1-G7P0MX",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Rogan Josh (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 21,
              "description": "Onion fried in Rogan oil added to marinated meat of your preference, with seasoning of Kashmiri spices",
              "subtitle": "",
              "image": {
                "_id": "b9d37096-8847-4151-b1bf-fee6b760179c",
                "name": "CL9.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "8I-yrq37O",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Balti",
              "display_name": "",
              "description": "A fusion of spicy Rogan Josh and fresh sauteed carrots, Broccoli, onion, ginger and Garlic",
              "subtitle": "",
              "price": 24,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "c979ed4e-dbec-459b-9a9b-b1974b8063cc",
                "name": "CL10.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "DWKi2nrTh",
                "tsR6yhMN",
                "BkLG8E9mm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "H1Ss7DCfX",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Kadai (GF) (SF)",
              "display_name": "",
              "price": 25,
              "description": "Wholesome Indian flavors of crushed pepper, onion capsicum, and coriander curry ",
              "subtitle": "",
              "image": {
                "_id": "068b11f8-68d3-4eb7-9b33-a1dce8fe2269",
                "name": "CL11.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SyZHsPCzX",
              "menu_id": "S1JgD8AGm",
              "category_id": "HyEYr80fX",
              "name": "Dhansak (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 20.9,
              "description": "The Parsi national dish (two words dhaan meaning rice and sak meaning the vegetable pot pourn and lentils ",
              "subtitle": "",
              "image": {
                "_id": "180e1ddc-2a41-4668-ae31-ddaca73b4b3a",
                "name": "DHANSAK.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "rJDxHubsX",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            }
          ]
        },
        {
          "_id": "r1qDBU0GQ",
          "menu_id": "S1JgD8AGm",
          "name": "Plant-Based",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "pepp-Y3MR",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Spinach Potato",
              "display_name": "",
              "description": "Diced potato and freshly chopped spinach sauteed in roasted spices and simmered in fresh coconut cream",
              "subtitle": "",
              "price": 21,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "cad7bbfe-316f-4560-b112-94a235170192",
                "name": "PB1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "DWKi2nrTh",
                "tsR6yhMN",
                "BkLG8E9mm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SkBU4vX2Q",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Vegetable Korma (GF) (SF) (DF)",
              "display_name": "",
              "price": 23,
              "description": "Lightly spiced mixed vegetables & nuts, simmered in a mild creamy sauce",
              "subtitle": "",
              "image": {
                "_id": "a01c5771-1454-4200-98b3-3d9a63f0e3ea",
                "name": "PB2.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Byqugv0GQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Lentil Spinach (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 21,
              "description": "Protein-packed homestyle lentil and spinach curry cooked in roasted Indian spices with garlic, tomato, onion & ginger",
              "subtitle": "",
              "image": {
                "_id": "d126eaf1-33d6-4755-9b5e-559cf296b1e6",
                "name": "PB3.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "u-6K8V-O",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Lentil Vegetable (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "Fresh vegetables and lentils sauteed and tempered into a healthy, wholesome curry ",
              "subtitle": "",
              "price": 22,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "0ad18951-a925-481b-8c9f-77665ce4e6a2",
                "name": "PB4.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "grvG92FY",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Mushroom Spinach (GF) (SF) (DF)",
              "display_name": "",
              "description": "Chunky mushrooms added in spinach curry made with creamy coconut milk for a scrumptious texture",
              "subtitle": "",
              "price": 24,
              "tags": [
                "rk9ZdLAMX",
                "rkKbuICfX",
                "ByBoVDI0z7",
                "HJh4P8AGm"
              ],
              "image": {
                "_id": "9d68889d-f99d-40b3-b01e-3f299e1444d6",
                "name": "PB5.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Syct6I0Gm",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Chickpea Potato (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 22,
              "description": "Diced potato & boiled chickpea tempered and cooked in red sauce",
              "subtitle": "",
              "image": {
                "_id": "d6487e47-aa66-42bb-acc3-1b9c9a0000c1",
                "name": "PB6.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Btb_7Ve-",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Jalfrezi Vegetable (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "A delectable veggie alternative to the Jalfrezi",
              "subtitle": "",
              "price": 23,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "c059016d-3538-42c3-a5ab-3c8de253ce38",
                "name": "PB7.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2HXVgo20w",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Eggplant Potato (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "A mild and versatile gourmet dish made from eggplants & diced potatoes",
              "subtitle": "",
              "price": 23,
              "tags": [
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HJh4P8AGm"
              ],
              "image": {
                "_id": "dd49274e-32a4-4279-ae2a-36428348ba78",
                "name": "PB8.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "D-hayvdrU",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Green Kofta (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "A chef-special vegan curry made from mid sized fried veggie balls simmered in a flavourful gravy",
              "subtitle": "",
              "price": 24,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "fc75357f-c459-4cc6-96a1-76b826bc3011",
                "name": "PB1.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "DWKi2nrTh",
                "tsR6yhMN",
                "BkLG8E9mm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "BypTT8CzX",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Kidney Beans Masala ",
              "display_name": "",
              "price": 17.9,
              "description": "A very famous home-style North Indian curry made with onion, tomato sauce and light seasoning",
              "subtitle": "",
              "image": {
                "_id": "053d4a4a-bcbd-4559-98fa-20639b4dac30",
                "name": "KIDNEY-BEAN-MASALA.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rk91A8Rfm",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Eggplant Masala",
              "display_name": "",
              "price": 21,
              "description": "Eggplant curry cooked with onion and tomato, finished with shallots",
              "subtitle": "",
              "image": {
                "_id": "8869e318-a1b7-4965-bbca-c0d2bea7c70a",
                "name": "EGGPLANT-MASALA.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "S1tSgv0GX",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Tadka Daal (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 18,
              "description": "Lentils cooked and sizzled with garlic and cumin",
              "subtitle": "",
              "image": {
                "_id": "a9da8c7e-9712-4119-96a5-49ffbaee071e",
                "name": "TADKA-DAAL.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ByTkSPQ2X",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Aloo Gobi (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 20,
              "description": "Traditional potato & cauliflower curry, simmered with onion and tomato sauce",
              "subtitle": "",
              "image": {
                "_id": "add1f558-b4c5-471a-bf47-2db9e107fd80",
                "name": "ALOO-GOBI.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HymjNDIAzX",
                "ByBoVDI0z7"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ByubEDXhQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Bombay Spiced Potato (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 18,
              "description": "Diced potato sautéed in garlic and dry res chilli with roasted spices, finished with shallots",
              "subtitle": "",
              "image": null,
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "B1fKVPm2Q",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Green Potato (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 20,
              "description": "Fresh spinach simmered with potato, roasted spices and a touch of cream (Can be made dairy free on request)",
              "subtitle": "",
              "image": {
                "_id": "a3009c76-2d6e-4674-8e6f-240c454fad06",
                "name": "GREEN-POTATO.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "xnSi67zS",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Pumpkin Coconut (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "This South Indian-inspired pumpkin coconut curry makes a warming and healthy lunch or dinner.",
              "subtitle": "",
              "price": 21,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "48c5f911-6feb-4af6-945a-b697e1a6fe54",
                "name": "Pumpkin-coconut.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Epxrc_4v",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Mushroom Pumpkin Masala (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "A healthy veg and spice curry with a an exquisite onion and tomato base.",
              "subtitle": "",
              "price": 18.9,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "b2cf26c5-3a42-4a35-96cf-c963912ae116",
                "name": "mushroom-masala.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HyaoT80fQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Green  Mung Masala (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 17.9,
              "description": "Home style mung curry with fresh tomato sauce, seasoned with ginger, garlic and light spices.",
              "subtitle": "",
              "image": {
                "_id": "0a200d1a-c976-4b19-8ae0-c60d77bca394",
                "name": "Green-Mung-Masala.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rylbRDaXm",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Veg Vindaloo (GF) (SF) (NF) (DF)",
              "display_name": "",
              "price": 17.9,
              "description": "A favourite among the hot food lovers one of the delicacies from the west coast of the it is chilli hot but delicious now you can have with mix veg as well ",
              "subtitle": "",
              "image": {
                "_id": "d14f99a1-189a-4f02-ab10-a60d5e6a9be9",
                "name": "VEG-VINDALOO-1.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "2U2Hc-SFE",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Kashmiri aloo (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 19.9,
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "fcWInL6lT",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Coconut Aloo (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 19.9,
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YF2FIgulR",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Masala Aloo (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 19.9,
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "AW2LU3QZP",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1qDBU0GQ",
              "name": "Mushroom Aloo (GF) (SF) (NF) (DF)",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 19.9,
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "SJOOBUCM7",
          "menu_id": "S1JgD8AGm",
          "name": "Vegetarian",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "B15ZzPRG7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJOOBUCM7",
              "name": "Shahi Paneer (GF) (SF)",
              "display_name": "",
              "price": 22.9,
              "description": "Cubed cottage cheese cooked in a thick aromatic tomato gravy and topped with fresh cream",
              "subtitle": "",
              "image": {
                "_id": "58ee5074-6fcb-47c0-850a-dfb1cbdcad16",
                "name": "VE1_Paneer.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HytvMPCMX",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJOOBUCM7",
              "name": "Shahi Kofta (GF) (SF)",
              "display_name": "",
              "price": 22.9,
              "description": "Cubed cottage cheese cooked in a thick aromatic tomato gravy and topped with fresh cream",
              "subtitle": "",
              "image": {
                "_id": "cddfb127-e4c3-4f55-894e-77aa558a27f4",
                "name": "VE1_Kofta.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "0KXYgW0ob",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJOOBUCM7",
              "name": "Shahi Veg (GF) (SF)",
              "display_name": "",
              "price": 22.9,
              "description": "Cubed cottage cheese cooked in a thick aromatic tomato gravy and topped with fresh cream",
              "subtitle": "",
              "image": {
                "_id": "dba8c161-51b0-4a56-8bf2-6200d4f3d112",
                "name": "VE1_Veg.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SyinBwXhQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJOOBUCM7",
              "name": "Paneer Kadhai (GF) (SF)",
              "display_name": "",
              "price": 24,
              "description": "A North-Indian vegetarian delicacy. Paneer Kadhai is cottage cheese simmered with onion, capsicum, and black pepper in a rich tomato and onion gravy ",
              "subtitle": "",
              "image": {
                "_id": "ac2f94db-9b2c-4ae7-9e15-fe37043a86e7",
                "name": "VE5.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "LEnn2XtYt",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJOOBUCM7",
              "name": "Chickpea Paneer (GF) (SF)",
              "display_name": "",
              "description": "Cubed cottage cheese and boiled chickpeas tempered and cooked with flavourful Indian spices",
              "subtitle": "",
              "price": 22.9,
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "29e37332-efa6-4c1f-8df1-d6fc6541ab17",
                "name": "VE3.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "DWKi2nrTh",
                "tsR6yhMN",
                "BkLG8E9mm"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "H16MfvAGm",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJOOBUCM7",
              "name": "Palak Paneer (GF) (SF)",
              "display_name": "",
              "price": 24,
              "description": "Fresh spinach leaves simmered with paneer, roasted spices and a touch of cream",
              "subtitle": "",
              "image": {
                "_id": "790671ea-0c88-4c0b-9d66-6bf2b7790214",
                "name": "VE4.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SJDPT80GQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJOOBUCM7",
              "name": "Daal Makhani (GF) (SF)",
              "display_name": "",
              "price": 22,
              "description": "Whole urad pulses and kidney beans braised in spices and added in creamy tomato sauce",
              "subtitle": "",
              "image": {
                "_id": "c76c968c-db41-4006-bf16-8c7f0f02749f",
                "name": "VE2.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "r17NM_bi7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJOOBUCM7",
              "name": "Fresh Veg Butter Masala (GF) (SF)",
              "display_name": "",
              "price": 18.9,
              "description": "The same delicious flavours of our popular butter chicken  with only vegetable instead",
              "subtitle": "",
              "image": {
                "_id": "1ac5eb37-beb0-4304-ac50-9edabdd13015",
                "name": "FRESH-VEG-BUTTER-MASALA.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "rJkqSI0G7",
          "menu_id": "S1JgD8AGm",
          "name": "Seafood",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "HkakVwCz7",
              "menu_id": "S1JgD8AGm",
              "category_id": "rJkqSI0G7",
              "name": "Kerala Curry",
              "display_name": "",
              "price": 27,
              "description": "Prawns or Fish mixed with chopped ginger, onion & garlic simmered in fresh coconut cream and topped with dry spices",
              "subtitle": "",
              "image": {
                "_id": "635a2f39-c779-40e1-ba98-76e2cabb982c",
                "name": "SE1.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "Syn6wObsm",
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "H1dmud-s7",
              "menu_id": "S1JgD8AGm",
              "category_id": "rJkqSI0G7",
              "name": "Prawns  Tikka Masala",
              "display_name": "",
              "price": 27,
              "description": "Tempered capsicum, onion, & juicy prawns cooked in butter sauce",
              "subtitle": "",
              "image": {
                "_id": "bed77bee-4c76-4077-bd4f-2f9407c97aad",
                "name": "SE3.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN",
                "DWKi2nrTh"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            }
          ]
        },
        {
          "_id": "S1gMI8Cfm",
          "menu_id": "S1JgD8AGm",
          "name": "Breads",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "rkIEEPAM7",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Naan",
              "display_name": "",
              "price": 5,
              "description": "All-purpose flour dough kneaded and toasted to make a fluffy Indian flatbread",
              "subtitle": "",
              "image": {
                "_id": "0e965774-9163-4049-9a2b-72f63c4920d5",
                "name": "BR2_2.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HJEHEvRGm",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Garlic Naan",
              "display_name": "",
              "price": 6,
              "description": "Aromatic garlic mixed with all-purpose flour & rolled in a flatbread",
              "subtitle": "",
              "image": {
                "_id": "908b9a5a-e962-4e76-84c7-a09e22c40f7f",
                "name": "BR2.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "S1kuEwRfQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Spinach & Cheese Naan",
              "display_name": "",
              "price": 7,
              "description": "Unique combination of health and taste rolled together",
              "subtitle": "",
              "image": {
                "_id": "0d3a1109-c435-45ba-9869-c3c10f8145b9",
                "name": "BR3_2.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "BkWq4w0MX",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Garlic & Cheese Naan",
              "display_name": "",
              "price": 7,
              "description": "All-purpose flour kneaded with cheese and garlic to make fluffy dainty naan ",
              "subtitle": "",
              "image": {
                "_id": "96d85f24-e01c-4dd6-b438-a05a470ebc8b",
                "name": "BR3.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "r1IIVvRzm",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Peshwari Naan",
              "display_name": "",
              "price": 7,
              "description": "Chopped cashews, raisins, dates & desiccated coconut blended and stuffed in naan ",
              "subtitle": "",
              "image": {
                "_id": "a85d3297-ce35-4c12-83d5-0bfb53e44469",
                "name": "BR3_2.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "B1rP4DCGm",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "It’s Mirchi Special Naan",
              "display_name": "",
              "price": 8,
              "description": "Healthy flavourful naan straight out of it's Mirchi Kitchen stuffed with spinach, mushroom and onion",
              "subtitle": "",
              "image": {
                "_id": "dcea135c-c815-4712-a9e4-e9d09437342e",
                "name": "BR3.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "S1e7n80MQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Whole Grain Roti",
              "display_name": "",
              "price": 5,
              "description": "Plain Indian flatbread made with whole-grain wheat for a healthy meal",
              "subtitle": "",
              "image": {
                "_id": "8417b313-b3b3-4c0d-bb67-e0e33cab130d",
                "name": "BR7.png",
                "modifiers": null
              },
              "tags": [
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "rJ3vBwTXQ"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ryvYEPRz7",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Cheese Naan",
              "display_name": "",
              "price": 5.9,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "HktqNPCMQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Avocado & Onion Naan",
              "display_name": "",
              "price": 6.5,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "SkmiNPAGQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Mushroom & Onion Naan",
              "display_name": "",
              "price": 6.5,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "BJz34vCzm",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "Onion Kulcha",
              "display_name": "",
              "price": 5.9,
              "description": "Stuffed with diced onion, green chillies & fresh coriander",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "4LXGdRSUS",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1gMI8Cfm",
              "name": "New*Herb & Cheese  Naan ",
              "display_name": "",
              "price": 7.5,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            }
          ]
        },
        {
          "_id": "r1MwH8RMQ",
          "menu_id": "S1JgD8AGm",
          "name": "Plant Based Breads",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "r1_IhI0MX",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1MwH8RMQ",
              "name": "Vegan Naan",
              "display_name": "",
              "price": 7,
              "description": "Gluten-Free Indian Bread made with chickpea flour & tinge of rice flour",
              "subtitle": "",
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "image": {
                "_id": "0f061f4b-2166-4445-82bf-cb0c1e72367d",
                "name": "PBB1_2.png",
                "modifiers": null
              },
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Byj2fDboQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1MwH8RMQ",
              "name": "Vegan Garlic Naan",
              "display_name": "",
              "price": 7,
              "description": "Gluten free naan rubbed in garlic",
              "subtitle": "",
              "image": {
                "_id": "260c0838-f1b8-4ac3-8cc8-952a9fa262b4",
                "name": "PBB1_2.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "jvid47eyE",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1MwH8RMQ",
              "name": "Vegan Cheese & Garlic",
              "display_name": "",
              "description": "Doughy naan with the aroma of roasted garlic & the goodness of vegan cheese",
              "subtitle": "",
              "price": 8.5,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "4119edf0-da28-4515-9986-10e844d903b7",
                "name": "PBB1_2.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "r1JA3UAfX",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1MwH8RMQ",
              "name": "Vegan Gluten-free It’s Mirchi Naan",
              "display_name": "",
              "price": 6.5,
              "description": "Gluten free naan with onion, spinach, mushroom & avocado stuffing",
              "subtitle": "",
              "image": {
                "_id": "24b69c94-f84c-48f2-a208-8c1092f3af38",
                "name": "cb.PNG",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "YOwBHWXz",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1MwH8RMQ",
              "name": "Vegan Cauliflower Broccoli Naan",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.5,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "138bce23-34f3-4b29-b539-784269713b0d",
                "name": "cb.PNG",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            }
          ]
        },
        {
          "_id": "S1hf88AM7",
          "menu_id": "S1JgD8AGm",
          "name": "Rice",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "HkApVDRfQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Steamed Basmati Rice",
              "display_name": "",
              "price": 4,
              "description": "Long and slender rice grains that capture the uniqueness of Indian food",
              "subtitle": "",
              "image": {
                "_id": "f159f7f2-99f2-4e1c-9d6e-1732a50efee2",
                "name": "RI1.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rk6lSPAGX",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Saffron Rice",
              "display_name": "",
              "price": 6,
              "description": "Saffron added Basmati rice for that earthy flavor",
              "subtitle": "",
              "image": {
                "_id": "24681998-435e-4d55-96b8-165cc5afb61a",
                "name": "RI4.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Hk6jSvaQm",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Cauliflower Broccoli Rice",
              "display_name": "",
              "price": 9,
              "description": "A delicious no-carb option made with cauliflower and broccoli crumbs ",
              "subtitle": "",
              "image": {
                "_id": "10149b20-2e8e-48f8-92c5-da9d6836fab5",
                "name": "RI3.png",
                "modifiers": null
              },
              "tags": [
                "ByBoVDI0z7",
                "rkKbuICfX",
                "HJh4P8AGm",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HJpVHDCfQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Coconut Rice",
              "display_name": "",
              "price": 6,
              "description": "A South Indian rice dish made with rice and light spices mixed with roasted coconut ",
              "subtitle": "",
              "image": {
                "_id": "a15ab2f8-be51-4a86-af11-416d6a36b636",
                "name": "RI2.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HkU1SDCfQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Brown Rice",
              "display_name": "",
              "price": 6.5,
              "description": "Boiled brown rice (a healthier choice)",
              "subtitle": "",
              "image": {
                "_id": "653c8fa9-6c11-4933-b6a9-b483fe1cf8f0",
                "name": "BROWN-RICE.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SkrfrDCfm",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Vegetable Rice",
              "display_name": "",
              "price": 9.5,
              "description": "Basmati rice and tender morsels of vegetables cooked with ginger and garlic, finished with shallots",
              "subtitle": "",
              "image": {
                "_id": "19040754-6643-4014-9fbd-df201e631ca9",
                "name": "VEGETABLE-BASMATI-RICE.jpg",
                "modifiers": null
              },
              "tags": [
                "SJs4D8RGX",
                "HJh4P8AGm",
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "SJdXHwRMm",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Cumin Rice",
              "display_name": "",
              "price": 6.5,
              "description": "Basmati rice sautéed with ghee and cumin seeds",
              "subtitle": "",
              "image": {
                "_id": "4262f9a6-4278-41d9-8f34-143eafb4798a",
                "name": "CUMIN-RICE.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SkZUSPAGm",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Kashmiri Rice",
              "display_name": "",
              "price": 7.5,
              "description": "A popular North Indian style basmati rice cooked with sultanas and cashews, flavoured with cardamom and sugar",
              "subtitle": "",
              "image": {
                "_id": "3b316a5f-19a0-4f4e-8471-daecb497c708",
                "name": "KASHMIRI-RICE.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "ZWtppGzr",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Quinoa",
              "display_name": "",
              "description": "Boiled quinoa (a healthier choice).",
              "subtitle": "",
              "price": 6.5,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "XaweU2FR",
              "menu_id": "S1JgD8AGm",
              "category_id": "S1hf88AM7",
              "name": "Lemon Rice ",
              "display_name": "",
              "description": "Mildly spiced citrus rice sautéed with ginger, garlic onion, tomato and fresh lemon juice.",
              "subtitle": "",
              "price": 6.5,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            }
          ]
        },
        {
          "_id": "HJGS8L0M7",
          "menu_id": "S1JgD8AGm",
          "name": "Desserts",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "Skhv8PRG7",
              "menu_id": "S1JgD8AGm",
              "category_id": "HJGS8L0M7",
              "name": "Gulab Jamun",
              "display_name": "",
              "price": 12,
              "description": "Milk solids orbs soaked in sugar syrup & flavoured with cardamom and rose water make up India's favorite dessert",
              "subtitle": "",
              "image": {
                "_id": "b8220849-48d1-4a2e-b613-8495e47caca4",
                "name": "DE.png",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "wtcF1ELrA",
              "menu_id": "S1JgD8AGm",
              "category_id": "HJGS8L0M7",
              "name": "Mango Kulfi",
              "display_name": "",
              "description": "A light dessert similar to ice cream but with a desi touch. Indulge in the divine flavors of Mango Kulfi to top off a hearty meal",
              "subtitle": "",
              "price": 13,
              "tags": [],
              "image": {
                "_id": "fe0f5982-67a5-4b26-8441-eb75ddaae1cd",
                "name": "2.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "k6j5dqTy",
              "menu_id": "S1JgD8AGm",
              "category_id": "HJGS8L0M7",
              "name": "Chocolate Brownie",
              "display_name": "",
              "description": "Chocolaty brownies packed with chopped dry fruits and nuts",
              "subtitle": "",
              "price": 15,
              "tags": [
                "ByBoVDI0z7",
                "ryDhpL-iX",
                "HJh4P8AGm"
              ],
              "image": {
                "_id": "a520c966-6d41-4a71-aac1-2a011ec7f50c",
                "name": "DE3.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Syq_8PAM7",
              "menu_id": "S1JgD8AGm",
              "category_id": "HJGS8L0M7",
              "name": "Home-made Indian ice cream (Mango / Malai)",
              "display_name": "",
              "price": 9.9,
              "description": "Home-made Indian ice cream",
              "subtitle": "",
              "image": {
                "_id": "32ec23bc-5218-4f3e-bc66-ca4f5ce7b6f4",
                "name": "mango-pistachio-Kulfi.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "1sHbuFQg",
              "menu_id": "S1JgD8AGm",
              "category_id": "HJGS8L0M7",
              "name": "Stawberry Kulfi",
              "display_name": "",
              "description": "Our strawberry kulfi is an easy pick to cleanse the palette and leave you feeling fresh and satisfied.",
              "subtitle": "",
              "price": 10.5,
              "tags": [
                "HJh4P8AGm"
              ],
              "image": {
                "_id": "ec16d1dd-309d-487a-bd55-3816a0121908",
                "name": "STRAWBERRY-Kulfi.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            }
          ]
        },
        {
          "_id": "B1uQLLRzX",
          "menu_id": "S1JgD8AGm",
          "name": "Condiments",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "H1kKSPAGX",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Lime Pickle",
              "display_name": "",
              "price": 5,
              "description": "A homemade sour and citrusy side completing every Indian meal ",
              "subtitle": "",
              "image": {
                "_id": "3e9c2e62-6f36-4d55-b330-887b9ca9de49",
                "name": "It_s_Mirchi.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "r1KoBDCMQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Raita",
              "display_name": "",
              "price": 5,
              "description": "A refreshing North Indian dip made from cool whipped curd to refresh your palate ",
              "subtitle": "",
              "image": {
                "_id": "9690743e-1ef7-4b42-8c60-83468c7ae75c",
                "name": "It_s_Mirchi.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YZX141mE5",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Green Salad",
              "display_name": "",
              "description": "Fresh vegetables chopped and seasoned to complement your meal ",
              "subtitle": "",
              "price": 11,
              "tags": [],
              "image": {
                "_id": "bf1aa4b0-7344-40cf-9b64-a8a16623a923",
                "name": "2.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "eN8-5xbEX",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Onion Salad",
              "display_name": "",
              "description": "Paper-thin onion slices dressed and garnished with spices for an added crunch ",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "3bb95184-1758-445d-81a1-265aba905630",
                "name": "2.jpeg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "HyP3BD0zm",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Mango Chutney",
              "display_name": "",
              "price": 6,
              "description": "Adding a twist on every mom's classic pickle recipe, an exquisite dip made from raw mango for a sweet & tangy accompaniment",
              "subtitle": "",
              "image": {
                "_id": "6053d9cb-0e4a-4dbe-a98d-a321f93e4938",
                "name": "It_s_Mirchi.png",
                "modifiers": null
              },
              "tags": [
                "rk9ZdLAMX"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YnTn16hCt",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Chilli Pickle",
              "display_name": "",
              "description": "A spicy and flavourful pickle straight from the kitchens of It's Mirchi, made from fresh and spicy chillies and condiments",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "471a41a5-a9fa-4875-8620-a9d63516a017",
                "name": "Mirchi_Menu_FS_Spices.jpg",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Sy55Sw0zQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Accompaniments Pappadums",
              "display_name": "Pappadum",
              "price": 5,
              "description": "Thin crispy Indian crackers served with refreshing mint sauce",
              "subtitle": "",
              "image": {
                "_id": "2a001b92-77da-4569-b11f-c5394f21cbce",
                "name": "EN1.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "rk5trw0f7",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Mango Pickle",
              "display_name": "",
              "price": 3.9,
              "description": "",
              "subtitle": "",
              "image": {
                "_id": "eda15ace-8828-4043-a847-7bc603fad02d",
                "name": "It_s_Mirchi.png",
                "modifiers": null
              },
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "lfMq4hArd",
              "menu_id": "S1JgD8AGm",
              "category_id": "B1uQLLRzX",
              "name": "Mint Sauce",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          }
        },
        {
          "_id": "r1GNLICzm",
          "menu_id": "S1JgD8AGm",
          "name": "Kids",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "H1ZCrwCGX",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1GNLICzm",
              "name": "Kids - Chicken Nuggets",
              "display_name": "",
              "price": 12.5,
              "description": "Gourmet chicken nuggets made from quality ‘real’ chicken breast. An easy go-to for the kids",
              "subtitle": "",
              "image": {
                "_id": "b02a3114-54bb-448e-91ce-6a9127f67cc8",
                "name": "It_s_Mirchi__1_.png",
                "modifiers": null
              },
              "tags": [],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "ryIgLvCGQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1GNLICzm",
              "name": "Kids - Butter Chicken",
              "display_name": "",
              "price": 14.5,
              "description": "An easy favourite for kids. Braised chicken breast simmered in extra mild creamy tomato sauce",
              "subtitle": "",
              "image": {
                "_id": "83453b32-3444-4fee-8feb-454ac1ddb27c",
                "name": "72.png",
                "modifiers": null
              },
              "tags": [
                "rk9ZdLAMX",
                "HJh4P8AGm"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "S1BfIPAfQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1GNLICzm",
              "name": "Kids - Mango Chicken",
              "display_name": "",
              "price": 14.5,
              "description": "Braised chicken breast simmered in an extra mild mango sauce. Another winner with little ones",
              "subtitle": "",
              "image": {
                "_id": "ae3dd6af-4566-4fd8-b71f-af7295a8cd2f",
                "name": "71.png",
                "modifiers": null
              },
              "tags": [
                "SJs4D8RGX",
                "rkKbuICfX",
                "HJh4P8AGm"
              ],
              "status": null,
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "r15D9_bjm",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1GNLICzm",
              "name": "Kids - Chicken Korma",
              "display_name": "",
              "price": 12.5,
              "description": "An extra mild Indian gravy. Kids love the creamy and nutty flavour.",
              "subtitle": "",
              "image": {
                "_id": "c517d7cc-b08a-4a32-807e-375313e05b14",
                "name": "super-Chicken-korma.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "BJEKqdWsX",
              "menu_id": "S1JgD8AGm",
              "category_id": "r1GNLICzm",
              "name": "Chocolate Naan",
              "display_name": "",
              "price": 8.5,
              "description": "Yum, yum and yum! Kids will love the naan and chocolate combination. Just try not to lick their plate when they’re done.",
              "subtitle": "",
              "image": {
                "_id": "a3eb773a-f5c6-4b0b-a6c6-933e2d54abbc",
                "name": "Chocolate-naan.jpg",
                "modifiers": null
              },
              "tags": [
                "rkKbuICfX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            }
          ]
        },
        {
          "_id": "SJ_4L80f7",
          "menu_id": "S1JgD8AGm",
          "name": "Drinks",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "4qgpw896",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Soft Drink Can",
              "display_name": "",
              "description": "Coke / Coke Zero / Lemonade",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "bb3f3b03-2078-4428-9da1-a35fb03c38bf",
                "name": "It_s_Mirchi.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "r11YWlwE7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "bHT6Ud2a",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Mango Lassi",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.5,
              "tags": [],
              "image": {
                "_id": "79a335af-bf59-40fd-afd1-d95ea9879d80",
                "name": "66.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "xnRnLfwD",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Kombucha Raspberry Lemonade",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "b592d945-9ef8-4fc1-b5bd-b86c29b26fd2",
                "name": "65.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "JQk_NFRr",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Kombucha Ginger Lemon ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "88569eea-d551-4095-a834-cdea2106ea17",
                "name": "64.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "eMO7g6Z3z",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Bundaberg Ginger Beer",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "4ca1bf64-c0f8-4516-9741-f74c63730f18",
                "name": "62.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "WQZTTM8_ha",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Bundaberg Lemon, Lime & Bitters",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7,
              "tags": [],
              "image": {
                "_id": "e3851d0b-65ca-4a8f-8dc6-9243688bb152",
                "name": "63.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "0m_olFc_I",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": " Watermelon Pear Cold Pressed Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "ed30c65b-c6a6-4e24-a7e2-a9368ad7d2cc",
                "name": "68.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Vo50znj94",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Super Greens Cold Pressed Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "d57f3cf2-cdb5-422d-9e56-bcd5ed361781",
                "name": "69.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "K7yygnDY3",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Cloudy Apple Cold Pressed Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "701c84a8-3756-4a15-9c89-6af833d4388a",
                "name": "67.png",
                "modifiers": null
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "yGqsyq8U7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Wallaby still spring water",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "GST"
              ]
            },
            {
              "type": "standard",
              "_id": "rab4HZAA",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Strangelove Dry Ginger ALE",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.5,
              "tags": [],
              "image": {
                "_id": "13d1c1be-5a9c-4c0e-b698-8d1145d558e4",
                "name": "Strangelove.png",
                "modifiers": "-/preview/-/enhance/75/"
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "j62sPSAF",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Soda Water/Tonic Water",
              "display_name": "",
              "description": "Peach / Raspberry / Ginger Lemon / Cherry Plum",
              "subtitle": "",
              "price": 4.5,
              "tags": [],
              "image": {
                "_id": "babd1da0-4a7a-41bc-8c4d-98ae7b38ee6d",
                "name": "soda.png",
                "modifiers": "-/preview/-/enhance/79/"
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "rVgATHUE",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJ_4L80f7",
              "name": "Cold Pressed Juice",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6,
              "tags": [],
              "image": {
                "_id": "f40ca908-8c1e-4386-847d-2e77e3d48060",
                "name": "juice.png",
                "modifiers": "-/preview/-/enhance/74/"
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ]
        },
        {
          "_id": "HkIVj8Wim",
          "menu_id": "S1JgD8AGm",
          "name": "Healthy Entrée",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "Sy9LKI0f7",
              "menu_id": "S1JgD8AGm",
              "category_id": "HkIVj8Wim",
              "name": "Light Tandoori Prawns",
              "display_name": "",
              "price": 13.9,
              "description": "Prawns marinated and roasted in an oven",
              "subtitle": "",
              "image": {
                "_id": "8706801a-bd1f-4579-8da2-1143dccbfd12",
                "name": "LIGHT-TANDOORI-PRAWNS-FISH.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "SJs4D8RGX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "SJADnUbiX",
              "menu_id": "S1JgD8AGm",
              "category_id": "HkIVj8Wim",
              "name": "Garlic Prawns",
              "display_name": "",
              "price": 14.9,
              "description": "Delicacy of the seafood marinated with garlic and cream",
              "subtitle": "",
              "image": {
                "_id": "6ed6ad17-8877-4232-a04b-402d6120a1ec",
                "name": "GARLIC-PRAWNS.jpg",
                "modifiers": null
              },
              "tags": [
                "SJs4D8RGX",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HkIFsI-oX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "H1ho6LWs7",
              "menu_id": "S1JgD8AGm",
              "category_id": "HkIVj8Wim",
              "name": "Smokey Tawa Tofu ",
              "display_name": "",
              "price": 12.9,
              "description": "Tofu cooked and marinated with smokey BBQ sauce",
              "subtitle": "",
              "image": {
                "_id": "fcf45bd0-c4d9-458c-bd21-b496bd24a8f8",
                "name": "SMOKY-TAWA-TOFU-MUSHROOM.jpg",
                "modifiers": null
              },
              "tags": [
                "ryDhpL-iX",
                "SJs4D8RGX",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HkIFsI-oX",
                "ByBoVDI0z7"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "HkLuKU0MX",
              "menu_id": "S1JgD8AGm",
              "category_id": "HkIVj8Wim",
              "name": "Light Tandoori Broccoli",
              "display_name": "",
              "price": 12.9,
              "description": "Broccoli florets marinated in yoghurt base sauce and cooked in tandoor",
              "subtitle": "",
              "image": {
                "_id": "5b06b7fb-8b0b-44fe-9a87-3b89ef6d4e61",
                "name": "LIGHT-TANDOORIBROCCOLI.jpg",
                "modifiers": null
              },
              "tags": [
                "SJs4D8RGX",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HkIFsI-oX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            }
          ]
        },
        {
          "_id": "SJPnRSAGQ",
          "menu_id": "S1JgD8AGm",
          "name": "Healthy Curries",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "BJUhtI0zm",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJPnRSAGQ",
              "name": "Free-range Chicken Breast Saagwala",
              "display_name": "",
              "price": 26.9,
              "description": "Braised chicken breast cooked with spinach & onion, finished with fenugreek",
              "subtitle": "",
              "image": {
                "_id": "9125fa68-5b06-4786-ae02-e79e1ddc1dbf",
                "name": "Free-Range-Chicken-Saagwala-1.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "r1IAtURzX",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJPnRSAGQ",
              "name": "Free-range Chicken Breast Dhanshak",
              "display_name": "",
              "price": 26.9,
              "description": "Braised chicken breast cooked with lentils and finished with coriander",
              "subtitle": "",
              "image": {
                "_id": "e61d4470-2739-47f7-a666-72102a8b84e5",
                "name": "Free-Range-Chicken-Dhanshak-1.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "SkVl5LAG7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJPnRSAGQ",
              "name": "Organic Lamb Saagwala",
              "display_name": "",
              "price": 26.9,
              "description": "Organic lamb cooked with ginger, garlic, herbs & spices, finished with spinach and fenugreek",
              "subtitle": "",
              "image": {
                "_id": "d90a9f4c-1988-4241-943e-ef8eb9e80aa9",
                "name": "Free-Range-Chicken-Saagwala-1.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "HkVPZD-i7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SJPnRSAGQ",
              "name": "Organic Lamb Dhansak",
              "display_name": "",
              "price": 26.9,
              "description": "Organic lamb cook with lentil & spices finished with coriander",
              "subtitle": "",
              "image": {
                "_id": "4f299c4c-aa75-4f33-ac9b-c7050ee0e197",
                "name": "Free-Range-Chicken-Dhanshak-1.jpg",
                "modifiers": null
              },
              "tags": [
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "ByBoVDI0z7"
              ],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm",
                "tsR6yhMN"
              ],
              "price_type": "standard",
              "choices": []
            }
          ]
        },
        {
          "_id": "rk8wrLCMm",
          "menu_id": "S1JgD8AGm",
          "name": "Healthy Sides",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "BkqBpLCf7",
              "menu_id": "S1JgD8AGm",
              "category_id": "rk8wrLCMm",
              "name": "Roasted Pappadum",
              "display_name": "",
              "price": 4.5,
              "description": "Pappadums are traditionally fried and high in fat but roasted Pappadums are tastier and great for you",
              "subtitle": "",
              "image": {
                "_id": "ac80b669-829f-4399-aac9-861f6d9c032f",
                "name": "ROASTED-PAPPADUM.jpg",
                "modifiers": null
              },
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "price_type": "standard",
              "choices": []
            }
          ]
        },
        {
          "_id": "DKpoqKUbf",
          "menu_id": "S1JgD8AGm",
          "name": "Indian Salads",
          "display_name": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "EawzEEAfa",
              "menu_id": "S1JgD8AGm",
              "category_id": "DKpoqKUbf",
              "name": "It's Mirchi Salad Bowl",
              "display_name": "",
              "description": "Antibiotic-free chicken breast, oven roasted to perfection with cauliflower and broccoli rice, sunflower seeds, broccoli, cucumber, coconut tahini, chilli sauce and crispy shallots",
              "subtitle": "",
              "price": 22,
              "tags": [
                "ByBoVDI0z7",
                "rkKbuICfX",
                "rk9ZdLAMX",
                "HJh4P8AGm"
              ],
              "image": {
                "_id": "3dead08a-7f6a-41d3-9315-904e2211ea5b",
                "name": "ITS-MIRCHI-HEALTHY-BOWL-1.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "9-O3fQrV6",
              "menu_id": "S1JgD8AGm",
              "category_id": "DKpoqKUbf",
              "name": "Green Salad",
              "display_name": "",
              "description": "Delicious slices of fresh tomato and cucumber on a bed of baby spinach leaves.",
              "subtitle": "",
              "price": 5.9,
              "tags": [
                "ByBoVDI0z7",
                "HJh4P8AGm",
                "rkKbuICfX",
                "rk9ZdLAMX"
              ],
              "image": {
                "_id": "56706a6a-c96b-4d1e-914c-8e8403429631",
                "name": "GREEN-SALAD-1.jpg",
                "modifiers": null
              },
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            }
          ]
        },
        {
          "_id": "SkHLUUCzQ",
          "menu_id": "S1JgD8AGm",
          "name": "Specials",
          "display_name": " ",
          "dishes": [
            {
              "type": "standard",
              "_id": "BkzHgtbi7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Butter Chicken",
              "display_name": "Butter Chicken",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "SkI8lYZj7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Chicken Tikka Masala",
              "display_name": "Chicken Tikka Masala",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "S1QDlKZo7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Mango Chicken",
              "display_name": "Mango Chicken",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "r1-ulYWsm",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Coconut Beef",
              "display_name": "Coconut Beef",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "BycFgYbs7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Dhansak",
              "display_name": "Dhansak",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "S1eEgFZiX",
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "S13ceFbi7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Roganjosh",
              "display_name": "Roganjosh",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "S1eEgFZiX",
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "HyKigYWjm",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Korma",
              "display_name": "Korma",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "S1eEgFZiX",
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "HymagKZsm",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Kadai",
              "display_name": "Kadai",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "S1eEgFZiX",
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "r1BReFZs7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Vindaloo",
              "display_name": "Vindaloo",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "S1eEgFZiX",
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "rJPJWFbo7",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo - Saagwala",
              "display_name": "Saagwala",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "S1eEgFZiX",
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "SyvebKWiX",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Combo -  Masala",
              "display_name": "Masala",
              "price": 10,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "S1eEgFZiX",
                "BkLG8E9mm"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "ryRN0lv4Q",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "Hidden - Combo - Extras",
              "display_name": "Combo Extras",
              "price": 0,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "B1Aky-w4m"
              ],
              "price_type": "standard",
              "choices": []
            },
            {
              "type": "standard",
              "_id": "rJRB4HdmQ",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "s. Rice",
              "display_name": "Rice",
              "price": 1,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "BJiRNSO77"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": [],
              "print_name": "Rice"
            },
            {
              "type": "standard",
              "_id": "rkQVVHumX",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "s. Naan",
              "display_name": "Naan",
              "price": 1,
              "description": "",
              "subtitle": "",
              "image": null,
              "tags": [],
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "Hk1TNBOXX"
              ],
              "price_type": "standard",
              "choices": [],
              "taxes": [],
              "print_name": "Naan"
            },
            {
              "type": "standard",
              "_id": "IZX7FDxxp",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "s. Entree",
              "display_name": "Entree",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "f4lo-Cx_x"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": "Entree"
            },
            {
              "type": "standard",
              "_id": "jrecyJ9w0",
              "menu_id": "S1JgD8AGm",
              "category_id": "SkHLUUCzQ",
              "name": "s. Drinks",
              "display_name": "Drinks",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "PfywAmEkY"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": [],
              "print_name": "Drinks"
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "description": ""
        }
      ]
    }];

export const indianOptionSet = [
    {
      "_id": "BkZyjPRzQ",
      "name": "Butter Chicken / Mango Chicken / Chicken Tikka Masala",
      "display_name": "Chicken",
      "show_in_menu": true,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "r1q35D0fm",
          "name": "Regular",
          "quantity": 1,
          "price": ""
        },
        {
          "_id": "SJgq2cvRMm",
          "name": "Free-Range",
          "quantity": 0,
          "price": 6
        }
      ]
    },
    {
      "_id": "Hk1TNBOXX",
      "name": "Combo - Naan Type",
      "display_name": "Type",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": true,
        "min_options": 2,
        "max_options": 2,
        "free_amount": ""
      },
      "options": [
        {
          "_id": "B1R0fFZi7",
          "name": "Plain Naan",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "HJTtNrumm",
          "name": "Garlic Naan",
          "quantity": 0,
          "price": 2
        },
        {
          "_id": "r1xpFNr_mm",
          "name": "Cheese & Garlic Naan",
          "quantity": 0,
          "price": 4
        }
      ]
    },
    {
      "_id": "f4lo-Cx_x",
      "name": "Combo - Entree Type",
      "display_name": "Entree Choice",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "oodM7kAB6Y",
          "name": "Onion Spinach Pakora",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "KXSk7qU-4d",
          "name": "3 pcs Veg Samosa",
          "quantity": 0,
          "price": "6"
        },
        {
          "_id": "eqUVPKAO0r",
          "name": "Chicken Tikka",
          "quantity": 0,
          "price": "10"
        }
      ]
    },
    {
      "_id": "BJiRNSO77",
      "name": "Combo - Rice Type",
      "display_name": "Type",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "BkY64SuQ7",
          "name": "Steamed Basmati Rice",
          "quantity": 1,
          "price": ""
        },
        {
          "_id": "H1eFpNBu7X",
          "name": "Saffron Rice",
          "quantity": 0,
          "price": "2.5"
        },
        {
          "_id": "r1ljOY-jQ",
          "name": "Coconut Rice",
          "quantity": 0,
          "price": "2"
        }
      ]
    },
    {
      "_id": "rJ3vBwTXQ",
      "name": "Wholemeal Roti Type",
      "display_name": "Type",
      "show_in_menu": true,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "rJjUBwpX7",
          "name": "No Butter",
          "quantity": 1,
          "price": ""
        },
        {
          "_id": "rkeo8Hvp77",
          "name": "With Butter",
          "quantity": 0,
          "price": ""
        }
      ]
    },
    {
      "_id": "r11YWlwE7",
      "name": "Soft Drinks Choices",
      "display_name": "Drink Type",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "BJdwWewE7",
          "name": "Coke",
          "quantity": 1,
          "price": ""
        },
        {
          "_id": "SyKwWeDVQ",
          "name": "Coke Zero",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "B1jvWevE7",
          "name": "Lemonade",
          "quantity": 0,
          "price": ""
        }
      ]
    },
    {
      "_id": "PfywAmEkY",
      "name": "Add-ons: Soft Drinks Choices",
      "display_name": "Drink Add-on",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2OFm4mz7Qo",
          "name": "Coke",
          "quantity": 0,
          "price": "3.5"
        },
        {
          "_id": "2R1Q8N7j2e",
          "name": "Coke Zero",
          "quantity": 0,
          "price": "3.5"
        },
        {
          "_id": "cCmLe5c-rT",
          "name": "Lemonade",
          "quantity": 0,
          "price": "3.5"
        }
      ]
    },
    {
      "_id": "B1Aky-w4m",
      "name": "Hidden - Combo - Condiments",
      "display_name": "Condiments",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "HJ_R0xPEX",
          "name": "Mango Chutney",
          "quantity": 0,
          "price": 3
        },
        {
          "_id": "ryedCAxP4m",
          "name": "Mango Pickle",
          "quantity": 0,
          "price": 3
        },
        {
          "_id": "HJb_0Rlv4X",
          "name": "Lime Pickle",
          "quantity": 0,
          "price": 3
        }
      ]
    },
    {
      "_id": "rJDxHubsX",
      "name": "Meat Type (Classics)",
      "display_name": "Protein / Meat Type",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "HyyR4O-s7",
          "name": "Regular Chicken Breast",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "ryekRE_bom",
          "name": "Regular Lamb",
          "quantity": 0,
          "price": "2.5"
        },
        {
          "_id": "HJZyAE_bj7",
          "name": "Beef",
          "quantity": 0,
          "price": "1.5"
        },
        {
          "_id": "S1fJRV_Zjm",
          "name": "Free Range Chicken Breast",
          "quantity": 0,
          "price": 6
        }
      ]
    },
    {
      "_id": "Syn6wObsm",
      "name": "Barramundi or Prawns",
      "display_name": "Protein / Meat Type",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "rkA2w_Wi7",
          "name": "Barramundi",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "SJgCnw_biQ",
          "name": "Prawns",
          "quantity": 0,
          "price": ""
        }
      ]
    },
    {
      "_id": "S1eEgFZiX",
      "name": "Combo Meat Types",
      "display_name": "Protein / Meat Type",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "SyumxY-oX",
          "name": "Chicken",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "rJY7xtWs7",
          "name": "Lamb",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "rkeYXgFbom",
          "name": "Beef",
          "quantity": 0,
          "price": ""
        }
      ]
    },
    {
      "_id": "BkLG8E9mm",
      "name": "Spice Level",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": 1,
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "ry4WXE5mm",
          "name": "Mild",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "HygEb7VcXX",
          "name": "Medium",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "ryZEZQE97m",
          "name": "Western Hot",
          "quantity": 0,
          "price": ""
        },
        {
          "_id": "SJBZmVcmQ",
          "name": "Indian Hot",
          "quantity": 0,
          "price": ""
        }
      ]
    },
    {
      "_id": "DWKi2nrTh",
      "name": "Add-Ons [Rice, Sides, Bread, PBB]",
      "display_name": "Add-Ons",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "PsRzBuEz0",
          "name": "Steamed Basmati Rice",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "XM5PfpgZh",
          "name": "Saffron Rice",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "s4Yjj1fMs3",
          "name": "Cauliflower & Broccoli Rice",
          "price": "9",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "-eM2LT5cXv",
          "name": "Coconut Rice",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "8VcUg341fV",
          "name": "Naan",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "KmE8FlFm8n",
          "name": "Garlic Naan",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "wnDYZzNWZ",
          "name": "Spinach & Cheese Naan",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "qsX5TEXfXb",
          "name": "Garlic & Cheese Naan",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "3WMi6akEkb",
          "name": "Peshawari Naan",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Wyek3bUcch",
          "name": "It's Mirchi Naan",
          "price": "8",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "fmsf8-Jbg8",
          "name": "Whole Grain Roti",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "3udFkXy_-t",
          "name": "Vegan Naan [GF, NF, SF]",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "qNMKJtYON",
          "name": "Vegan Garlic Naan [GF, NF, SF] ",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "H-jq1vaZxL",
          "name": "Vegan Cheese Garlic Naan [GF, NF, SF]",
          "price": "8.5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "EWqsiMtX3E",
          "name": "Lime Pickle",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "3aklskuXT",
          "name": "Raita",
          "price": "5",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "WiEl8Av-bM",
          "name": "Green Salad",
          "price": "11",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "4MMCYxsBll",
          "name": "Onion Salad",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "Um6PK_3DUT",
          "name": "Mango Chutney",
          "price": "6",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "H7PAxkNbT",
          "name": "Chilli Pickle",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "2BUF5MEa3",
          "name": "Pappadums",
          "price": "5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "tsR6yhMN",
      "name": "Add-On [Drinks]",
      "display_name": "Add Drinks",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "0ysqrAXB",
          "name": "Coke",
          "price": "4.5",
          "quantity": 0
        },
        {
          "_id": "EhmsF-iX",
          "name": "Coke zero",
          "price": "4.5",
          "quantity": 0
        },
        {
          "_id": "zAJm3vGi",
          "name": "Lemonade",
          "price": "4.5",
          "quantity": 0
        },
        {
          "_id": "UTrJf0jf",
          "name": "Remedy Kombucha Raspberry",
          "price": "7",
          "quantity": 0
        },
        {
          "_id": "DiQ8nNsi",
          "name": "Remedy Kombucha Ginger Lemon",
          "price": "7",
          "quantity": 0
        },
        {
          "_id": "pLbLj65_",
          "name": "Watermelon Pear Juice",
          "price": "6",
          "quantity": 0
        },
        {
          "_id": "omqMDlYZ",
          "name": "Super Greens Juice",
          "price": "6",
          "quantity": 0
        },
        {
          "_id": "3Vq7IvRn2",
          "name": "Cloudy Apple",
          "price": "6",
          "quantity": 0
        },
        {
          "_id": "3euiugRV-",
          "name": "Bundaberg Ginger Beer",
          "price": "7",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "VYdvb2kzq1",
          "name": "Bundaberg Lemon, Lime & Bitters",
          "price": "7",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "-AQa-AmNI",
      "name": "Entree - Meal Deal for Two",
      "display_name": "Entree",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "eGEtD0ecE",
          "name": "Vegetable Samosas (4 pieces)",
          "price": "",
          "quantity": 1,
          "prices": {},
          "status": null
        }
      ]
    },
    {
      "_id": "qvD8edFWR",
      "name": "Meal Deal Curry - Meal Deal for Two",
      "display_name": "Meal Deal Curry",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": true,
        "min_options": 2,
        "max_options": 2,
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2VD6INzel8",
          "name": "Butter Chicken",
          "price": "",
          "quantity": 0,
          "prices": {},
          "status": null
        },
        {
          "_id": "UP1s2AWSO",
          "name": "Chicken Tikka Masala",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "YP_VIwWF5",
          "name": "Chicken Khorma",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "YYotC-KuRf",
          "name": "Pumpkin, Chickpeas & Spinach Salan",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "SkgraXWQCf",
          "name": "Aloo Baingan Bagarey",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "98XmdTNaGU",
          "name": "Aloo Gobhi",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "hqzTRU9sjG",
          "name": "Tadka Dahl",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "_gy4zDm2D",
          "name": "Paneer Butter Masala",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "lLChgOU4M",
          "name": "Palak Paneer",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "PGwagiVW7",
      "name": "Meal Deal Rice - Meal Deal for Two",
      "display_name": "Meal Deal Rice",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "RpDdo1G9Z3",
          "name": "Rice (2 qty)",
          "price": "",
          "quantity": 1,
          "prices": {},
          "status": null
        }
      ]
    },
    {
      "_id": "mlCMKD-b5",
      "name": "Meal Deal Naan - Meal Deal for Two",
      "display_name": "Meal Deal Naan",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "7iJDA5pyvu",
          "name": "Plain Naan",
          "price": "",
          "quantity": 0,
          "prices": {},
          "status": null
        },
        {
          "_id": "ADBEyeiTq",
          "name": "Garlic Naan",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    }
  ]

function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createIndianMenu = () => {
    return {
        indianMenu:updateIds(indianMenu), indianOptionSet:updateIds(indianOptionSet)
    }
}

