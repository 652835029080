import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input, SelectAdv } from '@lib/components';
import { DataPaygateCurrencies } from '@lib/common';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentPaygatePayweb;

@inject('store')
@observer
export class SettingsFormPaymentsPaygate extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.paygate_payweb || {
      enabled: false,
      label: '',
      services: [],
      paygate_id: '',
      secret: '',
      currency: '',
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.paygate_payweb = values;
          const update = { $set: { 'settings.payments.paygate_payweb': r.settings.payments.paygate_payweb } };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: any) => (
                  <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="paygate_id"
                render={({ field }: any) => (
                  <FormGroup title="PayGate ID" help="Your unique PayGate account ID">
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="secret"
                render={({ field }: any) => (
                  <FormGroup title="PayGate Secret" help="Your unique PayGate account secret">
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="currency"
                render={({ field }: any) => (
                  <FormGroup
                    title="Currency"
                    help="Select the currency you would like to accept charges in. Make sure you are authorized to accept charges in this currency and that it is supported"
                  >
                    <SelectAdv
                      type="single"
                      options={DataPaygateCurrencies}
                      value={field.value}
                      onChange={(code: string) => setFieldValue('currency', code)}
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="Payweb Paygate" setFieldValue={setFieldValue} />

              {error && <FormGroup error={error} />}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && 'Save'}
              </Button>
            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
