import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { FieldFont } from "@lib/components";
import { Switch } from "@lib/components";
// import { GoogleService } from "";
import { GoogleCreateService } from "../../../../../../../core/libs/google";
import { MobxComponent } from "../../../../../../../mobx/components";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { LinkTag } from "@lib/components";
import { config } from "../../../../../../../config"
import _ from 'lodash';

let GoogleService: any = {}

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["fonts"];

@inject("store") @observer
export class SettingsFormFonts extends MobxComponent<Props, State> {
	initialValues: FormValues;
	constructor(props: Props) {
		super(props);
		this.state = {};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.website.fonts;
		//
		const reseller = this.injected.store.reseller;
		GoogleService = GoogleCreateService(); //(config.services.google.api_key, reseller)
	}
	render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.fonts = values;
          const update = { $set: { 'website.fonts': r.website.fonts } };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">
              <FastField
                name="use_google"
                render={({ field }: any) => (
                  <>
                    <FormGroup
                      title="Use Google Fonts"
                      no_border
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Switch
                        id="use_google_font"
                        checked={_.isNil(field.value) ? true : field.value}
                        onChange={e => {
                          setFieldValue('use_google', e.target.checked);
                          if(!e.target.checked) {
                            //set default hosted fonts
                            setFieldValue('heading', {
                              category: "sans-serif",
                              family: "Roboto",
                              query: "Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic",
                            });
                            setFieldValue('body', {
                              category: "sans-serif",
                              family: "Lato",
                              query: "Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic",
                            });
                          }
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <p>
                      Loading of Google fonts may be prohibited in some countries. Disable this feature to use fonts from our server.
                        View all available fonts at{' '}
                        <LinkTag href="https://fonts.google.com" target="_blank">
                          https://fonts.google.com
                        </LinkTag>
                      </p>
                    </FormGroup>
                  </>
                )}
              />

              <FastField
                name="heading"
                shouldUpdate={(nextProps: any, currentProps: any) =>
                  nextProps.formik.values.use_google !== currentProps.formik.values.use_google
                  || nextProps.formik.values.heading !== currentProps.formik.values.heading
                }
                render={({ field }: any) => (
                  <FormGroup title="Heading Font">
                    <FieldFont
                      value={field.value.family}
                      onChange={font => setFieldValue('heading', font)}
                      google={GoogleService}
                      self_hosted={form.values.use_google == false}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="body"
                shouldUpdate={(nextProps: any, currentProps: any) =>
                  nextProps.formik.values.use_google !== currentProps.formik.values.use_google
                  || nextProps.formik.values.body !== currentProps.formik.values.body
                }
                render={({ field }: any) => (
                  <FormGroup title="Normal Font">
                    <FieldFont
                      value={field.value.family}
                      onChange={font => setFieldValue('body', font)}
                      google={GoogleService}
                      self_hosted={form.values.use_google == false}
                    />
                  </FormGroup>
                )}
              />

          <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <RotateLoader size={2} color="white" />}
            {!isSubmitting && "Save"}
          </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
	}
}
