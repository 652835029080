import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Input } from "@lib/components";
import { DataLocales, DistanceMeasures, FORMATS } from "@lib/common";
import { FieldTaxes } from "@lib/components";
import { Switch } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { DataTimezonesSelectValues } from "@lib/common";
import { SelectAdv } from "@lib/components";
import { DataCurrencies, DataCurrenciesList } from "@lib/common";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { ButtonGroup } from "@lib/components";
import { RestaurantUtils } from "@lib/common";
import moment from "moment";

interface Props { }
interface State { }
interface FormValues {
	name: string;
	locale: string;
	kmmile: string;
	timezone: string;
	formats: {
		date: string;
		time: string;
	};
	currency: T.Schema.Restaurant.RestaurantSchema["settings"]["region"]["currency"];
	taxes: T.Core.Business.BusinessTaxes;
	tax_in_prices: boolean;
	tax_on_tips: boolean;
	tax_on_delivery_fee: boolean;
	day_of_the_week: string;
}

@inject("store") @observer
export class SettingsFormGeneral extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.state = {};
		this.initialValues = {
			name: r.name,
			locale: r.settings.region.locale,
			kmmile: r.settings.region.kmmile,
			timezone: r.settings.region.timezone,
			formats: r.settings.region.formats || {
				date: "DD/MM/YYYY",
				time: "h:mm a", // HH:mm
			},
			currency: r.settings.region.currency,
			taxes: r.settings.region.tax.rates,
			tax_in_prices: r.settings.region.tax.in_prices,
			tax_on_tips: r.settings.region.tax.on_tips ?? true,
			tax_on_delivery_fee: r.settings.region.tax.on_delivery_fee ?? true,
			day_of_the_week: r.settings.region.day_of_the_week,
		};
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				initialValues={this.initialValues}
				submit={async (r, values) => {
					values.formats.date = values.formats.date.toUpperCase();
					r.name = values.name;
					r.settings.region.locale = values.locale;
					r.settings.region.kmmile = values.kmmile;
					r.settings.region.timezone = values.timezone;
					r.settings.region.formats = values.formats;
					r.settings.region.currency = values.currency;
					r.settings.region.tax.rates = values.taxes;
					r.settings.region.tax.in_prices = values.tax_in_prices;
					r.settings.region.tax.on_tips = values.tax_on_tips;
					r.settings.region.tax.on_delivery_fee = values.tax_on_delivery_fee;
					r.settings.region.day_of_the_week = values.day_of_the_week;

					const update = {
						$set: {
							"name": values.name,
							"settings.region.locale": values.locale,
							"settings.region.kmmile": values.kmmile,
							"settings.region.timezone": values.timezone,
							"settings.region.formats": values.formats,
							"settings.region.currency": values.currency,
							"settings.region.tax.rates": values.taxes,
							"settings.region.tax.in_prices": values.tax_in_prices,
							"settings.region.tax.on_tips": values.tax_on_tips,
							"settings.region.tax.on_delivery_fee": values.tax_on_delivery_fee,
							"settings.region.day_of_the_week": values.day_of_the_week,
						},
					};
					return { r, update };
				}}
				validators={{
					name: (values) => {
						if (!values.name)
							return { name: "Required - please enter a name for your store location" };
						return undefined;
					},
					locale: (values) => {
						if (!values.locale)
							return { locale: "Required - search and select your locale" };
						return undefined;
					},
					kmmile: (values) => {
						if (!values.kmmile)
							return { locale: "Required - search and select your preferred unit" };
						return undefined;
					},
					timezone: (values) => {
						if (!values.timezone)
							return { timezone: "Required - search and select your timezone" };
						return undefined;
					},
					"formats.date": (values) => {
						const dateFormatRegex: any = /^(D{1,2}|M{1,4})[-\/](D{1,2}|M{1,4})[-\/](Y{1,4})$/;
						
						const multiDateValidator = (value: string) => {
							return dateFormatRegex.test(value) && moment().format(value);
						};
						
						const isValidFormat = multiDateValidator(values.formats.date.toLocaleUpperCase());
						if (isValidFormat) {
							return undefined;
						} else {
							return { "formats.date": "Invalid date format" };
						}
					},
					currency: (values) => {
						if (!values.currency)
							return { currency: "Required - search and select your currency" };
						return undefined;
					},
					taxes: (values) => {
						if (values.taxes) {
							for (const tax of values.taxes) {
								if (!tax.rate || !tax.name) {
									return { taxes: `One of your taxes is missing a name or tax rate. Please ensure all the fields are completed` };
								}
								if (tax.rate > 100) {
									return { taxes: `Tax rate cannot exceed 100%`}
								}
							}
						}
						return undefined;
					},
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error, getFieldError }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FastField
								name="name"
								render={({ field }: any) => (
									<FormGroup
										title="Name"
										help="The name of this restaurant location, used throughout the system"
										error={getFieldError(form, "name")}>
										<Input type="text" {...field} required={true} />
									</FormGroup>
								)}
							/>

							<FastField
								name="locale"
								render={({ field }: any) => (
									<FormGroup
										title="System Locale"
										help="Determines your default store language and how certain dates / currencies are formatted"
										error={getFieldError(form, "locale")}>
										<SelectAdv
											type="single"
											options={DataLocales}
											value={field.value}
											onChange={(option: string) => setFieldValue("locale", option)}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="kmmile"
								render={({ field }: any) => (
									<FormGroup
										title="Kilometres/Miles"
										help="Determines your preferred distance unit"
										error={getFieldError(form, "kmmile")}>
										<SelectAdv
											type="single"
											options={DistanceMeasures}
											value={field.value}
											onChange={(option: string) => setFieldValue("kmmile", option)}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="timezone"
								render={({ field }: any) => (
									<FormGroup
										title="Timezone"
										help="Select the timezone this restaurant location resides in to ensure accurate timings"
										error={getFieldError(form, "timezone")}>
										<SelectAdv
											type="single"
											options={DataTimezonesSelectValues}
											value={field.value}
											onChange={(option: string) => setFieldValue("timezone", option)}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="formats.time"
								render={({ field }: any) => (
									<FormGroup
										title="Time Formatting"
										help="Determines how times are formatted for this store">
										<ButtonGroup
											size={"xs"}
											selected={field.value}
											options={[
												{ value: "h:mm a", name: "12 Hour (9:00pm)" },
												{ value: "HH:mm", name: "24 Hour (21:00)" },
											]}
											onSelect={(v) => setFieldValue("formats.time", v.value)}
											width={150}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="formats.date"
								render={({ field }: any) => (
									<FormGroup
										title="Date Formatting"
										help="Determines how dates are formatted. DD is the day, MM is the month and YYYY is year. Ensure characters are in uppercase. From example, DD/MM/YYYY will display dates as 21/03/2019"
										error={getFieldError(form, "formats.date")}
										>
										<Input
											type="text"
											required={true}
											placeholder="DD-MM-YYYY or MM/DD/YYYY"
											{...field}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="currency"
								render={({ field }: any) => (
									<FormGroup
										title="Currency"
										help="Select your store currency. This affects financial calculations and how your prices are displayed"
										error={getFieldError(form, "currency")}>
										<SelectAdv
											type="single"
											options={DataCurrenciesList}
											value={field.value ? field.value.code : null}
											onChange={(option: string) => {
												const currency = DataCurrencies[option as keyof typeof DataCurrencies];
												setFieldValue("currency", {
													code: currency.code,
													symbol: currency.symbol_native,
													precision: currency.decimal_digits,
												});
											}}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="taxes"
								render={({ field }: any) => (
									<FormGroup
										title="Sales Taxes"
										help="Taxes are calculated from top to bottom if compounded"
										optional={true}
										error={getFieldError(form, "taxes")}>
										<div className="m-t-2">
											<FieldTaxes
												treeData={
													RestaurantUtils.menu.getTreeFilter(this.injected.store.restaurant!, () => true)
												}
												taxes={field.value}
												onChange={(taxes) => {
													if (!field.value || field.value.length === 0) {
														setFieldTouched("taxes", false);
													}
													setFieldValue("taxes", taxes);
												}} />
										</div>
									</FormGroup>
								)}
							/>

							<FastField
								name="tax_in_prices"
								render={({ field }: any) => (
									<FormGroup
										title="Tax In Prices"
										help="Enable this if all your store prices are already inclusive of tax. If disabled, taxes will be calculated and added to an order's total cost">
										<Switch
											id="tax_in_prices"
											checked={field.value}
											onChange={(e) => setFieldValue("tax_in_prices", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="tax_on_tips"
								render={({ field }: any) => (
									<FormGroup
										title="Tax On Tips"
										help="Enable this to charge taxes on tips.">
										<Switch
											id="tax_on_tips"
											checked={field.value ?? true}
											onChange={(e) => setFieldValue("tax_on_tips", e.target.checked)} />
									</FormGroup>
								)}
							/>

							{/* <FastField
								name="tax_on_delivery_fee"
								render={({ field }: any) => (
									<FormGroup
										title="Tax On Delivery Fee"
										help="Enable this to charge taxes on delivery fee.">
										<Switch
											id="tax_on_delivery_fee"
											checked={field.value ?? true}
											onChange={(e) => setFieldValue("tax_on_delivery_fee", e.target.checked)} />
									</FormGroup>
								)}
							/> */}

							<FastField
								name="day_of_the_week"
								render={({ field }: any) => (
									<FormGroup
										title="First day of the week"
										help="Select your preferred start day of the week for weekly reports.">
										<SelectAdv
											type="single"
											options={[
												{label: 'Sunday', value: 'sun'},
												{label: 'Monday', value: 'mon'}]}
											value={field.value ? field.value : 'mon'}
											onChange={(option: string) => {
												setFieldValue("day_of_the_week", option);
											}}
										/>
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);
	}

}
