import React from 'react';
import { CommonText } from '@lib/components';
import styled from 'styled-components';
import { MENU_ITEM_TYPE } from '../constant';
import { calculateMomentAfterMinutes, isDurationCrossingNextDay } from '../../orders/sidebars/pauseServicesSidebar/constants';

const PauseTagWrapper = styled.div`
  background: #bc362f;
  padding: 2px 8px;
  gap: 10px;
  border-radius: 4px;
  margin-left: 24px;
  margin-right: 24px;
`;

type PauseTagProps = {
  pauseMenu: boolean | undefined;
  type: string;
  pauseDuration?: number;
  pauseStart?: number;
  isPauseInfinity?: boolean;
};

export const PauseTag: React.FC<PauseTagProps> = ({ pauseMenu = false, type, pauseDuration, pauseStart , isPauseInfinity}) => {
  const nextDayLabel = isDurationCrossingNextDay(pauseDuration, pauseStart) ? "tomorrow" : "";

  if (!pauseMenu || type !== MENU_ITEM_TYPE.DISH || !pauseStart) {
    return <></>;
  }
  const pauseLabel = !isPauseInfinity ? `Paused Until: ${calculateMomentAfterMinutes(pauseDuration, pauseStart)} ${nextDayLabel}` : `Pause Indefinitely`;
  return (
    <PauseTagWrapper>
      <CommonText size="small" weight={700} color="#fff">
        {pauseLabel}
      </CommonText>
    </PauseTagWrapper>
  );
};
