import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import { inject, observer } from 'mobx-react';
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  ModalContent,
  ModalTitle,
  RotateLoader,
  SelectAdv,
} from '@lib/components';
import { Untrusive, logger, RefundablePaymentMethods, RefundablePaymentMethodTypes, roundedNum } from '@lib/common';
import { MobxComponent } from '../../../../../mobx/components';
import { UI } from '../../../../../core/ui';

interface State {
  submitting: boolean;
  refundAmount: number;
  refundReason: T.Lib.Payments.PayMongo.RefundReason;
}

interface Props extends WithTranslation {
  paymentMethod: RefundablePaymentMethodTypes;
  order: T.Schema.Order.OrderSchema;
  restaurant: T.Schema.Restaurant.RestaurantSchema;
  showModal: boolean;
  onClose: () => void;
}

const paymongoRefundReason = [
  {
    value: 'duplicate',
    label: 'Duplicate',
  },
  {
    value: 'fraudulent',
    label: 'Fraudulent',
  },
  {
    value: 'requested_by_customer',
    label: 'Request By Customer',
  },
  {
    value: 'others',
    label: 'Others',
  },
] as Array<{
  value: T.Lib.Payments.PayMongo.RefundReason;
  label: string;
}>;

@inject('store')
@observer
class RefundModalClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      submitting: false,
      refundAmount: 0,
      refundReason: 'others',
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>, maxValue: number): void => {
    event?.preventDefault();

    const value = parseFloat(event.target.value);

    if (value >= maxValue) {
      this.setState({ refundAmount: maxValue });
      return;
    }

    if (value < 0) return;

    this.setState({ refundAmount: value });
  };

  closeModal = () => {
    this.setState({ refundAmount: 0 });
    this.props.onClose();
  };

  performRefund = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const proceed = confirm('Once refunded, it cannot be reversed. Refund the order?');
    if (!proceed) {
      return;
    }

    const paymentMethodToRefundMethod = {
      swish: this.injected.store.api.orderCreateSwishRefund,
      fiserv: this.injected.store.api.orderCreateFiservRefund,
      bambora_na: this.injected.store.api.orderCreateBamboraNaRefund,
      paymongo: this.injected.store.api.orderCreatePayMongoRefund,
    };

    const {
      paymentMethod,
      order: { _id: orderId },
      restaurant: { _id: restaurantId },
    } = this.props;

    try {
      Untrusive.start();
      let payload: T.API.DashboardOrderRefundRequest = {
        orderId,
        restaurantId,
        amount: this.state.refundAmount,
      };

      if (paymentMethod === 'paymongo') {
        payload = { ...payload, amount: payload.amount * 100, reason: this.state.refundReason };
      }

      const response = await paymentMethodToRefundMethod[paymentMethod](payload);

      if (response.outcome) {
        UI.notification.error(response.message, { timeout: 6000 });
      } else {
        UI.notification.success('Payment refunded');
      }
    } catch (e) {
      logger.captureException(e);
      UI.notification.error('An error occurred, try again soon or contact us', {
        timeout: 6000,
      });
    } finally {
      Untrusive.stop();
      this.closeModal();
    }
  };

  render() {
    const { t } = this.injected;
    const { paymentMethod, order, showModal } = this.props;

    const {
      payment: { currency, total, refunded_amount },
    } = order;

    const { submitting, refundAmount: refund_amount, refundReason: refund_reason } = this.state;

    const { step, precision } = this.injected.store.intl.s.currency;

    // Avoiding problems with JavaScript's weird decimal calculations
    const refundableAmount = roundedNum(total - (refunded_amount || 0), precision);

    const displayRefundAmount = t('currency', { value: refundableAmount });

    return (
      <Modal width="xs" active={showModal} preventClose id={`${paymentMethod}--refund-modal`} close={this.closeModal}>
        <ModalTitle className="round-top">
          <h4>{`${ _capitalize(paymentMethod)} - Refund A Payment`}</h4>
        </ModalTitle>

        <ModalContent>
          <FormGroup title={`Total Paid`}>
            <InputGroup iconHtml={<p className="font-semi-bold">{currency}</p>}>
              <Input value={total} readOnly />
            </InputGroup>
          </FormGroup>

          <FormGroup title={`Refunded`}>
            <InputGroup iconHtml={<p className="font-semi-bold">{currency}</p>}>
              <Input value={refunded_amount || '0.00'} readOnly />
            </InputGroup>
          </FormGroup>

          <FormGroup
            title={`Refund Amount (Max: ${displayRefundAmount})`}
          >
            <InputGroup iconHtml={<p className="font-semi-bold">{currency}</p>}>
              <Input
                type="number"
                min={0}
                max={refundableAmount}
                required={true}
                step={step}
                value={refund_amount}
                onChange={event => this.handleChange(event, refundableAmount)}
              />
            </InputGroup>
          </FormGroup>

          {paymentMethod === 'paymongo' && (
            <FormGroup title="Reason">
              <SelectAdv
                type="single"
                value={refund_reason}
                onChange={(value: string) =>
                  this.setState({ refundReason: value as T.Lib.Payments.PayMongo.RefundReason })
                }
                options={paymongoRefundReason}
              />
            </FormGroup>
          )}

          {paymentMethod === 'bambora_na' && (
            <FormGroup>
              <div>
                <p className="font-bold lhp">
                  Bambora NA doesn’t allow refunding two times with the exactly same amount.
                </p>
                <br />
                <p className="lhp">
                  Which means, you have previously refunded $10. You can not refund $10 on this transaction anymore, but
                  you can choose another amount, such as $10.01 and it will work as expected.
                </p>
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <Button
              full={true}
              color="primary"
              type="button"
              disabled={submitting || !refund_amount}
              onClick={this.performRefund}
            >
              {submitting && <RotateLoader size={2} color="white" />}
              {!submitting && 'Refund'}
            </Button>
          </FormGroup>
        </ModalContent>
      </Modal>
    );
  }
}

export const RefundModal = withTranslation()(RefundModalClass);
