import * as React from "react";
import { FastField, Form } from "formik";
import { Checkbox, FormGroup, SelectAdv } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { Input } from "@lib/components";
import { MobxComponent } from "../../../../../../../mobx/components";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import styled from "styled-components";
import { DataCountries } from "@lib/common";


export const NameContainer = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
align-self: stretch;
margin-bottom: 16px;
`

export const NameInput = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
flex: 1 0 0;
`

export const BusinessContainer = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
align-self: stretch;
margin-bottom: 16px;
`

export const AddressContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;

& .name-input-100 {
    width: 100%;
}

& .name-input-50 {
    width: 50%;
}
`

export const TwoColumn = styled.div`
display: flex;
align-items: flex-start;
gap: 20px;
align-self: stretch;
`

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["billing_info"];

@inject("store") @observer
export class SettingsFormBillingInfo extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.billing_info;
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.billing_info = values;
          const update = { $set: { "settings.billing_info": values } };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue, setFieldTouched } = form;
          return (
            <div className="p-4">

              <FormGroup no_border small_title title="Billing" />
              <FormGroup title="Business" no_border />
              <BusinessContainer>
                <NameInput className="name-input-100">
                  <label>Business Name</label>
                  <FastField
                    name="business_name"
                    render={({ field }: any) => (
                      <Input type="text" required  {...field}/> )} />
                </NameInput>
              </BusinessContainer>
              <FormGroup title="Contact Details" no_border />
              <NameContainer>
                <NameInput>
                  <label>First Name</label>
                  <FastField
                    name="first_name"
                    render={({ field }: any) => (
                      <Input type="text" required  {...field}/> )} />
                </NameInput>
                <NameInput>
                  <label>Middle Name</label>
                  <FastField
                    name="middle_name"
                    render={({ field }: any) => (
                      <Input type="text"   {...field}/> )} />
                </NameInput>
                <NameInput>
                  <label>Last Name</label>
                  <FastField
                    name="last_name"
                    render={({ field }: any) => (
                      <Input type="text" required  {...field}/> )} />
                </NameInput>
              </NameContainer>
              <NameContainer>
                <NameInput className="name-input-100">
                  <label>Phone Number</label>
                  <FastField
                    name="phone_number"
                    render={({ field }: any) => (
                      <Input type="text" required  {...field}/> )} />
                </NameInput>

                <NameInput className="name-input-100">
                  <label>Email</label>
                  <FastField
                    name="email"
                    render={({ field }: any) => (
                      <Input type="email" required  {...field}/> )} />
                </NameInput>
              </NameContainer>
              <FormGroup title="Address" no_border />
              <AddressContainer>
                <NameInput className="name-input-100">
                  <label>Street 1</label>
                  <FastField
                    name="street1"
                    render={({ field }: any) => (
                      <Input type="text" required  {...field}/> )} />
                </NameInput>
                <NameInput className="name-input-100">
                  <label>Street 2</label>
                  <FastField
                    name="street2"
                    render={({ field }: any) => (
                      <Input type="text"   {...field}/> )} />
                </NameInput>
                <TwoColumn>
                  <NameInput className="name-input-100">
                    <label>City</label>
                    <FastField
                      name="city"
                      render={({ field }: any) => (
                        <Input type="text" required  {...field}/> )} />
                  </NameInput>
                  <NameInput className="name-input-100">
                    <label>Zip Code</label>
                    <FastField
                      name="zip_code"
                      render={({ field }: any) => (
                        <Input type="text" required  {...field}/> )} />
                  </NameInput>
                </TwoColumn>
                <TwoColumn>
                  <NameInput className="name-input-100">
                    <label>State</label>
                    <FastField
                      name="state"
                      render={({ field }: any) => (
                        <Input type="text" required  {...field}/> )} />
                  </NameInput>
                  <NameInput className="name-input-100">
                    <label>Country</label>
                    <FastField
                      name="country"
                      render={({ field }: any) => (
                        <SelectAdv
                          type="single"
                          value={field.value}
                          onChange={(v: string) => setFieldValue("country", v)}
                          options={DataCountries.map(({ label }) => ({ label, value: label }))} />

                      )}/>
                  </NameInput>
                </TwoColumn>
              </AddressContainer>

              <FormGroup no_border help="">

              </FormGroup>
              <FormGroup no_border>
                <FastField
                  name="agree"
                  render={({ field }: any) => (
                    <>
                      <Checkbox
                        required
                        id="agree"
                        checked={field.value}
                        label={(     <span>Agree to <a href="https://www.cloudwaitress.com/privacy/" target="_blank" style={{color: '#0064C8', textDecoration: 'underline'}}>Data Privacy Policy</a>, <a href="https://www.cloudwaitress.com/terms/" target="_blank" style={{color: '#0064C8', textDecoration: 'underline'}}>Terms and Condition</a></span>)}
                        onChange={(e) => setFieldValue("agree", e.target.checked)} />

                    </>
                  )}
                />
              </FormGroup>

              {error && <FormGroup error={error} />}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white" />}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }

}
