import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { TabSelect } from "@lib/components";
import { Input } from "@lib/components";
import { Switch } from "@lib/components";
import { ServiceOrderTimeFields, ServiceAutoStatusFields, ServiceHoursField, CustomCheckoutField } from "./base";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { updateRestaurantNotificationOptions } from "../../../../../../../core/libs/receipt";

interface Props { }
interface State {
	tab: string;
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["table_booking"];

@inject("store") @observer
export class SettingsFormServicesTableBooking extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
		};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.services.table_booking;
	}

	render() {
		const { tab } = this.state;
		return (
			<div>
				<TabSelect
					id="accounts-tab-options"
					className="border-white-tb-10"
					hasBorder={true}
					screenWidth={this.injected.store.view.screen_width}
					onChange={(v) => this.setState({ tab: v.value })}
					value={tab}
					values={[
						{ label: "General", value: "0" },
						{ label: "Booking Timings", value: "1" },
						{ label: "Available Hours", value: "2" },
						{ label: "Custom Booking Fields", value: "3" },

						// { label: "Wait Times & Auto Statuses", value: "3" },
					]} />
				<RestaurantForm<FormValues>
					submit={async (r, values) => {
						r.settings.services.table_booking = values;
						const update = { $set: { "settings.services.table_booking": r.settings.services.table_booking } };
						return { r, update };
					}}
					initialValues={this.initialValues}
					onSuccess={async () => await updateRestaurantNotificationOptions(this.injected.store)}
					onError={() => UI.notification.error("An error occurred")}
					onSuccessMessage="Settings Updated"
				>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue } = form;
						return (
							<div className="p-4">

								<FormGroup>
									{tab === "0" && (
										<div>											
											<FastField
												name="enabled"
												render={({ field }: any) => (
													<FormGroup
														title="Enabled"
														help="Allows customers to making a table booking online">
														<Switch
															id="enable-pickup-switch"
															checked={field.value}
															onChange={(e) => setFieldValue("enabled", e.target.checked)} />
													</FormGroup>
												)}
											/>
											<FastField
												name="display_name"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Display Name"
														help="Override the service name. This will override the translation as well. Defaults to Table Booking."
													>
														<Input
															type="text"
															{...field}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="notes"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Notes"
														help="This will be shown to customers if they choose this order method">
														<Input type="text" {...field} />
													</FormGroup>
												)}
											/>
											<FastField
												name="max_people"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Maximum Number Of People"
														help="Maximum number of people allowed for a booking request. Leave blank for no limit">
														<Input
															type="number"
															min={1}
															step={1}
															value={field.value || form.values.limit_number_of_people || ""}
															{...field}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="min_people"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Minimum Number Of People"
														help="Minimum number of people allowed for a booking request. Leave blank for no minimum">
														<Input
															type="number"
															min={1}
															step={1}
															value={field.value || ""}
															{...field}
														/>
													</FormGroup>
												)}
											/>
										</div>
									)}
									{tab === "1" && <ServiceOrderTimeFields service="table_booking" form={form} />}
									{tab === "2" && <ServiceHoursField service="table_booking" error={(field) => getFieldError(form, field) as string | null | undefined} />}
									{/* {tab === "3" && <ServiceAutoStatusFields service="table_booking" values={form.values} />} */}
									{tab === "3" && <CustomCheckoutField service="table_booking" form={form} />}
								</FormGroup>

								{error && <FormGroup error={error} />}

								<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</Button>

							</div>
						);
					}}
				</RestaurantForm>
			</div>
		);
	}

}
