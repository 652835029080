import React from 'react';

export const RadioCircleCheck = ({ color = '#FEFEFE' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.25334 8.95996L6.10667 7.81329C6.00889 7.71551 5.88445 7.66663 5.73334 7.66663C5.58223 7.66663 5.45778 7.71551 5.36 7.81329C5.26223 7.91107 5.21334 8.03551 5.21334 8.18663C5.21334 8.33774 5.26223 8.46218 5.36 8.55996L6.88 10.08C6.98667 10.1866 7.11112 10.24 7.25334 10.24C7.39556 10.24 7.52 10.1866 7.62667 10.08L10.64 7.06663C10.7378 6.96885 10.7867 6.8444 10.7867 6.69329C10.7867 6.54218 10.7378 6.41774 10.64 6.31996C10.5422 6.22218 10.4178 6.17329 10.2667 6.17329C10.1156 6.17329 9.99112 6.22218 9.89334 6.31996L7.25334 8.95996ZM8 13.3333C7.26223 13.3333 6.56889 13.1932 5.92 12.913C5.27112 12.6328 4.70667 12.2529 4.22667 11.7733C3.74667 11.2933 3.36676 10.7288 3.08694 10.08C2.80712 9.43107 2.66703 8.73774 2.66667 7.99996C2.66667 7.26218 2.80676 6.56885 3.08694 5.91996C3.36712 5.27107 3.74703 4.70663 4.22667 4.22663C4.70667 3.74663 5.27112 3.36671 5.92 3.08689C6.56889 2.80707 7.26223 2.66698 8 2.66663C8.73778 2.66663 9.43111 2.80671 10.08 3.08689C10.7289 3.36707 11.2933 3.74698 11.7733 4.22663C12.2533 4.70663 12.6334 5.27107 12.9136 5.91996C13.1938 6.56885 13.3337 7.26218 13.3333 7.99996C13.3333 8.73774 13.1932 9.43107 12.9131 10.08C12.6329 10.7288 12.253 11.2933 11.7733 11.7733C11.2933 12.2533 10.7289 12.6334 10.08 12.9136C9.43111 13.1937 8.73778 13.3336 8 13.3333ZM8 12.2666C9.19111 12.2666 10.2 11.8533 11.0267 11.0266C11.8533 10.2 12.2667 9.19107 12.2667 7.99996C12.2667 6.80885 11.8533 5.79996 11.0267 4.97329C10.2 4.14663 9.19111 3.73329 8 3.73329C6.80889 3.73329 5.8 4.14663 4.97334 4.97329C4.14667 5.79996 3.73334 6.80885 3.73334 7.99996C3.73334 9.19107 4.14667 10.2 4.97334 11.0266C5.8 11.8533 6.80889 12.2666 8 12.2666Z"
      fill={color}
    />
  </svg>
);

export const PlayIconOutline = () => {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 1.3329C0.500075 1.09941 0.561464 0.870035 0.678026 0.667719C0.794587 0.465404 0.962233 0.297244 1.16419 0.180065C1.36615 0.0628864 1.59534 0.00079654 1.82883 7.61314e-06C2.06232 -0.000781314 2.29192 0.0597584 2.49467 0.17557L9.81733 4.35957C10.0213 4.47624 10.1908 4.64471 10.3086 4.84795C10.4265 5.05118 10.4886 5.28196 10.4886 5.5169C10.4886 5.75185 10.4265 5.98262 10.3086 6.18586C10.1908 6.38909 10.0213 6.55757 9.81733 6.67424L2.49467 10.8596C2.29192 10.9754 2.06232 11.0359 1.82883 11.0351C1.59534 11.0343 1.36615 10.9723 1.16419 10.8551C0.962233 10.7379 0.794587 10.5697 0.678026 10.3674C0.561464 10.1651 0.500075 9.93573 0.5 9.70224V1.3329ZM9.156 5.5169L1.83333 1.3329V9.70224L9.156 5.5169Z"
        fill="#4B4F5E"
      />
    </svg>
  );
};
