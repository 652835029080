import * as React from "react";
import { Droppable } from "react-beautiful-dnd";
import { StyledColumn, StyledList } from './component';
import Item from './item';

interface ColumnProps {
	col: {
		id: string;
		list: string[];
	};
	activeCol: string[],
	updateActiveCol: (colName: string) => void;
}

const Column: React.FC<ColumnProps> = ({ col: { list, id }, activeCol, updateActiveCol }) => {
	return (
		<Droppable droppableId={id}>
			{(provided) => (
				<StyledColumn>
					<StyledList {...provided.droppableProps} ref={provided.innerRef}>
						{list.map((text, index) => (
							<Item key={text} text={text} index={index} activeCol={activeCol.includes(text.split(".").pop()!)} updateActiveCol={updateActiveCol} />
						))}
						{provided.placeholder}
					</StyledList>
				</StyledColumn>
			)}
		</Droppable>
	);
};

export default Column;
