import React from 'react';
import { GenericPromo, PromoType } from '../type';
import { SettingsPromosForm } from '../../promos.form';
import { SettingsFreeItemPromosForm } from '../../free-item-promos/form';
import { constructPlainPromoInstance, promosByType } from '../util';

function getInitialFormValues(
  item: GenericPromo | null,
  type: PromoType
): GenericPromo | null {
  if (item !== null) return item;
  return constructPlainPromoInstance(type);
}

export const PromoItemModal = (props: {
  restaurant: T.Schema.Restaurant.RestaurantSchema;
  type: PromoType;
  activeId: string | null;
  shouldSkipOpening: boolean;
  onClose: () => void;
}) => {
  const { restaurant, type, activeId, shouldSkipOpening, onClose } = props;

  // As we're copying or removing promo, we don't want to show the form here.
  if (shouldSkipOpening) return null;

  let initialValues = null;

  if (activeId === null) {
    initialValues = getInitialFormValues(null, type);
  } else {
    const promos = promosByType(restaurant, type);
    const item = promos.find(p => p._id === activeId);
    initialValues = getInitialFormValues(item || null, type);
  }

  const actionType = activeId ? 'edit' : 'create';

  switch (type) {
    case 'discount':
      return (
        <SettingsPromosForm
          type={actionType}
          initialValues={
            initialValues as T.Schema.Restaurant.Promo.RestaurantPromo
          }
          close={onClose}
        />
      );
    case 'free_item':
      return (
        <SettingsFreeItemPromosForm
          promoType="free_item"
          type={actionType}
          initialValues={
            initialValues as T.Schema.Restaurant.FreeItemPromo.RestaurantFreeItemPromo
          }
          close={onClose}
        />
      );
    case 'conventional_discount':
      return (
        <SettingsFreeItemPromosForm
          promoType="conventional_discount"
          type={actionType}
          initialValues={
            initialValues as T.Schema.Restaurant.DiscountPromo.RestaurantConventionalDiscountPromo
          }
          close={onClose}
        />
      );
    default:
      return null;
  }
};
