import React, { useState, useEffect } from "react";
import { FormGroup, Switch, Input } from "@lib/components";
import { IconPicker, IconList } from "react-fa-icon-picker";

import {
	Modal,
	ModalContent,
	ModalTitle,
	Button,
	SelectAdv,
} from "@lib/components";

import { FaPlus, FaTrash } from "react-icons/fa";
import _get from "lodash/get";
import _findIndex from "lodash/findIndex";
import _isEmpty from "lodash/isEmpty";
import { UI } from "../../../../../../../../core/ui";

type FieldType = T.Schema.Restaurant.Services.CustomCheckoutField;

interface DropDownInputsProps {
	options: Array<string>;
	onOptionsChange: (value: Array<string>) => void;
}

interface ModalCustomCheckoutFieldProps {
	field: FieldType;
	onUpdate: (value: FieldType) => void;
	currentModal: string;
	modalType: "Add" | "Edit";
	closeModal: () => void;
}

const MAX_DROPDOWN_OPTIONS = 10;

const DropDownInputs = (props: DropDownInputsProps) => {
	const { onOptionsChange } = props;
	const [options, setOptions] = useState(
		props.options.length === 0
			? ["Sample Option 1", "Sample Option 2"]
			: props.options
	);

	useEffect(() => {
		onOptionsChange(options);
	}, []);

	return (
		<>
			{options.map((option, index) => (
				<FormGroup key={index} title={`#${index + 1}`}>
					<div className="flex-line centered">

						<Input
							type="text"
							value={option}
							onChange={(e) => {
								const optionsClone = [...options];
								optionsClone[index] = e.target.value;
								setOptions(optionsClone);
							}}
							onBlur={() => {
								onOptionsChange(options);
							}}
						></Input>

						{options.length > 2 && (
							<p className="m-l-2">
								<FaTrash
									onClick={() => {
										const optionsClone = [...options];
										optionsClone.splice(index, 1);
										setOptions(optionsClone);
										onOptionsChange(optionsClone);
									}}
								/>
							</p>
						)}
					</div>
				</FormGroup>
			))}

			{options.length < MAX_DROPDOWN_OPTIONS && (
				<div
					className="flex-line centered cursor"
					onClick={() => {
						const optionsClone = [...options];
						optionsClone.push(`New Option`);
						setOptions(optionsClone);
						onOptionsChange(optionsClone);
					}}
				>
					<p className="m-r-2">
						<FaPlus />
					</p>
					<p>Add Option</p>
				</div>
			)}
		</>
	);
};

export const ModalCustomCheckoutField = (
	props: ModalCustomCheckoutFieldProps
) => {
	const { field, onUpdate, currentModal, modalType, closeModal } = props;

	const [enabled, setEnabled] = useState(_get(field, "enabled", false));
	const [type, setType] = useState(_get(field, "type"));
	const [icon, setIcon] = useState(_get(field, "icon"));
	const [label, setLabel] = useState(_get(field, "label"));
	const [description, setDescription] = useState(_get(field, "description"));
	const [required, setRequired] = useState(_get(field, "required", false));
	const [dropdown_options, setDropDownOptions] = useState(
		_get(field, "dropdown_options", [])
	);

	useEffect(() => {
		setEnabled(_get(field, "enabled", false));
		setType(_get(field, "type"));
		setIcon(_get(field, "icon"));
		setLabel(_get(field, "label"));
		setDescription(_get(field, "description"));
		setRequired(_get(field, "required", false));
		setDropDownOptions(_get(field, "dropdown_options", []));
	}, [currentModal]);

	const handleUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		if (_isEmpty(label)) {
			UI.notification.error("Label field is required.");
			return;
		}

		if (type === "dropdown" &&
			dropdown_options.some((option) => _isEmpty(option.trim()))) {
			UI.notification.error(
				"Dropdown options are invalid. Please check again."
			);
			return;
		}

		return onUpdate({
			_id: currentModal,
			enabled,
			type,
			icon,
			label,
			description,
			required,
			dropdown_options,
		});
	};

	return (
		<Modal width={480} active={!!currentModal} close={() => closeModal()}>
			<ModalTitle className="round-top-sm">
				<h4>{`${modalType} Custom Field`}</h4>
			</ModalTitle>

			<ModalContent>
				<FormGroup
					title="Enabled"
					help="Show this custom field on the checkout form"
				>
					<Switch
						id="custom_checkout_fields.enabled"
						checked={enabled}
						onChange={(e) => setEnabled(e.target.checked)}
					/>
				</FormGroup>

				<FormGroup
					title="Icon"
					help="Choose the icon that will display on this custom field"
				>
					<IconPicker
						value={icon as IconList}
						onChange={(value: string) => setIcon(value)}
					/>
				</FormGroup>

				<FormGroup title="Label" help="Name of this custom field">
					<Input
						type="text"
						value={label}
						required={true}
						onChange={(e) => setLabel(e.target.value)}
					/>
				</FormGroup>

				<FormGroup
					title="Description"
					optional={true}
					help="Describe this field briefly"
				>
					<Input
						type="text"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</FormGroup>

				<FormGroup
					title="Type"
					help={
						type === "dropdown"
							? "Show options in a dropdown and let customers pick one."
							: type === "checkbox"
								? "Let the customer choose yes/no option."
								: type === "input"
									? "Let the customer fill out a text box."
									: "Choose the type that will display on this custom field"
					}
				>
					<SelectAdv
						type="single"
						options={[
							{
								label: "Dropdown",
								value: "dropdown",
							},
							{
								label: "Checkbox",
								value: "checkbox",
							},
							{
								label: "Text Input",
								value: "input",
							},
						]}
						value={type as string}
						onChange={(value: any) => {
							if (value === "checkbox") {
								setRequired(false);
							}
							setType(value);
						}}
					/>
				</FormGroup>

				<FormGroup
					title="Required"
					help="The customer has to answer this field to complete the checkout step. Apply on Dropdown and Text Input Types."
				>
					<Switch
						id="custom_checkout_fields.required"
						checked={required}
						onChange={(e) =>
							type !== "checkbox" && setRequired(e.target.checked)
						}
					/>
				</FormGroup>

				{type === "dropdown" && (
					<DropDownInputs
						options={dropdown_options}
						onOptionsChange={(options: Array<string>) => {
							setDropDownOptions(options);
						}}
					/>
				)}
			</ModalContent>

			<ModalContent>
				<Button full={true} color="primary" onClick={(handleUpdate)}>
					{modalType === "Add" && "Add"}
					{modalType === "Edit" && "Update"}
				</Button>
			</ModalContent>
		</Modal>
	);
};
