import shortid from 'shortid';

export const western_menu =  [
    {
      "_id": "sLRx8GKpR",
      "name": "Western Menu",
      "display_name": "Western Menu",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": true,
        "services": [],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": "",
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "D1xP7AURjh",
          "menu_id": "sLRx8GKpR",
          "name": "Lunch Menu",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272562",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Rump Steak",
              "display_name": "",
              "description": "MB4+ rump steak served w/ fries, salad & choice of creamy mushroom or pepper sauce",
              "subtitle": "",
              "price": 19,
              "tags": [
                "XEV79Encv"
              ],
              "image": {
                "_id": "e132f72e-0d07-4cca-9f65-90259ac80a8e",
                "name": "LS___RUMP_STEAK.jpg",
                "modifiers": "-/crop/716x726/173,202/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "212756",
                "212703",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272561",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Char Grilled Chicken Fillet",
              "display_name": "",
              "description": "Char grilled chicken fillet served w/ fries, salad & a choice of creamy mushroom or pepper sauce",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "73570429-5a9f-40c3-9d43-d21132d9f209",
                "name": "LS___CHAR_GRILLED_CHICKEN_FILLET_1_.jpg",
                "modifiers": "-/crop/651x651/236,255/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212756",
                "212703",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272560",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Chicken Schnitzel",
              "display_name": "",
              "description": "SServed w/ fries, salad & choice of gravy or creamy mushroom sauce",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "f99f7451-01ce-45e9-be92-e18dde42a4b3",
                "name": "LS___CHICKEN_SCHNITZEL.jpg",
                "modifiers": "-/crop/614x616/238,262/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212756",
                "212703",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272558",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Chicken Parmigiana",
              "display_name": "",
              "description": "Chicken schnitzel, cheese topped w/ napolitana sauce served w/ fries & salad",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "67aacdcb-5900-4ede-8f10-2e15cdce339d",
                "name": "LS___CHICKEN_PARMIGIANA.jpg",
                "modifiers": "-/crop/792x790/168,199/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212756",
                "212726",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "i8Mo1P_Ic",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Lava Chicken Wings",
              "display_name": "",
              "description": "Wings tossed in our special in-house smoky lava sauce served w/ fries",
              "subtitle": "",
              "price": 16,
              "tags": [],
              "image": {
                "_id": "6f68035a-5783-4166-8945-0d353264df8a",
                "name": "LS___LAVA_CHICKEN_WINGS.jpg",
                "modifiers": "-/crop/727x719/170,219/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272552",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Napoletana",
              "display_name": "",
              "description": "Tossed through, chicken, garlic, onion, fresh basil & tomato.",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "493ceacb-1af9-4757-ba96-116994754041",
                "name": "Napoletana.jpg",
                "modifiers": "-/crop/671x679/218,214/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272554",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Boscaiola",
              "display_name": "  Ls Boscaiola",
              "description": "Tossed in creamy sauce, mushrooms, shallots and turkey rashers",
              "subtitle": "",
              "price": 19,
              "tags": [],
              "image": {
                "_id": "f5547ead-8acf-4424-b8c6-7337a13afce6",
                "name": "BOSCAIOLA.jpg",
                "modifiers": "-/crop/661x651/236,247/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212734"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272564",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Cheese Burger",
              "display_name": "Ls Cheese Burger",
              "description": "Beef patty, American cheese, pickles, Spanish onions, special eruption sauce",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": {
                "_id": "45199d14-e547-4664-941a-a1888bd1d81c",
                "name": "LS___CHEESE_BURGER.jpg",
                "modifiers": "-/crop/906x429/79,436/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212760"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272551",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Beef Burger",
              "display_name": "",
              "description": "Beef patty, lettuce, cheese, tomatoes, Spanish onions, pickles & tomato sauce served w/ fries",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": {
                "_id": "aa796a6b-24bd-4769-a9ee-e88c9a82c530",
                "name": "LS___BEEF_BURGER.jpg",
                "modifiers": "-/crop/969x533/36,360/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212761"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272556",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Chicken Burger",
              "display_name": " Ls Chicken Burger",
              "description": "Char-grilled chicken fillet, cheese, lettuce, tomatoes & aioli, served w/ fries",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": {
                "_id": "77df47af-1ee5-427e-8e18-54eecb4f1dcc",
                "name": "LS___CHICKEN_BURGER.jpg",
                "modifiers": "-/crop/1010x484/21,333/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212762"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272557",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls Chicken Garden Salad",
              "display_name": "",
              "description": "Chicken, lettuce, tomatoes, capsicum, onions cucumbers and topped with aioli",
              "subtitle": "",
              "price": 15,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212689",
                "212758"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272563",
              "menu_id": "sLRx8GKpR",
              "category_id": "D1xP7AURjh",
              "name": "Ls S&P Squid Salad",
              "display_name": "",
              "description": "Crispy  salt and pepper squid tossed on a bed of crisp mix lettuce, cherry tomatoes, capsicum, Spanish onions, shredded carrots, drizzled with our homemade lime aioli dressing",
              "subtitle": "",
              "price": 15,
              "tags": [],
              "image": {
                "_id": "1e17ac7c-eb5d-4420-9de6-b50aeb4186c0",
                "name": "SALT___PEPPER_SQUID_SALAD.jpg",
                "modifiers": "-/crop/694x694/193,244/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212689",
                "212759"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [
              "pickup",
              "delivery",
              "dine_in"
            ],
            "hours": [
              {
                "day": "Monday",
                "open": "11:30",
                "close": "15:00",
                "h24": false
              },
              {
                "day": "Tuesday",
                "open": "11:30",
                "close": "15:00",
                "h24": false
              },
              {
                "day": "Wednesday",
                "open": "11:30",
                "close": "15:00",
                "h24": false
              },
              {
                "day": "Thursday",
                "open": "11:30",
                "close": "15:00",
                "h24": false
              },
              {
                "day": "Friday",
                "open": "11:30",
                "close": "15:00",
                "h24": false
              }
            ]
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "yX-HMvo7ny",
          "menu_id": "sLRx8GKpR",
          "name": "Appetisers",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272412",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Garlic Bread",
              "display_name": "",
              "description": "Toasted stone-baked sourdough w/ gourmet garlic butter",
              "subtitle": "",
              "price": 10.9,
              "tags": [],
              "image": {
                "_id": "c76affe4-8628-493f-b8ea-4b72e5955dac",
                "name": "GARLIC_BREAD.jpg",
                "modifiers": "-/crop/727x739/190,217/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212687"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272408",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Bruschetta Platter",
              "display_name": "",
              "description": "Fresh tomatoes, basil & onions w/ tarragon & olive oil on toasted stone-baked garlic sourdough",
              "subtitle": "",
              "price": 16.9,
              "tags": [],
              "image": {
                "_id": "357da56a-7818-4183-a411-d2c66691b9d1",
                "name": "BRUSCHETTA_PLATTER.jpg",
                "modifiers": "-/crop/770x772/190,199/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212688"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272413",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Mozzarella Sticks",
              "display_name": "",
              "description": "6 Fried mozzarella fingers served with smoky BBQ sauce",
              "subtitle": "",
              "price": 16.9,
              "tags": [],
              "image": {
                "_id": "bdd6fd1a-29a7-4726-9b5d-eee81d5ae497",
                "name": "MOZZARELLA_STICKS.jpg",
                "modifiers": "-/crop/672x672/238,239/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221358"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272410",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Cheesy Fries  ",
              "display_name": "",
              "description": "Steakhouse fries topped w/ a mix of 4 cheeses ( Mozzarella, Feta, Cheddar & Grana Padano)",
              "subtitle": "",
              "price": 15.9,
              "tags": [],
              "image": {
                "_id": "890172eb-408a-4ad4-abf5-d623f68edb6d",
                "name": "CHEESY_FRIES.jpg",
                "modifiers": "-/crop/825x840/130,159/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272409",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Lava Chicken Wings",
              "display_name": "",
              "description": "Wings tossed in our special in-house lava sauce topped with aioli",
              "subtitle": "",
              "price": 18.9,
              "tags": [],
              "image": {
                "_id": "573921e9-07ea-4199-847b-b7b832ab8410",
                "name": "LAVA_CHICKEN_WINGS.jpg",
                "modifiers": "-/crop/869x596/89,350/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221359"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272411",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Chilli Prawns Hot Pot",
              "display_name": "",
              "description": "Garlic prawns, olives, tossed in a creamy pink sauce, w/ a touch of chilli, served w/ garlic bread",
              "subtitle": "",
              "price": 28.9,
              "tags": [],
              "image": {
                "_id": "fd80df91-656c-451a-8fba-2a3d9c90814f",
                "name": "CHILLI_PRAWNS_HOT_POT_1_.jpg",
                "modifiers": "-/crop/822x820/148,166/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221360"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272414",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Nachos",
              "display_name": "",
              "description": "Corn chips topped w/ Beef Mexican mix, special 4 cheese sauce, sour cream, guacamole, tomato & corn salsa",
              "subtitle": "",
              "price": 25.9,
              "tags": [],
              "image": {
                "_id": "904ef2ab-5247-49eb-8049-26b5148fa3a9",
                "name": "NACHOS_2_.jpg",
                "modifiers": "-/crop/747x754/178,199/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221361"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272415",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Salt & Pepper Squid",
              "display_name": "",
              "description": "Crispy salt & pepper squid, tostadas, corn salsa drizzled in aioli",
              "subtitle": "",
              "price": 18.9,
              "tags": [],
              "image": {
                "_id": "c6cddafc-6cdf-456d-9bab-c1ba541179b7",
                "name": "SALT___PEPPER_SQUID.jpg",
                "modifiers": "-/crop/669x668/218,217/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221362"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272417",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Wagyu Spring Rolls",
              "display_name": "",
              "description": "4 pce of spring rolls stuffed w/ MB5 Wagyu, mozzarella, mushrooms & topped w/ grana padano",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": {
                "_id": "eb832d09-a796-4bbe-b896-bc3e111b45ae",
                "name": "WAGYU_SPRING_ROLLS.jpg",
                "modifiers": "-/crop/754x757/188,191/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272416",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Sweet Potato Fries",
              "display_name": "",
              "description": "Crispy hand cut sweet potato fries served w/ signature eruption sauce",
              "subtitle": "",
              "price": 16.9,
              "tags": [],
              "image": {
                "_id": "dfc11e52-65a2-4c0c-ab9e-e6b36cae6c7f",
                "name": "SWEET_POTATO_FRIES.jpg",
                "modifiers": "-/crop/767x770/183,186/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221363"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272407",
              "menu_id": "sLRx8GKpR",
              "category_id": "yX-HMvo7ny",
              "name": "Appetiser Combo",
              "display_name": "",
              "description": "Triple-deck combo of our 3 most popular entrées: salt & pepper squid, lava chicken wings & bruschetta platter",
              "subtitle": "",
              "price": 31.9,
              "tags": [],
              "image": {
                "_id": "3d5061a0-75fc-476e-b6a6-ae405aa353e3",
                "name": "APPETISER_COMBO_1_.jpg",
                "modifiers": "-/crop/684x926/198,121/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "hQkchNhsfQ",
          "menu_id": "sLRx8GKpR",
          "name": "Sides",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272456",
              "menu_id": "sLRx8GKpR",
              "category_id": "hQkchNhsfQ",
              "name": "Corn Cob",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": {
                "_id": "1a0e3c62-fcc2-46ec-bef2-8ae92f6a42de",
                "name": "CORN_COBS.jpg",
                "modifiers": "-/crop/674x674/195,239/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272457",
              "menu_id": "sLRx8GKpR",
              "category_id": "hQkchNhsfQ",
              "name": "Garden Salad",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": {
                "_id": "927f1465-0c4f-4a31-a9b3-c8add84433b3",
                "name": "Side_Salad.jpg",
                "modifiers": "-/crop/543x545/266,272/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272458",
              "menu_id": "sLRx8GKpR",
              "category_id": "hQkchNhsfQ",
              "name": "Steakhouse Fries",
              "display_name": "Steakhouse Fries",
              "description": "",
              "subtitle": "",
              "price": 7.9,
              "tags": [],
              "image": {
                "_id": "21af947a-bc1b-4eb9-a4b9-0103eb68b934",
                "name": "Hot_chips.jpg",
                "modifiers": "-/crop/704x701/170,227/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272459",
              "menu_id": "sLRx8GKpR",
              "category_id": "hQkchNhsfQ",
              "name": "Mash Potatoes",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.9,
              "tags": [],
              "image": {
                "_id": "3dae1f0f-5527-421d-84bd-1d8ff4a84f9d",
                "name": "MASH_POTATOES.jpg",
                "modifiers": "-/crop/538x548/281,292/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272460",
              "menu_id": "sLRx8GKpR",
              "category_id": "hQkchNhsfQ",
              "name": "Onion Rings",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 15.9,
              "tags": [],
              "image": {
                "_id": "b768e323-78ff-4256-b202-c6f30b6cd9f4",
                "name": "ONION_RINGS.jpg",
                "modifiers": "-/crop/870x873/95,126/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272461",
              "menu_id": "sLRx8GKpR",
              "category_id": "hQkchNhsfQ",
              "name": "Potato Wedges",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": {
                "_id": "ec314558-ab4b-4dd2-9785-a64b192ae840",
                "name": "Potato_Wedges.jpg",
                "modifiers": "-/crop/714x719/150,229/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272462",
              "menu_id": "sLRx8GKpR",
              "category_id": "hQkchNhsfQ",
              "name": "Steamed Veggies",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.9,
              "tags": [],
              "image": {
                "_id": "277baaf6-0da5-433e-94da-b0ba40d3acfd",
                "name": "STEAMED_VEGGIES.jpg",
                "modifiers": "-/crop/641x613/236,252/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "gBQ7LFmCXb",
          "menu_id": "sLRx8GKpR",
          "name": "Salads",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272423",
              "menu_id": "sLRx8GKpR",
              "category_id": "gBQ7LFmCXb",
              "name": "Caesar Salad",
              "display_name": "",
              "description": "Baby cos lettuce, turkey rashers, croutons, boiled egg & parmesan cheese, drizzled w/ Caesar anchovies dressing",
              "subtitle": "",
              "price": 21.9,
              "tags": [],
              "image": {
                "_id": "7ec4d659-0ba0-487b-82cb-fb3d2ab8da12",
                "name": "CAESAR_SALAD.jpg",
                "modifiers": "-/crop/661x673/218,212/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212713",
                "212689"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272424",
              "menu_id": "sLRx8GKpR",
              "category_id": "gBQ7LFmCXb",
              "name": "Salt & Pepper Squid Salad",
              "display_name": "",
              "description": "Crispy salt and pepper squid tossed on a bed of crisp mix lettuce, cherry tomatoes, Spanish onions, drizzled with our homemade lime aioli dressing",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": {
                "_id": "5e07d84e-ecf3-4a13-aeef-a6e48c9a74b5",
                "name": "SALT___PEPPER_SQUID_SALAD.jpg",
                "modifiers": "-/crop/699x694/190,244/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212714",
                "212689"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272425",
              "menu_id": "sLRx8GKpR",
              "category_id": "gBQ7LFmCXb",
              "name": "Schnitzel Salad",
              "display_name": "",
              "description": "Chicken schnitzel w/ cos lettuce, cucumbers, cherry tomatoes, onions, capsicum, olives, feta cheese, tostadas, oregano, dressed w/ our special honey mustard sauce *contains nuts*",
              "subtitle": "",
              "price": 24.9,
              "tags": [],
              "image": {
                "_id": "7f66f32b-b323-4ded-a54f-73cfbfccfced",
                "name": "SCHNITZEL_SALAD.jpg",
                "modifiers": "-/crop/661x666/206,255/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212715",
                "212689"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272426",
              "menu_id": "sLRx8GKpR",
              "category_id": "gBQ7LFmCXb",
              "name": "Southwest Chicken Salad",
              "display_name": "",
              "description": "Char-grilled chicken fillet, feta, jalapeños, baby spinach, rocket, corn salsa, crispy tostadas & fresh avocado, dressed w/ our lava hot chipotle",
              "subtitle": "",
              "price": 25.9,
              "tags": [],
              "image": {
                "_id": "3edaeb23-4d71-48a3-8996-6d2df9fb7264",
                "name": "SOUTHWEST_CHICKEN_SALAD_1_.jpg",
                "modifiers": "-/crop/636x636/223,257/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212716",
                "212689"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "M6s3niOAOR",
          "menu_id": "sLRx8GKpR",
          "name": "Burgers",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272429",
              "menu_id": "sLRx8GKpR",
              "category_id": "M6s3niOAOR",
              "name": "Wagyu Double  Cheese Burger",
              "display_name": "",
              "description": "2 Wagyu Beef patty, American cheese, crinkle cut pickles, Spanish onions, special eruption sauce",
              "subtitle": "",
              "price": 26.9,
              "tags": [],
              "image": {
                "_id": "df8aed1b-240c-496b-92c4-e770838507cc",
                "name": "WAGYU_DOUBLE_CHEESE_BURGER.jpg",
                "modifiers": "-/crop/1058x578/0,295/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212717"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272431",
              "menu_id": "sLRx8GKpR",
              "category_id": "M6s3niOAOR",
              "name": "Pulled Beef Burger",
              "display_name": "",
              "description": "Wagyu MBS 5 pulled beef, coleslaw, turkey rashers, American cheese, frizzled onions & smoky BBQ sauce",
              "subtitle": "",
              "price": 28.9,
              "tags": [],
              "image": {
                "_id": "e8ed86fd-865d-4dfa-987d-8a86270bd255",
                "name": "PULLED_BEEF.jpg",
                "modifiers": "-/crop/1069x629/11,297/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212718"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272432",
              "menu_id": "sLRx8GKpR",
              "category_id": "M6s3niOAOR",
              "name": "Brisket Sandwich",
              "display_name": "",
              "description": "Slow-cooked MBS 5 Brisket, caramelised smoked onions, American cheese sauce, crinkle cut pickles & special hot lava sauce on stone-baked Turkish bread.",
              "subtitle": "",
              "price": 32.9,
              "tags": [],
              "image": {
                "_id": "9bd55af5-bb6e-4366-af1a-389a8ccf57bf",
                "name": "STEAKHOUSE_SANDWICH.jpg",
                "modifiers": "-/crop/1004x643/19,300/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212719"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272430",
              "menu_id": "sLRx8GKpR",
              "category_id": "M6s3niOAOR",
              "name": "Eruption Burger",
              "display_name": "",
              "description": "2 wagyu beef patties, American cheese, lettuce, tomato, turkey rashers, caramelised onions, crinkle cut pickles, special eruption sauce.",
              "subtitle": "",
              "price": 32.9,
              "tags": [],
              "image": {
                "_id": "d35f5267-d2ed-43c0-aed1-0f3d5d3dd34b",
                "name": "ERUPTION_BURGER.jpg",
                "modifiers": "-/crop/1049x588/9,325/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212721"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272428",
              "menu_id": "sLRx8GKpR",
              "category_id": "M6s3niOAOR",
              "name": "Buttermilk Chicken Burger",
              "display_name": "",
              "description": "Crispy buttermilk chicken thigh, crinkle cut pickles, coleslaw, chilli aioli & american cheese",
              "subtitle": "",
              "price": 26.9,
              "tags": [],
              "image": {
                "_id": "9d93ffe9-1693-4bfc-851c-976a02eb1504",
                "name": "BUTTERMILK_CHICKEN_BURGER.jpg",
                "modifiers": "-/crop/1080x608/0,305/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212722"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "a6VQSlIXHM",
          "menu_id": "sLRx8GKpR",
          "name": "Steaks",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272447",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "Sirloin 300g",
              "display_name": "",
              "description": "MBS 4-5 | 270 Days Grain Fed | MSA | Young Prime",
              "subtitle": "",
              "price": 45.9,
              "tags": [],
              "image": {
                "_id": "e515af17-1fe2-4f0f-8eec-86ca79bb14f0",
                "name": "SIRLOIN_1_.jpg",
                "modifiers": "-/crop/813x820/165,184/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212693",
                "212691",
                "212694",
                "215277",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272449",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "Scotch Fillet 350g",
              "display_name": "",
              "description": "MBS 4-5 | 270 Days Grain Fed | MSA | Young Prime",
              "subtitle": "",
              "price": 60.9,
              "tags": [],
              "image": {
                "_id": "945dc1d8-7f05-4ab7-9dca-e9dae9638231",
                "name": "SCOTCH_FILLET.jpg",
                "modifiers": "-/crop/805x807/158,179/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212693",
                "212691",
                "212694",
                "215277",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272439",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "Eye Fillet 300g",
              "display_name": "",
              "description": "MBS 4-5 | 270 Days Grain Fed | MSA | Young Prime",
              "subtitle": "",
              "price": 59.9,
              "tags": [],
              "image": {
                "_id": "30fc7508-cbaf-481d-8bf6-00bd9ad5ff21",
                "name": "EYE_FILLET.jpg",
                "modifiers": "-/crop/802x807/158,159/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212693",
                "212691",
                "212694",
                "215277",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3168612",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "T-Bone 450g",
              "display_name": "",
              "description": "MBS 4+ | 270 Days Grain Fed | MSA | Young Prime",
              "subtitle": "",
              "price": 75.9,
              "tags": [],
              "image": {
                "_id": "61ca9f42-14c4-4a8e-9a59-22e476f08ce2",
                "name": "T_BONE.jpg",
                "modifiers": "-/crop/820x815/145,164/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212693",
                "212691",
                "212694",
                "215277",
                "215272",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272448",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "Bone in Rib Eye 500g",
              "display_name": "",
              "description": "MBS 4+ | 270 Days Grain Fed | MSA | Young Prime",
              "subtitle": "",
              "price": 86.9,
              "tags": [],
              "image": {
                "_id": "38bbc724-148f-45d1-8748-b18d7913ef49",
                "name": "BONE_IN_RIB_EYE.jpg",
                "modifiers": "-/crop/731x734/168,217/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212693",
                "212691",
                "212694",
                "215277",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "06qWpszlG",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "Dry Aged Bone in Rib Eye 500g ",
              "display_name": "",
              "description": "35 Days Dry Aged MBS 4+ | 270 Days Grain Fed | MSA | Young Prime",
              "subtitle": "",
              "price": 96.9,
              "tags": [],
              "image": {
                "_id": "095b4eb8-9563-434a-8a2b-9a79a4c7abe3",
                "name": "BONE_IN_RIB_EYE.jpg",
                "modifiers": "-/crop/729x732/170,219/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212693",
                "212691",
                "212694",
                "215277",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "IGpluMxF7",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": " Dry Aged Tomahawk",
              "display_name": "",
              "description": "15-20 Days Dry Aged MBS 4+ | 270 Days Grain Fed | MSA | Young Prime *Variety of weights available*",
              "subtitle": "$158/kg (Weights may vary depending on the availability, Staff will contact you ASAP.)",
              "price": 158,
              "tags": [],
              "image": {
                "_id": "8f855de3-cf75-4643-93cc-89ba923f8683",
                "name": "DRY_AGED_TOMAHAWK_1_.jpg",
                "modifiers": "-/crop/747x991/178,53/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212693",
                "212691",
                "212694",
                "215277",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272455",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "Wagyu MB5+ Scotch Fillet 300g",
              "display_name": "",
              "description": "400+ Days Grain Fed",
              "subtitle": "",
              "price": 98.9,
              "tags": [],
              "image": {
                "_id": "50ee5f09-349a-4056-b5c7-3a8db279881b",
                "name": "SCOTCH_FILLET.jpg",
                "modifiers": "-/crop/805x808/158,176/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212693",
                "212691",
                "212694",
                "215277",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272450",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "Brisket MB5+",
              "display_name": "",
              "description": "Slow cooked MB5+ Brisket, mushrooms, king prawns, chat potatoes, basil crisps served w/ your choice of sauce *excludes sides*",
              "subtitle": "",
              "price": 60.9,
              "tags": [],
              "image": {
                "_id": "4a20ad81-1bc9-4174-9ce1-8dfca6a4023f",
                "name": "SIGNATURE_TENDERLOIN_1_.jpg",
                "modifiers": "-/crop/721x715/211,244/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212694",
                "212723",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272453",
              "menu_id": "sLRx8GKpR",
              "category_id": "a6VQSlIXHM",
              "name": "Volcano's Meat Platter",
              "display_name": "",
              "description": "Bone In Ribeye (500g),T-bone (450g), beef short ribs, lamb ribs, 1 lamb skewer, 1 chicken skewer, chicken fillet, on a bed of steakhouse fries & a choice of 3 sauces",
              "subtitle": "",
              "price": 389.9,
              "tags": [],
              "image": {
                "_id": "0141a21c-3054-473e-a377-455c7a807ff1",
                "name": "VOLCANO_S_MEAT_PLATTER.jpg",
                "modifiers": "-/crop/956x1032/49,27/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212692",
                "212694",
                "215277",
                "3Q8QIPjq7"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "gX7dNhPldx",
          "menu_id": "sLRx8GKpR",
          "name": "Ribs",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272464",
              "menu_id": "sLRx8GKpR",
              "category_id": "gX7dNhPldx",
              "name": "Beef Short Ribs & Wings",
              "display_name": "",
              "description": "MBS2+ Grass Fed Slow cooked for 12 hours, basted w/ our special rib sauce",
              "subtitle": "",
              "price": 76.9,
              "tags": [],
              "image": {
                "_id": "20ba46e6-95c1-4f86-a22d-51db96472d49",
                "name": "BEEF_SHORT_RIBS___WINGS_1_.jpg",
                "modifiers": "-/crop/1027x653/21,300/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212696",
                "212694",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272465",
              "menu_id": "sLRx8GKpR",
              "category_id": "gX7dNhPldx",
              "name": "Lamb Ribs & Wings",
              "display_name": "",
              "description": "Grass fed tender lamb ribs basted w/ our special rib sauce",
              "subtitle": "",
              "price": 58.9,
              "tags": [],
              "image": {
                "_id": "c6eddc05-6dc2-420d-b8ef-3655c80d7377",
                "name": "LAMB_RIBS___WINGS.jpg",
                "modifiers": "-/crop/716x716/168,207/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212696",
                "212694",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "ZQISsHL6d4",
          "menu_id": "sLRx8GKpR",
          "name": "Skewers",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272466",
              "menu_id": "sLRx8GKpR",
              "category_id": "ZQISsHL6d4",
              "name": "Chicken Skewer",
              "display_name": "",
              "description": "Marinated chicken, toasted pita bread with a side of tzatziki, served with a choice of volcanos salad or chips",
              "subtitle": "",
              "price": 32.9,
              "tags": [],
              "image": {
                "_id": "6c440c4b-dcbb-41c3-82bb-ef248420d9bd",
                "name": "CHICKEN_SKEWER.jpg",
                "modifiers": "-/crop/593x1030/216,50/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212697",
                "212724"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272467",
              "menu_id": "sLRx8GKpR",
              "category_id": "ZQISsHL6d4",
              "name": "Lamb Skewer",
              "display_name": "",
              "description": "Mediterranean lamb, toasted pita bread w/ a side of tzatziki & a choice of Volcanos salad or steakhouse fries",
              "subtitle": "",
              "price": 42.9,
              "tags": [],
              "image": {
                "_id": "c72e9387-62d0-4b2b-b5c8-caac99a8d646",
                "name": "LAMB_SKEWER.jpg",
                "modifiers": "-/crop/661x1027/168,53/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "215312",
                "212697",
                "212725"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "pid04wQ11Q",
          "menu_id": "sLRx8GKpR",
          "name": "Chicken",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272469",
              "menu_id": "sLRx8GKpR",
              "category_id": "pid04wQ11Q",
              "name": "Chicken Parmigiana",
              "display_name": "",
              "description": "Chicken schnitzel, cheese topped with napolitana sauce *contains nuts*",
              "subtitle": "",
              "price": 34.9,
              "tags": [],
              "image": {
                "_id": "f6cdc9db-37e9-4f75-bd6c-7039cfb0187b",
                "name": "CHICKEN_PARMIGIANA.jpg",
                "modifiers": "-/crop/785x790/173,156/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212698",
                "212726",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272468",
              "menu_id": "sLRx8GKpR",
              "category_id": "pid04wQ11Q",
              "name": "Chicken Avocado",
              "display_name": "",
              "description": "Char-grilled chicken fillet, topped with lime avocado & creamy mushroom sauce",
              "subtitle": "",
              "price": 34.9,
              "tags": [],
              "image": {
                "_id": "bfaae555-d42f-4ed8-8766-96c4c4206b91",
                "name": "CHICKEN_AVOCADO.jpg",
                "modifiers": "-/crop/785x792/130,209/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212698",
                "212727",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272470",
              "menu_id": "sLRx8GKpR",
              "category_id": "pid04wQ11Q",
              "name": "Chicken Schnitzel",
              "display_name": "",
              "description": "Chicken schnitzel served with gravy on the side *contains nuts*",
              "subtitle": "",
              "price": 32.9,
              "tags": [],
              "image": {
                "_id": "e9cbfc2d-8b0b-480d-b31d-d254b329178d",
                "name": "CHICKEN_Schnitzel.jpg",
                "modifiers": "-/crop/827x837/158,169/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212698",
                "215306",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272472",
              "menu_id": "sLRx8GKpR",
              "category_id": "pid04wQ11Q",
              "name": "Pollo De Gambari Chicken",
              "display_name": "",
              "description": "Char-grilled chicken fillet, served with prawns in our special pink sauce",
              "subtitle": "",
              "price": 37.9,
              "tags": [],
              "image": {
                "_id": "1535da0e-eafc-4020-aeb9-49aa65d59238",
                "name": "POLLO_DE_GAMBARI_CHICKEN.jpg",
                "modifiers": "-/crop/800x787/130,179/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212698",
                "212729",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272471",
              "menu_id": "sLRx8GKpR",
              "category_id": "pid04wQ11Q",
              "name": "Creamy Chicken Fillet",
              "display_name": "",
              "description": "Char-grilled chicken fillet topped with creamy mushroom sauce",
              "subtitle": "",
              "price": 32.9,
              "tags": [],
              "image": {
                "_id": "347bcb0d-dd25-416c-a09e-5fb04cb34ca1",
                "name": "CREAMY_CHICKEN_FILLET.jpg",
                "modifiers": "-/crop/823x828/117,138/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212698",
                "215306",
                "212730",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272474",
              "menu_id": "sLRx8GKpR",
              "category_id": "pid04wQ11Q",
              "name": "Pinnacle Chicken",
              "display_name": "",
              "description": "Char-grilled chicken fillet tossed through our special creamy pesto sauce, sautéed mushrooms & sundried tomatoes..*contains traces of nuts*",
              "subtitle": "",
              "price": 34.9,
              "tags": [],
              "image": {
                "_id": "6ee93ab2-9d72-4ade-8a6d-5a0a1a9139ab",
                "name": "PINNACLE_CHICKEN_2_.jpg",
                "modifiers": "-/crop/694x699/190,234/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212698",
                "212731",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "OiBQF83JZH",
          "menu_id": "sLRx8GKpR",
          "name": "Pasta ",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272481",
              "menu_id": "sLRx8GKpR",
              "category_id": "OiBQF83JZH",
              "name": "Boscaiola Pasta",
              "display_name": "",
              "description": "Tossed in creamy sauce, mushrooms, shallots & turkey rashers, topped w/ grana padano",
              "subtitle": "",
              "price": 28.9,
              "tags": [],
              "image": {
                "_id": "9fde457c-ac46-456d-938e-18de1da11cc3",
                "name": "BOSCAIOLA.jpg",
                "modifiers": "-/crop/659x649/238,249/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212734"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272480",
              "menu_id": "sLRx8GKpR",
              "category_id": "OiBQF83JZH",
              "name": "Bolognese Pasta",
              "display_name": "",
              "description": "Tossed through traditional bolognese sauce, topped w/ grana padano",
              "subtitle": "",
              "price": 24.9,
              "tags": [],
              "image": {
                "_id": "179c4fa7-3637-4955-8037-7956a02360ef",
                "name": "BOLOGNESE2.jpg",
                "modifiers": "-/crop/1184x740/156,553/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272487",
              "menu_id": "sLRx8GKpR",
              "category_id": "OiBQF83JZH",
              "name": "Truffle Pasta",
              "display_name": "",
              "description": "Chicken tossed through our special creamy truffle sauce, mushrooms & basil, topped w/ grana padano",
              "subtitle": "",
              "price": 34.9,
              "tags": [],
              "image": {
                "_id": "31be7da6-3b81-48d3-bcb7-dd91048d08be",
                "name": "TRUFFLE_PASTA.jpg",
                "modifiers": "-/crop/712x709/198,237/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212735"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272486",
              "menu_id": "sLRx8GKpR",
              "category_id": "OiBQF83JZH",
              "name": "Pinnacle Pasta",
              "display_name": "",
              "description": "Chicken tossed through our special creamy pesto sauce, sautéed mushrooms, sundried tomatoes & baby spinach",
              "subtitle": "",
              "price": 29.9,
              "tags": [],
              "image": {
                "_id": "8c9cc919-81a4-4a09-a0c6-6260e5c96152",
                "name": "PINNACLE_PASTA.jpg",
                "modifiers": "-/crop/865x873/342,406/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212736"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272485",
              "menu_id": "sLRx8GKpR",
              "category_id": "OiBQF83JZH",
              "name": "Molten Lava Pasta",
              "display_name": "",
              "description": "Chicken, mushrooms, olives & chilli tossed through our special creamy pink sauce, topped w/ grana padano",
              "subtitle": "",
              "price": 28.9,
              "tags": [],
              "image": {
                "_id": "0f253823-5c5d-4c67-b5ec-44002637d1e2",
                "name": "MOLTEN_LAVA.jpg",
                "modifiers": "-/crop/651x649/218,282/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212737"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272479",
              "menu_id": "sLRx8GKpR",
              "category_id": "OiBQF83JZH",
              "name": "Wagyu Pasta",
              "display_name": "",
              "description": "Slow cooked Wagyu MB5 beef tossed through ragu sauce, spanish onions, garlic, mild chilli & capsicum topped w/ crumbled feta",
              "subtitle": "",
              "price": 35.9,
              "tags": [],
              "image": {
                "_id": "515a0773-0c06-42c7-9207-5ad6d0547aa9",
                "name": "WAGYU_PASTA.jpg",
                "modifiers": "-/crop/878x535/112,378/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212738"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272483",
              "menu_id": "sLRx8GKpR",
              "category_id": "OiBQF83JZH",
              "name": "Spicy Prawns Pasta",
              "display_name": "",
              "description": "Garlic prawns, thyme, asparagus, chilli, tossed through our special chicken stock topped w/grana padano",
              "subtitle": "",
              "price": 34.9,
              "tags": [],
              "image": {
                "_id": "515b8e0c-becd-4883-a0bd-3fabc384d242",
                "name": "SPICY_PRAWNS_PASTA.jpg",
                "modifiers": "-/crop/578x586/256,272/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212739"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "SmIsT2xk1X",
          "menu_id": "sLRx8GKpR",
          "name": "Lamb",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272476",
              "menu_id": "sLRx8GKpR",
              "category_id": "SmIsT2xk1X",
              "name": "Lamb Cutlets",
              "display_name": "",
              "description": "Char Grilled mediterranean seasoned lamb cutlets, served w/ chermoula & a choice of steakhouse fries or volcanos salad ",
              "subtitle": "",
              "price": 58.9,
              "tags": [],
              "image": {
                "_id": "a01f5c3f-90a8-46a2-a1f2-2fe01f264246",
                "name": "LAMB_CUTLETS_1_.jpg",
                "modifiers": "-/crop/876x880/102,111/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "212732",
                "215277",
                "szz_Xseml"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272477",
              "menu_id": "sLRx8GKpR",
              "category_id": "SmIsT2xk1X",
              "name": "Medi Lamb",
              "display_name": "",
              "description": "Mediterranean seasoned lamb w/ pan fried halloumi, bruschetta mix, spinach, rocket & parmesan. 100% natural free range grass fed lamb",
              "subtitle": "",
              "price": 42.9,
              "tags": [],
              "image": {
                "_id": "1d9e556c-31c6-42fe-bf49-516b62f7ee08",
                "name": "MEDITERRANEAN_LAMB_1_.jpg",
                "modifiers": "-/crop/762x764/183,189/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "212733",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "2flOBGiI_6",
          "menu_id": "sLRx8GKpR",
          "name": "Seafood",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272489",
              "menu_id": "sLRx8GKpR",
              "category_id": "2flOBGiI_6",
              "name": "Barramundi",
              "display_name": "",
              "description": "Pan fried whole butterflied barramundi, topped w/ chimichurri, side of dill mayo & a choice of steakhouse fries or volcanos salad",
              "subtitle": "",
              "price": 43.9,
              "tags": [],
              "image": {
                "_id": "bcff150f-1293-4c3a-8ebb-7ad27d972131",
                "name": "BARRAMUNDI.jpg",
                "modifiers": "-/crop/732x732/213,194/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212701",
                "212741"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272488",
              "menu_id": "sLRx8GKpR",
              "category_id": "2flOBGiI_6",
              "name": "Atlantic Salmon",
              "display_name": "",
              "description": "Pan fried Atlantic salmon drizzled in lemon & herb glaze, served w/ asparagus, mash & special dill mayo",
              "subtitle": "",
              "price": 45.9,
              "tags": [],
              "image": {
                "_id": "dc9029f8-cf7d-4037-b6d7-eb1b94ae9dfa",
                "name": "ATLANTIC_SALMON.jpg",
                "modifiers": "-/crop/767x772/173,164/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "215312",
                "212701",
                "212744"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272493",
              "menu_id": "sLRx8GKpR",
              "category_id": "2flOBGiI_6",
              "name": "Octopus",
              "display_name": "",
              "description": "Marinated spicy octopus served with chips, garden salad and tartare sauce",
              "subtitle": "",
              "price": 38.9,
              "tags": [],
              "image": {
                "_id": "598b3a4c-14f0-469e-a763-096f5aa3aa17",
                "name": "OCTOPUS.jpg",
                "modifiers": "-/crop/827x835/158,151/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212701"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272490",
              "menu_id": "sLRx8GKpR",
              "category_id": "2flOBGiI_6",
              "name": "Calamari Rings",
              "display_name": "",
              "description": "Crumbed calamari rings served with chips garden salad and tartare sauce",
              "subtitle": "",
              "price": 25.9,
              "tags": [],
              "image": {
                "_id": "4697b966-6c90-4010-a0a8-1df4fa1c48f7",
                "name": "CALAMARI_RINGS.jpg",
                "modifiers": "-/crop/764x773/168,186/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212701",
                "212743"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272494",
              "menu_id": "sLRx8GKpR",
              "category_id": "2flOBGiI_6",
              "name": "Seafood Platter for 1",
              "display_name": "",
              "description": "Atlantic Salmon, salt & pepper squid, crumbed calamari rings, garlic prawns, marinated spicy grilled octopus, scallops served on a bed of fries & a side of garden salad & tartare sauce.",
              "subtitle": "",
              "price": 82.9,
              "tags": [],
              "image": {
                "_id": "4caa38dc-4230-409d-bae7-f30b9ee68660",
                "name": "SEAFOOD_PLATTER.jpg",
                "modifiers": "-/crop/825x846/145,118/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212701",
                "213440"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272495",
              "menu_id": "sLRx8GKpR",
              "category_id": "2flOBGiI_6",
              "name": "Seafood Platter for 2",
              "display_name": "",
              "description": "Atlantic salmon, salt and pepper squid, crumbed calamari rings, garlic prawns, marinated spicy grilled octopus, scallops served on a bed of chips and a side of garden salad and tartare sauce",
              "subtitle": "",
              "price": 161.9,
              "tags": [],
              "image": {
                "_id": "50375128-d8ea-430e-9dd7-bffc30f08704",
                "name": "SEAFOOD_PLATTER.jpg",
                "modifiers": "-/crop/825x846/140,118/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212701",
                "213440"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "lmPWpwkNv1",
          "menu_id": "sLRx8GKpR",
          "name": "Pizza",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272516",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "BBQ Chicken Pizza (T)",
              "display_name": "",
              "description": "Chicken, BBQ sauce, onions & capsicum, topped w/ mozzarella & grana padano",
              "subtitle": "",
              "price": 25.9,
              "tags": [],
              "image": {
                "_id": "8d54e18b-c3ec-4e24-86a5-913ee4b6fd64",
                "name": "Bbq_chicken.jpg",
                "modifiers": "-/crop/707x704/170,207/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212749"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272517",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "BBQ Meat Pizza (G)",
              "display_name": "",
              "description": "Pepperoni, Salami, smoked beef, ground beef, BBQ sauce & Mozzarella",
              "subtitle": "",
              "price": 28.9,
              "tags": [],
              "image": {
                "_id": "96934224-2275-4f85-a800-f0e5109a2ea3",
                "name": "BBQ_MEAT.jpg",
                "modifiers": "-/crop/749x754/183,199/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212750"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272523",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "Peri Peri Chicken Pizza (T)",
              "display_name": "Peri Peri Chicken Pizza (T)",
              "description": "Chicken, capsicum, onions, jalapenos, mozzarella topped with peri peri sauce",
              "subtitle": "",
              "price": 26.9,
              "tags": [],
              "image": {
                "_id": "c6626cb4-3753-41a0-96d0-8eac740f7806",
                "name": "Peri_Peri.jpg",
                "modifiers": "-/crop/724x729/211,209/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212751"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272522",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "Pepperoni Pizza (T)",
              "display_name": "",
              "description": "Pepperoni on a napolitana base, topped w/ mozzarella & grana padano",
              "subtitle": "",
              "price": 24.9,
              "tags": [],
              "image": {
                "_id": "d3ae657a-0787-4647-be5e-09d4cc3a8f73",
                "name": "PEPPERONI.jpg",
                "modifiers": "-/crop/752x767/160,174/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213148"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272515",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "Brisket  Pizza (G)",
              "display_name": "",
              "description": "Slow cooked MB5+ Brisket, chimichurri, rocket, parmesan, basil on a tomato base, topped w/ mozzarella & grana padano",
              "subtitle": "",
              "price": 34.9,
              "tags": [],
              "image": {
                "_id": "75dac468-f8ea-49dc-9514-e9c1d2ab6fc9",
                "name": "ANGUS_STEAK.jpg",
                "modifiers": "-/crop/747x757/170,207/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212752"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "wwMMVFqKU",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "Truffle beef pizza",
              "display_name": "",
              "description": "Truffle base, smoked beef, mushrooms, rocket, rosemary, olive oil, topped w/ truffle mayo, mozzarella & grana padano",
              "subtitle": "New",
              "price": 36.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272518",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": " Garlic Chicken Pizza (T)",
              "display_name": "",
              "description": "Garlic base, thinly sliced smoked chicken, feta, onions, rosemary, rocket, olive oil, topped w/ garlic sauce , mozzarella & grana padano",
              "subtitle": "New",
              "price": 33.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212753"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272520",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "Margherita Pizza (T)",
              "display_name": "",
              "description": "Basil on a tomato base, topped w/ mozzarella & grana padano",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": {
                "_id": "c743e9e8-b35c-4872-b96a-bfceb3ebedf4",
                "name": "Margherita.jpg",
                "modifiers": "-/crop/823x831/132,143/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212754"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272524",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "Vegetarian Pizza (T)",
              "display_name": "",
              "description": "Spinach, caramelised onions, mushroom, roasted capsicum, olives and mozzarella",
              "subtitle": "",
              "price": 24.9,
              "tags": [],
              "image": {
                "_id": "14830ff1-0979-4dae-bd04-97cfd389bc1f",
                "name": "Vegetarian__1_.jpg",
                "modifiers": "-/crop/838x848/112,123/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212755"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272519",
              "menu_id": "sLRx8GKpR",
              "category_id": "lmPWpwkNv1",
              "name": "Garlic Prawn Pizza (G)",
              "display_name": "",
              "description": "Garlic prawns, lemon on a garlic base, topped w/ mozzarella & grana padano",
              "subtitle": "",
              "price": 34.9,
              "tags": [],
              "image": {
                "_id": "583843ff-a1eb-4171-9d66-afa6a53a21d3",
                "name": "GARLIC_PRAWNS.jpg",
                "modifiers": "-/crop/720x722/190,219/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213442"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": true,
            "services": [],
            "hours": [
              {
                "day": "Monday",
                "open": "16:00",
                "close": "21:00",
                "h24": false
              },
              {
                "day": "Tuesday",
                "open": "16:00",
                "close": "21:00",
                "h24": false
              },
              {
                "day": "Wednesday",
                "open": "16:00",
                "close": "21:00",
                "h24": false
              },
              {
                "day": "Thursday",
                "open": "16:00",
                "close": "22:00",
                "h24": false
              },
              {
                "day": "Friday",
                "open": "16:00",
                "close": "22:00",
                "h24": false
              },
              {
                "day": "Saturday",
                "open": "11:30",
                "close": "22:00",
                "h24": false
              },
              {
                "day": "Sunday",
                "open": "11:30",
                "close": "21:00",
                "h24": false
              }
            ]
          },
          "abacus_open_item": 3272658
        },
        {
          "_id": "XLAHh3jPfm",
          "menu_id": "sLRx8GKpR",
          "name": "Kids Meals",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272497",
              "menu_id": "sLRx8GKpR",
              "category_id": "XLAHh3jPfm",
              "name": "Kids Beef Burger",
              "display_name": "",
              "description": "American cheese, tomato sauce served w/ a side of fries",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "7f00879c-9e42-404d-8bca-f933c8963d6e",
                "name": "KIDS_BURGER__CHEESE_BURGER_.jpg",
                "modifiers": "-/crop/1021x608/0,262/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "215304",
                "212746"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272499",
              "menu_id": "sLRx8GKpR",
              "category_id": "XLAHh3jPfm",
              "name": "Kids Chicken Burger",
              "display_name": "",
              "description": "American cheese, tomato sauce served w/ a side of fries",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "411c8069-653b-48db-8a15-1af00f3e635d",
                "name": "KIDS_BURGER__CHICKEN_.jpg",
                "modifiers": "-/crop/1037x470/21,360/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "215304",
                "212746"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272501",
              "menu_id": "sLRx8GKpR",
              "category_id": "XLAHh3jPfm",
              "name": "Kids Creamy Mushroom Pasta",
              "display_name": "",
              "description": "Penne pasta tossed in creamy mushroom sauce ",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "ed4b3f7b-1768-4e6e-a0d8-3a245f618134",
                "name": "KIDS_CREAMY_MUSHROOM_PASTA.jpg",
                "modifiers": "-/crop/687x681/218,232/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "215304",
                "212702"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272498",
              "menu_id": "sLRx8GKpR",
              "category_id": "XLAHh3jPfm",
              "name": "Kids Bolognese",
              "display_name": "",
              "description": "Penne pasta tossed through traditional bolognese sauce.",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "cf83d2e8-cb3a-4c8c-96e4-42d2e484c496",
                "name": "KIDS_BOLOGNESE.jpg",
                "modifiers": "-/crop/654x653/251,237/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "215304",
                "212702"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272502",
              "menu_id": "sLRx8GKpR",
              "category_id": "XLAHh3jPfm",
              "name": "Kids Schnitzel",
              "display_name": "",
              "description": "Chicken schnitzel and gravy. Served with a side a chips.",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "6592fbcb-10d1-4154-a109-f10edf85737f",
                "name": "KIDS_SCHNITZEL.jpg",
                "modifiers": "-/crop/664x669/228,219/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "215304",
                "212747",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272500",
              "menu_id": "sLRx8GKpR",
              "category_id": "XLAHh3jPfm",
              "name": "Kids Chicken Nuggets",
              "display_name": "",
              "description": "Chicken nuggets served with hot chips",
              "subtitle": "",
              "price": 12.9,
              "tags": [],
              "image": {
                "_id": "dd775f66-f7dc-4f09-9834-ef945a981f6e",
                "name": "Kids___CHICKEN_NUGGETS.jpg",
                "modifiers": "-/crop/681x679/231,257/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "215304",
                "212748"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272506",
              "menu_id": "sLRx8GKpR",
              "category_id": "XLAHh3jPfm",
              "name": "Kids Juices",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 2,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "213094"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272662
        },
        {
          "_id": "T40GqptbHA",
          "menu_id": "sLRx8GKpR",
          "name": "GF - Menu",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272512",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Sirloin 300g",
              "display_name": "GF - Sirloin 300g",
              "description": "MBS 4-5 Grain Fed | MSA Young Prime",
              "subtitle": "",
              "price": 45.9,
              "tags": [],
              "image": {
                "_id": "bd15f118-b100-40db-91d1-2be050665086",
                "name": "SIRLOIN_1_.jpg",
                "modifiers": "-/crop/822x835/163,174/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "213447",
                "213448",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272443",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Scotch Fillet 350g",
              "display_name": "GF - Scotch Fillet 350g",
              "description": "MBS 4-5 Grain Fed | MSA Young Prime",
              "subtitle": "",
              "price": 60.9,
              "tags": [],
              "image": {
                "_id": "785253db-6752-4ad6-aa15-c9763de41c5f",
                "name": "SCOTCH_FILLET.jpg",
                "modifiers": "-/crop/820x822/150,169/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "213447",
                "213448",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272441",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Eye Fillet 300g",
              "display_name": "GF - Eye Fillet 300g",
              "description": "MBS 4-5 Grain Fed | MSA Young Prime",
              "subtitle": "",
              "price": 59.9,
              "tags": [],
              "image": {
                "_id": "e5832338-8ddb-43d0-b219-2d8d016b46fe",
                "name": "EYE_FILLET.jpg",
                "modifiers": "-/crop/807x810/153,156/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "213447",
                "213448",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272444",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - T-Bone 450g",
              "display_name": "GF - T-Bone 450g",
              "description": "MBS 4+ Grain Fed | MSA Young Prime",
              "subtitle": "",
              "price": 75.9,
              "tags": [],
              "image": {
                "_id": "43ff5752-d6c3-4d52-ae56-f8a436340586",
                "name": "T_BONE.jpg",
                "modifiers": "-/crop/833x835/137,151/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "213447",
                "213448",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272442",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Bone in Rib Eye 500g",
              "display_name": "GF - Bone in Rib Eye 500g",
              "description": "MBS 4+ Grain Fed | MSA Young Prime",
              "subtitle": "",
              "price": 86.9,
              "tags": [],
              "image": {
                "_id": "88146dcd-3a64-4b14-ba21-4477c9f7fdf0",
                "name": "BONE_IN_RIB_EYE.jpg",
                "modifiers": "-/crop/727x729/170,219/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "213447",
                "213448",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272445",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Dry Aged Bone in Rib Eye 500G",
              "display_name": "GF - Dry Aged Bone in Rib Eye 500G",
              "description": "35 Days Dry Aged | MBS 4+ Grain Fed | MSA Young Prime",
              "subtitle": "",
              "price": 96.9,
              "tags": [],
              "image": {
                "_id": "34bd4359-54cd-4eea-ba7f-5313f254dd7c",
                "name": "BONE_IN_RIB_EYE.jpg",
                "modifiers": "-/crop/722x714/170,224/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "213447",
                "213448",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272492",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Wagyu MB5+ Scotch Fillet 300G",
              "display_name": "GF - Wagyu MB5+ Scotch Fillet 300G",
              "description": "400+ Days Grain Fed",
              "subtitle": "",
              "price": 98.9,
              "tags": [],
              "image": {
                "_id": "e433138b-aa8a-4f99-bf07-0a478607ba2f",
                "name": "SCOTCH_FILLET.jpg",
                "modifiers": "-/crop/803x790/155,184/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212701",
                "212741"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272491",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Dry Aged Tomahawk",
              "display_name": "GF - Dry Aged Tomahawk",
              "description": "15-20 Days Dry Aged | MBS 4+ Grain Fed | MSA Young Prime *Variety of weights available*",
              "subtitle": "$158/kg (Weights may vary depending on the availability, Staff will contact you ASAP.)",
              "price": 158,
              "tags": [],
              "image": {
                "_id": "782d3c6b-573f-4c29-af1e-b8a45b576b23",
                "name": "DRY_AGED_TOMAHAWK.jpg",
                "modifiers": "-/crop/636x868/233,138/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212701",
                "212744"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272475",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Brisket MB5+",
              "display_name": "GF - Brisket MB5+",
              "description": "Slow cooked MB5+ Brisket, mushrooms, king prawns, chat potatoes, basil crisps served w/ your choice of sauce *excludes sides*",
              "subtitle": "",
              "price": 60.9,
              "tags": [],
              "image": {
                "_id": "d056e93d-bebb-4184-ba3f-0a1bed2b649a",
                "name": "SIGNATURE_TENDERLOIN_1_.jpg",
                "modifiers": "-/crop/697x698/223,255/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "212733",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "DcOnKn_tB",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Barramundi",
              "display_name": "GF - Barramundi",
              "description": "Pan fried whole butterflied barramundi, topped w/ chimichurri, side of dill mayo & a choice of steakhouse fries or volcanos salad",
              "subtitle": "",
              "price": 43.9,
              "tags": [],
              "image": {
                "_id": "3aa29007-168e-429b-862a-0574fae33eb0",
                "name": "BARRAMUNDI.jpg",
                "modifiers": "-/crop/709x706/221,207/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "212733",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "314RTMGdO",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Atlantic Salmon",
              "display_name": "GF - Atlantic Salmon",
              "description": "Pan fried Atlantic salmon drizzled in lemon & herb glaze, served w/ asparagus, mash & special dill mayo",
              "subtitle": "",
              "price": 45.9,
              "tags": [],
              "image": {
                "_id": "10570413-a2c3-43cb-8ea7-0f87c99308fb",
                "name": "ATLANTIC_SALMON.jpg",
                "modifiers": "-/crop/739x732/188,181/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212690",
                "212733",
                "215277"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "_DzvwNH1i",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Mediterranean Lamb",
              "display_name": "GF - Mediterranean Lamb",
              "description": "Mediterranean seasoned lamb w/ pan fried halloumi, bruschetta mix, spinach, rocket & parmesan. 100% natural free range grass fed lamb",
              "subtitle": "",
              "price": 42.9,
              "tags": [],
              "image": {
                "_id": "a8696791-5325-4a9b-a5ea-c691b90d314a",
                "name": "MEDITERRANEAN_LAMB_1_.jpg",
                "modifiers": "-/crop/742x736/193,202/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "GhcCkVE1x"
              ]
            },
            {
              "type": "standard",
              "_id": "HPCzHHsuV",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Chicken Skewer",
              "display_name": "GF - Chicken Skewer",
              "description": "Marinated chicken, toasted pita bread w/ a side of tzatziki & a choice of Volcanos salad or steakhouse fries (No Tostados)",
              "subtitle": "",
              "price": 32.9,
              "tags": [],
              "image": {
                "_id": "3b46dfbb-4328-4a34-bac3-39354fe57de4",
                "name": "CHICKEN_SKEWER.jpg",
                "modifiers": "-/crop/558x1012/241,68/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "GhcCkVE1x"
              ]
            },
            {
              "type": "standard",
              "_id": "Tj0fWdPnS",
              "menu_id": "sLRx8GKpR",
              "category_id": "T40GqptbHA",
              "name": "GF - Lamb Skewer",
              "display_name": "GF - Lamb Skewer",
              "description": "Marinated lamb, toasted pita bread w/ a side of tzatziki & a choice of Volcanos salad or steakhouse fries (No Tostados)",
              "subtitle": "",
              "price": 42.9,
              "tags": [],
              "image": {
                "_id": "c71936c9-b7f6-4682-860b-b2856f7c9761",
                "name": "LAMB_SKEWER.jpg",
                "modifiers": "-/crop/603x1005/198,75/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": [
                "GhcCkVE1x"
              ]
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "c0XKJUoBR",
          "menu_id": "sLRx8GKpR",
          "name": "Vegetarian  ",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "OI3FlfOWgj",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Garlic Bread",
              "display_name": "Garlic Bread",
              "description": "Toasted stone-baked sourdough w/ gourmet garlic butter ADD Mozzarella +6.0",
              "subtitle": "",
              "price": 10.9,
              "tags": [],
              "image": {
                "_id": "c76affe4-8628-493f-b8ea-4b72e5955dac",
                "name": "GARLIC_BREAD.jpg",
                "modifiers": "-/crop/727x739/190,217/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212687"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "N-eBHxGxDM",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Bruschetta Platter",
              "display_name": "",
              "description": "Fresh tomatoes, basil & onions w/ tarragon & olive oil on toasted stone-baked garlic sourdough ADD Feta or Halloumi +4.0",
              "subtitle": "",
              "price": 16.9,
              "tags": [],
              "image": {
                "_id": "357da56a-7818-4183-a411-d2c66691b9d1",
                "name": "BRUSCHETTA_PLATTER.jpg",
                "modifiers": "-/crop/770x772/190,199/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212688"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "4ew2LuROgE",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Mozzarella Sticks",
              "display_name": "",
              "description": "6 Fried mozzarella fingers served with smoky BBQ sauce",
              "subtitle": "",
              "price": 16.9,
              "tags": [],
              "image": {
                "_id": "bdd6fd1a-29a7-4726-9b5d-eee81d5ae497",
                "name": "MOZZARELLA_STICKS.jpg",
                "modifiers": "-/crop/672x672/238,239/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221358"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "YbEXLeqYli",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Cheesy Fries  ",
              "display_name": "",
              "description": "Steakhouse fries topped w/ a mix of 4 cheeses ( Mozzarella, Feta, Cheddar & Grana Padano )",
              "subtitle": "",
              "price": 15.9,
              "tags": [],
              "image": {
                "_id": "890172eb-408a-4ad4-abf5-d623f68edb6d",
                "name": "CHEESY_FRIES.jpg",
                "modifiers": "-/crop/825x840/130,159/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "6z9UpX1ICs",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Sweet Potato Fries",
              "display_name": "Sweet Potato Fries",
              "description": "Crispy hand-cut sweet potato fries served w/ signature eruption sauce",
              "subtitle": "",
              "price": 16.9,
              "tags": [],
              "image": {
                "_id": "d18a4605-f3e4-4824-8ab7-efdd0c67d10f",
                "name": "SWEET_POTATO_FRIES.jpg",
                "modifiers": "-/crop/762x755/183,191/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221359"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "RN53XgT3tx",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Caesar Salad",
              "display_name": "Caesar Salad",
              "description": "Baby cos lettuce, croutons, boiled egg & parmesan cheese, drizzled with our aioli dressing",
              "subtitle": "",
              "price": 21.9,
              "tags": [],
              "image": {
                "_id": "7cf24423-373c-44e8-b71c-b6d472da3270",
                "name": "CAESAR_SALAD.jpg",
                "modifiers": "-/crop/654x648/218,227/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "221363"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "qO6opX-_5",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Margherita Traditional Pizza",
              "display_name": "Margherita Traditional Pizza",
              "description": "Basil on a tomato base, topped w/ mozzarella & grand padano",
              "subtitle": "",
              "price": 22.9,
              "tags": [],
              "image": {
                "_id": "22ec3ef9-739f-4372-ac42-a3e5f54a08ae",
                "name": "Margherita.jpg",
                "modifiers": "-/crop/803x802/142,151/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212713",
                "212689"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "me8etCYRK",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Vegetarian Traditional Pizza",
              "display_name": "Vegetarian Traditional Pizza",
              "description": "Spinach, caramelised onions, mushroom, roasted capsicum, olives, topped w/ mozzarella & grana padano",
              "subtitle": "",
              "price": 24.9,
              "tags": [],
              "image": {
                "_id": "48cf5486-53bf-40b9-9fda-43dd3ac2aa88",
                "name": "Vegetarian__1_.jpg",
                "modifiers": "-/crop/813x812/127,141/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212737"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "hiwTZzzHw",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Truffle Pasta (No chicken)",
              "display_name": "Truffle Pasta (No chicken)",
              "description": "Pasta tossed through our special creamy truffle sauce, mushrooms & basil, topped w/ grana padano",
              "subtitle": "",
              "price": 34.9,
              "tags": [],
              "image": {
                "_id": "14b61114-56ac-4493-abc2-99cc4b1cf41b",
                "name": "TRUFFLE_PASTA_1_.jpg",
                "modifiers": "-/crop/1153x704/205,571/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212699",
                "212700",
                "212736"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Som3UyQEX",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Pinnacle Pasta (No Chicken)",
              "display_name": "Pinnacle Pasta (No Chicken)",
              "description": "Pasta tossed through our special creamy pesto sauce, sautéed mushrooms, sundried tomatoes & baby spinach",
              "subtitle": "",
              "price": 29.9,
              "tags": [],
              "image": {
                "_id": "eee43cb0-2dd0-40c8-9e71-f5d7cbd2df86",
                "name": "PINNACLE_PASTA_1_.jpg",
                "modifiers": "-/crop/747x502/165,333/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212754"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "j0c9BmWOV",
              "menu_id": "sLRx8GKpR",
              "category_id": "c0XKJUoBR",
              "name": "Molten Lava Pasta (No Chicken)",
              "display_name": "Molten Lava Pasta (No Chicken)",
              "description": "Pasta tossed through our special volcano’s creamy pink sauce with, mushrooms, olives & a touch of chilli",
              "subtitle": "",
              "price": 28.9,
              "tags": [],
              "image": {
                "_id": "fa961746-6472-4337-a11e-235856c9775e",
                "name": "MOLTEN_LAVA.jpg",
                "modifiers": "-/crop/641x639/223,287/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "212755"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        },
        {
          "_id": "5i6YYZLrDdk",
          "menu_id": "sLRx8GKpR",
          "name": "Desserts",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "fSpdsGlsM",
              "menu_id": "sLRx8GKpR",
              "category_id": "5i6YYZLrDdk",
              "name": "Biscoff Tiramisu",
              "display_name": "",
              "description": "Layers of delicate lady fingers soaked in espresso, biscoff & mascarpone cream cheese, topped w/ cocoa & vanilla bean ice cream",
              "subtitle": "",
              "price": 21.9,
              "tags": [],
              "image": {
                "_id": "e88df161-2379-41b9-87b0-ffcfab6090e9",
                "name": "BISCOFF_TIRAMIS_.jpg",
                "modifiers": "-/crop/861x856/117,138/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272529",
              "menu_id": "sLRx8GKpR",
              "category_id": "5i6YYZLrDdk",
              "name": "Chocolate Fondant",
              "display_name": "",
              "description": "Lava chocolate cake w/ melted centre, drizzled w/ warm Belgian Chocolate, Served w/ strawberries & creamy vanilla bean ice-cream",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "ca83c1a9-7d73-4472-8b42-e4b48b8e7a28",
                "name": "Chocolate_Fondant.jpg",
                "modifiers": "-/crop/1030x722/21,252/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272530",
              "menu_id": "sLRx8GKpR",
              "category_id": "5i6YYZLrDdk",
              "name": "Creme Brulee",
              "display_name": "",
              "description": "Vanilla bean crème brûlée w/ caramelised top",
              "subtitle": "",
              "price": 17,
              "tags": [],
              "image": {
                "_id": "e16b334c-334a-42e4-be62-daffc0c674ba",
                "name": "CREME_BRULEE.jpg",
                "modifiers": "-/crop/722x729/160,204/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272532",
              "menu_id": "sLRx8GKpR",
              "category_id": "5i6YYZLrDdk",
              "name": "Nutella Brownies",
              "display_name": "",
              "description": "Hot baked Nutella brownies topped w/ warm melted Belgian Chocolate, strawberries & vanilla bean ice cream",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "6e724873-190f-4129-8927-193a25deccdf",
                "name": "NUTELLA_BROWNIES.jpg",
                "modifiers": "-/crop/894x659/79,287/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272533",
              "menu_id": "sLRx8GKpR",
              "category_id": "5i6YYZLrDdk",
              "name": "Sticky Date Pudding",
              "display_name": "",
              "description": "Sticky Date Pudding served warm, topped w/ Hot Caramel Sauce Served w/ Fresh Strawberries & creamy vanilla bean ice-cream",
              "subtitle": "",
              "price": 18,
              "tags": [],
              "image": {
                "_id": "9875386a-5526-4469-b547-9192631c80f8",
                "name": "Sticky_Date.jpg",
                "modifiers": "-/crop/906x668/79,308/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272531",
              "menu_id": "sLRx8GKpR",
              "category_id": "5i6YYZLrDdk",
              "name": "Dessert Platter",
              "display_name": "",
              "description": "Chocolate calzone, Sticky Date Pudding, Brownies, Strawberries, Bananas, Marshmallows, creamy vanilla bean ice cream w/ Warm Belgian Chocolate",
              "subtitle": "",
              "price": 56.9,
              "tags": [],
              "image": {
                "_id": "2918522b-4092-4bc3-b1ca-c6da77212cc0",
                "name": "DESSERT_PLATTER_5_.jpg",
                "modifiers": "-/crop/1007x1016/6,38/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": [
              {
                "day": "Monday",
                "open": "17:00",
                "close": "21:00",
                "h24": false
              },
              {
                "day": "Tuesday",
                "open": "17:00",
                "close": "21:00",
                "h24": false
              },
              {
                "day": "Wednesday",
                "open": "17:00",
                "close": "21:00",
                "h24": false
              },
              {
                "day": "Thursday",
                "open": "17:00",
                "close": "22:00",
                "h24": false
              },
              {
                "day": "Friday",
                "open": "16:00",
                "close": "22:00",
                "h24": false
              },
              {
                "day": "Saturday",
                "open": "14:00",
                "close": "22:00",
                "h24": false
              },
              {
                "day": "Sunday",
                "open": "12:00",
                "close": "21:00",
                "h24": false
              }
            ]
          },
          "abacus_open_item": 3272659
        },
        {
          "_id": "77p67I-Ir5r",
          "menu_id": "sLRx8GKpR",
          "name": "Premium Mocktails",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272571",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Blue Lagoon",
              "display_name": "",
              "description": "Combination of blue curacao. Fragrant mint, tangy lime and sugar syrup (Shaken Tangy Sweet Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "a5a9eba8-10b8-49c9-a618-db88fb5b2d04",
                "name": "BLUE_LAGOON.jpg",
                "modifiers": "-/crop/792x1181/352,248/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272572",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Croatian Bomb",
              "display_name": "",
              "description": "Combination of Strawberries, pomegranate Mango and Lime (Shaken Tangy Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "4611fdb9-28b7-4a21-9ff3-1c913ed7cdbe",
                "name": "CROATIAN_BOMB.jpg",
                "modifiers": "-/crop/813x1174/405,252/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272573",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Dark Knight",
              "display_name": "",
              "description": "Pomegranate, blue curacao and mixed berries (Shaken Sweet Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "ebf68a52-8bc1-44c9-9128-5fee3cb499f0",
                "name": "DARK_KNIGHT.jpg",
                "modifiers": "-/crop/781x1216/398,262/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272577",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Strawberry Mojito",
              "display_name": "",
              "description": "The combination of Strawberries fragrant mint, Lemon, tangy lime (Shaken Tangy Sweet Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "928390de-ae4a-44be-b73b-bf066d4da996",
                "name": "STRAWBERRY_MOJITO.jpg",
                "modifiers": "-/crop/879x1191/321,280/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272579",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Watermelon Mango",
              "display_name": "",
              "description": "Watermelon, Lemon and Mango (Shaken Tangy Sweet Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "cabe6940-16c4-45f9-87eb-104b11b5a9da",
                "name": "WATERMELON_MANGO.jpg",
                "modifiers": "-/crop/595x821/289,249/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272580",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Watermelon Mojito",
              "display_name": "",
              "description": "The combination of Watermelon, fragrant mint, tangy lime (Shaken Tangy Sweet Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "7ed2e07d-92c9-407a-bf0e-7c6c060110da",
                "name": "WATERMELON_MOJITO.jpg",
                "modifiers": "-/crop/862x1216/366,248/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272574",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Lychee Tease",
              "display_name": "",
              "description": "Lychee and Pineapple (Blended  Sweet Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "a5224b80-594f-4566-b15b-5c709f08dfe1",
                "name": "LYCHEE_TEASE.jpg",
                "modifiers": "-/crop/904x1157/331,297/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272576",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Pink Panther",
              "display_name": "",
              "description": "Pineapple juice, lemon juice with fresh strawberries and raspberries (Shaken Sweet Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "76f96508-96c2-49c0-9eb6-55b03f4e550d",
                "name": "PINK_PANTHER.jpg",
                "modifiers": "-/crop/555x777/281,179/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272575",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Pinacolada",
              "display_name": "",
              "description": "Pineapple and Coconut Cream Cocktail (Blended Sweet Coconut Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "a6d05565-83a5-4eed-b2b2-1ad9d661985e",
                "name": "PINACOLADA.jpg",
                "modifiers": "-/crop/568x792/271,214/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272578",
              "menu_id": "sLRx8GKpR",
              "category_id": "77p67I-Ir5r",
              "name": "Virgin Mojito",
              "display_name": "",
              "description": "The combination of Fragrant mint, Lemon and tangy lime (Shaken Tangy Sweet Flavour)",
              "subtitle": "",
              "price": 13.9,
              "tags": [],
              "image": {
                "_id": "9e865684-a335-47d9-8548-6e35a718380d",
                "name": "VIRGIN_MOJITO.jpg",
                "modifiers": "-/crop/834x1157/352,304/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272660
        },
        {
          "_id": "SVKFjMCLZeS",
          "menu_id": "sLRx8GKpR",
          "name": "Cold Beverages",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272587",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Crushers",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 10.9,
              "tags": [],
              "image": {
                "_id": "2084ac85-77a3-4172-b429-1fd5e51d5b23",
                "name": "CRUSHERS.jpg",
                "modifiers": "-/crop/898x856/107,143/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213092",
                "213101"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272594",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Lemon Lime Mint Crush",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 10.9,
              "tags": [],
              "image": {
                "_id": "8cadc899-fa03-4b9c-8d64-c200c63b942a",
                "name": "MINT_CRUSH.jpg",
                "modifiers": "-/crop/570x873/251,171/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213101"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "Ku0-rXhJ-",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Watermelon Mint Crush",
              "display_name": "",
              "description": "",
              "subtitle": " ",
              "price": 10.9,
              "tags": [],
              "image": {
                "_id": "6f110042-b05b-4c02-a675-8c0a8a004f33",
                "name": "watermelon_mint_crush.jpg",
                "modifiers": "-/crop/565x871/264,191/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272581",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Sparkling Water 750ml",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272583",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Still Mineral Water 750ml",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272602",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Soda Water",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 1.5,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272591",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Iced Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": {
                "_id": "c7573804-cdde-440a-9066-4f39abe4616b",
                "name": "ICE_Latte.jpg",
                "modifiers": "-/crop/651x898/206,126/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213101",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272590",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Iced Chocolate",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": {
                "_id": "8ec20cf2-d026-48a9-b357-733b14405590",
                "name": "ICE_Latte.jpg",
                "modifiers": "-/crop/643x906/211,126/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213101",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "tRm3CvI-W",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Iced Mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 8.9,
              "tags": [],
              "image": {
                "_id": "024e7084-a504-4e71-8389-7f8d0e52274e",
                "name": "ICE_Latte.jpg",
                "modifiers": "-/crop/659x913/218,121/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213101",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272601",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Smoothies",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 10.9,
              "tags": [],
              "image": {
                "_id": "7e3d3b33-aee8-45b1-8e59-a0fe18e950a9",
                "name": "MANGO_SMOOTHIES_1_.jpg",
                "modifiers": "-/crop/583x971/241,96/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213093",
                "213101"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272596",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Non-Alcoholic Beer",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 7.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272593",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Lemon Lime Bitters",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "7c8162dc-57e2-4074-a443-46977b425f76",
                "name": "lemon_lime_bitter.jpg",
                "modifiers": "-/crop/671x815/231,186/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272599",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Peach Ice Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.9,
              "tags": [],
              "image": {
                "_id": "3634841b-df2c-4f96-9823-98b561263c6c",
                "name": "peach_iced_tea.jpg",
                "modifiers": "-/crop/671x777/223,242/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272592",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Juices",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 6.9,
              "tags": [],
              "image": {
                "_id": "a8039de4-2144-4a20-a911-191c2d87c3a7",
                "name": "peached_iced_tea.jpg",
                "modifiers": "-/crop/686x755/231,257/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213094"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272506",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Kids Juices",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 2,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "213094"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272585",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Coke",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "73259ff5-c88d-4fee-b938-47019e0e09ce",
                "name": "SOFT_DRINKS_3_.jpg",
                "modifiers": "-/crop/525x739/319,267/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272586",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Coke Zero",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "b1961db2-97e5-4b46-a041-4a5a5fb72d8e",
                "name": "SOFT_DRINKS.jpg",
                "modifiers": "-/crop/464x712/299,277/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272582",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Sprite",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "9aabb971-19c9-42b5-a7fd-bab80b868d58",
                "name": "SOFT_DRINKS_1_.jpg",
                "modifiers": "-/crop/575x760/284,277/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272595",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Lift",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272588",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Fanta",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "822f6a23-6f81-4e7a-a710-74470fe069eb",
                "name": "SOFT_DRINKS_2_.jpg",
                "modifiers": "-/crop/447x699/301,275/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272600",
              "menu_id": "sLRx8GKpR",
              "category_id": "SVKFjMCLZeS",
              "name": "Pink Lemonade",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272660
        },
        {
          "_id": "-4jnzGc2EjR",
          "menu_id": "sLRx8GKpR",
          "name": "Hot Beverages",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272619",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "English Breakfast Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "33a369cb-52c3-4e51-8166-78f0e863e2ee",
                "name": "TEA.jpg",
                "modifiers": "-/crop/913x719/82,257/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272622",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Green Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "459f6820-983a-4b5e-9204-69545bcdd5f2",
                "name": "TEA_1_.jpg",
                "modifiers": "-/crop/921x702/49,224/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272628",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Peppermint Tea",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "6a11e967-5c46-4b9c-b838-825b0813d0ee",
                "name": "TEA.jpg",
                "modifiers": "-/crop/914x719/79,260/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272621",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Flat White",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": {
                "_id": "5e064fb3-eb6d-41f8-beb0-615bb74473ed",
                "name": "Flat_white.jpg",
                "modifiers": "-/crop/603x565/246,308/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272617",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Cappuccino",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": {
                "_id": "2882c2fb-b15f-4555-b231-e84b19819f17",
                "name": "capuccino.jpg",
                "modifiers": "-/crop/588x568/269,277/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272624",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": {
                "_id": "ad6b46fc-15e3-4925-92fd-ecfedd7bb1e3",
                "name": "Latte.jpg",
                "modifiers": "-/crop/834x760/338,501/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272625",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Long Black",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": {
                "_id": "c031d841-8122-473a-9c08-d6f7b3c18a80",
                "name": "Long_Black.JPG",
                "modifiers": "-/crop/873x739/267,873/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272620",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Espresso",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272626",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Macchiato",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272629",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Piccolo",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 4.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272627",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Mocha",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "a127921e-a1b3-4c50-95b7-b2b7a8a5df9a",
                "name": "Hot_Chocolate_and_Mocha.jpg",
                "modifiers": "-/crop/729x706/285,434/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272618",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Chai Latte",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": null,
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "3272623",
              "menu_id": "sLRx8GKpR",
              "category_id": "-4jnzGc2EjR",
              "name": "Hot Chocolate",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 5.9,
              "tags": [],
              "image": {
                "_id": "a3dfc9d6-3182-4cd5-8f47-e8ee6e879a2b",
                "name": "Hot_Chocolate_and_Mocha.jpg",
                "modifiers": "-/crop/747x724/273,428/-/preview/"
              },
              "status": null,
              "ingredients": [],
              "option_sets": [
                "213100",
                "213102",
                "213103"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272661
        },
        {
          "_id": "TcbbaEpQMhf",
          "menu_id": "sLRx8GKpR",
          "name": "CW Online",
          "display_name": "",
          "description": "",
          "dishes": [
            {
              "type": "standard",
              "_id": "3272657",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Food",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272658",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Pizza",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272662",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Kids Meals",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272659",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Dessert ",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272660",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Drinks",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272661",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Coffee",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272663",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Receipt",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272664",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Fees",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272665",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Promos",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            },
            {
              "type": "standard",
              "_id": "3272666",
              "menu_id": "sLRx8GKpR",
              "category_id": "TcbbaEpQMhf",
              "name": "Online Tips",
              "display_name": "",
              "description": "",
              "subtitle": "",
              "price": 0,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [],
              "choices": [],
              "price_type": "standard"
            }
          ],
          "conditions": {
            "hide_unavailable_category": false,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272658
        }
      ],
      "abacus_sync": true,
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    },
    {
      "_id": "5IFfBkym3",
      "name": "DINE-IN ONLY MENU",
      "display_name": " ",
      "description": "",
      "conditions": {
        "hide_unavailable_menu": true,
        "services": [
          "dine_in"
        ],
        "times": [],
        "hours": [],
        "pre_order": {
          "enabled": false,
          "days_in_advance": 0,
          "cutoff_time": ""
        },
        "age_restricted": false
      },
      "categories": [
        {
          "_id": "PSCE-9WpG",
          "menu_id": "5IFfBkym3",
          "name": "Set Menus",
          "display_name": "",
          "description": "A 48-hour notice is required to increase or decrease the number of guests. There's a $10 cancellation fee per person and will be charged to the card. ",
          "dishes": [
            {
              "type": "standard",
              "_id": "r7ZA63s9K",
              "menu_id": "5IFfBkym3",
              "category_id": "PSCE-9WpG",
              "name": "Meat Platter Option 1",
              "display_name": "",
              "description": "Starter: Garlic Bread; Mains: New York Steaks, Chicken Fillet, Buffalo Chicken Wings; Sides: Chips, Corn Cobs, Garden Salad, Choice of 2 Sauces.",
              "subtitle": "Minimum 4 People ($69 per head)",
              "price": 69,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "oNHT_G7HC",
                "UuKEdp4Cc",
                "Djj-vaCnJ",
                "MhEz6JzKj"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            },
            {
              "type": "standard",
              "_id": "qlV6XdyAa",
              "menu_id": "5IFfBkym3",
              "category_id": "PSCE-9WpG",
              "name": "Seafood Platter - Option 2",
              "display_name": "",
              "description": "Starter: Garlic Bread; Mains: Atlantic Salmon, Salt & Pepper Squid, Crumbed Calamari Rings, Garlic Prawns, Marinated Octopus, Scallops; ; Sides: Chips, Onion Rings, Garden Salad, Tartare Sauce.",
              "subtitle": "Minimum 4 People ($69 per head)",
              "price": 69,
              "tags": [],
              "image": null,
              "status": "hidden",
              "ingredients": [],
              "option_sets": [
                "oNHT_G7HC",
                "Djj-vaCnJ",
                "MhEz6JzKj"
              ],
              "choices": [],
              "price_type": "standard",
              "taxes": []
            }
          ],
          "conditions": {
            "hide_unavailable_category": true,
            "services": [],
            "hours": []
          },
          "abacus_open_item": 3272657
        }
      ],
      "kounta": {
        "kounta_sync_export_enabled": false
      }
    }
  ]

export const western_option_sets = [
    {
      "_id": "217674",
      "name": "Tomahawk - Choose 1 side 1 sauce",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2073900",
          "name": "Chips Only",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073901",
          "name": "Salad Only",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073902",
          "name": "Mash Only",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073903",
          "name": "Veggies Only",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "217675",
      "name": "Choose Your Sauce: (1st Choice)",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2073911",
          "name": "Creamy Mushroom",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073912",
          "name": "Truffle sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073913",
          "name": "Hot Lava Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073914",
          "name": "Peppercorn",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073915",
          "name": "Gravy",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073916",
          "name": "Surf & Turf",
          "price": "18.90",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2073917",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "216423",
      "name": "Dessert Instructions:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2066335",
          "name": "Now",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2066336",
          "name": "On Hold",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2066337",
          "name": "Birthday",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "217678",
      "name": "Dessert Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2073929",
          "name": "Ice Cream Scoop",
          "price": "2.5",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212690",
      "name": "Meat Temp:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2053254",
          "name": "Blue",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043633",
          "name": "Rare",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043634",
          "name": "Medium Rare",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043635",
          "name": "Medium",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043636",
          "name": "Medium Well",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043637",
          "name": "Well Done",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "3Q8QIPjq7",
      "name": "Meat Temp: (2)",
      "display_name": "Meat Temp:",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "WzbBnjN_vH",
          "name": "Well Done",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "SJOQV5_Oa0",
          "name": "Medium Well Done",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "KxOrwZqyZt",
          "name": "Medium",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "8nLAcM5vO4",
          "name": "Medium Rare",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "esDbQ9YR-O",
          "name": "Rare",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "215312",
      "name": "Salmon Temp: ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2059090",
          "name": "Medium",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059092",
          "name": "Well Done",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212693",
      "name": "Choose Your Side:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043650",
          "name": "Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043651",
          "name": "Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043652",
          "name": "Mash",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043653",
          "name": "Veggies",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212698",
      "name": "Chicken - Choose 2 Sides:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2071779",
          "name": "Chips & Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2071780",
          "name": "Chips & Veggies",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2071781",
          "name": "Salad & Mash",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2071782",
          "name": "Veggies & Mash",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2072868",
          "name": "Chips & Mash",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2071783",
          "name": "Salad & Veggies",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043667",
          "name": "Chips Only",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043668",
          "name": "Salad Only",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043669",
          "name": "Mash Only",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043670",
          "name": "Veggies Only",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "215304",
      "name": "Kids- Choose 1 Juice: ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2059027",
          "name": "Apple Juice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059028",
          "name": "Orange Juice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059413",
          "name": "No Juice",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "215303",
      "name": "Kids- Choose 1 Side: ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2059023",
          "name": "Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059025",
          "name": "Mash",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059026",
          "name": "Veggies",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213447",
      "name": "Choose 1 Side (Gluten Free):",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2047991",
          "name": "Mash",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047993",
          "name": "Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047992",
          "name": "Veggies",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213448",
      "name": "Choose 1 Sauce (Gluten Free): ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2047997",
          "name": "Gravy",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047994",
          "name": "Hot Lave Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047995",
          "name": "Peppercorn",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047998",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212687",
      "name": "Garlic Bread - Add Ons:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043628",
          "name": "Add Mozzarella",
          "price": "6",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212688",
      "name": "Bruschetta Platter - Add Ons:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043629",
          "name": "Add Feta",
          "price": "4",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043630",
          "name": "Add Halloumi",
          "price": "4",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "215306",
      "name": "Choose Your Sauce for Chicken: ",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2059042",
          "name": "Creamy Mushroom",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059044",
          "name": "Hot Lava Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059045",
          "name": "Peppercorn",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059046",
          "name": "Gravy",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2059047",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212691",
      "name": "Choose Your Sauce:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043638",
          "name": "Chimichurri",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043641",
          "name": "Hot Lava Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043640",
          "name": "Truffle Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043639",
          "name": "Creamy Mushroom",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043642",
          "name": "Peppercorn",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043643",
          "name": "Gravy",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2055959",
          "name": "Surf & Turf",
          "price": "18.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047879",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212692",
      "name": "Choose 3 Sauces:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": 3,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043645",
          "name": "Creamy Mushroom",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043649",
          "name": "Gravy",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043647",
          "name": "Hot Lava Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043648",
          "name": "Peppercorn",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "IBDx6fN0u",
          "name": "Truffle sauce ",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047883",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212756",
      "name": "Ls Steak/Chicken Side Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2058958",
          "name": "Chips & Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043868",
          "name": "Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043869",
          "name": "Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212703",
      "name": "Sauce Choice (Lunch Special):",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2058334",
          "name": "Creamy Mushroom",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058335",
          "name": "Truffle sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058336",
          "name": "Hot Lava Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058337",
          "name": "Peppercorn",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058339",
          "name": "Gravy",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047885",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212696",
      "name": "Choice of Side - Ribs:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043663",
          "name": "Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043664",
          "name": "Mash",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212694",
      "name": "Extra Sides:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "mOIa6L-U3",
          "name": "Steakhouse Fries",
          "price": "7.9",
          "quantity": 0,
          "prices": {},
          "print_name": ""
        },
        {
          "_id": "2043656",
          "name": "Mash Potatoes",
          "price": "7.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043657",
          "name": "Steamed Veggies",
          "price": "7.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043658",
          "name": "Onion Rings",
          "price": "13.90",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043659",
          "name": "Garden Salad",
          "price": "8.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043660",
          "name": "Corn Cobs",
          "price": "8.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043661",
          "name": "Potato Wedges",
          "price": "8.9",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212697",
      "name": "Skewers - Sides Choice:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043666",
          "name": "Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043665",
          "name": "Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212699",
      "name": "Choice of Pasta:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043671",
          "name": "Fettuccini",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043672",
          "name": "Penne",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212700",
      "name": "Pasta - Add Ons:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043673",
          "name": "Add Chicken",
          "price": "6.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043674",
          "name": "Add Prawns",
          "price": "12.90",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212713",
      "name": "Caesar Salad Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043711",
          "name": "NO Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105423",
          "name": "Extra Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105424",
          "name": "Less Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105425",
          "name": "Side Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043706",
          "name": "NO Baby Cos Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043709",
          "name": "NO Boiled Egg",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043708",
          "name": "NO Croutons",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043710",
          "name": "NO Parmesan Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043707",
          "name": "NO Turkey Rashers",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212714",
      "name": "S&P Squid Salad Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043715",
          "name": "NO Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105426",
          "name": "Extra Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105427",
          "name": "Less Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105428",
          "name": "Side Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043713",
          "name": "NO Cherry Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043712",
          "name": "NO Mix Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047922",
          "name": "NO Shredded Carrots",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043714",
          "name": "NO Spanish Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212715",
      "name": "Schnitzel Salad Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043725",
          "name": "NO Seeded Mustard Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105429",
          "name": "Extra Seeded Mustard Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105431",
          "name": "Less Seeded Mustard Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105432",
          "name": "Side Seeded Mustard Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043720",
          "name": "NO Capsicum",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043718",
          "name": "NO Cherry Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043716",
          "name": "NO Cos Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043717",
          "name": "NO Cucumbers",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043722",
          "name": "NO Feta Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043721",
          "name": "NO Olives",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043719",
          "name": "NO Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043724",
          "name": "NO Oregano",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043723",
          "name": "NO Tostadas",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212716",
      "name": "Southwest Chicken Salad Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043733",
          "name": "NO Chipotle Crema",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105430",
          "name": "Extra Chipotle Crema",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105433",
          "name": "Less Chipotle Creme",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105434",
          "name": "Side Chipotle Crema",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043730",
          "name": "NO Corn Salsa",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043728",
          "name": "NO Baby Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043731",
          "name": "NO Crispy Tostadas",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043726",
          "name": "NO Feta",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043732",
          "name": "NO Fresh Avocado",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043727",
          "name": "NO Jalapenos",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043729",
          "name": "NO Rocket",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212689",
      "name": "Salads - Add Ons:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043631",
          "name": "Add Chicken",
          "price": "6.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043632",
          "name": "Add Prawns",
          "price": "12.9",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212701",
      "name": "Seafood - Add Ons:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043676",
          "name": "Add Prawns",
          "price": "12.90",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212702",
      "name": "Kids Pasta - Add Ons:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043677",
          "name": "Add Chicken",
          "price": "4.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043678",
          "name": "Add Prawns",
          "price": "9.9",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212717",
      "name": "Cheese Burger Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043739",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043740",
          "name": "NO Aioli Dip",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043753",
          "name": "Bunless",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043734",
          "name": "NO American Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043737",
          "name": "NO Eruption Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043735",
          "name": "NO Pickles",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043736",
          "name": "NO Spanish Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212718",
      "name": "Pulled Beef Burger Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105441",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105442",
          "name": "NO Aioli Dip",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043755",
          "name": "Bunless",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043743",
          "name": "NO American Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043741",
          "name": "NO Coleslaw",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043744",
          "name": "NO Frizzled Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043745",
          "name": "NO Smoky BBQ Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043742",
          "name": "NO Turkey Rashers",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212719",
      "name": "Steakhouse Sandwich Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105443",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105444",
          "name": "NO Aioli Dip",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043749",
          "name": "NO American Cheese Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043752",
          "name": "No sourdough bread ",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "pUETtxDge",
          "name": "No truffle butter ",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "lJl5HNzjD",
          "name": "No caramelized onions ",
          "price": "0",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "212721",
      "name": "Eruption Burger Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105439",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105440",
          "name": "NO Aioli Dip",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043756",
          "name": "Bunless",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043761",
          "name": "NO Caramelised Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043763",
          "name": "NO Eruption Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043757",
          "name": "NO Melted American Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043758",
          "name": "NO Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043762",
          "name": "NO Pickles",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043759",
          "name": "NO Tomato",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043760",
          "name": "NO Turkey Rashers",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212722",
      "name": "Buttermilk Chicken Burger Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105437",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105438",
          "name": "NO Aioli Dip",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043769",
          "name": "Bunless",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043768",
          "name": "NO American Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047942",
          "name": "NO Chilli Aioli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043766",
          "name": "NO Coleslaw",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043767",
          "name": "NO Garlic Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043765",
          "name": "NO Guacamole",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043764",
          "name": "NO Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212723",
      "name": "Signature Tenderlion Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043777",
          "name": "NO Frizzled Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043770",
          "name": "NO Grilled Turkey Rashers",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043774",
          "name": "NO Olives",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043771",
          "name": "NO Prawns",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043776",
          "name": "NO Roasted Cherry Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043773",
          "name": "NO Sauteed Mushrooms",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043775",
          "name": "NO Shallots",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043772",
          "name": "NO Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043778",
          "name": "No mash",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212724",
      "name": "Chicken Skewer Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043779",
          "name": "NO Garlic Dip",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105445",
          "name": "NO Pita Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212725",
      "name": "Lamb Skewer Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043780",
          "name": "NO Tzatziki Side",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105446",
          "name": "NO Pita Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212726",
      "name": "Chicken Parma Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043781",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043782",
          "name": "NO Napolitana Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212727",
      "name": "Chicken Avocado Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043784",
          "name": "NO Creamy Mushroom Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043783",
          "name": "NO Lime Avocado",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212729",
      "name": "Pollo De Gambari Chicken Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043787",
          "name": "NO Pink Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043786",
          "name": "NO Prawns",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212730",
      "name": "Creamy Chicken Fillet Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043788",
          "name": "NO Creamy Mushroom Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212731",
      "name": "Pinnacle Chicken Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043790",
          "name": "NO Sauteed Mushrooms",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043791",
          "name": "NO Sundried Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212732",
      "name": "Lamb Cutlets Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043794",
          "name": "NO Chermoula ",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212733",
      "name": "Medi Lamb Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043796",
          "name": "NO Bruschetta Mix",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043795",
          "name": "NO Halloumi",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043799",
          "name": "NO Parmesan",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043798",
          "name": "NO Rocket",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043797",
          "name": "NO Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212734",
      "name": "Boscaiola Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043800",
          "name": "NO Mushrooms",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043801",
          "name": "NO Shallots",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043802",
          "name": "NO Turkey Rashers",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212735",
      "name": "Truffle Pasta Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043804",
          "name": "NO Basil",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043805",
          "name": "Crispy Parmesan",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043803",
          "name": "NO Shiitake Mushrooms",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212736",
      "name": "Pinnacle Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043808",
          "name": "NO Baby Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043806",
          "name": "NO Sauteed Mushrooms",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043807",
          "name": "NO Sundried Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212737",
      "name": "Molten Lava Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043812",
          "name": "NO Chilli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043809",
          "name": "NO Chicken",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043810",
          "name": "NO Mushrooms",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043811",
          "name": "NO Olives",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212738",
      "name": "Angus Steak Pasta Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043813",
          "name": "NO Caramelised Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043815",
          "name": "NO Garlic",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043816",
          "name": "NO Rocket",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043814",
          "name": "NO Shallots",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212739",
      "name": "Spicy Prawns Pasta Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043818",
          "name": "No thyme",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "CN2zb8Zkt",
          "name": "No asparagus ",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "kFM-r6SBX",
          "name": "No chilli",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "dpYPl8hpx",
          "name": "No chicken stock",
          "price": "0",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "212740",
      "name": "Chicken Mushroom Risotto Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043823",
          "name": "NO Parmesan",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043824",
          "name": "NO Baby Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043822",
          "name": "No mushrooms ",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043819",
          "name": "NO Chicken",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212741",
      "name": "Barramundi Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043827",
          "name": "NO Dill Mayo",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "tDoKlW6e-",
          "name": "Chimichurri ",
          "price": "0",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "212743",
      "name": "Calamari Rings Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043833",
          "name": "NO Tartare Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043831",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043832",
          "name": "NO Garden Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212744",
      "name": "Atlantic Salmon Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043835",
          "name": "NO Dill Mayo",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043834",
          "name": "NO Asparagus",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043836",
          "name": "NO Mash",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213440",
      "name": "Seafood Platter Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2047967",
          "name": "No Atlantic salmon ",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047973",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047969",
          "name": "NO Crumbed Calamari Rings",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047970",
          "name": "NO Garlic Prawns",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047974",
          "name": "NO Garden Salad",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047971",
          "name": "NO Marinated Spicy Grilled Octopus",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047968",
          "name": "NO Salt & Pepper Squid",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047972",
          "name": "NO Scallops",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047975",
          "name": "NO Tartare Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212746",
      "name": "Kids Burger Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043841",
          "name": "NO Tomato Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043840",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043842",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212747",
      "name": "Kids Schnitzel Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043843",
          "name": "NO Gravy",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043844",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212748",
      "name": "Kids Chicken Nuggets Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043845",
          "name": "NO Hot Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212749",
      "name": "BBQ Chicken Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2047976",
          "name": "NO BBQ Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045859",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043847",
          "name": "NO Capsicum",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043846",
          "name": "NO Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212750",
      "name": "BBQ Meat Lovers Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2047977",
          "name": "NO BBQ Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045860",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043851",
          "name": "NO Ground Beef",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043848",
          "name": "NO Pepperoni",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043849",
          "name": "NO Salami",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043850",
          "name": "NO Smoked Beef",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212751",
      "name": "Peri Peri Chicken Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045862",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043852",
          "name": "NO Capsicum",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043854",
          "name": "NO Jalapenos",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043853",
          "name": "NO Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047978",
          "name": "NO Peri Peri Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212752",
      "name": "Angus Steak Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045863",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043858",
          "name": "NO Basil",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043855",
          "name": "NO Chermoula",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043857",
          "name": "NO Parmesan",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043856",
          "name": "NO Rocket",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212753",
      "name": "Cheesy Garlic Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045864",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043860",
          "name": "NO Fresh Parsley",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047979",
          "name": "NO Garlic",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043859",
          "name": "NO Olive Oil",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212758",
      "name": "L*Chicken Garden Salad Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043876",
          "name": "NO Aioli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043873",
          "name": "NO Capsicum",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043875",
          "name": "NO Cucumbers",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043871",
          "name": "NO Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043874",
          "name": "NO Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043872",
          "name": "NO Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212754",
      "name": "Margherita Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045865",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043861",
          "name": "NO Basil",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212759",
      "name": "L*S&P Squid Salad Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043880",
          "name": "NO Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043881",
          "name": "NO Capsicum",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043878",
          "name": "NO Cherry Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043877",
          "name": "NO Mix Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043879",
          "name": "NO Spanish Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212755",
      "name": "Vegetarian Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045866",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043863",
          "name": "NO Caramelised Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043865",
          "name": "NO Mushroom",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043867",
          "name": "NO Olives",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043866",
          "name": "NO Roasted Capsicum",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043862",
          "name": "NO Spinach",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213442",
      "name": "Garlic Prawn Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2047982",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2047983",
          "name": "NO Lemon",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212760",
      "name": "L*Steakhouse Sandwich Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043885",
          "name": "NO American Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043886",
          "name": "NO Caramelised Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043889",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043890",
          "name": "NO Rocket",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043888",
          "name": "No sourdough bread ",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2ATbyRMJi",
          "name": "No garlic mayo",
          "price": "0",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "213148",
      "name": "Pepperoni Pizza Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045861",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212761",
      "name": "L*Beef Burger Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043891",
          "name": "Bunless",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043897",
          "name": "NO Tomato Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043893",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043892",
          "name": "NO Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043896",
          "name": "NO Pickles",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043895",
          "name": "NO Spanish Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043894",
          "name": "NO Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "212762",
      "name": "L*Chicken Burger Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2043898",
          "name": "Bunless",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043902",
          "name": "NO Aioli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043899",
          "name": "NO Cheese",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043903",
          "name": "NO Chips",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043900",
          "name": "NO Lettuce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2043901",
          "name": "NO Tomatoes",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213092",
      "name": "Crushers:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045557",
          "name": "Ferrero Rocher",
          "price": "1",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213093",
      "name": "Smoothie Flavours:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045559",
          "name": "Banana",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045558",
          "name": "Mango",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213094",
      "name": "Juices Selection:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045560",
          "name": "Apple",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045561",
          "name": "Orange",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213100",
      "name": "Milk Options: (Hot Bevs)",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": 0,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045572",
          "name": "Full Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045576",
          "name": "Skim Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045577",
          "name": "Soy Milk",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045578",
          "name": "NO Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213101",
      "name": "Milk Options: (Cold Drinks)",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "max_options": 1,
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045580",
          "name": "Full Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045584",
          "name": "Skim Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045585",
          "name": "Soy Milk",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045586",
          "name": "NO Milk",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213102",
      "name": "Sugar Options:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045587",
          "name": "Sugar",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045588",
          "name": "1/2 Sugar",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045589",
          "name": "1/4 Sugar",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045590",
          "name": "Equal",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045591",
          "name": "1/2 Equal",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213103",
      "name": "Hot Bev Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045592",
          "name": "Weak",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045593",
          "name": "Extra Shot (Strong)",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045594",
          "name": "Very Hot",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045595",
          "name": "Warm",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045596",
          "name": "Decaf",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045597",
          "name": "3/4 Full",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045598",
          "name": "1/2 Full",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045599",
          "name": "1/2 Strength Coffee",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045600",
          "name": "1/4 Strength Coffee",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045601",
          "name": "1/2 Strength Syrup",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045602",
          "name": "Caramel (Syrup)",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045603",
          "name": "Hazelnut (Syrup)",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045604",
          "name": "Vanilla (Syrup)",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045605",
          "name": "Lemon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045606",
          "name": "Honey",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045607",
          "name": "Extra Chocolate",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045608",
          "name": "Extra Cinnamon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045609",
          "name": "Whipped Cream",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045610",
          "name": "Ice Cream",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045611",
          "name": "Keep Cup",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045612",
          "name": "NO Chocolate",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045613",
          "name": "NO Cinnamon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045614",
          "name": "NO Froth",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045615",
          "name": "NO Lid",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045616",
          "name": "Ice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045617",
          "name": "Tea Bag on Side",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045618",
          "name": "Half Water",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045619",
          "name": "H.M.O.S",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045620",
          "name": "C.M.O.S",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045621",
          "name": "H.W.O.S",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045622",
          "name": "C.W.O.S",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045623",
          "name": "See Server",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045624",
          "name": "With Food",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045625",
          "name": "Hold Coffee",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "213104",
      "name": "Cold Bev Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2045626",
          "name": "NO Ice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045627",
          "name": "Extra Ice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045628",
          "name": "Less Ice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045629",
          "name": "Tall Glass",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045630",
          "name": "Ice on Side",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045631",
          "name": "Short Glass",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045632",
          "name": "NO Glasses",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045633",
          "name": "Extra Glasses",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045634",
          "name": "Lemon Slice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045635",
          "name": "Lime Slice",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045636",
          "name": "NO Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2045637",
          "name": "NO Ice Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "215277",
      "name": "EXTRA Sauce Add On:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2058357",
          "name": "Chermoula",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058358",
          "name": "Creamy Mushroom",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058360",
          "name": "Hot Lava Sauce",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058361",
          "name": "Peppercorn",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058362",
          "name": "Gravy",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058428",
          "name": "Aioli",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058429",
          "name": "Aioli Chilli",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058430",
          "name": "BBQ ",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058431",
          "name": "Tartare",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058432",
          "name": "English Mustard",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058433",
          "name": "Lime Aioli",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2066345",
          "name": "Rib Sauce",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058434",
          "name": "Sour Cream",
          "price": "2.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058435",
          "name": "Sweet Chilli",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058436",
          "name": "Tomato",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058437",
          "name": "Tzatziki",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058438",
          "name": "Seeded Mustard",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2070130",
          "name": "Surf & Turf",
          "price": "14.9",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "221357",
      "name": "Bruschetta Platter Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105385",
          "name": "NO Basil",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105387",
          "name": "NO Tarragon",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105386",
          "name": "NO Onions",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105384",
          "name": "NO Tomato",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "221358",
      "name": "Mozzarella Sticks Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105388",
          "name": "NO BBQ Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "221359",
      "name": "Buffalo Chicken Wings Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105389",
          "name": "NO Lava Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105390",
          "name": "NO Aioli",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "221360",
      "name": "Chilli Prawns Hot Pot Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105392",
          "name": "NO Chilli",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105391",
          "name": "NO Olives",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105393",
          "name": "NO Garlic Bread",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "221361",
      "name": "Nachos Fantasia Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105396",
          "name": "NO Guacamole",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105394",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105397",
          "name": "NO Salsa",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2105395",
          "name": "NO Sour Cream",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "221362",
      "name": "Salt & Pepper Squid Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105398",
          "name": "NO Salsa",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "221363",
      "name": "Sweet Potato Fries Mods:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2105399",
          "name": "NO Sauce",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "215272",
      "name": "General:",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": false,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "2058316",
          "name": "Add On",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058317",
          "name": "Take Away",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058318",
          "name": "Entree",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058319",
          "name": "S.O.S",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058320",
          "name": "Butterfly",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058321",
          "name": "ON CALL",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058322",
          "name": "Chips Seasoning",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058326",
          "name": "Gluten Free",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058370",
          "name": "Turkish Bread",
          "price": "4",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058371",
          "name": "Pita Bread",
          "price": "4",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058372",
          "name": "American Cheese",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058373",
          "name": "Haloumi Cheese",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058374",
          "name": "Parmesan Cheese",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058375",
          "name": "Feta Cheese",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058376",
          "name": "Olive Oil",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058377",
          "name": "Pesto",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058378",
          "name": "Avocado",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058379",
          "name": "Broccoli",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058380",
          "name": "Capsicum",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058381",
          "name": "Coleslaw",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058382",
          "name": "Carrot",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058383",
          "name": "Cherry Tomato",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058384",
          "name": "Croutons",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058385",
          "name": "Cucumber",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058386",
          "name": "Eggs",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058387",
          "name": "Eggplant",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058388",
          "name": "Guacamole",
          "price": "3",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058389",
          "name": "Jalapenos",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058390",
          "name": "Lettuce",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058391",
          "name": "Mushrooms",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058392",
          "name": "Olives",
          "price": "2",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058393",
          "name": "Onions",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058395",
          "name": "Pickles",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058396",
          "name": "Rocket",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058398",
          "name": "Spinach",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058399",
          "name": "Tomato",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058400",
          "name": "Zucchini",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058401",
          "name": "Calamari",
          "price": "7",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058402",
          "name": "Octopus",
          "price": "7",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058403",
          "name": "Prawns",
          "price": "11.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058404",
          "name": "Scallops",
          "price": "4.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058405",
          "name": "Squid",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058406",
          "name": "Kids Extra Chicken",
          "price": "4.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058407",
          "name": "Kids Extra Prawn",
          "price": "9.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058408",
          "name": "Salad Dressing",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058409",
          "name": "Steak Butter",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058410",
          "name": "Sauce Aioli",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058411",
          "name": "Sauce Aioli Chilli",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058412",
          "name": "Sauce BBQ",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058413",
          "name": "Sauce Tartare",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058414",
          "name": "Sauce English Mustard",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058415",
          "name": "Sauce Lime Aioli",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058416",
          "name": "Sauce Sour Cream",
          "price": "2.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058417",
          "name": "Sauce Sweet Chilli",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058418",
          "name": "Sauce Tomato",
          "price": "0",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058419",
          "name": "Sauce Tzatziki",
          "price": "2.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058420",
          "name": "Seeded Mustard",
          "price": "1",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058422",
          "name": "Shallots",
          "price": "0.5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058423",
          "name": "Crumbed Schnitzel",
          "price": "9.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058424",
          "name": "Chicken",
          "price": "6.9",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058425",
          "name": "Beef Paddy",
          "price": "5",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058426",
          "name": "Steak Turkey Rashes",
          "price": "3",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "2058427",
          "name": "Chilli",
          "price": "0",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "oNHT_G7HC",
      "name": "Set Menus - Add Person",
      "display_name": "Additional Person ",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": true,
        "max_options": "",
        "min_options": 4,
        "free_amount": 1
      },
      "options": [
        {
          "_id": "2KJyaC41eO",
          "name": "Add Adult",
          "price": "69",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "VwN08l5ZL",
          "name": "Add Kid (12yrs & below)",
          "price": "29.5",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "UuKEdp4Cc",
      "name": "Set Menus - Sauce Options",
      "display_name": "Sauce Options",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": true,
        "quantity_select": true,
        "max_options": 2,
        "min_options": 2,
        "free_amount": ""
      },
      "options": [
        {
          "_id": "8qSnGcuECP",
          "name": "Creamy Mushroom Sauce",
          "price": "",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "arAdUsctOJ",
          "name": "Diane Sauce",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "u52k53Bwj",
          "name": "Hot Lava Sauce",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "lMvAtM5ped",
          "name": "Peppercorn",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "LpclXwFm24",
          "name": "Gravy Mushroom",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "j5jcWf26w",
          "name": "Gravy",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "Djj-vaCnJ",
      "name": "Set Menus - Add Soft Drinks",
      "display_name": "Add Soft Drinks",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "ER_ds9zG70",
          "name": "Coke",
          "price": "4",
          "quantity": 0,
          "status": null
        },
        {
          "_id": "9Cjbw7IhPS",
          "name": "Coke Zero",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "BB7Xko-gMD",
          "name": "Lift",
          "price": "4",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "b2oQhst8uc",
          "name": "Fanta",
          "price": "4",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "MhEz6JzKj",
      "name": "Set Menus - Add Nutella",
      "display_name": "Add On",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": true,
        "quantity_select": true,
        "max_options": "",
        "min_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "nyuLd9sM4H",
          "name": "Nutella Brownie",
          "price": "10",
          "quantity": 0,
          "status": null
        }
      ]
    },
    {
      "_id": "lwai6jDm0",
      "name": "Mocktail Jug Flavour",
      "display_name": "Mocktail Selections",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "-Bh_79Qdz",
          "name": " Blue Lagoon",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "eSsnRu5vtw",
          "name": " Croatian Bomb",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "APiMnopEH",
          "name": " Dark Knight",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "8CZ7NU4KVE",
          "name": " Strawberry Mojito",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "qlphm8lWqy",
          "name": " Watermelon Mango",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "SD8I6qom7W",
          "name": " Watermelon Mojito",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "sZHMHPEtuU",
          "name": " Lychee Tease",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "-DUEjSH2M",
          "name": " Pink Panther",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "P8FRO9hoht",
          "name": " Pinacolada",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "6rrRrd7eu",
          "name": " Virgin Mojito",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "pvpnHhKEp",
      "name": "Soft Drinks Tower Size",
      "display_name": "Size",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "sViF4nHSn",
          "name": "2L Tower",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "gVOKvHBSCx",
          "name": "4L Tower",
          "price": "22",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "3ACOOXoy6",
      "name": "Soft Drink Towers Selections",
      "display_name": "Soft Drinks Selections",
      "show_in_menu": false,
      "conditions": {
        "required": true,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "QXpXpBCkQ",
          "name": "Coke",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "s7_E0thGqm",
          "name": "Coke Zero",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "TPGLjQDo1g",
          "name": "Lift",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "kK5i1tS5x",
          "name": "Fanta",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "gQLltdLUbY",
          "name": "Sprite",
          "price": "",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "8FRU3VEZzl",
          "name": "Pink Lemonade",
          "price": "",
          "quantity": 0,
          "prices": {}
        }
      ]
    },
    {
      "_id": "szz_Xseml",
      "name": "Lamb side",
      "display_name": "",
      "show_in_menu": false,
      "conditions": {
        "required": false,
        "multi_select": false,
        "quantity_select": false,
        "min_options": "",
        "max_options": "",
        "free_amount": ""
      },
      "options": [
        {
          "_id": "AZB_OaF55",
          "name": "Chips",
          "price": "0",
          "quantity": 0,
          "prices": {}
        },
        {
          "_id": "xtGsSPSFJ",
          "name": "salad",
          "price": "0",
          "quantity": 0,
          "prices": {}
        }
      ]
    }
  ];


function updateIds(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => updateIds(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        if (key === '_id' && isInsideImage(obj)) {
          obj[key] = shortid.generate();
        } else {
          updateIds(obj[key]);
        }
      });
    }
  
    return obj;
  }
  
function isInsideImage(parentObject: any) {
    return parentObject.hasOwnProperty('image') && parentObject.image && typeof parentObject.image === 'object';
  }
export const createWesternMenu = () => {
    return {
        western_menu:updateIds(western_menu), western_option_sets:updateIds(western_option_sets)
    }
}

