type EmailStore =
	T.Schema.Restaurant.RestaurantSchema["settings"]["notifications"]["email"]["store"];

export const getOptionalOverviewReportEmail = (emailStore: EmailStore) => {

	const supportReplyTo = emailStore.support_reply_to;
	const replyToAddress = supportReplyTo ? [supportReplyTo] : [];
	const otherAddresses = emailStore.addresses || [];

	return [...replyToAddress, ...otherAddresses];
}