import React from 'react';
import {
  Tag,
  ItemListContent,
  ItemListItem,
  ItemListWrapper,
  Tooltip,
  Button,
} from '@lib/components';
import { GenericPromo } from '../type';
import { FaCopy, FaTrashAlt } from 'react-icons/fa';

export const PromoList = (props: {
  promos: GenericPromo[];
  onCopy: (e: any, promo: GenericPromo) => void;
  onRemove: (e: any, promo: GenericPromo) => void;
  onActivate: (promo: GenericPromo) => void;
}) => (
  <div className="m-t-4">
    <ItemListWrapper>
      <ItemListContent className="border-white-10">
        {props.promos.map((promo, i) => (
          <ItemListItem key={promo._id} onClick={() => props.onActivate(promo)}>
            <div className="p-lr-4 p-tb-2 flex-line centered">
              <p className="big font-semi-bold">{promo.name}</p>
              <Tag
                onClick={e => e.stopPropagation()}
                className="cursor-text m-l-3 p-lr-2"
              >
                {promo.code}
              </Tag>
            </div>
            <div>
              <Tooltip
                text="Delete"
                width={65}
                position="top"
                disable={i !== 0}
              >
                <Button
                  type="button"
                  color="white"
                  className="no-round no-border no-shadow"
                  paddinglr={10}
                  onClick={e => props.onRemove(e, promo)}
                >
                  <FaTrashAlt />
                </Button>
              </Tooltip>
              <Tooltip text="Copy" width={65} position="top" disable={i !== 0}>
                <Button
                  type="button"
                  color="white"
                  className="no-round no-border no-shadow"
                  paddinglr={10}
                  onClick={e => props.onCopy(e, promo)}
                >
                  <FaCopy />
                </Button>
              </Tooltip>
            </div>
          </ItemListItem>
        ))}
      </ItemListContent>
    </ItemListWrapper>
  </div>
);
