import * as React from "react";
import * as styledComponents from "styled-components";

const {
	default: styled,
	css,
	keyframes,
	ThemeProvider,
	withTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<T.Core.BaseTheme.ThemeInterface>;

export { styled, css, keyframes, ThemeProvider, withTheme };
export default styled;
