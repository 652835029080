export const MENU_ACTIONS = {
  PAUSE: 'pause',
  EDIT: 'edit',
  DELETE: 'delete',
  COPY: 'copy',
};

export const MENU_COMPONENTS = {
  MENU: 'menu',
  CATEGORY: 'category',
  ITEM: 'item',
};

export const MENU_ITEM_TYPE = {
  MENU: "MENU",
  CATEGORY: "CATEGORY",
  DISH: "DISH"
};
