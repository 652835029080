import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { Button } from "@lib/components";
import { Modal } from "@lib/components";
import { ModalTitle, ModalContent } from "@lib/components";
import { FormGroup } from "@lib/components";

interface Props { }
interface State {
	active: boolean;
}

@inject("store") @observer
export class RestaurantMenuUploadService extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			active: false,
		};
	}

	render() {
		const { restaurant } = this.injected.store;
		const { isPaidRestaurant, initChargebeeSession } = this.injected.store.billing;
		const { active } = this.state;
		return (
			<div className="inline-block">
				<Button size="xxs" color="white" onClick={() => this.setState({ active: true })}>
					Upload Service
				</Button>
				<Modal
					active={active}
					width={440}
					close={() => this.setState({ active: false })}>
					<ModalTitle paddingtb={30} paddinglr={30} className="round-top-sm">
						<h3 className="text-center flex-grow">Menu Upload Service</h3>
					</ModalTitle>
					<ModalContent paddingtb={30} paddinglr={30}>
						<FormGroup contentClassName="child-mb-15">
							<p className="lhp big">Need a hand uploading your menu? Simply complete the upload request form linked below.</p>
							{!isPaidRestaurant && (
								<>
									<p className="lhp big">As a free user, we charge <span className="font-semi-bold">$7 per hour</span> for our upload service. <span className="underline">Upgrading to a paid plan will allow you to access this service for <span className="font-semi-bold">FREE</span></span></p>
									<p className="lhp big">We will process your request within 48-72 hours, notify you upon completion and bill you automatically. The average menu costs between $7 to $14 to upload.</p>
									<p className="lhp big">Please ensure you have added a payment method to your account before completing the upload form as we won't start without it.</p>
								</>
							)}
							{isPaidRestaurant && (
								<>
									<p className="lhp big">As a paid customer, you can utilize our menu upload service for <span className="font-semi-bold underline">FREE</span>. We recommend you take advantage of this to help you get setup quickly.</p>
									<p className="lhp big">We will process your request within 48-72 hours and notify you upon completion.</p>
								</>
							)}
						</FormGroup>
						<FormGroup contentClassName="child-mb-15">
							{!isPaidRestaurant && (
								<Button color="primary-inverse" full={true} onClick={() => initChargebeeSession(restaurant!._id)}>
									Update Payment Method & Subscription
								</Button>
							)}
							<Button
								color="primary-inverse"
								full={true}
								onClick={() => {
									window.open(`https://airtable.com/shrTnLexLfBva8wJO?prefill_Restaurant+ID=${restaurant!._id}&prefill_Restaurant+Subdomain=${restaurant!.subdomain}`);
								}}>
								Go To Upload Form
							</Button>
						</FormGroup>
					</ModalContent>
				</Modal>
			</div>
		);
	}

}
