import React from 'react';
import { PromoType } from '../type';
import { promosByType } from '../util';
import { RotateLoader, Modal, ModalContent, Button } from '@lib/components';

export const PromoCopyModal = (props: {
  restaurant: T.Schema.Restaurant.RestaurantSchema;
  type: PromoType;
  copyId: string | null;
  copyLoading: boolean;
  onClose: () => void;
  onCopy: (copyId: string | null, type: PromoType) => void;
  onCancel: () => void;
}) => {
  const { type, copyId, copyLoading, onClose, onCopy, onCancel } = props;
  const promos = promosByType(props.restaurant, type);
  const promo = promos.find(promo => promo._id === copyId);
  if (!promo) return null;
  return (
    <Modal
      width="sm"
      close={onClose}
      closeButton={false}
      active={copyId !== null}
    >
      <ModalContent className="flex-l-r-center">
        <h4 className="">Copy Promo</h4>
        <p className="big underline">{promo.name}</p>
      </ModalContent>
      <ModalContent className="flex-right">
        <Button
          type="button"
          className="m-r-2 width100 max100px"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className="width100 max100px"
          color="primary"
          onClick={() => onCopy(copyId, type)}
        >
          {copyLoading && <RotateLoader size={2} color="white" />}
          {!copyLoading && 'Copy'}
        </Button>
      </ModalContent>
    </Modal>
  );
};
