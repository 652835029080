import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Input } from "@lib/components";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { Tooltip } from "@lib/components"

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["seo"];

@inject("store") @observer
export class SettingsFormSEO extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = { error: null };
		const r = this.injected.store.restaurant!;
		this.initialValues = r.website.seo;
	}

	render() {

		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.website.seo = values;
					const update = { $set: { "website.seo": r.website.seo } };
					return { r, update };
				}}
				initialValues={this.initialValues}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">
							<FastField
								name="title"
								render={({ field }: any) => (
										<FormGroup
											optional={true}
											title="Store Page Title"
											help="The document title for your main store page">
											<Input title="Google typically displays the first 50-60 characters" {...field} />
										</FormGroup>
								)}
							/>

							<FastField
								name="meta"
								render={({ field }: any) => (
										<FormGroup
											optional={true}
											title="Store Page Meta"
											help="The page description / meta description for your main store page">
											<Input title="Google typically displays the first 50-60 characters" {...field} />
										</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);

	}

}
