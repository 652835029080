import * as React from "react";
import { FastField } from "formik";
import { Checkbox, FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { TabSelect } from "@lib/components";
import { Input } from "@lib/components";
import { Switch } from "@lib/components";
import { ServiceOrderTimeFields, ServiceAutoStatusFields, ServiceHoursField, CustomCheckoutField } from "./base";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { updateRestaurantNotificationOptions } from "../../../../../../../core/libs/receipt";
import { RestaurantUtils } from "@lib/common";

interface Props { }
interface State {
	tab: string;
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["pickup"];

@inject("store") @observer
export class SettingsFormServicesPickup extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
		};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.services.pickup;
	}
	
	render() {
	const { tab } = this.state;
    const customer_info = [
      { key: 'name', name: 'Name' },
      { key: 'phone', name: 'Phone' },
      { key: 'email', name: 'E-mail' },
      ];
		return (
			<div>
				<TabSelect
					id="accounts-tab-options"
					className="border-white-tb-10"
					hasBorder={true}
					screenWidth={this.injected.store.view.screen_width}
					onChange={(v) => this.setState({ tab: v.value })}
					value={tab}
					values={[
						{ label: "General", value: "0" },
						{ label: "Order Timing", value: "1" },
						{ label: "Available Hours", value: "2" },
						{ label: "Wait Times & Auto Statuses", value: "3" },
						{ label: "Custom Checkout Fields", value: "4" },
						{ label: "Customer Info", value: "5" },
					]} />
				<RestaurantForm<FormValues>
					submit={async (r, values) => {
						r.settings.services.pickup = values;
						const update = { $set: { "settings.services.pickup": r.settings.services.pickup } };
						return { r, update };
					}}
					initialValues={this.initialValues}
					onSuccess={async () => await updateRestaurantNotificationOptions(this.injected.store)}
					onError={() => UI.notification.error("An error occurred")}
					onSuccessMessage="Settings Updated"
				>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue } = form;
						return (
							<div className="p-4">
								<FormGroup>
									{tab === "0" && (
										<div>
											<FastField
												name="enabled"
												render={({ field }: any) => (
													<FormGroup
														title="Enabled"
														help="Allow customers to place orders that can be collected at your store location"
													>
														<Switch
															id="enable-pickup-switch"
															checked={
																field.value
															}
															onChange={(e) =>
																setFieldValue(
																	"enabled",
																	e.target
																		.checked
																)
															}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="display_name"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Display Name"
														help="Override the service name. This will override the translation as well. Defaults to Pickup."
													>
														<Input
															type="text"
															{...field}
														/>
													</FormGroup>
												)}
											/>
											<FastField
												name="notes"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Pickup Notes"
														help="This will be shown to customers if they choose this order method"
													>
														<Input
															type="text"
															{...field}
														/>
													</FormGroup>
												)}
											/>
										</div>
									)}
									{tab === "1" && (
										<ServiceOrderTimeFields
											service="pickup"
											form={form}
										/>
									)}
									{tab === "2" && (
										<ServiceHoursField
											service="pickup"
											error={(field) =>
												getFieldError(form, field) as
													| string
													| null
													| undefined
											}
										/>
									)}
									{tab === "3" && (
										<ServiceAutoStatusFields
											service="pickup"
											values={form.values}
										/>
									)}
									{tab === "4" && (
										<CustomCheckoutField form={form} />
									)}
									{tab === '5' && (
										<div>
										<FastField
											name="customer.required_info"
											render={({ field }: any) => (
											<FormGroup title="Required Customer Info">
												{customer_info.map((v, i) => (
												<Checkbox
													key={i}
													id={i + v.key}
													checked={
													(field.value || RestaurantUtils.services.defaultCustomerInfo()).indexOf(v.key) !==
													-1
													}
													label={v.name}
													className="m-t-3"
													onChange={() => {
													const value = field.value
														? [...field.value]
														: RestaurantUtils.services.defaultCustomerInfo();
													const index = value.indexOf(v.key);
													if (index === -1) value.push(v.key);
													else value.splice(index, 1);
													setFieldValue('customer.required_info', value);
													}}
												/>
												))}
												<p className="lhp m-t-2">
												You may choose which field will be required when placing orders. Please be aware that some
												or all fields may be required to process certain payment methods. Unchecking the info
												field means it will show as `Optional` on the checkout form.
												</p>
											</FormGroup>
											)}
										/>
										</div>
                  					)}
								</FormGroup>

								{error && <FormGroup error={error} />}

								<Button
									full={true}
									color="primary"
									type="submit"
									disabled={isSubmitting}
								>
									{isSubmitting && (
										<RotateLoader size={2} color="white" />
									)}
									{!isSubmitting && "Save"}
								</Button>
							</div>
						);
					}}
				</RestaurantForm>
			</div>
		);

	}
}
