import * as React from 'react';
import { WithTranslation } from 'react-i18next';
import { RootStore } from '../store';
import cloneDeep from 'lodash/cloneDeep';
import { UI } from '../../core/ui/index';
import { logger } from '@lib/common';

export interface InjectedProps extends WithTranslation {
  theme: T.Core.BaseTheme.ThemeInterface;
  store: RootStore;
}
interface SaveRestaurantOpts {
  before?: () => void;
  process: (r: T.Schema.Restaurant.RestaurantSchema) => { update: T.ObjectAny };
  onFail?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  successMsg: string;
  errorMsg?: string;
  shouldUpdateStore?: boolean;
}
interface SaveRestaurantSilentOpts {
  update: T.ObjectAny;
  backup: T.Schema.Restaurant.RestaurantSchema;
  before: () => void;
  onFail: () => void;
  onSuccess: () => void;
  onError: () => void;
  successMsg?: string;
  errorMsg?: string;
}

interface SaveWebsiteOpts {
  before: () => void;
  process: (w: T.Schema.Website.WebsiteSchema) => { update: T.ObjectAny };
  onFail: () => void;
  onSuccess: () => void;
  onError: () => void;
  successMsg: string;
}

export class MobxComponent<Props = {}, State = {}> extends React.Component<
  Props,
  State
> {
  get injected() {
    return this.props as InjectedProps & Props;
  }

  async saveRestaurant(opts: SaveRestaurantOpts) {
    try {
      if (opts.before) opts.before();
      const r = cloneDeep(this.injected.store.restaurant!);
      const _id = r._id;
      const { update } = opts.process(r);
      const res = await this.injected.store.api.restaurant_update({
        _id,
        update,
      });
      if (res.outcome) {
        UI.notification.error(res.message);
        if (opts.onFail) opts.onFail();
      } else {
        UI.notification.success(opts.successMsg);
        this.injected.store.setRestaurant(r);
        if (opts.onSuccess) opts.onSuccess();
        if (opts.shouldUpdateStore) {
          this.injected.store.updateRestaurant(update.$set || {});
        }
      }
    } catch (e) {
      logger.captureException(e);
      UI.notification.error(
        opts.errorMsg || 'An error occurred, try again or contact us'
      );
      if (opts.onError) opts.onError();
    }
  }

  async saveWebsite(opts: SaveWebsiteOpts) {
    try {
      opts.before();
      const w = cloneDeep(this.injected.store.website!);
      const _id = w._id;
      const { update } = opts.process(w);
      const res = await this.injected.store.api.website_update({ _id, update });
      if (res.outcome) {
        UI.notification.error(res.message);
        opts.onFail();
      } else {
        UI.notification.success(opts.successMsg);
        this.injected.store.setWebsite(w);
        opts.onSuccess();
      }
    } catch (e) {
      logger.captureException(e);
      UI.notification.error('An error occurred, try again or contact us');
      opts.onError();
    }
  }

  async saveRestaurantSilent(opts: SaveRestaurantSilentOpts) {
    opts.before();
    try {
      const _id = this.injected.store.restaurant!._id;
      const res = await this.injected.store.api.restaurant_update({
        _id,
        update: opts.update,
      });
      if (res.outcome) {
        UI.notification.error(res.message);
        this.injected.store.setRestaurant(opts.backup);
        opts.onFail();
      } else {
        if (opts.successMsg) {
          UI.notification.success(opts.successMsg);
        }
        opts.onSuccess();
      }
    } catch (e) {
      logger.captureException(e);
      UI.notification.error(
        opts.errorMsg || 'An error occurred, try again or contact us'
      );
      this.injected.store.setRestaurant(opts.backup);
      opts.onError();
    }
  }
}
