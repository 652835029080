import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { Switch } from "@lib/components";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["mobiles"];

@inject("store") @observer
export class SettingsFormDesignMobile extends MobxComponent<Props, State> {
	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.state = {};
		this.initialValues = r.website.sections.mobiles;
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.website.sections.mobiles = values;
					const update = { $set: { "website.sections.mobiles": values } };
					return { r, update };
				}}
				initialValues={this.initialValues}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FastField
								name="mobile_fullscreen"
								render={({ field }: any) => (
									<FormGroup
										title="Full screen checkout"
										help="When enable, the mobile version of the ordering page will display the cart and checkout pages as full screen.">
										<Switch
											id="mobile_fullscreen"
											checked={field.value || false}
											onChange={(e) => setFieldValue("mobile_fullscreen", e.target.checked)} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
