import * as React from "react";
import { FastField, Form } from "formik";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { OrganizationForm } from "../../../../../mobx/components/organization-form";
import { UI } from "../../../../../core/ui";
import { FormGroup, Input, Checkbox, Button, RotateLoader, SelectAdv } from "@lib/components";
import {
  BusinessContainer,
  NameContainer,
  NameInput,
  AddressContainer,
  TwoColumn } from "../../../restaurant/views/settings/forms/system/billing-info";
import { DataCountries } from "@lib/common";

interface Props { }
interface State { }

type FormValues = T.Schema.Organisation.OrganisationSchema['billing_info']

@inject("store") @observer
export class DashboardOrganization  extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const o = this.injected.store.organisation!;
    console.log('o', o)
    this.initialValues = o?.billing_info;
  }
  render() {
    return (
      <OrganizationForm<FormValues>
        submit={async (o, values) => {
          o.billing_info = values;
          const update = { $set: { "billing_info": values } };
          return { o, update };
        }}
        validators={{}}
        onErrorMessage="Something went wrong"
        initialValues={this.initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Organization Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
              <h2 className="m-b-4">Organization</h2>
              <div className="p-4 bg-white">

                <FormGroup title="Business" no_border />
                <BusinessContainer>
                  <NameInput className="name-input-100">
                    <label>Business Name</label>
                    <FastField
                      name="business_name"
                      render={({ field }: any) => (
                        <Input type="text" required  {...field}/> )} />
                  </NameInput>
                </BusinessContainer>
                <FormGroup title="Director Details" no_border />
                <NameContainer>
                  <NameInput>
                    <label>First Name</label>
                    <FastField
                      name="first_name"
                      render={({ field }: any) => (
                        <Input type="text" required  {...field}/> )} />
                  </NameInput>
                  <NameInput>
                    <label>Middle Name</label>
                    <FastField
                      name="middle_name"
                      render={({ field }: any) => (
                        <Input type="text"   {...field}/> )} />
                  </NameInput>
                  <NameInput>
                    <label>Last Name</label>
                    <FastField
                      name="last_name"
                      render={({ field }: any) => (
                        <Input type="text" required  {...field}/> )} />
                  </NameInput>
                </NameContainer>
                <NameContainer>
                  <NameInput className="name-input-100">
                    <label>Phone Number</label>
                    <FastField
                      name="phone_number"
                      render={({ field }: any) => (
                        <Input type="text" required  {...field}/> )} />
                  </NameInput>

                  <NameInput className="name-input-100">
                    <label>Email</label>
                    <FastField
                      name="email"
                      render={({ field }: any) => (
                        <Input type="email" required  {...field}/> )} />
                  </NameInput>
                </NameContainer>
                <FastField
                      name="abn"
                      render={({ field }: any) => (
                        <FormGroup className="no-border m-0">
                          <Input placeholder="ABN/Business Registration Number/VAT/ Tax ID" required type="text" {...field} value={field.value || ''} />
                        </FormGroup>
                      )}
                    />
                <FormGroup title="Address" no_border />
                <AddressContainer>
                  <NameInput className="name-input-100">
                    <label>Street 1</label>
                    <FastField
                      name="street1"
                      render={({ field }: any) => (
                        <Input type="text" required  {...field}/> )} />
                  </NameInput>
                  <NameInput className="name-input-100">
                    <label>Street 2</label>
                    <FastField
                      name="street2"
                      render={({ field }: any) => (
                        <Input type="text"   {...field}/> )} />
                  </NameInput>
                  <TwoColumn>
                    <NameInput className="name-input-100">
                      <label>City</label>
                      <FastField
                        name="city"
                        render={({ field }: any) => (
                          <Input type="text" required  {...field}/> )} />
                    </NameInput>
                    <NameInput className="name-input-100">
                      <label>Zip Code</label>
                      <FastField
                        name="zip_code"
                        render={({ field }: any) => (
                          <Input type="text" required  {...field}/> )} />
                    </NameInput>
                  </TwoColumn>
                  <TwoColumn>
                    <NameInput className="name-input-100">
                      <label>State</label>
                      <FastField
                        name="state"
                        render={({ field }: any) => (
                          <Input type="text" required  {...field}/> )} />
                    </NameInput>
                    <NameInput className="name-input-100">
                      <label>Country</label>
                      <FastField
                        name="country"
                        render={({ field }: any) => (
                          <SelectAdv
                            type="single"
                            value={field.value}
                            onChange={(v: string) => setFieldValue("country", v)}
                            options={DataCountries.map(({ label }) => ({ label, value: label }))} />

                        )}/>
                    </NameInput>
                  </TwoColumn>
                </AddressContainer>

                <FormGroup no_border help="">

                </FormGroup>
                <FormGroup no_border>
                  <FastField
                    name="agree"
                    render={({ field }: any) => (
                      <>
                        <Checkbox
                          required
                          id="agree"
                          checked={field.value}
                          label={(     <span>Agree to <a href="https://www.cloudwaitress.com/privacy/" target="_blank" style={{color: '#0064C8', textDecoration: 'underline'}}>Data Privacy Policy</a>, <a href="https://www.cloudwaitress.com/terms/" target="_blank" style={{color: '#0064C8', textDecoration: 'underline'}}>Terms and Condition</a></span>)}
                          onChange={(e) => setFieldValue("agree", e.target.checked)} />

                      </>
                    )}
                  />
                </FormGroup>

                {error && <FormGroup error={error} />}

                <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && "Save"}
                </Button>

              </div>
            </>
          );
        }}
      </OrganizationForm>
    )
  }
}
